export type DropFilterOptions = {
  league?: string | null
  sponsor?: string | null
  search: string | null
}

export enum DropStrategy {
  Capped = 'Capped',
  CappedOnly = 'CappedOnly',
  Default = 'Default',
}

export type MetaDTO = {
  dropStrategy?: DropStrategy
  dropFilterOptions?: DropFilterOptions
  editGroupID?: string | null
}
