import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AccessDenied } from '../../components/AccessDenied/AccessDenied'
import { RootState } from '../../store/Store.type'

const useAccessErrorHandling = (): [boolean, JSX.Element] => {
  const accessErrors = useSelector((state: RootState) => state.accessErrors)
  const [accessDenied, setAccessDenied] = useState<boolean>(false)

  useEffect(() => {
    if (accessErrors && Object.keys(accessErrors).length > 0) {
      setAccessDenied(true)
    }
  }, [accessErrors])

  const component = accessDenied ? <AccessDenied/> : <></>
  return [accessDenied, component]
}

export { useAccessErrorHandling }
