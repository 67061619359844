import { Config } from '../../commons'
import { Bucket } from './AwsS3.type'

class AwsS3Config {
  getS3BucketUrl = (bucket?: Bucket) => {
    if (!bucket) {
      bucket = Bucket.Static
    }
    return Config.getKeyByEnv({
      // TODO: Something in Akamai handling needs to updated so riotesports.com is also allowed. 
      // Currently we are getting 403
      // local: bucket === Bucket.Static ? `assets-dev.riotesports.com` : `dev-${bucket}.lolesports.com`,
      // dev: bucket === Bucket.Static ? `assets-dev.riotesports.com` : `dev-${bucket}.lolesports.com`,
      // test: bucket === Bucket.Static ? `assets-test.riotesports.com` : `test-${bucket}.lolesports.com`,
      // prod: bucket === Bucket.Static ? `assets.riotesports.com` : `${bucket}.lolesports.com`
      local: `dev-${bucket}.lolesports.com`,
      dev: `dev-${bucket}.lolesports.com`,
      test: `test-${bucket}.lolesports.com`,
      prod: `${bucket}.lolesports.com`
    })
  }

  getPresignedUrlBase = () =>
    Config.getKeyByEnv({
      local: `https://dev-emp-api.service.riotesports.com/api`,
      dev: `https://dev-emp-api.service.riotesports.com/api`,
      test: `https://test-emp-api.service.riotesports.com/api`,
      prod: `https://emp-api.service.riotesports.com/api`
    })

  s3Region = Config.getKeyByEnv({
    local: 'us-west-2',
    dev: 'us-west-2',
    test: 'us-west-2',
    prod: 'us-west-2'
  })
}

export default new AwsS3Config()
