import { EventListener, Subscriptions } from './EventBus.type'

class EventBus {
  subscriptions: Subscriptions
  constructor () {
    this.subscriptions = {}
  }

  /**
   * Subscribes to an event
   * @param event the event name to subscribe to
   * @param callback the callback to be fired when the event is triggered
   * @returns an unsubscribe function, when invoked will unsubscribe from the event
   */
  on<T> (event: string, callback: EventListener<T>): () => void {
    this.subscriptions[event]
      = this.subscriptions[event] || [] as EventListener<T>[]
    this.subscriptions[event].push(callback)

    const unsubscribe = (): void => {
      this.subscriptions[event] = this.subscriptions[event].filter(
        (cb) => cb !== callback
      )
    }

    return unsubscribe
  }

  trigger<T> (event: string, payload?: T): void {
    this.subscriptions[event]
      && this.subscriptions[event].forEach((callback) => callback(payload))
  }

  unsubscribeAll (): void {
    this.subscriptions = {}
  }
}

export default new EventBus()
