import { MouseEvent, useEffect, useRef, useState } from 'react'
import {
  classNames,
  debounce,
  deriveState,
  resizeImage,
  stopEventPropagation
} from '../../commons'
import { DropdownOption, DropdownValue } from '../Dropdown/Dropdown.type'
import styles from './Autocomplete.scss'
import { Props, State } from './Autocomplete.type'

const Autocomplete = ({
  onSelect,
  options: preloadedOptions,
  value,
  class: className,
  disabled,
  placeholder,
  title,
  itemClassName
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLElement | undefined>()

  const flattenOptions = (options: DropdownOption[]): DropdownOption[] =>
    options.flatMap((option) =>
      option.menu ? [option, ...option.menu] : [option]
    )

  useEffect(() => {
    closeMenu(value, preloadedOptions)
  }, [value, preloadedOptions])

  const getSelectedOption = (
    value: DropdownValue,
    options?: DropdownOption[]
  ): DropdownOption | null => {
    const currentOptions = options || preloadedOptions
    const allOptions = flattenOptions(currentOptions)
    return (
      allOptions.find((option: DropdownOption) => option.value === value)
      ?? null
    )
  }

  const preSelectedOption = getSelectedOption(value)
  const [currentOption, setCurrentOption] = useState<State>({
    dropdownOpen: false,
    input: preSelectedOption?.label || '',
    selectedMenu: null,
    reset: false
  })

  // simulate setState callback in original class component
  useEffect(() => {
    if (currentOption.reset) {
      onSelect(null)
    }
  }, [currentOption.reset])

  const setFocusAndClearSelected = (): void => {
    if (!disabled) {
      if (inputRef) {
        inputRef.current?.focus()
      }
      setCurrentOption({
        ...currentOption,
        selectedMenu: null,
        dropdownOpen: true,
        input: '',
        reset: false
      })
    }
  }

  const renderTitle = (title: string): JSX.Element => 
    <div className={ styles.title }>{ title }</div>

  const renderInput = (): JSX.Element => {
    const selectedOption = getSelectedOption(value)
    const icon = selectedOption && selectedOption.icon
    return (
      <div
        className={ styles.search }
        onClick={ (): void => setFocusAndClearSelected() }>
        <div className={ styles.icon }>
          { icon && <img className={ styles.image } src={ resizeImage(icon, 24) }/> }
        </div>
        <input
          id={ Math.random().toString() }
          ref={ (dom): void => {
            inputRef.current = dom as HTMLInputElement
          } }
          placeholder={ placeholder }
          className={ styles.input }
          onInput={ debounce(
            deriveState('input', 'target.value', (newState: State) => {
              setCurrentOption({ ...currentOption, ...newState })
            }),
            150
          ) }
          value={ currentOption.input }
          disabled={ !!disabled }
        />
        { !disabled && currentOption.input && renderCloseIcon() }
      </div>
    )
  }

  const renderBackMenuOption = (option: DropdownOption): JSX.Element => (
    <div
      className={ styles.item }
      onClick={ () => {
        setCurrentOption({ ...currentOption, selectedMenu: null })
      } }>
      <div className={ styles.chevronLeft }/>
      <div className={ styles.label }>{ option.label }</div>
    </div>
  )

  const renderCloseIcon = (): JSX.Element => {
    const onClick = (event: MouseEvent): void => {
      stopEventPropagation(event)
      setCurrentOption({ ...currentOption, input: '', reset: true })
    }

    return (
      <div className={ styles.close } onClick={ onClick }>
        <svg className={ styles.svgIcon }>
          <title>Clear Input</title>
        </svg>
      </div>
    )
  }

  const selectOption = (option: DropdownOption): void => {
    if (option.menu) {
      setCurrentOption({ ...currentOption, selectedMenu: option })
    }
    onSelect && onSelect(option.value)
  }

  const renderOption = (option: DropdownOption): JSX.Element => (
    <div
      className={ classNames(styles.item, itemClassName) }
      onClick={ (): void => selectOption(option) }
      key={ option.value as string }>
      <div className={ styles.icon }>
        { option.icon 
          && <img className={ styles.image } src={ resizeImage(option.icon, 24) }/>
        }
      </div>
      <div className={ styles.label }>{ option.label }</div>
      { option.menu && <div className={ styles.chevronRight }/> }
    </div>
  )

  const closeMenu = (
    value: DropdownValue,
    options?: DropdownOption[]
  ): void => {
    const selectedOption = getSelectedOption(value, options)
    setCurrentOption({
      ...currentOption,
      dropdownOpen: false,
      input: selectedOption ? selectedOption.label || '' : ''
    })
  }

  const renderBackground = (): JSX.Element => {
    const onClick = (): void => {
      closeMenu(value)
    }
    return <div className={ styles.background } onClick={ onClick }/>
  }

  const optionsToRender = currentOption.selectedMenu?.menu ?? preloadedOptions
  const shouldFilter
    = currentOption.input
    && currentOption.input.length >= 2
    && !currentOption.selectedMenu

  const refinedOptions = shouldFilter
    ? flattenOptions(optionsToRender).filter((option) =>
      option.label
        ? option.label
          .toLowerCase()
          .includes(currentOption.input.toLowerCase())
        : false
    )
    : optionsToRender

  const filteredOptions = refinedOptions.filter(
    (option) => !option.menu || option.menu.length > 0
  )
  const hasIcons = !!refinedOptions.find((option) => option.icon)
  const classes = classNames(
    styles.autocomplete,
    className,
    !hasIcons && styles.noIcons
  )
  const dropdownClasses = classNames(
    styles.dropdown,
    currentOption.dropdownOpen && styles.open
  )

  return (
    <div className={ classes }>
      { currentOption.dropdownOpen && renderBackground() }
      <div className={ dropdownClasses }>
        { title && renderTitle(title) }
        { renderInput() }
        { currentOption.dropdownOpen && !disabled && (
          <div className={ styles.menu }>
            { currentOption.selectedMenu
              && renderBackMenuOption(currentOption.selectedMenu) }
            { filteredOptions.map((option: DropdownOption) =>
              renderOption(option)
            ) }
          </div>
        ) }
      </div>
    </div>
  )
}

export { Autocomplete }
