import { MouseEvent } from 'react'
import checkImage from '../../assets/svg/check.svg'
import { classNames, stopEventPropagation } from '../../commons'
import styles from './Checkbox.scss'
import { Props } from './Checkbox.type'

const Checkbox = ({
  checkboxLocation,
  checked,
  label,
  onChange,
  class: className,
  disabled
}: Props): JSX.Element => {
  const classes = classNames(
    styles.container,
    styles[checkboxLocation],
    className,
    disabled ? styles.disabled : ''
  )

  const labelClasses = classNames(styles.label, disabled ? styles.disabled : '')

  const onClick = (event: MouseEvent): void => {
    if (disabled) return
    stopEventPropagation(event)
    onChange(!checked)
  }

  return (
    <div className={ classes } onClick={ onClick } data-testid="checkbox">
      <div className={ styles.checkbox }>
        { checked && (
          <svg className={ styles.check }>
            <title>Checkbox</title>
            <use href={ checkImage }/>
          </svg>
        ) }
      </div>
      <label className={ labelClasses }>{ label }</label>
    </div>
  )
}

export default Checkbox
