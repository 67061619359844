import { TriggerDTO } from '@riotgames/api-types/drops/Drops.type'
import { triggerGroup } from '../../../../../services/DropsApi/DropsApi.actions'
import { createSlice } from '../../../../actions/utils'
import { getAllSchedules } from '../../../../../services/Elds/Elds.actions'
import { Action, AnyPayloadAction, PayloadAction } from '../../../../Store.type'
import { SchedulesResponse } from '@riotgames/api-types/elds/Schedules_v3.type'
import { Reducer } from 'redux'

const initialState: DeepPartial<TriggerDTO> = {
  dropID: null,
  groupID: null,
  broadcastID: null,
  streamIDs: [],
  streamPosition: 0
}

const { reducer, actions } = createSlice({
  name: 'dropTrigger',
  initialState: initialState,
  reducers: {
    editTrigger: (state, { payload }: PayloadAction<Partial<TriggerDTO>>) => {
      if (payload.broadcastID) {
        // When changing the broadcast, we must clear the previously selected
        // streams otherwise the operator will not be able to clear it themselves.
        return {
          ...state,
          streamIDs: [],
          ...payload
        } as TriggerDTO
      }

      return {
        ...state,
        ...payload
      } as TriggerDTO
    }
  },
  extraReducers: {
    [triggerGroup.success.type]: () => initialState,
    [getAllSchedules.success.type]: (
      state,
      { payload }: PayloadAction<SchedulesResponse>
    ) => {
      // Resets only if the currently selected broadcast is not from a recently
      // scheduled event.  This prevents errors when the operator partially fills
      // out the UI, then leaves and comes back at a future date when that
      // broadcast is no longer live.  It should have no effect on an operator
      // revisiting the page later on the the same day.
      const broadcasts = payload.schedules
        .flatMap(({ scheduleBlocks }) => scheduleBlocks)
        .flatMap(({ broadcasts }) => broadcasts)

      const broadcast = broadcasts.find(({ id }) => id === state?.broadcastID)

      return broadcast && broadcast.status === 'inProgress'
        ? state
        : initialState
    }
  } as Record<string, Reducer<TriggerDTO, AnyPayloadAction>>
})

export default reducer
const { editTrigger } = actions
export { editTrigger }

export const getWIPTrigger = (state: TriggerDTO): TriggerDTO => state
