import { classNames } from '../../commons'
import styles from './Switch.scss'

type Props = {
  class?: string
  checked: boolean
  onChange: (checked: boolean) => void
}

export const Switch = (props: Props): JSX.Element => {
  const classes = classNames(styles.slider, styles.round)
  const { onChange } = props

  return (
    <label className={ styles.switch }>
      <input
        type="checkbox"
        checked={ props.checked }
        onChange={ ({ target }: DOMEventTarget): void =>
          onChange((target as HTMLInputElement).checked)
        }
      />
      <span className={ classes }/>
    </label>
  )
}

export default Switch
