// A Mapping of localization slugs to english labels. Represents the list of
// approved stage names for any given tournament. This list must be a subset
// of the list of localized names stages in elds-localizations.

import { createOption } from '../../../../components'
import { DropdownOption } from '../../../../components/Dropdown/Dropdown.type'

const stageNames: Record<string, string> = {
  play_in_groups: 'Play-In Groups',
  play_in_elim: 'Play-In Knockout',
  group_stage: 'Group Stage',
  groups: 'Groups',
  knockouts: 'Knockouts',
  regular_season: 'Regular Season',
  play_ins: 'Play-Ins',
  play_ins_draw_show: 'Play-Ins Draw Show',
  bracket_stage_draw_show: 'Bracket Stage Draw Show',
  bracket_stage: 'Bracket Stage',
  playoffs: 'Playoffs',
  playoff_play_ins: 'Playoff Play-Ins',
  playoff_play_in_knights_rival: 'Playoff Play-In Knights Rival',
  tiebreaker: 'Tiebreaker',
  exhibition: 'Exhibition',
  round_1: 'Round 1',
  round_2: 'Round 2',
  round_3: 'Round 3',
  round_4: 'Round 4',
  round_5: 'Round 5',
  regional_finals: 'Regional Finals',
  regional_qualifier: 'Regional Qualifier',
  rumble_stage: 'Rumble Stage',
  swiss: 'Swiss',
  weekend_1: 'Weekend 1',
  weekend_2: 'Weekend 2',
  weekend_3: 'Weekend 3',
  weekend_4: 'Weekend 4',
  weekend_5: 'Weekend 5',
  na: 'NA',
  emea: 'EMEA',
  jp: 'JP',
  sea: 'SEA',
  kr: 'KR',
  br: 'BR',
  latam: 'LATAM'
}

const shortStageNames: Record<string, string> = {
  play_in_groups: 'PIG',
  play_in_elim: 'PIK',
  group_stage: 'GS',
  groups: 'GS',
  knockouts: 'KO',
  regular_season: 'RS',
  play_ins: 'PI',
  play_ins_draw_show: 'PIDS',
  bracket_stage_draw_show: 'BSDS',
  bracket_stage: 'BS',
  playoffs: 'PO',
  playoff_play_ins: 'PPI',
  playoff_play_in_knights_rival: 'PPKR',
  tiebreaker: 'TB',
  exhibition: 'E',
  round_1: 'R1',
  round_2: 'R2',
  round_3: 'R3',
  round_4: 'R4',
  round_5: 'R5',
  regional_finals: 'RF',
  regional_qualifier: 'RQ',
  rumble_stage: 'RS',
  swiss: 'SW',
  weekend_1: 'W1',
  weekend_2: 'W2',
  weekend_3: 'W3',
  weekend_4: 'W4',
  weekend_5: 'W5',
  na: 'NA',
  emea: 'EMEA',
  jp: 'JP',
  sea: 'SEA',
  kr: 'KR',
  br: 'BR',
  latam: 'LATAM'
}

// Returns a list of stage names formatted for use in Dropdowns and Autocompletes
export const getStageNameOptions = (): DropdownOption[] =>
  Object.entries(stageNames).map(([slug, label]) => createOption(label, slug))

export const getStageLocalizationForSlug = (slug: string): string =>
  stageNames[slug]

export const getShortStageLocalizationForSlug = (slug: string): string =>
  shortStageNames[slug]

export const getSlugForStageLabel = (label: string): string | undefined => {
  const entries = Object.entries(stageNames)
  const match = entries.find((entry) => entry[1] === label)

  return match ? match[0] : undefined
}
