import { ComponentType } from 'react'
import { classNames } from '../../commons'
import styles from './WithValidation.scss'
import { Props } from './WithValidation.type'

/**
 * WithValidation is a Higher Order Component intended to add validation error
 * messages to components.  The component will display an error message when
 * the isValid prop is false and will also pass an isInvalid class to the child
 * component via the class prop.
 * @param WrappedComponent The component to enhance
 */
const WithValidation
  = <P extends object>(
    WrappedComponent: ComponentType<P>
  ): ComponentType<P & Props> =>
      ({ validator, validationStyles, ...props }: P & Props): JSX.Element => {
        const wrapperClasses = classNames(styles.wrapper, validationStyles?.wrapper)

        const validationMessageClasses = classNames(
          styles.validationMessage,
          validationStyles?.message
        )

        const childClasses = classNames(
          props.className,
          validator.isInvalid && styles.isInvalid,
          validator.isInvalid && validationStyles?.invalid
        )

        const componentProps = props as P & Props
        return (
          <div className={ wrapperClasses }>
            <WrappedComponent { ...componentProps } className={ childClasses }>
              { componentProps.children }
            </WrappedComponent>
            { validator.isInvalid 
          && <span className={ validationMessageClasses }>{ validator.message }</span>
            }
          </div>
        )
      }

export default WithValidation
