import { createSlice } from '../../store/actions/utils'
import { FeatureList } from './FeatureToggle.type'

// Defines the list of features in the project, keyed by environment.
const features: FeatureList = {
  prod: {
    autocompleteMatch: false,
    selfServiceTournaments: true,
    seasons: true,
    accounts: true,
    statSync: false,
    rbac: true
  },
  test: {
    autocompleteMatch: true,
    selfServiceTournaments: true,
    seasons: true,
    accounts: true,
    statSync: false,
    rbac: true
  },
  dev: {
    autocompleteMatch: true,
    selfServiceTournaments: true,
    seasons: true,
    accounts: true,
    statSync: true,
    rbac: true
  }
}

const slice = createSlice({
  name: 'features',
  initialState: features
})

export default slice.reducer

export const getFeatureToggleForEnvironment = (
  state: FeatureList,
  env: string,
  feature: string
): boolean => state && state[env as keyof FeatureList][feature]
