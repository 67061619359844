import { InventoryDescription } from '@riotgames/api-types/drops/DropsV2.type'
import { Action, AnyPayloadAction, PayloadAction } from '../../../../Store.type'
import { createSlice } from '../../../../actions/utils'
import { getDropsInventoryFulfillmentStatusDefaultDescriptions } from '../../../../../services/DropsApi/DropsApi.actions'
import { DefaultDescriptionsState } from './FulfillmentStatusDefaultDescription.type'
import { Reducer } from 'redux'

const { reducer, actions } = createSlice({
  name: 'defaultInventoryFulfillmentStatusDescriptions',
  initialState: {
    isLoading: false,
    defaultDescriptions: []
  },
  reducers: {
    editDefaultDescriptions: (
      state,
      { payload }: PayloadAction<InventoryDescription[]>
    ) => ({
      ...state,
      defaultDescriptions: payload
    })
  } as Record<
    string,
    Reducer<
      { isLoading: boolean; defaultDescriptions: InventoryDescription[] },
      AnyPayloadAction
    >
  >,
  extraReducers: {
    [getDropsInventoryFulfillmentStatusDefaultDescriptions.success.type]: (
      state,
      { payload }
    ) => ({
      ...state,
      isLoading: false,
      defaultDescriptions: payload ?? []
    }),
    [getDropsInventoryFulfillmentStatusDefaultDescriptions.start.type]: (
      state
    ) => ({
      ...state,
      isLoading: true
    }),
    [getDropsInventoryFulfillmentStatusDefaultDescriptions.failed.type]: (
      state
    ) => ({
      ...state,
      isLoading: false,
      defaultDescriptions: []
    })
  } as Record<
    string,
    Reducer<
      { isLoading: boolean; defaultDescriptions: InventoryDescription[] },
      AnyPayloadAction
    >
  >
})

export default reducer

const { editDefaultDescriptions } = actions

export { editDefaultDescriptions }

export const getDefaultDescriptions = (
  state: DefaultDescriptionsState
): InventoryDescription[] => state.defaultDescriptions

export const isFetchingDefaultDescription = (
  state: DefaultDescriptionsState
): boolean => state.isLoading
