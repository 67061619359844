import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EsportsGameMetadata,
  EsportsMappingRequest,
  ParticipantDetails,
  PlatformGameDetails,
  TeamDetails
} from '@riotgames/api-types/admin-api/Game_management.type'

import {
  createArray,
  createEsportsMappingRequest,
  createParticipantDetails,
  createTeamDetails,
  get,
  getTeamIndex,
  replaceAt,
  set,
  LolPlatformTeamId,
  valorantAgentByGuid,
  classNames,
  isTournamentOverOrArchived,
  isNullOrUndefined
} from '../../commons'
import { AdminApi } from '../../services'
import {
  getSport,
  getPlayersInTournament,
  getTeamsInTournament,
  getWIPTournamentById,
  getPlayers
} from '../../store/reducers'
import { RootState } from '../../store/Store.type'
import { Autocomplete } from '../Autocomplete/Autocomplete'
import { createOption, Dropdown } from '../Dropdown/Dropdown'
import { DropdownOption } from '../Dropdown/Dropdown.type'
import Modal from '../Modal/Modal'
import PlatformGameIdPicker from './PlatformGameIdPicker/PlatformGameIdPicker'
import styles from './StatMapper.scss'
import { Props, SportParticipant } from './StatMapper.type'
import { Sport } from '@riotgames/api-types/elds/Common.type'
import switchImage from '../../assets/svg/switch.svg'
import {
  sportsHeaders,
  tryMapAccountIdToPlayer,
  tryMapPuuidToPlayer
} from './StatMapper.config'
import { Button } from '../Simple/Simple'
import { Team } from '@riotgames/api-types/elds/Teams.type'
import Loading from '../Loading/Loading'
import { Player } from '@riotgames/api-types/elds/Players_v3.type'
import Confirm from '../Confirm/Confirm'
import {
  getEsportsGame,
  getPlatformGame,
  getPlatformGames,
  mapPlatformGame,
  unmapPlatformGame
} from './StatMapperUtils'

const getParticipants = get<ParticipantDetails[]>('participants')
const getChampionId = get<string>('championId')
const getDisplayName = get<string>('displayName')
const getPlatformAccountId = get<string>('platformAccountId')
const getPuuid = get<string>('puuid')
const [getTeams, setTeams] = [
  get<TeamDetails[]>('teams'),
  set<TeamDetails[]>('teams')
]
const [setId] = [set<string>('esportsTeamId')]
const [getPlayerId, setPlayerId] = [
  get<string>('esportsPlayerId'),
  set<string>('esportsPlayerId')
]

export const getTableRows = {
  [Sport.Lol]: [getChampionId, getPlatformAccountId],
  [Sport.Val]: [({ guid }: SportParticipant) => valorantAgentByGuid(guid)],
  [Sport.Wr]: [getPuuid] // TODO: To update when we begin supporting WR
}

export const getSidesTitles = {
  [Sport.Lol]: ['blue', 'red'],
  [Sport.Val]: ['attacking', 'defending'],
  [Sport.Wr]: ['blue', 'red'] // TODO: To update when we begin supporting WR
}

const UNKNOWN_TEAM = '???'

export const StatMapper = (props: Props): JSX.Element => {
  const [esportsGameMetadata, setEsportsGameMetadata] = useState(
    {} as EsportsGameMetadata
  )
  const [platformGames, setPlatformGames] = useState({})
  const [selectedPlatformId, setSelectedPlatformId] = useState('')
  const [useMappedGames, setUseMappedGames] = useState(false)
  const [esportsMappingRequest, setEsportsMappingRequest] = useState(
    createEsportsMappingRequest({
      esportsGameId: props.esportsGameId,
      teams: []
    })
  )
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const dispatch = useDispatch()

  const tournament
    = props.tournament
    ?? useSelector((state: RootState) =>
      getWIPTournamentById(state, props.tournamentId)
    ).tournament
  const isOldTournament = isTournamentOverOrArchived(
    tournament.endTime,
    tournament.status
  )
  const selectedSport = props.selectedSport || useSelector(getSport)
  let players: Record<string, Player> = props.players as Record<string, Player>
  if (!players) {
    players = isOldTournament
      ? useSelector((state: RootState) => getPlayers(state))
      : useSelector((state: RootState) =>
        getPlayersInTournament(state, props.tournamentId)
      )
  }
  const [isLoading, setIsLoading] = useState(false)
  const teams
    = props.teams
    || useSelector((state: RootState) =>
      getTeamsInTournament(state, props.tournamentId)
    )

  useEffect(() => {
    setIsLoading(true)
    initializeData()
  }, [])

  const initializeData = (): void => {
    const { esportsGameId } = props
    Promise.all([
      getEsportsGame(selectedSport, esportsGameId),
      getPlatformGames(selectedSport)
    ])
      .then(([esportsGameMetadataResp, platformGamesResp]) => {
        setEsportsGameMetadata(esportsGameMetadataResp)
        setPlatformGames(platformGamesResp)

        // If we already have a mapped platform game, we should pre-select it.
        if (esportsGameMetadataResp.mappedPlatformGames.length) {
          const { mappedPlatformGames } = esportsGameMetadataResp
          const platformGame
            = mappedPlatformGames[mappedPlatformGames.length - 1]
          setUseMappedGames(true)
          selectPlatformGame(
            selectedSport,
            platformGame?.platformGameId,
            true,
            platformGamesResp
          )
        }
        setIsLoading(false)
      })
      .finally(() => setIsLoading(false))
  }

  const selectPlatformGame = (
    sport: string,
    id: string,
    alreadyMapped: boolean = false,
    platformGamesResp?: Record<string, PlatformGameDetails>
  ): void => {
    const getGame = get<PlatformGameDetails>(id)
    const promise
      = getGame(platformGames) || id === 'MANUAL'
        ? Promise.resolve(getGame(platformGames))
        : getPlatformGame(
          sport,
          id,
          setPlatformGames,
          platformGames,
          platformGamesResp
        )

    promise.then((game) => {
      // The game may not exist if data has not come back from the server yet.
      // In that case, we must skip the update or risk corrupting the data by
      // setting blueTeam and redTeam to undefined
      const updated = game
        ? createEsportsMappingRequest(esportsMappingRequest, {
          // Deliberately preserve a preselected team when switching to a new
          // platform game which does not already have teams assigned to it.
          // Fixes ESPORTS-9813
          teams: getTeams(game)
        })
        : esportsMappingRequest

      // In instances where the platform game response or esports mapped reponse
      // is missing the teams array, we need to initialize it to an empty array to
      // prevent Stat Mapper from crashing
      if (isNullOrUndefined(updated.teams)) {
        updated.teams = []
      }

      setSelectedPlatformId(id)
      setEsportsMappingRequest(updated)
      // If the game already contains a mapping, skip the API call to get the proposed mapping
      if (id !== 'MANUAL' && !alreadyMapped) {
        try {
          AdminApi.getProposedMapping(sport, updated.esportsGameId, id).then(
            (mapping: EsportsMappingRequest) => {
              if (mapping.teams.length === 0) return
              setEsportsMappingRequest(mapping)
            }
          )
        }
        catch (err) {}
      }
    })
  }

  const swapTeams = (): void => {
    const { teams } = esportsMappingRequest

    const blueTeamIndex = getTeamIndex(selectedSport, 'blue', teams)
    const redTeamIndex = getTeamIndex(selectedSport, 'red', teams)
    // Swap teams based on indices
    let updatedBlueTeam = teams[redTeamIndex]
    let updatedRedTeam = teams[blueTeamIndex]

    if (selectedSport === Sport.Lol) {
      updatedBlueTeam = createTeamDetails(updatedBlueTeam, {
        platformTeamId: LolPlatformTeamId.Blue
      })
      updatedRedTeam = createTeamDetails(updatedRedTeam, {
        platformTeamId: LolPlatformTeamId.Red
      })
    }

    const updatedRequest = createEsportsMappingRequest(esportsMappingRequest, {
      teams: [updatedBlueTeam, updatedRedTeam]
    })

    setEsportsMappingRequest(updatedRequest)
  }

  const renderPlayers = (): JSX.Element => (
    <div className={ styles.players }>
      { renderTeamPlayers('blue') }
      { renderTeamPlayers('red') }
    </div>
  )

  const onPlayerSelect = (key: string, index: number, value: string): void => {
    const { teams } = esportsMappingRequest
    const teamIndex = getTeamIndex(selectedSport, key, teams)
    let { participants } = teams[teamIndex] as TeamDetails
    if (!participants) {
      participants = createArray(5).map(() => createParticipantDetails())
    }
    const participant = setPlayerId(participants[index], value as string)
    const updatedParticipants = replaceAt(participants, participant, index)

    const updatedTeams = teams
    updatedTeams[teamIndex] = createTeamDetails(teams[teamIndex], {
      participants: updatedParticipants
    })

    setEsportsMappingRequest(setTeams(esportsMappingRequest, updatedTeams))
  }

  const renderPlayerSearchAutoComplete = (
    player: ParticipantDetails,
    team: Team,
    key: string,
    index: number
  ): JSX.Element => {
    const options = Object.values(players).map((player) =>
      createOption(player.handle, player.id, player.photoUrl)
    )
    return (
      <Autocomplete
        title="Player"
        options={ options }
        value={ player.esportsPlayerId }
        onSelect={ (value): void => onPlayerSelect(key, index, value as string) }
      />
    )
  }

  const renderPlayerDropdown = (
    player: ParticipantDetails,
    team: Team,
    key: string,
    index: number
  ): JSX.Element => {
    const options
      = team
      && team.roster.map(({ playerId }) =>
        createOption(players[playerId]?.handle, playerId)
      )

    // If the player has already been stat mapped to this team, pre-select them
    // in the dropdown. Otherwise, try to pre-select them based on the proposed mapping if one exists
    // Fallback to the player's platform account ID for LoL and PUUID for VAL
    let playerId
    if (
      team.roster.some(({ playerId }) => playerId === player.esportsPlayerId)
    ) {
      playerId = getPlayerId(player)
    }
    else if (selectedSport === Sport.Lol) {
      playerId = tryMapAccountIdToPlayer(
        players,
        player.lol.platformAccountId,
        team.id
      )?.id
    }
    else if (selectedSport === Sport.Val) {
      playerId = tryMapPuuidToPlayer(players, player.val.puuid, team.id)?.id
    }

    return (
      <Dropdown
        class={ styles.dropdown }
        options={ [createOption('Unknown', '-'), ...options] }
        value={ playerId || '' }
        onSelect={ ({ value }): void =>
          onPlayerSelect(key, index, value as string)
        }
      />
    )
  }

  const renderPlayer = (
    player: ParticipantDetails,
    teamId: string,
    key: string,
    index: number
  ): JSX.Element => {
    const team = teams[teamId]
    const selectedRows = getTableRows[selectedSport]
    const playerDropdownFn = isOldTournament
      ? renderPlayerSearchAutoComplete
      : renderPlayerDropdown
    return (
      <tr>
        <td className={ styles.cell }>
          <div className={ styles.contents }>
            { team ? playerDropdownFn(player, team, key, index) : '—' }
          </div>
        </td>
        { selectedRows?.map((row, index) => (
          <td className={ styles.cell } key={ index }>
            <div className={ styles.contents }>
              { row(player[selectedSport] as SportParticipant) || '—' }
            </div>
          </td>
        )) }
        <td className={ styles.cell }>
          <div className={ styles.contents }>{ getDisplayName(player) || '—' }</div>
        </td>
      </tr>
    )
  }

  const renderTeamPlayers = (side: string): JSX.Element => {
    const { teams } = esportsMappingRequest
    const teamIndex = getTeamIndex(selectedSport, side, teams)
    const team = teams[teamIndex] as TeamDetails
    const participants
      = getParticipants(team)
      || createArray(5).map(() => createParticipantDetails())
    const selectedHeaders = sportsHeaders[selectedSport]
    return (
      <table className={ `${styles.team} ${styles[side]}` }>
        <tr>
          { selectedHeaders.map((header) => (
            <th key={ header } className={ styles.header }>
              { header }
            </th>
          )) }
        </tr>
        { participants.map((player, index) =>
          renderPlayer(player, team?.esportsTeamId, side, index)
        ) }
      </table>
    )
  }

  const onTeamSelect = (
    value: string,
    team: TeamDetails,
    teamIndex: number
  ) => {
    const updatedTeams = esportsMappingRequest.teams
    updatedTeams[teamIndex] = setId(team, value as string)
    setEsportsMappingRequest(
      createEsportsMappingRequest(esportsMappingRequest, {
        teams: updatedTeams
      })
    )
  }

  const renderTeam = (side: string): JSX.Element => {
    const teamOptions = Object.values(teams).map((team) =>
      createOption(team.name, team.id, team.logoUrl)
    )
    const teamIndex = getTeamIndex(
      selectedSport,
      side,
      esportsMappingRequest.teams
    )
    if (teamIndex === -1) {
      swapTeams()
    }
    const team = esportsMappingRequest.teams[teamIndex]
    const esportsTeamId = team?.esportsTeamId
    const selectedTitle = getSidesTitles[selectedSport]

    return (
      <div className={ styles.team }>
        <Autocomplete
          title={ `${
            side === 'blue' ? selectedTitle[0] : selectedTitle[1]
          } Team` }
          options={ teamOptions }
          value={ esportsTeamId }
          onSelect={ (value): void => {
            onTeamSelect(value as string, team, teamIndex)
          } }
        />
      </div>
    )
  }

  const renderTeams = (): JSX.Element => (
    <div className={ styles.teams }>
      { renderTeam('blue') }
      <div className={ styles.switchSides }>
        <button className={ styles.switch } onClick={ (): void => swapTeams() }>
          <img src={ switchImage }/>
        </button>
      </div>
      { renderTeam('red') }
    </div>
  )

  const buildPlatformGameOptions = (
    platformGames: PlatformGameDetails[],
    noManualOption?: boolean
  ): DropdownOption[] => {
    const inferTricodeFromDisplayName = ({
      displayName
    }: {
      displayName: string
    }): string => displayName.split(' ')[0]

    const isSameOrUnknown = (a: string, b: string): string =>
      a === b ? a : UNKNOWN_TEAM

    const teamToTricode = (team: TeamDetails): string => {
      const participants = team?.participants

      // Assume unknown if a team has zero participants
      if (participants.length === 0) return UNKNOWN_TEAM

      return participants
        .map(inferTricodeFromDisplayName)
        .reduce(isSameOrUnknown)
    }

    const manualOption = [createOption('Manual', 'MANUAL')]
    const platformGameOptions = platformGames.map((game) => {
      const { teams, platformGameId } = game
      // get blueTeam or redTeam based on the platformTeamId
      const blueTeamIndex = getTeamIndex(selectedSport, 'blue', teams)
      const redTeamIndex = getTeamIndex(selectedSport, 'red', teams)
      const blueTeam = teams[blueTeamIndex]
      const redTeam = teams[redTeamIndex]
      const bCount = blueTeam?.participants?.length || 0
      const rCount = redTeam?.participants?.length || 0
      const matchSize = `${bCount}v${rCount}`
      const bTricode = blueTeam && teamToTricode(blueTeam)
      const rTricode = redTeam && teamToTricode(redTeam)
      const blueVSred = `${bTricode} v ${rTricode}`

      const label = `${matchSize} - ${blueVSred} - ${platformGameId}`

      return createOption(label, platformGameId)
    })
    return noManualOption
      ? platformGameOptions
      : manualOption.concat(platformGameOptions)
  }

  const renderMappedPlatformGamePicker = (): JSX.Element => {
    const options = buildPlatformGameOptions(
      esportsGameMetadata?.mappedPlatformGames || [],
      true
    )

    return (
      <div className={ styles.platformGame }>
        <Dropdown
          title="Mapped Games"
          options={ options }
          value={ selectedPlatformId }
          onSelect={ (option): void => {
            const value = option.value as string
            return selectPlatformGame(selectedSport, value, true)
          } }
        />
        <div className={ styles.select }>
          <span className={ styles.platformId }>
            Game will be mapped to: { selectedPlatformId }
          </span>
        </div>
      </div>
    )
  }

  const renderPlatformGamePicker = (): JSX.Element => {
    const options = buildPlatformGameOptions(Object.values(platformGames))

    return (
      <div className={ styles.platformGame }>
        <Dropdown
          title="Live Games"
          options={ options }
          value={ selectedPlatformId }
          onSelect={ (option): void => {
            const value = option.value as string
            return selectPlatformGame(selectedSport, value)
          } }
        />
        <div className={ styles.select }>
          { selectedPlatformId === 'MANUAL' ? (
            <PlatformGameIdPicker
              onChange={ (id): void => selectPlatformGame(selectedSport, id) }
            />
          ) : (
            <span className={ styles.platformId }>
              Game will be mapped to: { selectedPlatformId }
            </span>
          ) }
        </div>
      </div>
    )
  }

  const hasAMappedGame
    = (esportsGameMetadata?.mappedPlatformGames?.length || 0) >= 1
  const buttonText = useMappedGames
    ? 'Switch to Live Games'
    : 'Switch to Mapped Games'

  return (
    <Modal
      title="Stat Mapper"
      onCancel={ (): void => props.onClose() }
      onConfirm={ (): void =>
        mapPlatformGame(
          selectedSport,
          selectedPlatformId,
          esportsMappingRequest,
          props.onClose
        )
      }
      onDelete={ hasAMappedGame ? () => setShowDeleteConfirm(true) : undefined }
      deleteText="Unmap Game"
      className={ styles.statMapper }>
      { hasAMappedGame && (
        <Button
          className={ styles.switchToMappedButton }
          onClick={ () => setUseMappedGames(!useMappedGames) }>
          { buttonText }
        </Button>
      ) }
      { isLoading ? (
        <div className={ classNames(styles.content, styles.loading) }>
          <Loading simple/>
        </div>
      ) : (
        <div className={ styles.content }>
          { !useMappedGames && renderPlatformGamePicker() }
          { useMappedGames && renderMappedPlatformGamePicker() }
          { renderTeams() }
          { renderPlayers() }
        </div>
      ) }
      { showDeleteConfirm && (
        <Confirm
          title="Unmap Game"
          body="Are you sure you want to unmap this game?"
          onConfirm={ () =>
            unmapPlatformGame(
              selectedSport,
              selectedPlatformId,
              dispatch,
              props.onClose
            )
          }
          onCancel={ () => setShowDeleteConfirm(false) }
        />
      ) }
    </Modal>
  )
}

export default StatMapper
