import { classNames, resizeImage } from '../../commons'
import styles from './Icon.scss'
import { Props } from './Icon.type'

const DEFAULT_SIZE = 24

const Icon = ({
  class: className,
  height,
  label,
  url,
  width,
  forceDimensions
}: Props): JSX.Element => {
  const h = height || DEFAULT_SIZE
  const w = width || DEFAULT_SIZE
  const classes = classNames(styles.icon, className)

  if (!url) {
    return <div className={ classes }>-</div>
  }

  const additionalProps = forceDimensions ? { height: h, width: w } : {}

  return (
    <div className={ classes }>
      <img
        src={ resizeImage(url, w, h) }
        className={ styles.image }
        loading="lazy"
        { ...additionalProps }
      />
      { label && <span className={ styles.label }>{ label }</span> }
    </div>
  )
}

export default Icon
