import {
  LocalizedStringsWithSlug,
  PublicationStatus
} from '@riotgames/api-types/elds/Common.type'
import { OutcomeSource } from '@riotgames/api-types/elds/Matches.type'
import {
  DecisionPointConfig,
  GroupConfig,
  Lineup,
  LineupEntry,
  MatchCell,
  MatchColumn,
  MatchConfig,
  MatchConfigStrategy,
  MatchView,
  SectionType,
  StandingsPoints,
  StandingsPositionPoints,
  TournamentSchemaType,
  TournamentStatus
} from '@riotgames/api-types/elds/Tournaments.type'
import { compose } from 'redux'
import {
  createArray,
  getSafe,
  omit,
  resizeLineup,
  setCurried,
  setSafe,
  Logger
} from '../../../commons'
import { getSlugsForSections } from '../Builders/Tournament/SectionNames'
import {
  NormalizedSection,
  NormalizedStage,
  NormalizedTournament,
  ReducerShape
} from '../Tournament.type'
import { createEdgesForStage, createEdgesForTournament } from './EdgeUtils'

const log = new Logger('Util')

export type HasSeedingAndStanding = { seeding: Lineup; standing: Lineup }
export const resizeLineups = <T extends HasSeedingAndStanding>(
  entity: T,
  newSize: number
): T => {
  // Resizes a given lineup by preserving additional data and adding new empty
  // entries
  // API types requires outcomeSource to be set so in oder to set it to undefined we have to force cast it here
  const resize = (lineup: Lineup, size: number): Lineup => ({
    outcomeSource: undefined as unknown as OutcomeSource,
    entries: createArray(size).map(
      (index) =>
        ({
          index: lineup.entries[index]?.index || index + 1,
          teamId: lineup.entries[index]?.teamId,
          description: lineup.entries[index]?.description || '',
          // Tournament creation fails if an invalid standing (ie. out of range [1, (tournament team size)]) is set.
          // If a placement is already set, then all or none placements must be set. Since this will get reordered
          // once standings are finalized, setting this to the index + 1 will keep it in an acceptable and valid range
          placement: lineup.entries[index]?.placement || index + 1
        }) as LineupEntry
    )
  })

  return {
    ...entity,
    seeding: resize(entity.seeding, newSize),
    standing: resize(entity.standing, newSize)
  }
}

type HasCircuitPoints = { points: StandingsPoints }
export const updatePoints = <T extends HasCircuitPoints>(
  entity: T,
  newPoints: StandingsPositionPoints[]
): T => ({
    ...entity,
    points: {
      ...entity.points,
      entries: newPoints
    }
  })

export const resizePoints = <
  T extends HasCircuitPoints & HasSeedingAndStanding,
>(
    entity: T
  ): T => {
  if (!entity.points) {
    return entity
  }

  const points = {
    entries: entity.standing.entries.map((entry) => {
      const pointEntry = entity.points.entries.find(
        (e) => e.position === entry.index
      )

      return pointEntry || { position: entry.index, points: 0 }
    })
  }

  return {
    ...entity,
    points
  }
}

export type HasLocalizedStringsWithSlug = { name: LocalizedStringsWithSlug }
export const updateSlug = <T extends HasLocalizedStringsWithSlug>(
  entity: T,
  newSlug: MaybeNullable<string>
): T => {
  const name: LocalizedStringsWithSlug = {
    slug: newSlug as string,
    localizations: entity?.name?.localizations || {}
  }

  return {
    ...entity,
    name
  }
}

export const updateDroppable = <T>(entity: T, droppable: boolean): T =>
  ({
    ...entity,
    droppable
  }) as T

export const updateRewardable = <T>(entity: T, rewardable: boolean): T =>
  ({
    ...entity,
    rewardable
  }) as T

export const updateLocalizations = <T extends HasLocalizedStringsWithSlug>(
  entity: T,
  locale: string,
  localeName: string
): T => {
  const name: LocalizedStringsWithSlug = {
    slug: entity?.name?.slug || '',
    localizations: { ...entity?.name?.localizations }
  }

  name.localizations[locale] = localeName

  return {
    ...entity,
    name
  }
}

export const createMatchCell = (slug: string | null = null): MatchCell => ({
  name: {
    slug: slug as string,
    localizations: {}
  },
  contents: []
})

export const addMatchCellToColumn = (column: MatchColumn): MatchColumn => ({
  ...column,
  cells: column.cells.concat(createMatchCell())
})

export const addMatchToCellContents = (
  cell: MatchCell,
  structuralId: string
): MatchCell => ({
  ...cell,
  contents: cell.contents.concat({ structuralId } as MatchView)
})

export const removeCellFromColumn = (
  column: MatchColumn,
  cellIdx: number
): MatchColumn => ({
  ...column,
  cells: column.cells.filter((cell, index) => index !== cellIdx)
})

// Creates an empty draft version of a NormalizedTournament
export const createNormalizedTournament = (
  tournamentId?: string
): NormalizedTournament => {
  const tournament: NormalizedTournament = {
    id: tournamentId || null,
    leagueId: null,
    name: {
      slug: null,
      localizations: {}
    },
    schemaType: TournamentSchemaType.GraphStructured,
    status: TournamentStatus.Draft,
    publicationStatus: PublicationStatus.Draft,
    seeding: { entries: [], outcomeSource: undefined },
    standing: { entries: [], outcomeSource: undefined },
    points: null,
    startTime: new Date().toISOString(), // defaulting to the current date and time as inputs feels more natural
    endTime: new Date().toISOString(),
    streamGroupId: null,
    edges: [],
    sport: null,
    splitId: null,
    seasonId: null,
    stageIds: [],
    awardedCircuitPoints: [],
    timeZone: 'Etc/UTC'
  } as unknown as NormalizedTournament

  // The minimum number of participants in a tournament is 2, so it's safe to
  // initialize a tournament with two participants
  return resizeLineups(tournament, 2)
}

// Creates an empty draft version of a NormalizedStage.
export const createNormalizedStage = (
  localizationSlug: string,
  structuralId: string
): NormalizedStage => {
  const stage: NormalizedStage = {
    id: null,
    structuralId: structuralId,
    name: {
      slug: localizationSlug,
      localizations: {}
    },
    publicationStatus: PublicationStatus.Draft,
    seeding: { entries: [], outcomeSource: undefined },
    standing: { entries: [], outcomeSource: undefined },
    edges: [],
    columns: [],
    sectionIds: []
  } as unknown as NormalizedStage

  // The minimum number of participants in a stage is 2, so it's safe to initialize
  // a stage with two participants
  return resizeLineups(stage, 2)
}

// Creates an empty draft version of a NormalizedSection.
export const createNormalizedSection = (
  sectionIds: string[],
  structuralId: string
): NormalizedSection => {
  // Get the first unused slug from the list of all possible slugs
  const slug = getSlugsForSections()
    .filter((slug) => !sectionIds.includes(slug))
    .pop()

  const section: NormalizedSection = {
    id: null,
    structuralId: structuralId,
    publicationStatus: PublicationStatus.Draft,
    name: {
      slug: slug,
      localizations: {}
    },
    type: SectionType.Group,
    seeding: {
      entries: [],
      outcomeSource: undefined
    },
    standing: {
      entries: [],
      outcomeSource: undefined as unknown as OutcomeSource
    },
    groupConfig: {
      roundRobinRounds: 1,
      matchTemplate: {
        strategy: MatchConfigStrategy.BestOf,
        bestOfConfig: {
          count: 1
        }
      }
    } as GroupConfig,
    teamRecords: {},
    ranks: [],
    matchIds: [],
    decisionPointIds: [],
    edges: [],
    columns: []
  } as unknown as NormalizedSection
  // The minimum number of participants in a section is 2, so it's safe to
  // initialize a section with two participants
  return resizeLineups(section, 2)
}

export const createMatchConfig = (
  structuralId?: string,
  description?: string
): Partial<MatchConfig> => ({
  structuralId,
  description,
  strategy: MatchConfigStrategy.BestOf,
  bestOfConfig: {
    count: 1
  }
})

const createGroupConfig = (): GroupConfig => ({
  roundRobinRounds: 1,
  matchTemplate: createMatchConfig() as MatchConfig
})

const removeMatchesFromSection = (
  matchIds: string[],
  matches: Record<string, Partial<MatchConfig>>
) => matchIds.reduce((acc, id) => omit(acc, id), matches)

export const setSectionType = (
  section: NormalizedSection,
  type: SectionType,
  matches: Record<string, Partial<MatchConfig>>
): NormalizedSection => {
  const prevType = section.type
  if (prevType === type) {
    return section
  }
  if (type === SectionType.Bracket) {
    const sec = omit(section, 'groupConfig')
    removeMatchesFromSection(section.matchIds, matches)
    return {
      ...sec,
      matchIds: [],
      type
    } as unknown as NormalizedSection
  }

  if (type === SectionType.Group || type === SectionType.CrossGroup) {
    return {
      ...section,
      type,
      groupConfig: createGroupConfig()
    }
  }

  log.warn('Tried to set Section Type with unsupported type!')
  return section
}

export const resizeSection = (
  section: NormalizedSection,
  numberOfEntries: number
): NormalizedSection => {
  const seeding = resizeLineup(section.seeding, numberOfEntries)
  const standing = resizeLineup(section.standing, numberOfEntries)

  return {
    ...section,
    seeding,
    standing
  }
}

const updateStage = (
  model: ReducerShape,
  stageId: string,
  doNotModifyStageEdges = false,
  doNotResizeSections = false
): ReducerShape => {
  const stage = getSafe(model.stages, stageId)
  const numberOfParticipants = stage.seeding.entries.length
  const numberOfSections = stage.sectionIds.length
  const numberOfParticipantsPerSection = Math.ceil(
    numberOfParticipants / numberOfSections
  )

  const numberOfGroupsToReduce
    = numberOfParticipantsPerSection * numberOfSections - numberOfParticipants

  const sections = Object.entries(model.sections).reduce(
    (sectionAccumulator, [sectionId, section]) => {
      const sectionIndex = stage.sectionIds.indexOf(sectionId)
      // We never want groups to differ by more than 1 in size
      // We want the last section to be the first one reduce
      // and move forward (for consistency of behavior).
      // To do this we find the index of the section
      // within the stage list, we subtract the index from
      // the number of sections and compare that to the number
      // of groups to reduce in size.  Since lists are 0 indexed,
      // number of sections - section index will range from
      // number of sections to 1.
      const sectionSize
        = numberOfSections - sectionIndex > numberOfGroupsToReduce
          ? numberOfParticipantsPerSection
          : numberOfParticipantsPerSection - 1

      return setSafe(
        sectionAccumulator,
        sectionId,
        sectionIndex >= 0 && !doNotResizeSections
          ? resizeSection(section, sectionSize)
          : section
      )
    },
    {} as Record<string, NormalizedSection>
  )

  const newModel = { ...model, sections: sections }

  return doNotModifyStageEdges
    ? compose(
      setCurried(newModel, 'stages'),
      setCurried(newModel.stages, stageId)
    )(stage)
    : compose(
      setCurried(newModel, 'stages'),
      setCurried(newModel.stages, stageId),
      setCurried(stage, 'edges')
    )(createEdgesForStage(newModel, stageId))
}

export const updateAllStages = (
  model: ReducerShape,
  doNotModifyStageEdges?: boolean | string,
  doNotResizeSections?: boolean
): ReducerShape =>
  model.tournament.stageIds?.reduce(
    (acc, id) => {
      if (typeof doNotModifyStageEdges === 'string') {
        return updateStage(
          acc,
          id,
          doNotModifyStageEdges !== id,
          doNotResizeSections ?? doNotModifyStageEdges !== id
        )
      }
      else if (typeof doNotModifyStageEdges === 'boolean') {
        return updateStage(
          acc,
          id,
          doNotModifyStageEdges,
          doNotResizeSections ?? doNotModifyStageEdges
        )
      }
      else {
        return updateStage(acc, id, doNotModifyStageEdges, doNotResizeSections)
      }
    },
    Object.assign({}, model)
  )

export const updateEdgesForModel = (
  model: ReducerShape,
  doNotModifyStageEdges?: boolean | string,
  doNotResizeSections?: boolean
): ReducerShape =>
  compose(
    (model: ReducerShape) =>
      updateAllStages(model, doNotModifyStageEdges, doNotResizeSections),
    setCurried(model, 'tournament'),
    setCurried(model.tournament, 'edges'),
    createEdgesForTournament
  )(model)

export const createDecisionPointConfig = (
  description?: string,
  structuralId?: string
): Partial<DecisionPointConfig> =>
  resizeLineups(
    {
      structuralId,
      description,
      seeding: {
        entries: [],
        outcomeSource: undefined as unknown as OutcomeSource
      },
      standing: {
        entries: [],
        outcomeSource: undefined as unknown as OutcomeSource
      }
    },
    2
  )

export const FORCE_TOURNAMENT_RELOAD_KEY = 'forceTournamentReload'
