import { createSlice } from '../../store/actions/utils'
import {
  getAllClaimedPromoCodes,
  getAllUnclaimedPromoCodes
} from './PromoCodesApiV2.actions'
import { CodesResponse } from '@riotgames/api-types/drops/PromoCodeV2.type'
import { Action } from '../../store/Store.type'
import { AnyAction, combineReducers, Reducer } from 'redux'

const { reducer, actions } = createSlice({
  name: 'claimedCodes',
  initialState: {} as Record<string, CodesResponse>,
  reducers: {
    reset: (state, payload) => ({})
  },
  extraReducers: {
    [getAllUnclaimedPromoCodes.success.type]: (
      state,
      payload: Action<CodesResponse>
    ) => ({
      ...state,
      unclaimed: payload.payload
    }),
    [getAllClaimedPromoCodes.success.type]: (
      state,
      payload: Action<CodesResponse>
    ) => ({
      ...state,
      claimed: payload.payload
    })
  } as Record<string, Reducer<Record<string, CodesResponse>, AnyAction>>
})

const combinedReducer = combineReducers({
  codeStatus: reducer
})

export default combinedReducer
export const resetPromoCodes = actions.reset
