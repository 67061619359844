export enum LolPlatformTeamId {
  Blue = 100,
  Red = 200,
}

export const guidToAgentMap = {
  '117ed9e3-49f3-6512-3ccf-0cada7e3823b': {
    name: 'Cypher',
    agentNumber: 5,
    enabled: 1
  },
  '1e58de9c-4950-5125-93e9-a0aee9f98746': {
    name: 'Killjoy',
    agentNumber: 4,
    enabled: 1
  },
  '22697a3d-45bf-8dd7-4fec-84a9e28c69d7': {
    name: 'Chamber',
    agentNumber: 18,
    enabled: 1
  },
  '320b2a48-4d9b-a075-30f1-1f93a9b638fa': {
    name: 'Sova',
    agentNumber: 6,
    enabled: 1
  },
  '36fb82af-409d-c0ed-4b49-57b1eb08fbd5': {
    name: null,
    agentNumber: 0,
    enabled: 0
  },
  '41fb69c1-4189-7b37-f117-bcaf1e96f1bf': {
    name: 'Astra',
    agentNumber: 16,
    enabled: 1
  },
  '569fdd95-4d10-43ab-ca70-79becc718b46': {
    name: 'Sage',
    agentNumber: 7,
    enabled: 1
  },
  '5f8d3a7f-467b-97f3-062c-13acf203c006': {
    name: 'Breach',
    agentNumber: 13,
    enabled: 1
  },
  '601dbbe7-43ce-be57-2a40-4abd24953621': {
    name: 'KAYO',
    agentNumber: 17,
    enabled: 1
  },
  '6f2a04ca-43e0-be17-7f36-b3908627744d': {
    name: 'Skye',
    agentNumber: 14,
    enabled: 1
  },
  '707eab51-4836-f488-046a-cda6bf494859': {
    name: 'Viper',
    agentNumber: 2,
    enabled: 1
  },
  '7f94d92c-4234-0a36-9646-3a87eb8b5c89': {
    name: 'Yoru',
    agentNumber: 15,
    enabled: 1
  },
  '8e253930-4c05-31dd-1b6c-968525494517': {
    name: 'Omen',
    agentNumber: 3,
    enabled: 1
  },
  '9f0d8ba9-4140-b941-57d3-a7ad57c6b417': {
    name: 'Brimstone',
    agentNumber: 1,
    enabled: 1
  },
  'a3bfb853-43b2-7238-a4f1-ad90e9e46bcc': {
    name: 'Reyna',
    agentNumber: 11,
    enabled: 1
  },
  'add6443a-41bd-e414-f6ad-e58d267f4e95': {
    name: 'Jett',
    agentNumber: 10,
    enabled: 1
  },
  'bb2a4828-46eb-8cd1-e765-15848195d751': {
    name: 'Neon',
    agentNumber: 19,
    enabled: 1
  },
  'dade69b4-4f5a-8528-247b-219e5a1facd6': {
    name: 'Fade',
    agentNumber: 20,
    enabled: 1
  },
  'ded3520f-4264-bfed-162d-b080e2abccf9': {
    name: 'Sova',
    agentNumber: 0,
    enabled: 0
  },
  'eb93336a-449b-9c1b-0a54-a891f7921d69': {
    name: 'Phoenix',
    agentNumber: 9,
    enabled: 1
  },
  'f94c3b30-42be-e959-889c-5aa313dba261': {
    name: 'Raze',
    agentNumber: 12,
    enabled: 1
  },
  '95b78ed7-4637-86d9-7e41-71ba8c293152': {
    name: 'Habor',
    agentNumber: 21,
    enabled: 1
  },
  'e370fa57-4757-3604-3648-499e1f642d3f': {
    name: 'Gekko',
    agentNumber: 22,
    enabled: 1
  },
  'CC8B64C8-4B25-4FF9-6E7F-37B4DA43D235': {
    name: 'Deadlock',
    agentNumber: 23,
    enabled: 1
  },
  '0E38B510-41A8-5780-5E8F-568B2A4F2D6C': {
    name: 'Iso',
    agentNumber: 24,
    enabled: 1
  },
  '1DBF2EDD-4729-0984-3115-DAA5EED44993': {
    name: 'Clove',
    agentNumber: 25,
    enabled: 1
  },
  'EFBA5359-4016-A1E5-7626-B1AE76895940': {
    name: 'Vyse',
    agentNumber: 26,
    enabled: 1
  }
}
