import { ComponentType, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Loading from '../../components/Loading/Loading'
import { AccessDenied } from '../AccessDenied/AccessDenied'
import { Claims, getClaims } from './utils'

type Props = {
  children?: React.ReactNode
  requiredRoles?: string[]
  allowed?: string[]
}

export const AuthComponent
  = <P extends object>(WrappedComponent: ComponentType<P>) =>
    ({ requiredRoles, allowed, ...rest }: Props & P): JSX.Element => {
      const location = useLocation()
      const [claims, setClaims] = useState<Claims>({ roles: [] })
      const [isLoading, setIsLoading] = useState<boolean>(true)

      useEffect(() => {
        const checkRbac = async (): Promise<void> => {
          const c = await getClaims()
          setClaims(c)
        }
        checkRbac().then(() => {
          setIsLoading(false)
        })
      }, [location])

      let isAuthorized = false
      if (claims && claims.roles && claims.roles.length > 0) {
        const permissions = claims.roles
          .map((u: any) => u.resources.map((r: any) => r.split(':')[0]))
          ?.flat()
        isAuthorized
        = allowed?.findIndex((a: string) => permissions.includes(a)) !== -1

        const roleSet = new Set(claims.roles.map((u: any) => u.name))
        isAuthorized
        = requiredRoles?.some((role) => roleSet.has(role)) ?? isAuthorized
      }

      if (isLoading) {
        return <Loading/>
      }
      if (claims.roles.length === 0 || !isAuthorized) {
        return <AccessDenied/>
      }
      return <WrappedComponent { ...(rest as P) }/>
    }

export default AuthComponent
