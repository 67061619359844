import { classNames } from '../../commons'
import styles from './Loading.scss'
import logo from '../../assets/logos/logo.png'

type Props = {
  simple?: boolean
}

const Loading = (props: Props) => {
  const classes = classNames(styles.loading, props.simple && styles.simple)

  return (
    <div data-testid="indicator-loading" className={ classes }>
      <img className={ styles.logo } src={ logo }/>
    </div>
  )
}

export default Loading
