import { Navigate } from 'react-router-dom'

type Props = {
  to: string
}

const Redirect = (props: Props): JSX.Element => {
  const { to } = props
  return <Navigate to={ to } replace/>
}

export default Redirect
