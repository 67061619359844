import {
  compose,
  getSafe,
  isEqual,
  setCurried,
  setSafe
} from '../../../../../commons'
import {
  addNewDrop,
  AddNewDropParameter,
  clearWIPGroup,
  cloneGroupAction,
  createNewGroupAction,
  editInventoryItem,
  EditInventoryParameters,
  addInventoryItem,
  AddInventoryParameters,
  removeInventoryItem,
  RemoveInventoryParameters,
  editLocalizationKeys,
  removeDrop
} from '../../../../actions/Drops/Drops.actions'
import { createSlice } from '../../../../actions/utils'
import { AnyPayloadAction, PayloadAction } from '../../../../Store.type'
import {
  CappedDropRequestDTO,
  CappedDropResponseDTO,
  DropRequestDTO,
  DropResponseDTO,
  GroupResponseDTO,
  RarityResponseDTO
} from '@riotgames/api-types/drops/DropsV2.type'
import {
  CappedDropSettingsChange,
  EditRarityParameters,
  NormalizedGroup,
  ReducerShape,
  SetDropsParameter
} from './Group.type'
import {
  UNIQUE_KEY_UPLOAD_CONFIG,
  uploadAsset
} from '../../../../../services/AwsS3/AwsS3'
import { RecordStatus, Site } from '@riotgames/api-types/drops/Common.type'
import { DropStrategy } from '../Meta/Meta.type'
import { Sport } from '@riotgames/api-types/elds/Common.type'
import { setSport } from '../../../UserConfig/UserConfig'
import { Reducer } from 'redux'

export const LocKeySuffix = {
  TITLE: 'title',
  DESCRIPTION: 'description'
}

const initialDrop: DeepPartial<
  DropResponseDTO & { cappedDropRequest: DropRequestDTO }
> = {
  dropID: null,
  inventories: [],
  validGeolocations: [],
  rarity: null,
  sponsor: '',
  title: '',
  description: '',
  titleLocalizationGroup: LocKeySuffix.TITLE,
  descriptionLocalizationGroup: LocKeySuffix.DESCRIPTION,
  league: '',
  cardProgramImageURL: null,
  cardOverlaySponsorImageURL: null,
  notificationSponsorImageURL: null,
  notificationProgramImageURL: null,
  presentedBySponsorImageURL: null,
  cappedDropResponse: null,
  cappedDropRequest: null,
  status: RecordStatus.Active,
  sport: null,
  site: Site.LolEsports,
  createdDateEpochMillis: null
}

const initialGroup: DeepPartial<NormalizedGroup> = {
  id: '',
  orderedDrops: [initialDrop],
  description: '',
  createdDateEpochMillis: null,
  orderedDropRequests: [DropStrategy.Default]
}

const mapCalledDropDTO = (
  cappedDropDTO: CappedDropRequestDTO | CappedDropResponseDTO
): DeepPartial<CappedDropRequestDTO> | null => {
  if (!cappedDropDTO) return null
  return {
    quantity: getSafe(cappedDropDTO, 'quantity', null),
    percent: getSafe(cappedDropDTO, 'percent', null)
  }
}

// In the Drops Edit / Create / Clone flow, the desired Drop Object structure
// is the "DropRequestDTO". Unfortunately, this differs from the "DropResponseDTO"
// The primary differences are the following
//
// Additionally, this function is also confusing because the input could be
// EITHER a DropRequestDTO OR a DropResponseDTO. So, this function needs to be able to handle both cases,
// which is where this is some funky ternary fallback logic.
//
// `rarityID` is the field that we want, but the DropResponseDTO provides the whole `rarity` object
//       so we need to pluck the ID from that if it is there.
//
// `inventoryIDs` is the field that we want, but DropResponseDTO provides the whole array of `inventories` objects
//       so we need to map inside and pluck the Inventory ID's from that.
//
// `cappedDropRequest` is the field that we want. On a the RequestDTO, we can take that. On the ResponseDTO,
//       the field name is `cappedDropResponse`, so we assign it to that value. Otherwise, we fall back to null.
const mapDropResponseToDropRequest = (
  drop: Partial<DropRequestDTO & DropResponseDTO>
): DeepPartial<DropRequestDTO> => {
  const inventoryIDs
    = drop?.inventoryIDs
    ?? getSafe(drop, 'inventories', [])?.reduce(
      (acc: string[], inv) => inv && inv.id ? acc.concat(inv.id) : acc,
      []
    )
  const rarityID
    = drop?.rarityID
    ?? getSafe(getSafe(drop, 'rarity', {} as RarityResponseDTO), 'id', null)
  const cappedDropRequest
    = mapCalledDropDTO(
      getSafe(
        drop,
        'cappedDropRequest',
        null
      ) as unknown as CappedDropResponseDTO
    )
    ?? mapCalledDropDTO(
      getSafe(
        drop,
        'cappedDropResponse',
        null
      ) as unknown as CappedDropResponseDTO
    )

  const titleLocalizationGroup = drop?.titleLocalizationGroup ?? ''
  const descriptionLocalizationGroup = drop?.descriptionLocalizationGroup ?? ''

  return {
    id: getSafe(drop, 'dropID', null),
    inventoryIDs,
    validGeolocations: drop.validGeolocations,
    rarityID,
    sponsor: getSafe(drop, 'sponsor', null),
    title: getSafe(drop, 'title', null),
    description: getSafe(drop, 'description', null),
    localizationDTOs: [],
    titleLocalizationGroup,
    descriptionLocalizationGroup,
    league: getSafe(drop, 'league', null),
    cardProgramImageURL: getSafe(drop, 'cardProgramImageURL', null),
    cardOverlaySponsorImageURL: getSafe(
      drop,
      'cardOverlaySponsorImageURL',
      null
    ),
    notificationSponsorImageURL: getSafe(
      drop,
      'notificationSponsorImageURL',
      null
    ),
    notificationProgramImageURL: getSafe(
      drop,
      'notificationProgramImageURL',
      null
    ),
    presentedBySponsorImageURL: getSafe(
      drop,
      'presentedBySponsorImageURL',
      null
    ),
    cappedDropRequest,
    status: getSafe(drop, 'status', RecordStatus.Pending),
    sport: getSafe(drop, 'sport', Sport.Lol),
    site: getSafe(drop, 'site', Site.LolEsports),
    tournamentID: getSafe(drop, 'tournamentID')
  }
}

const getDropStrategy = (
  dropsCount: number,
  response: {
    cappedDropRequest?: CappedDropRequestDTO
    cappedDropResponse?: CappedDropResponseDTO
  }
): string => {
  if (response.cappedDropRequest || response.cappedDropResponse) {
    if (dropsCount > 1) {
      return DropStrategy.Capped
    }
    else {
      return DropStrategy.CappedOnly
    }
  }
  else {
    return DropStrategy.Default
  }
}

const createReducerShape = (groupParam?: NormalizedGroup): ReducerShape => {
  const group = groupParam || initialGroup
  const orderedDropsFromGroup: DropRequestDTO[] = getSafe(
    group,
    'orderedDrops',
    []
  )

  // get normalized ordered drops array
  const orderedDrops = orderedDropsFromGroup.map((drop) =>
    mapDropResponseToDropRequest(
      drop as Partial<DropRequestDTO & DropResponseDTO>
    )
  )
  // get ordered strategies
  const orderedDropRequests = orderedDropsFromGroup.map((drop) =>
    getDropStrategy(orderedDrops.length, drop)
  )

  // Set drops to look something like this:
  // drops: {
  //   "Capped": { ...drop },
  //   "Default": { ...drop }
  // }
  const drops: Record<string, DropRequestDTO> = {}
  orderedDrops.forEach((drop) => {
    const strategy = getDropStrategy(orderedDrops.length, drop)
    drops[strategy] = drop
  })

  return {
    group: {
      ...group,
      orderedDrops,
      orderedDropRequests
    },
    drops
  }
}

const UPLOAD_DIR = 'drops'

export const editCardProgramImageURL = uploadAsset(
  'drops/editDropsetForCard',
  UPLOAD_DIR,
  UNIQUE_KEY_UPLOAD_CONFIG
)
export const editCardOverlaySponsorImageURL = uploadAsset(
  'drops/editCardOverlay',
  UPLOAD_DIR,
  UNIQUE_KEY_UPLOAD_CONFIG
)
export const editNotificationSponsorImageURL = uploadAsset(
  'drops/editNotificationSponsorImageURL',
  UPLOAD_DIR,
  UNIQUE_KEY_UPLOAD_CONFIG
)
export const editNotificationProgramImageURL = uploadAsset(
  'drops/editNotificationProgramImageURL',
  UPLOAD_DIR,
  UNIQUE_KEY_UPLOAD_CONFIG
)
export const editPresentedBySponsorImageURL = uploadAsset(
  'drops/editPresentedBySponsorImageURL',
  UPLOAD_DIR,
  UNIQUE_KEY_UPLOAD_CONFIG
)

const initialState: ReducerShape = createReducerShape()

const editAllDrops
  = <K extends keyof DropRequestDTO>(key: K, newValue: DropRequestDTO[K]) =>
    (reducerShape: ReducerShape): ReducerShape => {
      const newDrops = Object.entries(reducerShape.drops).reduce(
        (drops, [entry, value]) =>
          setSafe(drops, entry, setSafe(value, key, newValue)),
      {} as Record<string, DropRequestDTO>
      )

      return setSafe(reducerShape, 'drops', newDrops)
    }

const { reducer, actions } = createSlice({
  name: 'group',
  initialState: initialState,
  reducers: {
    initialize: () => initialState,
    loadCurrentGroup: (state: ReducerShape) => state,
    clear: () => initialState,
    editGroupDescription: (
      state: ReducerShape,
      { payload }: PayloadAction<string>
    ) => {
      const editAllDescriptions = editAllDrops('description', payload)

      const updatedDrops = editAllDescriptions(state)

      return compose(
        setCurried(updatedDrops, 'group'),
        setCurried(updatedDrops.group, 'description')
      )(payload)
    },
    editValidGeolocations: (
      state: ReducerShape,
      { payload }: PayloadAction<string[]>
    ) => editAllDrops('validGeolocations', payload)(state),
    editLeague: (state: ReducerShape, { payload }: PayloadAction<string>) =>
      editAllDrops('league', payload)(state),
    editRarity: (
      state: ReducerShape,
      { payload }: PayloadAction<EditRarityParameters>
    ) => {
      if (!payload.dropID) return state
      const drop = getSafe(state?.drops, payload.dropID, {} as DropRequestDTO)
      return compose(
        setCurried(state, 'drops'),
        setCurried(state?.drops, payload.dropID),
        setCurried(drop, 'rarityID')
      )(payload.rarityID)
    },
    setDrops: (
      state: ReducerShape,
      { payload }: PayloadAction<SetDropsParameter>
    ) => {
      const currentGroup = getSafe(state, 'group')
      const { orderedDropRequests, orderedDrops } = payload
      const newGroup = {
        ...currentGroup,
        orderedDropRequests,
        orderedDrops
      }

      return compose(setCurried(state, 'group'))(newGroup)
    },
    editCappedDropSettings: (
      state: ReducerShape,
      { payload }: PayloadAction<CappedDropSettingsChange>
    ) => {
      const { dropID, quantity, percent } = payload
      const orderedDrops = [...state.group.orderedDrops]
      const cappedDropSettings: CappedDropRequestDTO = {
        quantity: quantity >= 0 ? Math.floor(quantity) : 0,
        percent: percent <= 99 && percent >= 0 ? Math.floor(percent) : 0
      }

      const tempCappedDrop = {
        ...orderedDrops[0],
        cappedDropRequest: cappedDropSettings
      }

      orderedDrops[0] = tempCappedDrop
      return {
        ...state,
        group: {
          ...state.group,
          orderedDrops
        },
        drops: {
          ...state.drops,
          [dropID]: {
            ...state.drops[dropID],
            cappedDropRequest: cappedDropSettings
          }
        }
      }
    },
    editTitle: (state: ReducerShape, { payload }: PayloadAction<string>) =>
      editAllDrops('title', payload)(state),
    editSponsor: (state: ReducerShape, { payload }: PayloadAction<string>) =>
      editAllDrops('sponsor', payload)(state),
    editSport: (state: ReducerShape, { payload }: PayloadAction<Sport>) =>
      editAllDrops('sport', payload)(state),
    editSite: (state: ReducerShape, { payload }: PayloadAction<Site>) =>
      editAllDrops('site', payload)(state),
    editTournamentID: (
      state: ReducerShape,
      { payload }: PayloadAction<string>
    ) => editAllDrops('tournamentID', payload)(state)
  } as Record<string, Reducer<ReducerShape, AnyPayloadAction>>,
  extraReducers: {
    [createNewGroupAction.success.type]: () => initialState,
    [clearWIPGroup.type]: () => createReducerShape(),
    [cloneGroupAction.type]: (
      _,
      { payload }: PayloadAction<{ group: GroupResponseDTO }>
    ) => {
      const { id, description, createdDateEpochMillis, orderedDrops }
        = payload.group
      const mappedOrderedDrops = orderedDrops.map(mapDropResponseToDropRequest)
      const mappedOrderedDropRequests = orderedDrops.map((drop) =>
        getDropStrategy(orderedDrops.length, drop)
      )

      const normalizedGroup = {
        id,
        description,
        createdDateEpochMillis,
        orderedDrops: mappedOrderedDrops,
        orderedDropRequests: mappedOrderedDropRequests
      }
      return createReducerShape(normalizedGroup)
    },
    [addInventoryItem.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<AddInventoryParameters>
    ) => {
      const { dropID } = payload
      const drop = getSafe(state.drops, dropID, {} as DropRequestDTO)
      const withNewEmptyInventorySlot = [...drop.inventoryIDs, null]

      return compose(
        setCurried(state, 'drops'),
        setCurried(state.drops, dropID),
        setCurried(drop, 'inventoryIDs')
      )(withNewEmptyInventorySlot)
    },
    [removeInventoryItem.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<RemoveInventoryParameters>
    ) => {
      const { dropID, inventoryIndex } = payload
      const drop = getSafe(state.drops, dropID, {} as DropRequestDTO)
      const inventoriesWithRemovedIndex = drop.inventoryIDs.filter(
        (_, i) => i !== inventoryIndex
      )

      return compose(
        setCurried(state, 'drops'),
        setCurried(state.drops, dropID),
        setCurried(drop, 'inventoryIDs')
      )(inventoriesWithRemovedIndex)
    },
    [editInventoryItem.type]: (
      state,
      { payload }: PayloadAction<EditInventoryParameters>
    ) => {
      const { dropID, inventoryIndex, inventoryID } = payload
      if (!dropID || !state) return state

      const drop = getSafe(state?.drops, dropID, {} as DropRequestDTO)

      const newInventories
        = drop.inventoryIDs.length === 0 ? [] : [...drop.inventoryIDs]

      newInventories[inventoryIndex] = inventoryID

      return compose(
        setCurried(state, 'drops'),
        setCurried(state.drops, dropID),
        setCurried(drop, 'inventoryIDs')
      )(newInventories)
    },
    [editLocalizationKeys.type]: (
      state,
      {
        payload
      }: PayloadAction<{
        title: string
        description: string
      }>
    ) => {
      const editAllTitleGroups = editAllDrops(
        'titleLocalizationGroup',
        payload.title
      )
      const editAllDescriptionGroups = editAllDrops(
        'descriptionLocalizationGroup',
        payload.description
      )

      return compose(editAllTitleGroups, editAllDescriptionGroups)(state)
    },
    [removeDrop.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<{ dropID: string }>
    ) => {
      const newDrops = Object.entries(getSafe(state, 'drops', {})).reduce(
        (acc, [key, drop]) =>
          key !== payload.dropID
            ? Object.assign({}, acc, { [key]: drop })
            : acc,
        {}
      )

      return setSafe(state, 'drops', newDrops)
    },
    [addNewDrop.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<AddNewDropParameter>
    ) => {
      const { dropID, dropRequest } = payload

      const dropWithoutID: DeepPartial<DropRequestDTO> = {
        ...dropRequest,
        id: null
      }
      const newDrops = setSafe(state.drops, dropID, dropWithoutID)
      return setSafe(state, 'drops', newDrops)
    },
    [editCardProgramImageURL.success.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<string>
    ) => editAllDrops('cardProgramImageURL', payload)(state),
    [editCardOverlaySponsorImageURL.success.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<string>
    ) => editAllDrops('cardOverlaySponsorImageURL', payload)(state),
    [editNotificationProgramImageURL.success.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<string>
    ) => editAllDrops('notificationProgramImageURL', payload)(state),
    [editNotificationSponsorImageURL.success.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<string>
    ) => editAllDrops('notificationSponsorImageURL', payload)(state),
    [editPresentedBySponsorImageURL.success.type]: (
      state: ReducerShape,
      { payload }: PayloadAction<string>
    ) => editAllDrops('presentedBySponsorImageURL', payload)(state),
    [setSport.type]: (state: ReducerShape, { payload }: PayloadAction<Sport>) =>
      editAllDrops('sport', payload)(state)
  } as Record<string, Reducer<ReducerShape, AnyPayloadAction>>
})

export default reducer

const {
  loadCurrentGroup,
  initialize,
  clear,
  editGroupDescription,
  editValidGeolocations,
  editCappedDropSettings,
  setDrops,
  editRarity,
  editLeague,
  editTitle,
  editSponsor,
  editSite,
  editTournamentID
} = actions

export {
  loadCurrentGroup,
  initialize,
  clear,
  editGroupDescription,
  editValidGeolocations,
  editCappedDropSettings,
  setDrops,
  editRarity,
  editLeague,
  editTitle,
  editSponsor,
  editSite,
  editTournamentID
}

export const getWIPGroup = (state: ReducerShape): ReducerShape => state

export const getWIPFirstDrop = (state: ReducerShape): DropRequestDTO => {
  const dropID = state.group.orderedDropRequests[0]
  return state.drops[dropID]
}

export const getWIPFirstDropField = <K extends keyof DropRequestDTO>(
  state: ReducerShape,
  key: K
): DropRequestDTO[K] => getSafe(getWIPFirstDrop(state), key)

export const getWIPGroupGeolocations = (state: ReducerShape): string[] =>
  getWIPFirstDropField(state, 'validGeolocations')
export const getWIPGroupDrops = (state: ReducerShape): DropRequestDTO[] =>
  state.group.orderedDropRequests.map((id) =>
    getSafe(state.drops, id, {} as DropRequestDTO)
  )

export const getWIPDropByID = (
  state: ReducerShape,
  id: string | null
): DropRequestDTO => getSafe(state.drops, id || '', {} as DropRequestDTO)

export const isGroupDirty = (state: ReducerShape): boolean =>
  !isEqual(state, initialState)

export const isDropCapValid = (state: ReducerShape): boolean => {
  const cappedDropRequest = getWIPFirstDropField(state, 'cappedDropRequest')
  if (cappedDropRequest) {
    const quantity = cappedDropRequest.quantity
    const percent = cappedDropRequest.percent
    if (percent <= 0 && quantity <= 0 || percent > 0 && quantity > 0) {
      return false
    }
    else if (quantity <= 0 && (percent >= 100 || percent <= 0)) {
      return false
    }

    return true
  }
  return false
}
