import { ReactNode, cloneElement, useEffect, useRef, useState } from 'react'
import filterImage from '../../assets/svg/filter.svg'
import { classNames } from '../../commons'
import styles from './SearchButton.scss'

type Props = {
  children?: ReactNode
  className?: string
  onChange?: (data: any) => void
  open?: boolean
}

const SearchButton = ({
  children,
  className,
  onChange,
  open
}: Props): JSX.Element => {
  const classes = classNames(styles.container, className)
  const [menuOpen, setMenuOpen] = useState<boolean | undefined>(open)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        menuRef?.current
        && !menuRef?.current.contains(event.target as Node)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(menuOpen)
    }
  }, [menuOpen])

  useEffect(() => {
    if (menuOpen !== open) {
      setMenuOpen(open)
    }
  }, [open])

  return (
    <div className={ classes }>
      <button className={ styles.search }>
        <div
          key={ `search-button-${open}` }
          data-testid="search-button"
          className={ `${styles.searchButton} ${menuOpen ? styles.active : ''}` }
          onClick={ () => {
            setMenuOpen(!menuOpen)
          } }>
          <picture>
            <svg>
              <use href={ filterImage }/>
            </svg>
          </picture>
          <div className={ styles.text }>Filter</div>
        </div>
      </button>
      <div className={ menuOpen ? styles.menuOpen : styles.menuClosed }>
        { cloneElement(children as any, { ref: menuRef }) }
      </div>
    </div>
  )
}

export default SearchButton
