export type Props = {
  title?: string
  selectText?: string
  removeText?: string
  fileUrl?: string
  name: string
  class?: string
  imageClass?: string
  type?: AssetType
  onChange: (file?: File, url?: string) => void
}

export type State = {
  title?: string
  selectText?: string
  removeText?: string
  fileUrl?: string
  file?: File
}

export enum AssetType {
  Image = 'image/*',
  Generic = '*',
}
