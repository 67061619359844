import { IndividualTriggerMetaDTO } from './IndividualTriggerMeta.type'
import { createSlice } from '../../../../actions/utils'
import { Action } from '../../../../Store.type'
import { isEqual } from '../../../../../commons'

const initialState: IndividualTriggerMetaDTO = {
  puuid: null,
  dropID: null
}

const { reducer, actions } = createSlice({
  name: 'individualTriggerMeta',
  initialState: initialState,
  reducers: {
    initialize: () => initialState,
    clear: () => initialState,
    editPuuid: (state, { payload }: Action<string>) => ({
      ...state,
      puuid: payload
    }),
    editDropID: (state, { payload }: Action<string>) => ({
      ...state,
      dropID: payload
    })
  },
  extraReducers: {}
})

export default reducer
const { initialize, clear, editPuuid, editDropID } = actions

export { initialize, clear, editPuuid, editDropID }

export const getWIPIndividualTriggerMeta = (
  state: IndividualTriggerMetaDTO
): IndividualTriggerMetaDTO => state
export const isIndividualTriggerMetaDirty = (
  state: IndividualTriggerMetaDTO
): boolean => !isEqual(state, initialState)
