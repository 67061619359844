import { Dispatch } from 'redux'
import { AwsS3, EventBus } from '../../../../services'
import { Bucket } from '../../../../services/AwsS3/AwsS3.type'
import {
  createBannerLeague,
  fetchBannerLeagues,
  putConfiguration
} from '../../../../services/BannersService/BannersService'
import {
  League,
  LeagueConfigurationStatus
} from '../../../../services/BannersService/BannersService.type'
import { postSlackMessage } from '../../../../services/Slack/Slack'
import { RootState } from '../../../Store.type'
import {
  getBannerLeagues,
  getLeagues,
  getWIPBannerConfiguration
} from '../../../reducers'
import { createAction } from '../../utils'

export const clearWIPBannerConfig = createAction('banners/clearWIPConfig')

export const createBannerConfigStart = createAction('banners/createNew/start')
export const createBannerConfigSuccess = createAction(
  'banners/createNew/success'
)
export const createbannerConfigFailed = createAction('banners/createNew/failed')

export const createNewConfig
  = () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(createBannerConfigStart())

    const config = getWIPBannerConfiguration(getState())
    const configFile = new File([JSON.stringify(config)], 'config.json')

    const bannerLeagues = getBannerLeagues(getState())
    const bannerLeague = bannerLeagues[config.league as string]
    const bannerPatch
      = bannerLeague
      && bannerLeague.patches.find((patch) => patch.patch === config.patch)
    const configVersion = bannerPatch
      ? bannerPatch.configuration.version + 1
      : 1

    let configUrl = ''
    try {
      configUrl = await AwsS3.uploadFile(
        `${config.league}/${config.patch}/v${configVersion}`,
        configFile,
        {
          bucket: Bucket.Banners,
          uniqueKey: false
        }
      )
    }
    catch (e) {
      return dispatch(createbannerConfigFailed())
    }

    const eldsLeagues = getLeagues(getState())
    const eldsLeagueSlug = eldsLeagues[config.league as string].name.slug

    const configPayload: League = {
      id: bannerLeague && bannerLeague.id,
      eldsId: config.league as string,
      slug: eldsLeagueSlug,
      patches: [
        {
          patch: config.patch as string,
          configuration: {
            id: bannerPatch?.configuration.id as string,
            url: configUrl,
            status: LeagueConfigurationStatus.NeedsScreenshots,
            version: configVersion
          }
        }
      ]
    }

    try {
      bannerLeague
        ? await putConfiguration(configPayload, 0)(dispatch)
        : await createBannerLeague(configPayload)(dispatch)

      EventBus.trigger('message', {
        type: 'success',
        message: `Configuration created!`
      })
    }
    catch (e) {
      return dispatch(createbannerConfigFailed())
    }

    await postSlackMessage({
      type: 'configCreated',
      leagueName: eldsLeagueSlug,
      patch: config.patch as string,
      version: configVersion.toString()
    })(dispatch)

    dispatch(clearWIPBannerConfig())
    window.route('banners/list')

    await fetchBannerLeagues()(dispatch)

    return dispatch(createBannerConfigSuccess())
  }

createNewConfig.start = createBannerConfigStart
createNewConfig.success = createBannerConfigSuccess
createNewConfig.failed = createbannerConfigFailed
