import { classNames } from '../../commons'
import styles from './Table.scss'
import { Header, Props } from './Table.type'

const Table = (props: Props): JSX.Element => {
  const { headers, data } = props
  const tableClasses = classNames(styles.table, props.class)
  const rowClasses = classNames(styles.row)

  const renderHeader = (header: Header, index: number): JSX.Element => {
    const { label, class: styleClass } = header
    const headerClasses = classNames(styles.text, header.headerLabelClass)

    return (
      <th
        className={ classNames(styles.header, styleClass) }
        key={ `${header.key || header.label}` }>
        { label && <div className={ headerClasses }>{ label }</div> }
      </th>
    )
  }

  return (
    <table className={ tableClasses }>
      <thead>
        <tr className={ styles.row } role="header">
          { headers.map((header, index) => renderHeader(header, index)) }
        </tr>
      </thead>
      <tbody>
        { data.map((rowEntry, rowIndex) => (
          <tr className={ rowClasses } role="row" key={ rowIndex }>
            { headers.map((header, index) => (
              <td
                className={ header.class }
                key={ `${header.key || header.label}-${index}` }>
                { rowEntry[header.key || header.label] as string }
              </td>
            )) }
          </tr>
        )) }
        { data.length === 0 && (
          <tr className={ styles.emptyRow } role="row">
            <td colSpan={ headers.length }>Nothing to display.</td>
          </tr>
        ) }
      </tbody>
    </table>
  )
}

export default Table
