import { combineReducers } from 'redux'
import bannerConfigurationReducer, * as fromBannerConfiguration from './Configuration/BannerConfiguration'
import { BannerConfigurationDTO } from '../../../../containers/Banners/Banners.type'

const combinedReducer = combineReducers({
  bannerConfiguration: bannerConfigurationReducer
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

export const getWIPBannerConfiguration = (
  state: StateShape
): BannerConfigurationDTO =>
  fromBannerConfiguration.getWIPBannerConfiguration(state.bannerConfiguration)

export const isBannerConfigurationDirty = (state: StateShape) =>
  fromBannerConfiguration.isBannerConfigurationDirty(state.bannerConfiguration)

export const getBannerGroups = (state: StateShape) =>
  fromBannerConfiguration.getBannerGroups(state.bannerConfiguration)

export const isBannerConfigurationUploading = (state: StateShape) =>
  fromBannerConfiguration.isBannerConfigurationUploading(
    state.bannerConfiguration
  )

export const wipConfigHasAssignedAssets = (state: StateShape) =>
  fromBannerConfiguration.wipConfigHasAssignedAssets(state.bannerConfiguration)
