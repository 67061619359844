import { NavLink } from 'react-router-dom'
import { classNames } from '../../commons'
import styles from './Header.scss'
import { Props } from './Header.type'

const Header = ({ label, title, subtext, links }: Props): JSX.Element => {
  const renderLinks = () => {
    if (!links) {
      return null
    }
    return (
      <div className={ styles.links }>
        { links.map((link) => (
          <NavLink
            className={ ({ isActive, isPending }) =>
              isActive || isPending
                ? classNames(styles.active, styles.anchor)
                : styles.anchor
            }
            to={ link.url }
            key={ link.label }>
            { link.label }
          </NavLink>
        )) }
      </div>
    )
  }

  const renderSubtext = () => <p className={ styles.subtext }>{ subtext }</p>

  return (
    <section className={ styles.header }>
      <span className={ styles.eyebrow }>{ label }</span>
      <h1 className={ styles.title }>{ title }</h1>
      { subtext && renderSubtext() }
      { links && renderLinks() }
    </section>
  )
}

export default Header
