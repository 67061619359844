import { applyMiddleware, compose, createStore, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'

export default function configureStore (preloadedState?: object): Store {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]

  // workaround for the typing of __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  const browserWindow = window as any
  const composeEnhancers
    = typeof browserWindow !== 'undefined'
      && browserWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    || compose
  const composedEnhancers = composeEnhancers(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}
