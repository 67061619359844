import { createSlice } from '../../actions/utils'
import { Action, AnyPayloadAction } from '../../Store.type'
import { Sport } from '@riotgames/api-types/elds/Common.type'
import { UserConfig } from './UserConfig.type'
import { Reducer } from 'redux'

const initialState: UserConfig = {
  sport: Sport.Lol
}

const { actions, reducer } = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    setSport: (state, { payload }: Action<Sport>) => ({
      ...state,
      sport: payload
    })
  } as Record<string, Reducer<UserConfig, AnyPayloadAction>>
})

export default reducer

type StateShape = ReturnType<typeof reducer>

const { setSport } = actions

export { setSport }

export const getSport = (state: StateShape): Sport => state.sport
