// TODO: export from Common library when more "stable"

export type LeaguesResponse = {
  leagues: League[]
}

export type League = {
  id: string
  eldsId: string
  slug: string
  patches: LeaguePatch[]
}

export type LeaguePatch = {
  patch: string
  configuration: LeagueConfiguration
}

export type LeagueConfiguration = {
  id: string
  url: string
  status: LeagueConfigurationStatus
  version: number
}

export enum LeagueConfigurationStatus {
  NeedsScreenshots = 'needs_screenshots',
  NeedsReview = 'needs_review',
  Approved = 'approved',
  Submitted = 'submitted',
}

export type PermissionsResponse = {
  accessLevel: PermissionsResponseAccessLevel
}

export enum PermissionsResponseAccessLevel {
  FullAccess = 'full_access',
  Disallowed = 'disallowed',
}
