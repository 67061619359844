import { useEffect } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ts-ignore to support custom import of Core SortableJS (without default plugins)
// @ts-ignore
import Sortable from 'sortablejs/modular/sortable.core.esm.js'
import { classNames } from '../../commons'
import { SortableEvent, SortableListProps } from './SortableList.type'
import styles from './SortableList.scss'

const SortableList = (props: SortableListProps): JSX.Element => {
  let base: HTMLElement | null = null
  const classes = classNames(props.class, styles.sortableList)

  useEffect(() => {
    initializeSortable()
    props.data && initializeData()

    base?.addEventListener('onTestOnEnd', (event) => {
      props.onEnd?.((event as CustomEvent).detail)
    })

    return () => {
      base?.removeEventListener('onTestOnEnd', (event) => {
        props.onEnd?.((event as CustomEvent).detail)
      })
    }
  }, [])

  const initializeData = (): void => {
    Object.keys(props.data || {}).map((key) => {
      const baseInit = base as HTMLElement
      baseInit.setAttribute(`data-${key}`, props.data?.[key] || '')
    })
  }

  const initializeSortable = (): void => {
    /* https://github.com/SortableJS/Sortable */
    Sortable.create(base, {
      group: 'all',
      animation: 150,
      ghostClass: styles.sortableGhost,
      disabled: props.disabled,
      onChange: (event: SortableEvent): void => {
        const movedElement = event.item

        // grab the previous class added to the moved element, or the moved child class
        // from the first list it was moved from
        const currentMovedClass
          = movedElement.dataset.currentMovedClass
          || event.from.dataset.movedChildClass
          || ''
        if (currentMovedClass) {
          movedElement.classList.remove(currentMovedClass)
        }

        const newClass = event.to.dataset.movedChildClass
        if (newClass) {
          movedElement.classList.add(newClass || '')
          movedElement.setAttribute('data-current-moved-class', newClass || '')
        }
      },
      onAdd: (event: SortableEvent): void => {
        // https://github.com/SortableJS/react-sortablejs/issues/55
        // React DOM sees it needs to remove the list item you dragged to the right,
        // but when it tries to remove it, it cannot because Sortable has already moved it to the other list.
        // To get around this, we temporarily hide the element, then move it back to the original list.
        // React will remove it from the DOM, and Sortable will move it to the new list.
        const movedElement = event.item
        const movedElementParent = event.from
        movedElement.style.display = 'none'
        movedElementParent.appendChild(movedElement)
      },
      onEnd: (event: SortableEvent): void => {
        props.onEnd && props.onEnd(event)
      }
    })
  }

  return (
    <div
      data-testid="sortable-list"
      className={ classes }
      data-moved-child-class={ props.movedChildClass }
      ref={ (dom): void => {
        base = dom
      } }>
      { props.children }
    </div>
  )
}

export default SortableList
