import { Envs, Env, RegexEnvMap, env } from '@riotgames/commons/env'
import { AppWindow } from '../../index.type'
import Logger from '../Logger/Logger'
import { EnvObject } from './Config.type'

const log = new Logger('Config')

const defaultEnv = Envs.Prod
const appWindow = window as AppWindow
const version = appWindow.APP_VERSION

export const envMap: Dict<Env> = {
  // local
  '127.0.0.1': Envs.Local,
  'localhost': Envs.Local,
  'local.emp.riotesports.com': Envs.Local,

  // dev
  'dev.emp.riotesports.com': Envs.Dev,
  // test
  'test.emp.riotesports.com': Envs.Test,
  // prod
  'emp.lolesports.com': Envs.Prod,
  'emp.riotesports.com': Envs.Prod
}

// Regex match for Netlify hosted previews in PRs
export const regexEnvMap: RegexEnvMap = [
  {
    regex: /^[a-z0-9-]*\.emp\.riotesports\.com$/i,
    env: Envs.Dev
  }
]

class Config {
  constructor () {
    env.init(envMap, version, regexEnvMap)
  }

  getEnv (apiEnvOverrideKey?: string): string {
    const savedEnv = window.sessionStorage.getItem('env')
    const envOverride = apiEnvOverrideKey
      ? window.sessionStorage.getItem(apiEnvOverrideKey)
      : undefined
    return (
      envOverride
      || savedEnv
      || envMap[window.location.hostname]
      || this.getDefaultFromEnvMap(regexEnvMap)
      || defaultEnv
    )
  }

  setEnv (env: string): void {
    if (env) {
      window.sessionStorage.setItem('env', env)
    }
  }

  isProdEnv (): boolean {
    return this.getEnv() === Envs.Prod
  }

  getKeyByEnv (obj: EnvObject, apiEnvOverride?: string): string | undefined {
    const env = (apiEnvOverride || this.getEnv()) as keyof EnvObject
    return obj[env]
  }

  getDefaultFromEnvMap (regexHostToEnv: RegexEnvMap) {
    if (regexHostToEnv) {
      // eslint-disable-next-line no-restricted-syntax
      for (const { regex, env } of regexHostToEnv) {
        if (regex.test(window.location.hostname)) {
          return env
        }
      }
    }
  }
}

export default new Config()
