import { AnyAction, Dispatch, Reducer } from 'redux'
import { createSelector } from 'reselect'
import {
  Action,
  ActionCreator,
  AnyPayloadAction,
  RootState,
  Thunk
} from '../Store.type'

export const createAction = <T, K = null>(
  type: string,
  mapPayload?: (payload?: T) => T,
  mapMeta?: (meta?: K) => K
): ActionCreator<T, K> => {
  const actionCreator = (payload?: T, meta?: K): Action<T, K> => ({
    type,
    payload: mapPayload ? mapPayload(payload) : payload,
    meta: mapMeta ? mapMeta(meta) : meta
  })

  // Overriding these lets us use the returned Action Creator in place of a
  // constant that holds the name of the action
  actionCreator.type = type
  actionCreator.toString = (): string => type

  return actionCreator
}

export const createReducer = <S, A extends AnyPayloadAction>(
  initialState: S,
  reducers: Record<string, Reducer<S, A>>
): Reducer<S, A> => (state: S = initialState, action: A): S =>
    reducers[action.type] ? reducers[action.type](state, action) : state

export type SliceConfig<S> = {
  name: string
  initialState: S
  reducers?: Record<string, Reducer<S, AnyPayloadAction>>
  extraReducers?: Record<string, Reducer<S, AnyPayloadAction>>
}

type SliceRet<S> = {
  reducer: Reducer<S, AnyPayloadAction | AnyAction>
  actions: Record<string, ActionCreator<any, any>>
}

export const createSlice = <S>(config: SliceConfig<S>): SliceRet<S> => {
  const reducers = config.reducers || {}

  const intermediate = Object.entries(reducers).reduce(
    (acc, [key, value]) => {
      const actionName = `${config.name}/${key}`
      acc.actions[key] = createAction(actionName)
      acc.reducer[actionName] = value

      return acc
    },
    {
      actions: {} as Record<string, ActionCreator>,
      reducer: {} as Record<string, Reducer<S, AnyPayloadAction>>
    }
  )

  const reducerMap = config.extraReducers
    ? { ...intermediate.reducer, ...config.extraReducers }
    : intermediate.reducer

  return {
    actions: intermediate.actions,
    reducer: createReducer(config.initialState, reducerMap) as Reducer<
      S,
      AnyPayloadAction | AnyAction
    >
  }
}

export const createThunk = <T extends (...params: any[]) => Promise<any>>(
  key: string,
  api: T
): Thunk<T> => {
  const startAction = createAction<Parameters<T>>(`${key}/start`)
  const successAction = createAction<ReturnType<T>, Parameters<T>>(
    `${key}/success`
  )
  const failedAction = createAction<Error, Parameters<T>>(`${key}/failed`)
  const actionCreator = (...params: Parameters<T>) => (dispatch: Dispatch) => {
    dispatch(startAction(params))

    return api(...params).then(
      (response: ReturnType<T>) => dispatch(successAction(response, params)),
      (error: Error) => {
        dispatch(failedAction(error, params))
        throw error
      }
    )
  }

  actionCreator.start = startAction
  actionCreator.success = successAction
  actionCreator.failed = failedAction

  return actionCreator
}

export const isLoading = (
  thunk: Thunk<any>,
  initialState = true
): Reducer<boolean, AnyPayloadAction> =>
  createReducer(initialState, {
    [thunk.start.type]: () => true,
    [thunk.success.type]: () => false,
    [thunk.failed.type]: () => false
  })

export const isLoadingSelector = (
  ...selectors: ((state: RootState) => boolean)[]
) =>
  createSelector(selectors, (...args: boolean[]) =>
    // Returns true until all dependencies are false
    args.some((arg) => arg)
  )
