import radioImageSelected from '../../assets/svg/radioSelected.svg'
import radioImageUnselected from '../../assets/svg/radioUnselected.svg'
import { classNames } from '../../commons'

import styles from './RadioButton.scss'

type Props = {
  selected?: string
  class?: string
  label: string
  onChange?: (target: any) => void
}

const RadioButton = ({
  selected,
  label,
  onChange,
  class: className
}: Props): JSX.Element => {
  const classes = classNames(styles.radio, className)
  const sanitizedLabel = label?.replace(/\s/g, '-')
  const key = `radio-${sanitizedLabel}-${selected}`
  const onChangeHandler = (event: any): void => {
    event.stopPropagation()
    if (onChange) {
      onChange({ event, key })
    }
  }
  return (
    <div
      key={ key }
      className={ classes }
      onClick={ onChangeHandler }
      data-testid={ key }>
      <div
        data-testid={ `${key}-btn` }
        className={ selected === label ? styles.selected : styles.unselected }>
        <svg>
          <title>Radio</title>
          <use href={ selected ? radioImageSelected : radioImageUnselected }/>
        </svg>
      </div>
      <label className={ styles.label }>{ label }</label>
    </div>
  )
}

export default RadioButton
