import { RecordStatus } from '@riotgames/api-types/elds/Common.type'
import { Season, Split } from '@riotgames/api-types/elds/Seasons.type'
import { AnyAction, combineReducers, Reducer } from 'redux'
import { createSelector } from 'reselect'
import { isEqual, replaceAt } from '../../commons'
import { createNewSeason, editSeason } from '../../services/Elds/Elds.actions'
import { createSlice } from '../../store/actions/utils'
import { Action, PayloadAction, RootState } from '../../store/Store.type'
import {
  HasLocalizedStringsWithSlug,
  updateSlug
} from '../Tournament/Utils/Utils'
import {
  EditSeasonPayload,
  EditSplitPayload,
  SeasonUIData,
  SeasonUIPayload
} from './Season.type'

type ModelShape = Record<string, Nullable<Season>>

export const getBeginningOfYear = () => {
  const today = new Date()
  const beginningOfYear = new Date(today.getUTCFullYear(), 0, 1)

  return beginningOfYear.toISOString()
}

export const createSeason = (): Nullable<Season> => ({
  id: null,
  name: {
    slug: 'my_season',
    localizations: {}
  },
  status: RecordStatus.Active,
  startTime: getBeginningOfYear(),
  endTime: getBeginningOfYear(),
  awardedCircuitPoints: [],
  splits: []
})

const updateSeason
  = <T extends keyof Season>(key: T) =>
    (
      state: Maybe<ModelShape>,
      { payload }: PayloadAction<EditSeasonPayload<Season[T]>>
    ) => {
      const season = state?.[payload.seasonId]
      return {
        ...state,
        [season?.id as string]: {
          ...season,
          [key]: payload.value
        }
      } as ModelShape
    }

const updateSplits
  = (updater: (splits: Split[], payload: EditSplitPayload) => Split[]) =>
    (state: Maybe<ModelShape>, { payload }: PayloadAction<EditSplitPayload>) => {
      const season = state?.[payload.seasonId]

      return {
        ...state,
        [season?.id as string]: {
          ...season,
          splits: updater(season?.splits as Split[], payload)
        }
      } as ModelShape
    }

const copyExistingSeasonReducer = (
  state: Maybe<ModelShape>,
  { payload }: PayloadAction<Season>
): ModelShape => ({
  ...state,
  [payload.id]: payload
})

// #region Reducers

// #region Season Slice
const seasonSlice = createSlice({
  name: 'season',
  initialState: {} as ModelShape,
  reducers: {
    initNewSeason: (state: Maybe<ModelShape>) => ({
      ...state,
      null: createSeason()
    }),
    copyForEdit: copyExistingSeasonReducer,
    editSeasonSlug: (
      state: Maybe<ModelShape>,
      { payload }: PayloadAction<EditSeasonPayload<string>>
    ) => {
      const season = state?.[payload.seasonId]

      return {
        ...state,
        [season?.id as string]: updateSlug(
          season as HasLocalizedStringsWithSlug,
          payload.value
        )
      } as ModelShape
    },
    editSeasonStartTime: updateSeason('startTime'),
    editSeasonEndTime: updateSeason('endTime'),
    addSplit: updateSplits((splits, payload) =>
      splits.concat(payload.value || [])
    ),
    editSplit: updateSplits((splits, payload) =>
      replaceAt(splits, payload.value as Split, payload.index as number)
    ),
    deleteSplit: updateSplits((splits, payload) =>
      splits.filter((_, index) => index !== payload.index)
    )
  },
  extraReducers: {
    [createNewSeason.success.type]: (state) => ({
      ...state,
      null: createSeason()
    }),
    [editSeason.success.type]: (state, { payload }: PayloadAction<Season>) => ({
      ...state,
      [payload.id]: payload
    })
  } as Record<string, Reducer<ModelShape, AnyAction>>
})

export const {
  initNewSeason,
  copyForEdit,
  editSeasonSlug,
  editSeasonStartTime,
  editSeasonEndTime,
  addSplit,
  editSplit,
  deleteSplit
} = seasonSlice.actions

// #endregion

// #region UI Data Slice

const uiDataSlice = createSlice({
  name: 'season',
  initialState: {
    showModal: null,
    splitIndex: 0
  } as SeasonUIData,
  reducers: {
    showModal: (state, { payload }: Action<SeasonUIPayload>) =>
      ({
        ...state,
        showModal: payload?.modal,
        splitIndex: payload?.index
      } as SeasonUIData),
    hideModal: (state) =>
      ({
        ...state,
        showModal: null
      } as SeasonUIData)
  },
  extraReducers: {
    [addSplit.type]: (state) => ({
      ...state,
      showModal: null
    }),
    [editSplit.type]: (state) => ({
      ...state,
      showModal: null
    })
  } as Record<string, Reducer<SeasonUIData, AnyAction>>
})

export const { showModal, hideModal } = uiDataSlice.actions

// #endregion

const combinedReducer = combineReducers({
  seasons: seasonSlice.reducer,
  uiData: uiDataSlice.reducer
})

export default combinedReducer
type StateShape = ReturnType<typeof combinedReducer>

// #endregion

// #region Selectors
const getSeasons = (state: StateShape) => state.seasons

export const getWIPSeasonById = (state: StateShape, id: string) =>
  getSeasons(state)[id]

export const getSlugForSeason = createSelector(
  [getWIPSeasonById],
  (season) => season?.name?.slug
)

export const getStartTimeForSeason = createSelector(
  [getWIPSeasonById],
  (season) => season?.startTime || ''
)

export const getEndTimeForSeason = createSelector(
  [getWIPSeasonById],
  (season) => season?.endTime || ''
)

export const getWIPSplitByIndex = createSelector(
  [getWIPSeasonById, (state, seasonId, index) => index],
  (season, index) => season?.splits?.[index]
)

export const isSeasonDirty = createSelector(
  [getWIPSeasonById, (state: any, seasonId: string, season: Season) => season],
  (model: Nullable<Season>, season: Season) => {
    const compareTo = season || createSeason()
    return !isEqual(model, compareTo)
  }
)

export const getUIDataForSplits = (state: StateShape) => state.uiData

// #endregion
