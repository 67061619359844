import styles from './Panel.scss'

type PanelProps = {
  id?: string
  title?: string
} & JSX.IntrinsicElements['div']

const Panel = (props: PanelProps): JSX.Element => (
  <div
    id={ props.id || '' }
    data-testid="panel-container"
    className={ `${styles.panel} ${props.className}` }>
    <div className={ styles.title } data-testid="panel-title">
      { props.title }
    </div>
    <div className={ styles.body } data-testid="panel-body">
      { props.children }
    </div>
  </div>
)

export default Panel
