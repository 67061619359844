import { EnvObject } from 'commons/Config/Config.type'
import { User, UserManager, WebStorageStateStore } from 'oidc-client'
import { Config, Logger } from '../../commons'

const log = new Logger('SingleSignOn')

// Util's fetch will need SSO user retrieval which will introduce circular dependency
// Therefore, to avoid circular dependency, copied urlBuilder from Util.
const _urlBuilder
  = (urls: EnvObject, apiEnvOverride?: string): Function =>
    (endpoint: string, params?: Record<string, string>): string => {
      const url = Config.getKeyByEnv(urls, apiEnvOverride)
      const queryString = params
        ? `?${Object.entries(params)
          .map((pair) => pair.join('='))
          .join('&')}`
        : ''
      return url + endpoint + queryString
    }

const baseUrl = _urlBuilder({
  local: 'https://riotgames-sandbox.okta.com',
  dev: 'https://riotgames-sandbox.okta.com',
  test: 'https://riotgames-sandbox.okta.com',
  prod: 'https://sso.riotgames.com'
})

const clientId = Config.getKeyByEnv({
  local: '0oa2pcf2khfx9epxn4x7',
  dev: '0oa2pcf2khfx9epxn4x7',
  test: '0oa2pcf2khfx9epxn4x7',
  prod: '0oa1l6pvdhutSkj6f0h8'
})

const redirectPath = '/oidc/consume'

const redirectUri = Config.getKeyByEnv({
  local: `https://local.emp.riotesports.com:8000${redirectPath}`,
  dev: `${window.location.origin}${redirectPath}`,
  test: `${window.location.origin}${redirectPath}`,
  prod: `${window.location.origin}${redirectPath}`
})

const settings = {
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: false,
  authority: baseUrl(''),

  /* eslint-disable */
  client_id: clientId,
  redirect_uri: redirectUri,
  response_mode: 'fragment',
  response_type: 'id_token token',
  /* eslint-enable */

  scope: 'openid profile groups',

  filterProtocolClaims: true,
  loadUserInfo: true,

  userStore: new WebStorageStateStore({ store: window.localStorage })
}

const userManager = new UserManager(settings)
class SingleSignOn {
  /**
   * Return a promise that resolves to a boolean as to whether the user
   * is logged in.
   */
  async isAuthenticated (): Promise<boolean> {
    const user = await userManager.getUser()
    if (user == null || user.expired) {
      await userManager.clearStaleState()
      await userManager.removeUser()
      return false
    }

    return true
  }

  /**
   * Return a promise that resolves to the results of redirecting to the
   * Single Sign On provider to authenticate the user.
   * @param prompt If specified, controls the value of the prompt query parameter, defaults to false
   * If true, the value of the prompt query parameter will be 'login' and the user will be prompted
   * to login. If false, no login prompt will result if the user is not logged in.
   */
  async authenticate (prompt = false): Promise<void> {
    await userManager.signinRedirect({
      prompt: prompt ? 'login' : 'none'
    })
  }

  /**
   * Return a promise that resolves to the authenticated user's id token
   */
  async getAccessToken (): Promise<string> {
    const user = await this.getUser()
    return user ? user.access_token : ''
  }

  async getIdToken (): Promise<string> {
    const user = await this.getUser()
    return user ? user.id_token : ''
  }

  async getTokens (): Promise<{ idToken: string; accessToken: string } | {}> {
    const user = await this.getUser()
    return user
      ? {
        idToken: user.id_token,
        accessToken: user.access_token
      }
      : {}
  }

  /**
   * Return a promise that resolves to the authenticated user
   */
  async getUser (): Promise<User | null> {
    return userManager.getUser()
  }

  async handleAuthenticationResponse (): Promise<void> {
    let failedAuth = false
    await userManager
      .signinRedirectCallback()
      .catch((error) => {
        log.error(error)
        failedAuth = true
      })
      .finally(async () => {
        await userManager.clearStaleState()
      })

    if (failedAuth) {
      this.authenticate(true)
      return
    }

    // Register to be notified of specific oidc events
    userManager.events.addAccessTokenExpired(this.onAccessTokenExpired)
    userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring)
    userManager.events.addSilentRenewError(this.onSilentRenewError)

    // Set the location back to the home page
    // TODO: Set it to the original requested path
    window.location.href = `${window.location.protocol}//${window.location.host}`
  }

  isCallbackUrl (): boolean {
    return window.location.pathname === redirectPath
  }

  onAccessTokenExpired (event: Event): void {
    log.debug('The SSO access token has expired, ' + event)
  }

  onAccessTokenExpiring (event: Event): void {
    log.debug('The SSO access token is expiring, ' + event)
  }

  onSilentRenewError (error: Error): void {
    log.debug('An SSO Renew error occurred, ' + error)
  }
}

export default new SingleSignOn()
