import { createSlice, isLoading } from '../../store/actions/utils'
import { getPuuidsByDropId } from './ViewerAccount.actions'
import { combineReducers } from 'redux'
import { PuuidDrop } from './ViewerAccount.type'
import { PayloadAction } from '../../store/Store.type'

const puuids = createSlice({
  name: 'viewerAccountPuuid',
  initialState: {} as Record<string, PuuidDrop>,
  extraReducers: {
    [getPuuidsByDropId.success.type]: (
      state,
      { payload }: PayloadAction<PuuidDrop>
    ) => ({
      ...state,
      [payload.dropId]: payload
    })
  }
})

const isLoadingReducer = combineReducers({
  isPuuidsLoading: isLoading(getPuuidsByDropId)
})

const combinedReducer = combineReducers({
  puuids: puuids.reducer,
  isLoading: isLoadingReducer
})

export default combinedReducer

// #region Selectors
type StateShape = ReturnType<typeof combinedReducer>

export const getPuuidsByDrop = (state: StateShape, dropId: string): PuuidDrop =>
  state.puuids && state.puuids[dropId]

export const isPuuidsLoading = (state: StateShape): boolean =>
  state.isLoading.isPuuidsLoading
