import { classNames } from '../../commons'
import styles from './Section.scss'

type Props = {
  title?: string
  class?: string
  children?: React.ReactNode
}

/**
 * A Section is a portion of a page delimited by a bottom border. Section is
 * intended to help break up logically separate portions of the page in a
 * consistent fashion.  Section renders its children within a grid for ease of
 * layout.
 *
 * Props:
 * - Title: An optional title to label the Section
 * - Class: An optional class that is appended to the parent container.
 */
const Section = (props: Props): JSX.Element => {
  const classes = classNames(styles.section, props.class)

  return (
    <div className={ classes }>
      { props.title && <h2 className={ styles.title }>{ props.title }</h2> }
      { props.children }
    </div>
  )
}

export default Section
