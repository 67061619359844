import {
  StatsFrame,
  StatsWindow
} from '@riotgames/api-types/events-api/aggregates-api.type'
import { Envs } from '@riotgames/commons/env'
import { Config, fetch, times, urlBuilder } from '../../commons'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'

export const LiveStatsApiEnvSessionStorageKey = 'liveStatsApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.LiveStats,
  Config.getEnv(LiveStatsApiEnvSessionStorageKey)
)
const livestatsBaseUrl = `${apiBaseUrl}/livestats/v1/windowUnrestricted/`

const livestatsUrl = urlBuilder({
  local: livestatsBaseUrl,
  dev: livestatsBaseUrl,
  test: livestatsBaseUrl,
  prod: livestatsBaseUrl
})

class LiveStatsApi {
  static async getOptions (method = 'GET'): Promise<RequestInit> {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    return {
      method: method,
      headers: headers
    }
  }

  async fetchEvents (gameId: string, timestamp?: string): Promise<StatsFrame[]> {
    const json: StatsWindow = await fetch(
      livestatsUrl(gameId, timestamp && { startingTime: timestamp }),
      await LiveStatsApi.getOptions()
    )
    return json.frames
  }

  async fetchLivestatsFrames (
    gameId: string,
    timestamp?: string
  ): Promise<StatsWindow> {
    return await fetch(
      livestatsUrl(gameId, timestamp && { startingTime: timestamp }),
      await LiveStatsApi.getOptions()
    )
  }

  async fetchFirstFrame (gameId: string): Promise<StatsWindow> {
    return await fetch(livestatsUrl(gameId), await LiveStatsApi.getOptions())
  }

  static async fetchGameStartFrameHelper (
    gameId: string,
    timestamp?: string
  ): Promise<StatsWindow> {
    const liveStatsFrames: StatsWindow = await fetch(
      livestatsUrl(gameId, timestamp && { startingTime: timestamp }),
      await LiveStatsApi.getOptions()
    )
    const frames = liveStatsFrames?.frames

    if (!frames || frames.length === 0) return {} as StatsWindow

    const frame = frames.find(
      (frame) =>
        frame.blueTeam.totalGold !== 0 || frame.redTeam.totalGold !== 0
    )

    const lastDate = new Date(
      timestamp ?? frames[frames.length - 1].rfc460Timestamp
    )

    let nextFetchDate = new Date(
      lastDate.setSeconds(Math.round(lastDate.getSeconds() / 10) * 10)
    ).setMilliseconds(0)

    if (nextFetchDate === Date.parse(lastDate.toISOString())) {
      // these are the same, need to increment by 10 seconds. This happens due because the API
      // returns the game start window when time is omitted.
      nextFetchDate += times.SECONDS * 10
    }

    return frame
      ? liveStatsFrames
      : await LiveStatsApi.fetchGameStartFrameHelper(
        gameId,
        new Date(nextFetchDate).toISOString()
      )
  }

  async fetchGameStartFrame (gameId: string) {
    return await LiveStatsApi.fetchGameStartFrameHelper(gameId)
  }
}

export default new LiveStatsApi()
