import { Logger } from '../commons'
import { RootState } from './Store.type'

const log = new Logger('Redux -> LocalStorage')

export const loadState = (version: string): Partial<RootState> | undefined => {
  try {
    const serializedState = localStorage.getItem('redux::persistedState')
    if (serializedState === null) return undefined

    const response = JSON.parse(serializedState)

    return response.metadata && response.metadata.version === version
      ? response
      : undefined
  }
  catch (e) {
    return undefined
  }
}

export const saveState = (state: Partial<RootState>): void => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('redux::persistedState', serializedState)
  }
  catch (e) {
    log.error('Unable to persist state to local storage', e)
  }
}
