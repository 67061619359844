import { TriggerFulfillmentStatusDTO } from '@riotgames/api-types/drops/DropsV2.type'
import { Envs } from '@riotgames/commons/env'
import { Config, fetch } from '../../commons'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'
import { PuuidDrop } from './ViewerAccount.type'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.ViewerAccount,
  Config.getEnv()
)
const viewerAccountBaseUrl = `${apiBaseUrl}/fandom-account/v1/`

const viewerAccountUrl = Config.getKeyByEnv({
  local: viewerAccountBaseUrl,
  dev: viewerAccountBaseUrl,
  test: viewerAccountBaseUrl,
  stage: viewerAccountBaseUrl,
  prod: viewerAccountBaseUrl
}) as any

export const FulfillmentStatuses = {
  CONTENT_EXPERIENCES_RETRY: 'CONTENT_EXPERIENCES_RETRY',
  AWAITING_ASYNC_CALL: 'AWAITING_ASYNC_CALL',
  CONTENT_EXPERIENCES_ERROR: 'CONTENT_EXPERIENCES_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  REQUESTED: 'REQUESTED',
  PLAYER_NOT_FOUND: 'PLAYER_NOT_FOUND',
  PLAYER_NOT_ELIGIBLE: 'PLAYER_NOT_ELIGIBLE',
  PACKAGE_CONFIGURATION_ERROR: 'PACKAGE_CONFIGURATION_ERROR',
  PLAYER_FOUND: 'PLAYER_FOUND',
  ACCOUNT_LOOKUP_FAILED: 'ACCOUNT_LOOKUP_FAILED',
  SHARD_NOT_FOUND: 'SHARD_NOT_FOUND',
  ALREADY_OWNED: 'ALREADY_OWNED',
  FULFILLED: 'FULFILLED',
  ERROR: 'ERROR',
  ACTIVATED: 'ACTIVATED',
  ACTIVATED_NO_RENEWAL: 'ACTIVATED_NO_RENEWAL',
  OVERRIDDEN: 'OVERRIDDEN',
  RIOTGO_ENRICHED: 'RIOTGO_ENRICHED',
  REQUESTED_EXPIRATION: 'REQUESTED_EXPIRATION',
  REQUESTED_RENEWAL: 'REQUESTED_RENEWAL',
  EXPIRED: 'EXPIRED',
  PARTIALLY_FULFILLED: 'PARTIALLY_FULFILLED'
}

class ViewerAccountApi {
  static async getOptions (method = 'POST'): Promise<RequestInit> {
    return {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }

  async fetchPuuidsByDropId (dropId: string): Promise<PuuidDrop> {
    const json: string[] = await fetch(
      `${viewerAccountUrl}puuids/${dropId}`,
      await ViewerAccountApi.getOptions('GET')
    )
    const result: PuuidDrop = { dropId: dropId, puuids: json }
    return result
  }

  async fetchFulfillmentInfoByTriggerIds (
    triggerIds: string[]
  ): Promise<TriggerFulfillmentStatusDTO[]> {
    const json: TriggerFulfillmentStatusDTO[] = await fetch(
      `${viewerAccountUrl}triggers/fulfillmentStatus?triggerIDs=${triggerIds}`,
      await ViewerAccountApi.getOptions('GET')
    )
    return json
  }
}
export default new ViewerAccountApi()
