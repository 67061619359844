import { combineReducers, Reducer } from 'redux'
import { createSelector } from 'reselect'
import { getSafe, isEqual, setSafe } from '../../../../../commons'
import { createSlice } from '../../../../../store/actions/utils'
import {
  Action,
  AnyPayloadAction,
  PayloadAction
} from '../../../../../store/Store.type'
import {
  GameIdEditPayload,
  GameTime,
  OffsetEditPayload,
  ProviderEditPayload,
  LivestatsStatus,
  LivestatsStatusEditPayload,
  GameTimeEditPayload
} from './StatSyncModal.type'

// #region Reducers
// #region Edit Offset
const defaultOffsetValue = 30

const offsetSlice = createSlice({
  name: 'offset',
  initialState: {} as Record<string, number>,
  reducers: {
    changeOffset: (
      state: Record<string, number>,
      { payload }: PayloadAction<OffsetEditPayload>
    ) => ({
      ...state,
      [payload.gameId + payload.providerId]: payload.offset
    })
  } as Record<string, Reducer<Record<string, number>, AnyPayloadAction>>
})

export const { changeOffset } = offsetSlice.actions

// #endregion

// #region Edit Provider
const providerSlice = createSlice({
  name: 'provider',
  initialState: {} as Record<string, string>,
  reducers: {
    changeProvider: (
      state: Record<string, string>,
      { payload }: PayloadAction<ProviderEditPayload>
    ) => ({
      ...state,
      [payload.id]: payload.provider
    })
  } as Record<string, Reducer<Record<string, string>, AnyPayloadAction>>
})

export const { changeProvider } = providerSlice.actions
// #endregion

// #region Edit Game Id
const gameIdSlice = createSlice({
  name: 'gameId',
  initialState: {} as Record<string, string>,
  reducers: {
    changeGameId: (
      state: Record<string, string>,
      { payload }: PayloadAction<GameIdEditPayload>
    ) => ({
      ...state,
      [payload.matchId]: payload.gameId
    })
  } as Record<string, Reducer<Record<string, string>, AnyPayloadAction>>
})

export const { changeGameId } = gameIdSlice.actions
// #endregion

// #region Edit Game Time
const getGameTimeModelFromState = (
  state: Record<string, GameTime>,
  id: string
): GameTime => getSafe(state, id, {} as GameTime)

const gameTimeSlice = createSlice({
  name: 'gameTime',
  initialState: {} as Record<string, GameTime>,
  reducers: {
    changeTime: (
      state: Record<string, GameTime>,
      { payload }: PayloadAction<GameTimeEditPayload>
    ) => {
      const model = getGameTimeModelFromState(
        state,
        payload.gameId + payload.providerId
      )
      const updatedSeconds = setSafe(
        model,
        'seconds',
        payload.time.getSeconds()
      )
      const updatedMinutes = setSafe(
        updatedSeconds,
        'minutes',
        payload.time.getMinutes()
      )

      const updatedHours = setSafe(
        updatedMinutes,
        'hours',
        payload.time.getHours()
      )

      const updatedVideoTime = setSafe(
        updatedHours,
        'videoTime',
        payload.videoTime
      )

      return {
        ...state,
        [payload.gameId + payload.providerId]: updatedVideoTime
      }
    }
  } as Record<string, Reducer<Record<string, GameTime>, AnyPayloadAction>>
})

export const { changeTime } = gameTimeSlice.actions
// #endregion

// #region Edit Livestats Status
const livestatsStatusSlice = createSlice({
  name: 'livestatsStatus',
  initialState: {} as Record<string, LivestatsStatus>,
  reducers: {
    changeStatus: (
      state: Record<string, LivestatsStatus>,
      { payload }: PayloadAction<LivestatsStatusEditPayload>
    ) => ({
      ...state,
      [payload.gameId + payload.providerId]: payload.status
    })
  } as Record<
    string,
    Reducer<Record<string, LivestatsStatus>, AnyPayloadAction>
  >
})

export const { changeStatus } = livestatsStatusSlice.actions
// #endregion

const combinedReducer = combineReducers({
  editOffset: offsetSlice.reducer,
  editProvider: providerSlice.reducer,
  editGameId: gameIdSlice.reducer,
  editGameTime: gameTimeSlice.reducer,
  editLivestatsStatus: livestatsStatusSlice.reducer
})

export default combinedReducer
// #endregion

// #region Selectors
type StateShape = ReturnType<typeof combinedReducer>
const getEditOffset = (state: StateShape) => state.editOffset
const getEditProvider = (state: StateShape) => state.editProvider
const getEditGameId = (state: StateShape) => state.editGameId
const getEditGameTime = (state: StateShape) => state.editGameTime
const getEditLivestatsStatus = (state: StateShape) => state.editLivestatsStatus

export const isEditOffsetDirty = createSelector(
  [
    getEditOffset,
    (state: StateShape, gameId: string, editOffset: Record<string, string>) =>
      editOffset
  ],
  (model, editOffset) => !isEqual(editOffset, model)
)

export const getEditOffsetById = createSelector(
  [
    getEditOffset,
    (_: StateShape, gameId: string, providerId: string) => gameId + providerId
  ],
  (editOffset, id) => editOffset[id]
)

// This value is combination of both the provider_locale - IE: youtube_en-US
export const getEditProviderIdById = createSelector(
  [getEditProvider, (_: StateShape, id: string) => id],
  (editProvider, id) => editProvider[id]
)

export const getEditGameIdByMatchId = createSelector(
  [getEditGameId, (_: StateShape, matchId: string) => matchId],
  (editGameId, matchId) => editGameId[matchId]
)

export const getEditGameTimeById = createSelector(
  [
    getEditGameTime,
    (_: StateShape, gameId: string, providerId: string) => gameId + providerId
  ],
  (editGameTime, id) => editGameTime[id]
)

export const getEditLivestatsStatusById = createSelector(
  [
    getEditLivestatsStatus,
    (_: StateShape, gameId: string, providerId: string) => gameId + providerId
  ],
  (editLivestatsStatus, id) => editLivestatsStatus[id]
)
// #endregion
