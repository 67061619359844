import { MouseEvent } from 'react'
import { Button } from '../'
import { classNames, stopEventPropagation } from '../../commons'
import styles from './Confirm.scss'

export enum ConfirmType {
  WARN = 'warn',
  INFO = 'info',
}

export type Props = {
  title: string
  body: string | JSX.Element
  confirmText?: string
  cancelText?: string
  extraActions?: JSX.Element[]
  onConfirm: () => void
  onCancel: () => void
  onDeny?: () => void
  class?: string
  containerClass?: string
  subbody?: string | JSX.Element
  type?: ConfirmType
}

const Confirm = ({
  body,
  subbody,
  onCancel,
  onConfirm,
  title,
  cancelText,
  confirmText,
  extraActions,
  onDeny,
  class: className,
  containerClass,
  type = ConfirmType.INFO
}: Props): JSX.Element => {
  const classes = classNames(styles.confirm, className)
  const containerClasses = classNames(styles.container, containerClass)

  const onConfirmHandler = (event: MouseEvent): void => {
    event && stopEventPropagation(event)
    onConfirm && onConfirm()
  }

  const onCancelHandler = (event: MouseEvent): void => {
    event && stopEventPropagation(event)
    onCancel && onCancel()
  }

  const onDenyHandler = (event: MouseEvent): void => {
    event && stopEventPropagation(event)
    if (onDeny) {
      onDeny()
    }
    else {
      onCancelHandler(event)
    }
  }

  const renderContent = (): JSX.Element => {
    const titleClasses = ConfirmType.WARN === type ? styles.warn : styles.info
    return (
      <div className={ styles.content }>
        <div className={ classNames(styles.title, titleClasses) }>{ title }</div>
        <div className={ styles.body }>{ body }</div>
        <div className={ styles.subbody }>{ subbody }</div>
      </div>
    )
  }

  const renderButtons = (): JSX.Element => {
    const confirmButtonText = confirmText || 'Confirm'
    const cancelButtonText = cancelText || 'Cancel'

    return (
      <div className={ styles.buttons }>
        <div className={ styles.actions }>
          <div className={ styles.action }>
            <Button
              onClick={ (event: MouseEvent): void => onDenyHandler(event) }
              className={ styles.cancel }>
              { cancelButtonText }
            </Button>
          </div>
        </div>
        <div className={ styles.actions }>
          { extraActions
            && extraActions.map((action) => (
              <div
                data-testid="extra-action"
                className={ styles.action }
                key={ action.key }>
                { action }
              </div>
            )) }
          <div className={ styles.action }>
            <Button
              onClick={ (event: MouseEvent): void => onConfirmHandler(event) }
              className={ styles.onConfirm }>
              { confirmButtonText }
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      data-testid="confirm-component"
      className={ classes }
      onClick={ (event): void => onCancelHandler(event) }>
      <div className={ containerClasses } onClick={ stopEventPropagation }>
        { renderContent() }
        { renderButtons() }
      </div>
    </div>
  )
}

export default Confirm
