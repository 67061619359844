import { useState, useRef, useEffect, ReactNode } from 'react'
import { classNames } from '../../commons'
import WithTitle from '../WithTitle/WithTitle'
import WithValidation from '../WithValidation/WithValidation'
import styles from './Simple.scss'

type SimpleElementProps = {
  className?: string
  children?: ReactNode
  [key: string]: any
}

// This file uses a Higher Order Component to define a handful of simple pre-styled
// components.
export const SimpleElement
  = (WrappedComponent: keyof JSX.IntrinsicElements, styleName: string) =>
    ({ className, ...props }: SimpleElementProps) => {
      const classes = classNames(styles[styleName], className)
      const { children, ...componentProps } = props
      return (
        <WrappedComponent
          data-no-scrollable
          { ...componentProps }
          className={ classes }>
          { children }
        </WrappedComponent>
      )
    }

type SimpleInputElementProps = {
  className?: string
  children?: ReactNode
  value?: string | number
  onChange?: (event: any) => void
  placeholder?: string
  [key: string]: any
}

// This is a special case of the SimpleElement, which is a simple input element
// which has a cursor position state and a ref to the input element to control
// the cursor position
export const SimpleInputElement
  = (styleName: string) =>
    ({ className, ...props }: SimpleInputElementProps) => {
      const classes = classNames(styles[styleName], className)

      const { value, onChange, children, ...rest } = props
      const [cursor, setCursor] = useState<number | null>(null)
      const ref = useRef<HTMLInputElement>(null)

      useEffect(() => {
        if (
          ref.current === null
        || cursor === null
        || ref.current.type !== 'text'
        ) {
          return
        }
        ref.current.setSelectionRange(cursor, cursor)
      }, [ref, cursor, value])

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCursor(e.target.selectionStart)
        onChange?.(e)
      }

      return (
        <input
          data-no-scrollable
          ref={ ref }
          value={ value }
          onChange={ handleChange }
          { ...rest }
          className={ classes }>
          { children }
        </input>
      )
    }

export const Button = SimpleElement('button', 'button')
export const TextArea = SimpleElement('textarea', 'textarea')
export const TitledTextArea = WithTitle(TextArea)
export const Input = SimpleInputElement('input')
export const TitledInput = WithTitle(Input)
export const ValidatedInput = WithValidation(TitledInput)
