import { TriggerFilterOptions, TriggerMetaDTO } from './TriggerMeta.type'
import { createSlice } from '../../../../actions/utils'
import { Action } from '../../../../Store.type'
import { isEqual } from '../../../../../commons'

const today = new Date(Date.now())

const startDate = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 30
).toISOString()

const endDate = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate()
).toISOString()

const initialState: DeepPartial<TriggerMetaDTO> = {
  triggerFilterOptions: {
    league: null,
    action: null,
    search: null,
    startDate,
    endDate
  }
}

const { reducer, actions } = createSlice({
  name: 'triggerMeta',
  initialState: initialState,
  reducers: {
    initialize: () => initialState,
    clear: () => initialState,
    clearFilters: (state) => ({
      ...state,
      triggerFilterOptions: {
        league: null,
        action: null,
        search: null,
        startDate: null,
        endDate: null
      }
    }),
    editFilters: (
      state,
      { payload }: Action<Partial<TriggerFilterOptions>>
    ) => ({
      ...state,
      triggerFilterOptions: Object.assign(
        {
          league: null,
          action: null,
          search: null,
          startDate: null,
          endDate: null
        },
        state?.triggerFilterOptions,
        payload
      )
    })
  },
  extraReducers: {}
})

export default reducer
const { initialize, clear, clearFilters, editFilters } = actions

export { initialize, clear, clearFilters, editFilters }

export const getWIPTriggerMeta = (state: TriggerMetaDTO): TriggerMetaDTO =>
  state
export const isTriggerMetaDirty = (state: TriggerMetaDTO): boolean =>
  !isEqual(state, initialState)
