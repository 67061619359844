import {
  CodesResponse,
  GenerateRequest,
  GetAllRequest,
  MappingRequest,
  UploadRequest,
  UploadResponse
} from '@riotgames/api-types/drops/PromoCodeV2.type'
import { Config, fetch, urlBuilder } from '../../commons'
import { RequestType } from '../requestType'
import DropsApi from '../DropsApi/DropsApi'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'

export const PromoCodeApiEnvSessionStorageKey = 'promoCodeApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.PromoCodes,
  Config.getEnv(PromoCodeApiEnvSessionStorageKey)
)

const baseUrl = Config.getKeyByEnv({
  local: apiBaseUrl,
  dev: apiBaseUrl,
  test: apiBaseUrl,
  stage: apiBaseUrl,
  prod: apiBaseUrl
})

const codesV2Url = urlBuilder({
  local: `${baseUrl}/codes/v2.0/`,
  dev: `${baseUrl}/codes/v2.0/`,
  test: `${baseUrl}/codes/v2.0/`,
  prod: `${baseUrl}/codes/v2.0/`
})

class PromoCodesApiV2 {
  static async getOptions (
    method: RequestType,
    headers?: HeadersInit
  ): Promise<RequestInit> {
    const defaultHeaders = headers || {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    return {
      method: method,
      headers: defaultHeaders
    }
  }

  async getAllUnclaimed (request: Partial<GetAllRequest>) {}

  async getAll (request: GetAllRequest): Promise<CodesResponse> {
    const url = codesV2Url(
      `getAll/${request.sponsor}/${request.dropInventoryID}/${request.disposition}`
    )

    const response = await fetch(
      url,
      await PromoCodesApiV2.getOptions(RequestType.GET)
    )
    return response
  }

  async generateSample (length?: number): Promise<string[]> {
    const options = await PromoCodesApiV2.getOptions(RequestType.GET)
    length = length || 8
    const url = codesV2Url('generate', { length })
    return await fetch(url, { ...options })
  }

  async upload (request: UploadRequest): Promise<UploadResponse> {
    const body = request.payload
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'text/plain'
    }
    const url = codesV2Url('ingest')
    return await fetch(url, {
      ...await PromoCodesApiV2.getOptions(RequestType.POST, headers),
      body
    })
  }

  async ingestFromGenerator (request: GenerateRequest): Promise<CodesResponse> {
    const body = JSON.stringify(request)
    const options = await PromoCodesApiV2.getOptions(RequestType.POST)
    const url = codesV2Url(
      `ingest/generate/${request.sponsor}/${request.dropInventoryID}/${request.quantity}`,
      { length: 8 }
    )

    const response = fetch(url, {
      ...options,
      body
    }).catch(() => {
      DropsApi.deleteInventoryItem(request.dropInventoryID)
    })

    return response
  }

  async mapInventoryToKey (request: MappingRequest): Promise<CodesResponse> {
    const body = JSON.stringify(request)
    const options = await PromoCodesApiV2.getOptions(RequestType.POST)
    const url = codesV2Url(
      `ingest/${request.sponsor}/${request.dropInventoryID}/${request.codesKey}`
    )

    const response = fetch(url, {
      ...options,
      body
    }).catch(() => {
      DropsApi.deleteInventoryItem(request.dropInventoryID)
    })

    return response
  }
}

export default new PromoCodesApiV2()
