import { useState } from 'react'
import { Input } from '../../Simple/Simple'
import styles from './PlatformGameIdPicker.scss'

type Props = {
  onChange: (id: string) => void
}

const PlatformGameIdPicker = (props: Props): JSX.Element => {
  const { onChange } = props

  const [realmId, setRealmId] = useState('')
  const [gameId, setGameId] = useState('')

  const updateParent = (): void => onChange(`${realmId}_${gameId}`)
  // If the user has filled in both fields, notify parent
  const onBlur = (): void => {
    if (realmId && gameId) {
      updateParent()
    }
  }
  // If user presses ENTER, try notifying parent
  const onKeyPress = (event: KeyboardEvent): void => {
    if (event.code === '13') {
      onBlur()
    }
  }

  return (
    <div className={ styles.manual }>
      <div className={ styles.id }>
        <label className={ styles.label }>Realm ID</label>
        <Input
          value={ realmId }
          onInput={ ({ target }: DOMEventTarget) =>
            setRealmId((target as HTMLInputElement).value)
          }
          onKeyPress={ onKeyPress }
          onBlur={ onBlur }
        />
      </div>
      <div className={ styles.id }>
        <label className={ styles.label }>Game ID</label>
        <Input
          value={ gameId }
          onInput={ ({ target }: DOMEventTarget) =>
            setGameId((target as HTMLInputElement).value)
          }
          onKeyPress={ onKeyPress }
          onBlur={ onBlur }
        />
      </div>
    </div>
  )
}

export default PlatformGameIdPicker
