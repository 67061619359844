import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import loading from '../../assets/gif/spinner.gif'
import xImage from '../../assets/svg/closeMessage.svg'
import failed from '../../assets/svg/failed.svg'
import success from '../../assets/svg/success.svg'
import { classNames } from '../../commons'
import { EventBus } from '../../services/'
import { Action, RootState } from '../../store/Store.type'
import { getMessages } from '../../store/reducers'
import styles from './Message.scss'
import { displayMessage, removeMessage } from './Message.slice'
import { MessageDisplayType, MessageEvent, MessageType } from './Message.type'

type Props = {
  messages?: MessageEvent[]
  displayMessage?: (
    message: string,
    type?: MessageType,
    displayType?: MessageDisplayType,
  ) => void
  removeMessage?: (message: MessageEvent) => void
  displayType?: MessageDisplayType
}

// #ask-esports-digital
const slackUrl = 'https://app.slack.com/client/T08NPASCQ/C2XV81TST'
const images = {
  failed: failed,
  success: success,
  x: xImage
}

const iconClasses = {
  [MessageType.Error]: styles.errorIcon,
  [MessageType.Loading]: styles.loadingIcon,
  [MessageType.Success]: styles.successIcon
}

const Message = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  const isModalActive = document.querySelector('[data-modal-active=true]')

  useEffect(() => {
    // Maps the old way of broadcasting messages to the new redux way
    EventBus.on('message', (event: MessageEvent) =>
      props.displayMessage
        ? props.displayMessage(
          event.message,
          event.type,
          document.querySelector('[data-modal-active=true]')
            ? 'modal'
            : 'default'
        )
        : dispatch(
            displayMessage(
              event.message,
              event.type,
              document.querySelector('[data-modal-active=true]')
                ? 'modal'
                : 'default'
            ) as unknown as AnyAction
        )
    )
  }, [])

  const messages = props.messages
    ? props.messages
    : useSelector((state: RootState) => {
      const messagesInState = getMessages(
        state,
        props.displayType ?? 'default'
      )
      if (isModalActive && props.displayType === 'default') {
        messagesInState.concat(getMessages(state, 'modal'))
      }
      return messagesInState
    })

  const renderSvg = (type: keyof typeof images): JSX.Element => (
    <svg className={ styles[type] }>
      <use href={ images[type] }/>
    </svg>
  )

  const renderMessage = (message: MessageEvent): JSX.Element => {
    const classes = classNames(
      styles.message,
      styles[message.type],
      styles[message.displayType]
    )
    const iconClass = iconClasses[message.type]

    const timeStamp = new Date().getTime()
    return (
      <div className={ classes } key={ `${message.message}-${timeStamp}` }>
        <div className={ styles.leftAligned }>
          <span className={ iconClass }>
            { message.type === MessageType.Error && renderSvg('failed') }
            { message.type === MessageType.Success && renderSvg('success') }
            { message.type === MessageType.Loading 
              && <img className={ styles.loading } src={ loading }/>
            }
          </span>
          <span>{ message.message }</span>
        </div>
        <div className={ styles.rightAligned }>
          { message.type === MessageType.Error && (
            <a
              href={ slackUrl }
              target="_blank"
              rel="noopener noreferrer"
              className={ styles.help }>
              Need Help?
            </a>
          ) }
          <button
            data-testid="btn-remove"
            className={ styles.remove }
            onClick={ (): void | Action<any, any> =>
              props.removeMessage
                ? props.removeMessage(message)
                : dispatch(removeMessage(message))
            }>
            { renderSvg('x') }
          </button>
        </div>
      </div>
    )
  }

  if (!messages || messages.length === 0) {
    return <></>
  }

  return (
    <div className={ styles.messages }>
      { messages.map((message: MessageEvent) => renderMessage(message)) }
    </div>
  )
}

export default Message
