import { MouseEvent, useState } from 'react'
import { classNames, stopEventPropagation } from '../../commons'
import styles from './ActionMenu.scss'
import ellipsisImage from '../../assets/svg/ellipsis.svg'
import ClickoutDetector from '../ClickoutDetector/ClickoutDetector'

type Props = {
  options: Option[]
}

type Option = {
  label: string
  onClick: () => void
}

function ActionMenu ({ options }: Props): JSX.Element {
  const [open, setOpen] = useState(false)

  function onClickParent (event: MouseEvent) {
    stopEventPropagation(event)
    toggleMenu()
  }

  function toggleMenu () {
    setOpen((prevState) => !prevState)
  }

  function closeMenu () {
    setOpen(false)
  }

  const onClick = (option: Option) => (event: MouseEvent) => {
    onClickParent(event)
    option.onClick()
  }

  function SubMenu ({ options }: { options: Option[] }) {
    return (
      <ClickoutDetector onClickout={ closeMenu }>
        <div className={ styles.dropdown }>
          { options.map((option) => (
            <button
              onClick={ onClick(option) }
              className={ styles.item }
              key={ option.label }>
              { option.label }
            </button>
          )) }
        </div>
      </ClickoutDetector>
    )
  }

  const buttonClasses = classNames(styles.button, open && styles.open)

  return (
    <div className={ styles.menu } tabIndex={ 0 } onClick={ stopEventPropagation }>
      <button
        data-testid="menu-button"
        className={ buttonClasses }
        onClick={ onClickParent }>
        <svg className={ styles.ellipsis }>
          <use href={ ellipsisImage }/>
        </svg>
      </button>
      { open && <SubMenu options={ options }/> }
    </div>
  )
}

export default ActionMenu
