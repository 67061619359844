import DropsApi from './DropsApi'
import { createThunk } from '../../store/actions/utils'

// #region Action Creators

export const getAllGroups = createThunk(
  'dropsApi/getAllGroups',
  DropsApi.fetchGroups
)
export const getAllInventoryItems = createThunk(
  'dropsApi/getAllInventoryItems',
  DropsApi.fetchInventoryItems
)
export const getInventoryItem = createThunk(
  'dropsApi/getAllInventoryItem',
  DropsApi.fetchInventoryItem
)
export const getAllRarity = createThunk(
  'dropsApi/getAllRarity',
  DropsApi.fetchRarityItems
)
export const getAllTrigger = createThunk(
  'dropsApi/getAllTrigger',
  DropsApi.fetchTriggerItems
)
export const getTrigger = createThunk(
  'dropsApi/getTrigger',
  DropsApi.pullTrigger
)
export const triggerDryRun = createThunk(
  'dropsApi/triggerDryRun',
  DropsApi.triggerDryRunGroup
)
export const triggerGroup = createThunk(
  'dropsApi/triggerDrop',
  DropsApi.triggerGroup
)
export const triggerIndividual = createThunk(
  'dropsApi/triggerIndividual',
  DropsApi.triggerIndividual
)
export const createFullGroup = createThunk(
  'dropsApi/createFullGroup',
  DropsApi.putFullGroup
)
export const editGroup = createThunk(
  'dropsApi/editGroup',
  DropsApi.putEditGroup
)
export const createLocalization = createThunk(
  'dropsApi/createLocalization',
  DropsApi.putLocalization
)
export const createLocalizations = createThunk(
  'dropsApi/createLocalizations',
  DropsApi.putLocalizations
)
export const createInventoryItem = createThunk(
  'dropsApi/createInventoryItem',
  DropsApi.putInventoryItem
)
export const upsertDropsInventoryFulfillmentStatusDefaultDescriptions = createThunk(
  'dropsApi/upsertDropsInventoryFulfillmentStatusDefaultDescriptions',
  DropsApi.putDropsInventoryFulfillmentStatusDefaultDescriptions
)
export const getDropsInventoryFulfillmentStatusDefaultDescriptions = createThunk(
  'dropsApi/getDropsInventoryFulfillmentStatusDefaultDescriptions',
  DropsApi.getDropsInventoryFulfillmentStatusDefaultDescriptions
)
export const createRarity = createThunk(
  'dropsApi/createRarity',
  DropsApi.postRarity
)
export const deleteGroup = createThunk(
  'dropsApi/deleteGroup',
  DropsApi.deleteGroup
)
export const pendingGroup = createThunk(
  'dropsApi/pendingGroup',
  DropsApi.pendingGroup
)
export const archiveGroup = createThunk(
  'dropsApi/archiveDrop',
  DropsApi.archiveGroup
)
export const archiveInventoryItem = createThunk(
  'dropsApi/archiveInventoryItem',
  DropsApi.archiveInventoryItem
)

export const unarchiveInventoryItem = createThunk(
  'dropsApi/unarchiveInventoryItem',
  DropsApi.unarchiveInventoryItem
)
export const archiveRarity = createThunk(
  'dropsApi/archiveRarity',
  DropsApi.archiveRarity
)

export const unarchiveRarity = createThunk(
  'dropsApi/unarchiveRarity',
  DropsApi.unarchiveRarity
)

export const getLocalizationForKey = createThunk(
  'dropsApi/getLocalizationForKey',
  DropsApi.fetchLocalizationForKey
)

export const getLocalizationForKeys = createThunk(
  'dropsApi/getLocalizationForKeys',
  DropsApi.fetchLocalizationForKeys
)

// #endregion
