import { RecordStatus } from '@riotgames/api-types/drops/Common.type'
import { LocalizationDTO } from '@riotgames/api-types/drops/Drops.type'
import {
  GroupResponseDTO,
  TriggerResponseDTO,
  GroupRequestDTO,
  InventoryResponseDTO,
  RarityResponseDTO,
  InventoryRequestDTO
} from '@riotgames/api-types/drops/DropsV2.type'
import { combineReducers, Reducer } from 'redux'
import { keyBy, keyById } from '../../commons'
import { createSlice, isLoading } from '../../store/actions/utils'
import { Action, AnyPayloadAction, PayloadAction } from '../../store/Store.type'
import {
  archiveGroup,
  archiveInventoryItem,
  deleteGroup,
  pendingGroup,
  createFullGroup,
  getAllGroups,
  getAllInventoryItems,
  getLocalizationForKey,
  triggerGroup,
  triggerDryRun,
  unarchiveInventoryItem,
  getAllRarity,
  archiveRarity,
  unarchiveRarity,
  getLocalizationForKeys,
  createInventoryItem,
  getAllTrigger
} from './DropsApi.actions'
import { Sport } from '@riotgames/api-types/elds/Common.type'

// #region Reducers

// #region Group

const updateGroupStatus = (
  state: Record<string, GroupResponseDTO> | undefined,
  meta: GroupResponseDTO[],
  status: RecordStatus
) => {
  const [{ id }] = meta
  const originalEntry = state?.[id]

  const updatedEntry = {
    ...originalEntry,
    orderedDrops: originalEntry?.orderedDrops.map((drop) => ({
      ...drop,
      status
    }))
  }

  return {
    ...state,
    [id]: updatedEntry
  }
}
const group = createSlice({
  name: 'group',
  initialState: {} as Record<string, GroupResponseDTO>,
  extraReducers: {
    [getAllGroups.success.type]: (
      state,
      action: PayloadAction<GroupResponseDTO[]>
    ) => keyBy('id')(action.payload),
    [triggerGroup.success.type]: (
      state,
      { payload }: PayloadAction<TriggerResponseDTO>
    ) => ({
      ...state,
      [payload.groupResponseDTO.id]: payload.groupResponseDTO
    }),
    [archiveGroup.success.type]: (
      state,
      { meta }: PayloadAction<string, GroupResponseDTO[]>
    ) => updateGroupStatus(state, meta, RecordStatus.Archived),
    [deleteGroup.success.type]: (
      state,
      { meta }: PayloadAction<string, GroupResponseDTO[]>
    ) => updateGroupStatus(state, meta, RecordStatus.Deleted),
    [pendingGroup.success.type]: (
      state,
      { meta }: PayloadAction<string, GroupResponseDTO[]>
    ) => updateGroupStatus(state, meta, RecordStatus.Pending)
  } as Record<
    string,
    Reducer<Record<string, GroupResponseDTO>, AnyPayloadAction>
  >
})

// #endregion

// #region Inventory

const updateStatus = (
  dto: InventoryResponseDTO | undefined,
  status: RecordStatus
): Partial<InventoryResponseDTO> => ({
  ...dto,
  status
})

const inventory = createSlice({
  name: 'inventory',
  initialState: {} as Record<string, InventoryResponseDTO>,
  extraReducers: {
    [getAllInventoryItems.success.type]: (
      state,
      { payload }: PayloadAction<InventoryResponseDTO[]>
    ) => keyById(payload.map((dto) => ({ ...dto }))),
    [archiveInventoryItem.success.type]: (
      state,
      { meta }: PayloadAction<null, string[]>
    ) => {
      const [id] = meta
      const entry = state?.[id]
      return {
        ...state,
        [id]: updateStatus(entry, RecordStatus.Archived)
      }
    },
    [unarchiveInventoryItem.success.type]: (
      state,
      { meta }: PayloadAction<null, string[]>
    ) => {
      const [id] = meta
      const entry = state?.[id]
      return {
        ...state,
        [id]: updateStatus(entry, RecordStatus.Active)
      }
    }
  } as Record<
    string,
    Reducer<Record<string, InventoryResponseDTO>, AnyPayloadAction>
  >
})

// #endregion

// #region Rarity

const updateRarityStatus = (
  dto: RarityResponseDTO | undefined,
  status: RecordStatus
): Partial<RarityResponseDTO> => ({
  ...dto,
  status
})

const rarity = createSlice({
  name: 'rarity',
  initialState: {} as Record<string, RarityResponseDTO>,
  extraReducers: {
    [getAllRarity.success.type]: (
      state,
      { payload }: PayloadAction<RarityResponseDTO[]>
    ) => keyById(payload.map((dto) => ({ ...dto }))),
    [archiveRarity.success.type]: (
      state,
      { meta }: PayloadAction<null, string[]>
    ) => {
      const [id] = meta
      const entry: RarityResponseDTO | undefined = state?.[id]
      return {
        ...state,
        [id]: updateRarityStatus(entry, RecordStatus.Archived)
      }
    },
    [unarchiveRarity.success.type]: (
      state,
      { meta }: PayloadAction<null, string[]>
    ) => {
      const [id] = meta
      const entry = state?.[id]
      return {
        ...state,
        [id]: updateRarityStatus(entry, RecordStatus.Active)
      }
    }
  } as Record<
    string,
    Reducer<Record<string, RarityResponseDTO>, AnyPayloadAction>
  >
})

// #endregion

// #region Localization

const updateReducer = (
  state: Record<string, Record<string, string>> | undefined,
  localizations: LocalizationDTO[]
): Record<string, Record<string, string>> => {
  const updates = localizations
    .filter((l) => !!l)
    .reduce(
      (
        acc: Record<string, Record<string, string>>,
        { localeGroup, localeKey, localization }
      ) => {
        const accumulatedValues = acc[localeKey] || {}
        const stateValues = state?.[localeKey]

        const update: Record<string, string> = {
          ...stateValues,
          ...accumulatedValues,
          [localeGroup]: localization
        }

        acc[localeKey] = update

        return acc
      },
      {}
    )

  return { ...state, ...updates }
}

const byLocaleCode = createSlice({
  name: 'localization',
  initialState: {} as Record<string, Record<string, string>>,
  extraReducers: {
    [getLocalizationForKey.success.type]: (
      state,
      { payload }: PayloadAction<LocalizationDTO[]>
    ) => updateReducer(state, payload),
    [getLocalizationForKeys.success.type]: (
      state,
      { payload }: PayloadAction<LocalizationDTO[]>
    ) => updateReducer(state, payload),
    [createFullGroup.success.type]: (
      state,
      { meta }: PayloadAction<null, GroupRequestDTO[]>
    ) =>
      updateReducer(
        state,
        meta[0].orderedDropRequests.flatMap((dto) => dto.localizationDTOs)
      ),
    [createInventoryItem.success.type]: (
      state,
      { meta }: PayloadAction<null, InventoryRequestDTO[]>
    ) => updateReducer(state, meta[0].localizationDTOs)
  }
})

const byLocalizationKey = createSlice({
  name: 'localization',
  initialState: {} as Record<string, string[]>,
  extraReducers: {
    [getLocalizationForKey.success.type]: (
      state,
      { payload }: PayloadAction<LocalizationDTO[]>
    ) => {
      const key = payload[0].localeGroup
      const locales = payload.map(({ localeKey }) => localeKey)

      return {
        ...state,
        [key]: locales
      }
    },
    [getLocalizationForKeys.success.type]: (
      state,
      { payload }: PayloadAction<LocalizationDTO[]>
    ) => {
      const keySets = payload.reduce(
        (acc: Record<string, string[]>, localization) => {
          const locales = acc[localization.localeGroup] || []
          return {
            ...acc,
            [localization.localeGroup]: [...locales, localization.localeKey]
          }
        },
        {}
      )
      return {
        ...state,
        ...keySets
      }
    },
    [createFullGroup.success.type]: (
      state,
      { meta }: PayloadAction<null, GroupRequestDTO[]>
    ) => {
      const localizationDTOs = meta[0].orderedDropRequests.flatMap(
        (dto) => dto.localizationDTOs
      )

      const updates = localizationDTOs.reduce(
        (update: Record<string, string[]>, localization) => {
          const arr = update[localization.localeGroup] || []
          update[localization.localeGroup] = arr.concat(localization.localeKey)

          return update
        },
        {}
      )

      return { ...state, ...updates }
    },
    [createInventoryItem.success.type]: (
      state,
      { meta }: PayloadAction<null, InventoryRequestDTO[]>
    ) => {
      const localizationDTOs = meta[0].localizationDTOs

      const updates = localizationDTOs.reduce(
        (update: Record<string, string[]>, localization) => {
          const arr = update[localization.localeGroup] || []
          update[localization.localeGroup] = arr.concat(localization.localeKey)

          return update
        },
        {}
      )

      return { ...state, ...updates }
    }
  }
})

const localizationReducer = combineReducers({
  byLocaleCode: byLocaleCode.reducer,
  byLocalizationKey: byLocalizationKey.reducer
})

// #endregion

// #region Trigger
const trigger = createSlice({
  name: 'dropTrigger',
  initialState: {} as Record<string, TriggerResponseDTO>,
  extraReducers: {
    [triggerDryRun.success.type]: (
      state,
      { payload }: PayloadAction<TriggerResponseDTO>
    ) => ({
      ...state,
      [payload.groupResponseDTO.id]: payload
    })
  }
})

const triggers = createSlice({
  name: 'dropTriggers',
  initialState: {} as Record<string, TriggerResponseDTO>,
  extraReducers: {
    [getAllTrigger.success.type]: (
      state,
      { payload }: PayloadAction<TriggerResponseDTO[]>
    ) => keyById(payload.map((dto) => ({ id: dto.triggerID, ...dto })))
  }
})
// #endregion

// #region IsLoading
const isLoadingReducer = combineReducers({
  isTriggersLoading: isLoading(getAllTrigger, false),
  isGroupLoading: isLoading(getAllGroups),
  isInventoryItemsLoading: isLoading(getAllInventoryItems),
  isTriggerLoading: isLoading(triggerDryRun, false),
  isRarityLoading: isLoading(getAllRarity, false),
  isLocalizationForKeysLoading: isLoading(getLocalizationForKeys, false)
})
// #endregion

const combinedReducer = combineReducers({
  triggers: triggers.reducer,
  groups: group.reducer,
  inventoryItems: inventory.reducer,
  rarities: rarity.reducer,
  localization: localizationReducer,
  trigger: trigger.reducer,
  isLoading: isLoadingReducer
})

export default combinedReducer

// #endregion

// #region Selectors
type StateShape = ReturnType<typeof combinedReducer>

// #endregion

// #region Group
export const getGroups = (
  state: StateShape,
  sport: Sport
): Record<string, GroupResponseDTO> =>
  keyById(
    Object.values(state.groups).filter((group) =>
      group.orderedDrops.every((drop) => drop.sport === sport)
    )
  )

export const getGroupById = (state: StateShape, id: string): GroupResponseDTO =>
  state.groups[id]
// #endregion

// #region Inventory

export const getInventoryItems = (
  state: StateShape
): Record<string, InventoryResponseDTO> => state.inventoryItems

export const getInventoryItemById = (
  state: StateShape,
  id: string
): InventoryResponseDTO => state.inventoryItems && state.inventoryItems[id]

// #endregion

// #region Rarity

export const getRarity = (
  state: StateShape
): Record<number, RarityResponseDTO> => state.rarities

export const getRarityById = (
  state: StateShape,
  id: number
): RarityResponseDTO => state?.rarities?.[id]

// #endregion

// #region Localization

export const getLocalization = (state: StateShape) => (
  locale: string,
  key: string
): string =>
  state.localization.byLocaleCode[locale]
    && state.localization.byLocaleCode[locale][key]
  // Falls back to english if localization is missing so that something always displays
  || state.localization.byLocaleCode.en_US
    && state.localization.byLocaleCode.en_US[key]
// TODO: Re-enable this fallback when drops backend is updated to only provide
// TODO: valid keys
// If English fallback fails, just return the key
// || key

export const getAllLocalizationsForKey = (state: StateShape) => (
  key: string
): Record<string, string> =>
  state.localization.byLocalizationKey[key]
    ? state.localization.byLocalizationKey[key].reduce(
      (locales: Record<string, string>, locale) => {
        locales[locale] = getLocalization(state)(locale, key)
        return locales
      },
      {}
    )
    : {}

// #endregion

// #region Trigger

export const getTriggerById = (
  state: StateShape,
  id: string
): TriggerResponseDTO => state.trigger[id]

export const getTriggerInfoById = (
  state: StateShape,
  id: string
): TriggerResponseDTO => state.triggers[id]

export const getTriggers = (
  state: StateShape
): Record<string, TriggerResponseDTO> => state.triggers

// #endregion

// #region isLoading

export const isRarityLoading = (state: StateShape): boolean =>
  state.isLoading.isRarityLoading

export const isLocalizationForKeysLoading = (state: StateShape): boolean =>
  state.isLoading.isLocalizationForKeysLoading

export const isGroupLoading = (state: StateShape): boolean =>
  state.isLoading.isGroupLoading

export const isInventoryItemsLoading = (state: StateShape): boolean =>
  state.isLoading.isInventoryItemsLoading

export const isTriggerLoading = (state: StateShape): boolean =>
  state.isLoading.isTriggerLoading

export const isTriggersLoading = (state: StateShape): boolean =>
  state.isLoading.isTriggersLoading

// #endregion

// #endregion
