/* eslint-disable camelcase */
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { Config, fetch } from '../../commons'
import Logger from '../../commons/Logger/Logger'
import { SingleSignOn } from '../../services'
import UserManagementConfig from '../../services/UserManagement/UserManagementConfig'

export type OktaJwtPayload = JwtPayload & { preferred_username?: string }

export type Claims = {
  roles: UserPermission[]
}

export interface Role {
  name: string
  resources: string[]
}

export type UserPermission = {
  id: string
  role: {
    id: string
    key: string
    name: string
    permissions: string[]
  }
  user: {
    id: string
    key: string
    email: string
    first_name: any
    last_name: any
    attributes: any
  }
  tenant: {
    id: string
    key: string
    name: string
    attributes: any
  }
  resource_instance: any
  organization_id: string
  project_id: string
  environment_id: string
  created_at: Date
}

export const log = new Logger('AuthRouter')

const encrypt = (token: string, rawData: any): string => {
  const cipherText = AES.encrypt(rawData, token).toString()
  return cipherText
}

const decrypt = (token: string, cipherText: string): any => {
  const bytes = AES.decrypt(cipherText, token)
  const rawData = bytes.toString(Utf8)
  return rawData
}

const store = (key: string, token: string, data: any): void => {
  const encrypted = encrypt(token, data)
  sessionStorage.setItem(key, encrypted)
}

const load = (key: string, token: string) => {
  const encrypted = sessionStorage.getItem(key)
  if (!encrypted) {
    return null
  }
  return JSON.parse(decrypt(token, encrypted))
}

export const getClaims = async (): Promise<Claims> => {
  const user = await SingleSignOn.getUser()
  const claims: Claims = { roles: [] }
  if (user) {
    try {
      // check cache first
      log.info('(@) user:', user?.profile?.name)
      const decoded = jwtDecode<OktaJwtPayload>(user?.id_token)
      const { cacheTTL, rolesApiEndpoint, elementAccess }
        = UserManagementConfig.getConfigs()
      const env = Config.getEnv()
      log.info('(@) cacheTTL(ms):', cacheTTL)
      log.debug('(@) Env:', env)

      const { preferred_username } = decoded
      const key = `${env}-${preferred_username}`
      const cacheLifeMs = Number.parseInt(cacheTTL, 10)
      let cachedData: any = {}
      try {
        cachedData = load(key, user?.id_token) || {}
      }
      catch {
        log.warn('(@) failed to load cache')
      }
      const cachedTimestamp = cachedData.timestamp
      log.debug('(@) cachedTimestamp:', cachedTimestamp)
      const timeDiff = new Date().getTime() - cachedTimestamp
      log.debug('(@) timeDiff', timeDiff)
      if (cachedData && cachedTimestamp && timeDiff < cacheLifeMs) {
        log.info('(@) cache is still valid. load from cache', timeDiff)
        claims.roles = cachedData?.roles ?? []
      }
      else {
        // cache is expired or doesn't exist
        log.info('(@) cache expired. fetch')
        sessionStorage.removeItem(preferred_username as any)
        const url = rolesApiEndpoint
        log.debug('(@) EMP Service Url:', url)
        const permissions = await fetch(url, {
          method: 'get'
        })
        const rolesData = permissions?.map((p: UserPermission) => ({
          name: p.role.key,
          resources: p.role.permissions
        }))
        claims.roles = rolesData ?? []
        store(
          key,
          user?.id_token,
          JSON.stringify({
            timestamp: new Date().getTime(),
            roles: claims.roles
          })
        )
      }
    }
    catch (e) {
      log.error(e)
      // TODO: error handling
    }
  }
  return claims
}
