import {
  EsportsGameMetadata,
  EsportsMappingRequest,
  PlatformGameDetails
} from '@riotgames/api-types/admin-api/Game_management.type'
import { Sport } from '@riotgames/api-types/elds/Common.type'
import { Dispatch } from 'redux'
import { set } from '../../commons/Util/Util'
import { AdminApi } from '../../services'
import { displayMessage } from '../Message/Message.slice'
import { MessageType } from '../Message/Message.type'

export const getEsportsGame = (
  sport: Sport,
  esportsGameId: string
): Promise<EsportsGameMetadata> => AdminApi.getEsportsGame(sport, esportsGameId)

export const getPlatformGames = (
  sport: string
): Promise<Record<string, PlatformGameDetails>> =>
  AdminApi.getPlatformGames(sport).then((games) =>
    games.length > 0
      ? games.reduce((acc, platformGame) => {
        const id = platformGame?.platformGameId
        const setId = set<PlatformGameDetails>(id)
        return setId(acc, platformGame)
      }, {})
      : {}
  )

export const getPlatformGame = (
  sport: string,
  platformGameId: string,
  setPlatformGames: (
    platformGames: Record<string, PlatformGameDetails>,
  ) => void,
  platformGames: Record<string, PlatformGameDetails>,
  platformGamesResp?: Record<string, PlatformGameDetails>
): Promise<PlatformGameDetails> =>
  AdminApi.getPlatformGame(sport, platformGameId).then((details) => {
    const id = details?.platformGameId

    const platformGamesRespSafe = platformGamesResp || { ...platformGames }
    platformGamesRespSafe[id] = details
    setPlatformGames(platformGamesRespSafe)

    return details
  })

export const unmapPlatformGame = (
  selectedSport: Sport,
  selectedPlatformId: string,
  dispatch: Dispatch,
  onClose: () => void
) => {
  AdminApi.unmapPlatformGame(selectedSport, selectedPlatformId)
    .then(() => {
      displayMessage(
        `Game has been successfully unmapped from platformGameId: ${selectedPlatformId}`
      )(dispatch)
    })
    .catch((error) => {
      displayMessage(
        `Failed to unmap game ${JSON.stringify(error)}`,
        MessageType.Error
      )(dispatch)
    })
    .finally(() => {
      onClose()
    })
}

export const mapPlatformGame = (
  selectedSport: Sport,
  selectedPlatformId: string,
  esportsMappingRequest: EsportsMappingRequest,
  onClose: () => void
) => {
  AdminApi.mapPlatformGame(
    selectedSport,
    selectedPlatformId,
    esportsMappingRequest
  ).finally(() => {
    onClose()
  })
}
