import { useState } from 'react'
import { classNames } from '../../commons'
import { useDebounce } from '../../commons/Hooks/debounce'
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup'
import { Input } from '../../components/Simple/Simple'
import CheckBoxGroup from '../CheckBoxGroup/CheckBoxGroup'
import SearchButton from '../SearchButton/SearchButton'
import styles from './SearchFilter.scss'
import { DropdownOption, DropdownValue } from '../Dropdown/Dropdown.type'
import { Autocomplete } from '../Autocomplete/Autocomplete'

type Props = {
  term?: string
  title: string
  type: 'checkbox' | 'radio'
  hasFilterText?: 'text' | 'autocomplete'
  filterTextTitle?: string
  filterTextOptions?: DropdownOption[]
  options?: string[]
  class?: string
  showButton?: boolean
  onChange?: (value: FilterSelection) => void
}

export type FilterSelection = {
  term: string
  group: string
  options: string[]
  radio?: string
  open?: boolean
}

const SearchFilter = ({
  term,
  title,
  type,
  hasFilterText = undefined,
  filterTextTitle,
  options,
  onChange,
  filterTextOptions,
  showButton = true,
  class: className
}: Props): JSX.Element => {
  const classes = classNames(styles.searchFilter, className)
  const [input, setInput] = useState<string>(term || '')
  const [searchParam, setSearchParam] = useState<{
    term: string
    group: string
    options: string[]
    radio?: string
    open?: boolean
  }>({
    term: '',
    group: '',
    options: [],
    radio: undefined,
    open: false
  })

  const filterOptions = options || ['24 hours ago', 'Week', 'Month']
  const debouncedAction = useDebounce(() => {
    const searchParamUpdated = { ...searchParam, term: input }
    setSearchParam(searchParamUpdated)
    if (onChange) {
      onChange(searchParamUpdated)
    }
  })

  return (
    <div className={ classes }>
      <Input
        className={ styles.input }
        placeholder="Search"
        onChange={ ({ target }: DOMEventTarget) => {
          const input = (target as HTMLInputElement).value
          setInput(input)
          debouncedAction()
        } }
        value={ input }
      />
      { showButton && (
        <span className={ styles.action }>
          <SearchButton
            open={ searchParam.open }
            onChange={ (menuStatus: boolean) => {
              setSearchParam({ ...searchParam, open: menuStatus })
            } }>
            <div className={ styles.menuFloat }>
              <div className={ styles.menuHeader }>
                <p>{ title }</p>
              </div>
              { type === 'radio' ? (
                <RadioButtonGroup
                  radios={ filterOptions }
                  selected={ searchParam.radio }
                  onChange={ (label: string) => {
                    setSearchParam({ ...searchParam, radio: label })
                  } }
                />
              ) : (
                <CheckBoxGroup
                  options={ filterOptions }
                  selectedOptions={ searchParam.options }
                  onChange={ (selectedItems: string[]) => {
                    setSearchParam({ ...searchParam, options: selectedItems })
                  } }
                />
              ) }
              { hasFilterText && (
                <div className={ styles.groupFilter }>
                  <div className={ styles.groupFilterLabel }>
                    { filterTextTitle }
                  </div>
                  <div className={ styles.groupFilterInput }>
                    { hasFilterText === 'autocomplete' && filterTextOptions ? (
                      <Autocomplete
                        options={ filterTextOptions }
                        onSelect={ (value: DropdownValue) => {
                          setSearchParam({
                            ...searchParam,
                            group: value as string
                          })
                        } }
                        value={ searchParam.group }
                        placeholder="eg. Game Changers NA"
                        itemClassName={ styles.groupFilterItems }
                      />
                    ) : (
                      <Input
                        onChange={ ({ target }: DOMEventTarget) => {
                          const input = (target as HTMLInputElement).value
                          setSearchParam({ ...searchParam, group: input })
                        } }
                        value={ searchParam.group }
                        placeholder={ filterTextTitle }
                      />
                    ) }
                  </div>
                </div>
              ) }
              <div className={ styles.menuFooter }>
                <button
                  className={ styles.btnClear }
                  onClick={ () => {
                    setSearchParam({
                      ...searchParam,
                      term: '',
                      group: '',
                      options: [],
                      radio: undefined
                    })
                  } }>
                  Clear
                </button>
                <button
                  className={ styles.btnSave }
                  onClick={ () => {
                    setSearchParam({ ...searchParam, open: false })
                    if (onChange) {
                      onChange(searchParam)
                    }
                  } }>
                  Save
                </button>
              </div>
            </div>
          </SearchButton>
        </span>
      ) }
    </div>
  )
}

export default SearchFilter
