// A Mapping of localization slugs to english labels. Represents the list of
// approved section names for any given tournament. This list must be a subset
// of the list of localized names sections in elds-localizations.

import { createOption } from '../../../../components'
import { DropdownOption } from '../../../../components/Dropdown/Dropdown.type'

const sectionNames: Record<string, string> = {
  play_in_groups: 'Play-In Groups',
  play_in_elim: 'Play-In Knockout',
  groups: 'Groups',
  knockouts: 'Knockouts',
  regular_season: 'Regular Season',
  playoffs: 'Playoffs',
  tiebreaker: 'Tiebreaker',
  exhibition: 'Exhibition',
  winners_bracket: "Winner's Bracket",
  losers_bracket: "Loser's Bracket",
  group_a: 'Group A',
  group_b: 'Group B',
  group_c: 'Group C',
  group_d: 'Group D',
  group_1: 'Group 1',
  group_2: 'Group 2',
  group_3: 'Group 3',
  group_4: 'Group 4',
  round_1: 'Round 1',
  round_2: 'Round 2',
  round_3: 'Round 3',
  round_4: 'Round 4',
  round_5: 'Round 5',
  group_ascend: 'Group Ascend',
  group_nirvana: 'Group Nirvana',
  regional_finals: 'Regional Finals',
  regional_qualifier: 'Regional Qualifier',
  swiss: 'Swiss',
  weekend_1: 'Weekend 1',
  weekend_2: 'Weekend 2',
  weekend_3: 'Weekend 3',
  weekend_4: 'Weekend 4',
  weekend_5: 'Weekend 5'
}

// Returns a list of slugs for sections
export const getSlugsForSections = (): string[] => Object.keys(sectionNames)

// Returns a list of section names formatted for use in Dropdowns and Autocompletes
export const getSectionNameOptions = (): DropdownOption[] =>
  Object.entries(sectionNames).map(([slug, label]) => createOption(label, slug))

export const getSectionLocalizationForSlug = (slug: string): string =>
  sectionNames[slug]

export const getSlugForSectionLabel = (label: string): string | undefined => {
  const entries = Object.entries(sectionNames)
  const match = entries.find((entry) => entry[1] === label)

  return match ? match[0] : undefined
}
