export enum RequestType {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum RequestTargetType {
  DROP_DROP = 'Drop_Drop',
  DROP_GROUP = 'Drop_Group',
  DROP_TRIGGER = 'Drop_Trigger',
  DROP_TRIGGER_INDIVIDUAL = 'Drop_Trigger_Individual',
  DROP_INVENTORY = 'Drop_Inventory',
  DROP_LOCALIZATION = 'Drop_Localization',
  DROP_RARITY = 'Drop_Rarity',
  PROMO_CODES_CLAIM = 'Promo_Codes_Claim',
  PROMO_CODES_GENERATE = 'Promo_Codes_Generate',
  PROMO_CODES_GET_ALL = 'Promo_Codes_Get_All',
  PROMO_CODES_MAP_TO_INVENTORY = 'Promo_Codes_Map_To_Inventory',
  STREAM_TIMELINE_ADMIN = 'Stream_Timeline_Admin',
}
