import { useState } from 'react'
import Confirm from '../Confirm/Confirm'
import styles from './Reset.scss'

type Props = {
  isDirty: boolean
  disabled?: boolean
  onClick: () => void
  label?: string
  title?: string
  showText?: boolean
  bodyText?: string
}

export const Reset = ({
  isDirty,
  disabled,
  onClick,
  label = 'Reset',
  title = 'Reset Data',
  showText = true,
  bodyText = 'Are you sure you want to clear ALL your changes?'
}: Props): JSX.Element | null => {
  const [showConfirm, setShowConfirm] = useState(false)

  const renderConfirm = (): JSX.Element => (
    <Confirm
      data-testid="confirm-container"
      title={ title }
      body={ bodyText }
      subbody="This action cannot be undone."
      onConfirm={ () => {
        setShowConfirm(false)
        onClick()
      } }
      onCancel={ () => setShowConfirm(false) }
    />
  )

  if (!isDirty) return null

  return (
    <div className={ styles.reset } data-testid="reset-container">
      <button
        disabled={ disabled }
        data-testid="reset-btn"
        className={ styles.btn }
        onClick={ () => setShowConfirm(true) }>
        { label }
      </button>
      { showText 
        && <span className={ styles.msg }>You have unsaved changes.</span>
      }
      { showConfirm && renderConfirm() }
    </div>
  )
}

export default Reset
