import { CSSProperties, ChangeEvent, useState } from 'react'
import { classNames } from '../../commons'
import { TitledInput } from '../Simple/Simple'
import styles from './ColorPicker.scss'
import { Props, State } from './ColorPicker.type'

const ColorPicker = ({
  label,
  color,
  onChange,
  class: className
}: Props): JSX.Element => {
  const [selectedColor, setSelectedColor] = useState<State>({ colorHex: color })
  const isValidHexcode = (str: string): boolean =>
    /^#[0-9A-F]{0,6}$/gi.test(str)

  const onInput = (event: ChangeEvent): void => {
    const { value } = event.target as HTMLInputElement
    setSelectedColor({ colorHex: value })
    if (value) {
      const colorValue = value.startsWith('#') ? value : `#${value}`
      if (isValidHexcode(colorValue)) {
        onChange && onChange(colorValue === '#' ? '' : colorValue)
      }
    }
  }

  const classes = classNames(styles.colorPicker, className)
  const style: CSSProperties | undefined = {
    backgroundColor: color as any
  } as CSSProperties

  return (
    <div className={ classes }>
      <TitledInput
        title={ label }
        className={ styles.input }
        type="text"
        onChange={ onInput }
        value={ selectedColor.colorHex || '' }
      />
      <div className={ styles.color } style={ style }/>
    </div>
  )
}
export default ColorPicker
