import { Dispatch } from 'redux'
import { displayMessage } from '../../../components/Message/Message.slice'
import { MessageType } from '../../../components/Message/Message.type'
import {
  getAllOrganizations,
  insertOrganization,
  updateOrganization as updateOrganizationElds
} from '../../../services/Elds/Elds.actions'
import { getWIPOrganization } from '../../reducers'
import { RootState } from '../../Store.type'
import { createAction } from '../utils'

export const clearWIPOrganization = createAction(
  'organizations/clearWipProgress'
)
export const createNewOrganizationStart = createAction(
  'organizations/createNew/start'
)
export const createNewOrganizationSuccess = createAction(
  'organizations/createNew/success'
)
export const createNewOrganizationFailed = createAction(
  'organizations/createNew/failed'
)

export const createNewOrganizationAction = {
  start: createNewOrganizationStart,
  success: createNewOrganizationSuccess,
  failed: createNewOrganizationFailed
}

export const createNewOrganization
  = () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(createNewOrganizationStart())

    const organization = getWIPOrganization(getState())
    try {
      await insertOrganization(organization)(dispatch)
    }
    catch (e) {
      return dispatch(createNewOrganizationFailed())
    }

    await getAllOrganizations()(dispatch)

    displayMessage(
      `Organization ${organization.name} created successfully!`,
      MessageType.Success
    )(dispatch)

    dispatch(createNewOrganizationSuccess())
    return window.route('/organizations/list')
  }

export const updateOrganizationStart = createAction(
  'organizations/update/start'
)
export const updateOrganizationSuccess = createAction(
  'organizations/update/success'
)
export const updateOrganizationFailed = createAction(
  'organizations/update/failed'
)

export const updateOrganizationAction = {
  start: updateOrganizationStart,
  success: updateOrganizationSuccess,
  failed: updateOrganizationFailed
}
export const updateOrganization
  = () => async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(updateOrganizationStart())

    const organization = getWIPOrganization(getState())
    try {
      await updateOrganizationElds(organization)(dispatch)
    }
    catch (e) {
      return dispatch(createNewOrganizationFailed())
    }

    displayMessage(
      `Organization ${organization.name} updated successfully!`,
      MessageType.Success
    )(dispatch)

    dispatch(createNewOrganizationSuccess())
    return window.route('/organizations/list')
  }
