import { Game, Match } from '@riotgames/api-types/elds/Matches.type'
import { BroadcastItem } from '@riotgames/api-types/elds/Schedules_v3.type'
import { NormalizedMatch } from '../../services/Elds/Elds.type'
import { keyById, omit } from '../Util/Util'

export const normalizeMatch = (match: Match): NormalizedMatch => ({
  ...omit(match, 'games'),
  gameIds: Object.keys(keyById(match.games))
})

export const broadcastItem = (
  unscheduledMatchId: string
): DeepPartial<BroadcastItem> => ({
  startTime: undefined,
  matchId: unscheduledMatchId,
  broadcastState: undefined,
  status: undefined,
  visibleAfterTime: undefined
})

export const createGame = (id: string): DeepPartial<Game> => ({
  assignedName: 'assigned',
  blueTeam: 'blue',
  gameplayConfiguration: undefined,
  id,
  number: 1,
  redTeam: 'red',
  resultMetadata: undefined,
  status: undefined,
  teamOutcomes: undefined
})

export const createMatch = (
  matchId: string,
  gameId: string
): DeepPartial<Match> => ({
  bestOfConfig: undefined,
  description: undefined,
  gameMode: undefined,
  games: [createGame(gameId)],
  id: matchId,
  participatingTeams: [],
  playAllConfig: undefined,
  publicationStatus: undefined,
  sectionId: '',
  sport: undefined,
  status: undefined,
  strategy: undefined,
  structuralId: '',
  teamBasedOutcome: undefined,
  teamOne: '',
  teamTwo: '',
  type: undefined
})
