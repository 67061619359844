
import { FlattenedBannerConfig } from '../../containers/Banners/Banners.type'

export enum Bucket {
  Static = 'static',
  Banners = 'banners',
}

export enum FunctionalityType {
  Banner = 'banner',
  NonBanner = 'non-banner'
}

export type UploadConfig = {
  bucket?: Bucket
  uniqueKey?: boolean
}

export type BannerAssetPayload = {
  assetList: {
    keys: string[]
  }
  flattenedConfigs: FlattenedBannerConfig[]
}

export type AssetLink = {
  presignedUrl: string
}