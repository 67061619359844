import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react'
import { Logger, classNames, resizeImage } from '../../commons'
import { EventBus } from '../../services'
import { Button } from '../Simple/Simple'
import styles from './AssetUpload.scss'
import { AssetType, Props, State } from './AssetUpload.type'

const log = new Logger('AssetUpload')
const maxImageDimension = 4000

const AssetUpload = ({
  name,
  onChange,
  class: className,
  imageClass,
  selectText,
  removeText,
  title,
  fileUrl,
  type
}: Props): JSX.Element => {
  const classes = classNames(styles.assetUpload, className)
  const assetType = type || AssetType.Generic
  const fileUploader = useRef<HTMLInputElement>()
  const [asset, setAsset] = useState<State>({
    fileUrl: fileUrl || '',
    file: undefined,
    title: title || 'Select a file',
    selectText: selectText || 'Attach',
    removeText: removeText || 'Remove'
  })

  useEffect(() => {
    setAsset(
      Object.assign({}, asset, {
        fileUrl
      })
    )
  }, [fileUrl])

  const onClickSelect = (): void => {
    fileUploader.current?.click()
  }

  const onClickRemove = (): void => {
    onChange(undefined, undefined)
    setAsset(
      Object.assign({}, asset, {
        fileUrl: '',
        file: undefined
      })
    )
  }

  const renderSelectGenericButton = (): JSX.Element => (
    <div className={ styles.selectGeneric }>
      <Button className={ styles.select } onClick={ (): void => onClickSelect() }>
        { asset.selectText }
      </Button>
    </div>
  )

  const renderRemoveImageButton = (): JSX.Element => {
    const imageClasses = classNames(styles.removeImage, imageClass)
    const imageUrl = resizeImage(asset.fileUrl, 200, 200)

    return (
      <div
        className={ imageClasses }
        style={ {
          backgroundImage: `url(${imageUrl})`
        } }>
        <Button className={ styles.remove } onClick={ (): void => onClickRemove() }>
          { asset.removeText }
        </Button>
      </div>
    )
  }

  const renderSelectImageButton = (): JSX.Element => (
    <div className={ styles.selectImage }>
      <Button className={ styles.select } onClick={ (): void => onClickSelect() }>
        { asset.selectText }
      </Button>
    </div>
  )

  const renderRemoveGenericButton = (): JSX.Element => {
    const { file, fileUrl } = asset
    const fileName = file ? file?.name : fileUrl

    return (
      <div className={ styles.removeGeneric }>
        <Button className={ styles.remove } onClick={ (): void => onClickRemove() }>
          { asset.removeText }
        </Button>
        { fileName }
      </div>
    )
  }

  const renderButton = (): JSX.Element => {
    switch (type) {
      case AssetType.Image:
        return asset.fileUrl
          ? renderRemoveImageButton()
          : renderSelectImageButton()
      case AssetType.Generic:
      default:
        return asset.fileUrl
          ? renderRemoveGenericButton()
          : renderSelectGenericButton()
    }
  }

  // Akamai can break with large image dimensions, which will as a
  // result break images on our FE. Therefore we need to ensure
  // that the images that are getting uploaded aren't egregiously large
  const validateImageDimensions = (file: File, fileUrl: string) => {
    const image = new Image()

    image.onload = () => {
      if (image.width > maxImageDimension || image.height > maxImageDimension) {
        EventBus.trigger('message', {
          type: 'error',
          message: `Image dimensions too large (max is ${maxImageDimension}px). Width: ${image.width}px; Height: ${image.height}px`
        })
        ;(fileUploader.current as HTMLInputElement).value = ''
      }
      else {
        onChange(file, fileUrl)
      }
    }
    image.src = encodeURI(fileUrl)
  }

  const onChangeHandler = (event: ChangeEvent): void => {
    try {
      const target = event.target as HTMLInputElement
      const file = target.files?.[0]
      if (file) {
        const fileUrl = URL.createObjectURL(file)
        if (type === AssetType.Image) {
          validateImageDimensions(file, fileUrl)
        }
        else {
          onChange(file, fileUrl)
        }
      }
    }
    catch (error) {
      log.error('No file selected', error)
    }
  }

  return (
    <div className={ classes }>
      <input
        data-testid="asset-upload-file-input"
        type="file"
        ref={ fileUploader as RefObject<HTMLInputElement> }
        name={ name }
        onChange={ (event): void => onChangeHandler(event) }
        accept={ assetType }
        style={ { display: 'none' } }
      />
      { renderButton() }
      <div className={ styles.titleText }>{ asset.title }</div>
    </div>
  )
}

export default AssetUpload
