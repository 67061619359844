import checkImage from '../../assets/svg/check.svg'
import partialCheckImage from '../../assets/svg/partialCheck.svg'
import { classNames, stopEventPropagation } from '../../commons'
import styles from './TriStateCheckBox.scss'

export type Location = 'left' | 'right'
type Props = {
  checkboxLocation: Location
  checked: TriState
  class?: string
  label: string
  onChange?: (target: any) => void
  labelClass?: string
}

export enum TriState {
  Checked = 'checked',
  PartialChecked = 'partialChecked',
  UnChecked = 'unChecked',
}

const TriStateCheckBox = ({
  checkboxLocation,
  checked,
  label,
  onChange,
  class: className,
  labelClass
}: Props): JSX.Element => {
  const classes = classNames(
    styles.container,
    styles[checkboxLocation],
    className
  )

  const onClick = (event: any): void => {
    stopEventPropagation(event)
    if (onChange) {
      onChange(event)
    }
  }

  const renderCheck = (): JSX.Element => {
    switch (checked) {
      case TriState.Checked:
        return (
          <div className={ styles.checkboxInverted } data-testid="image-checked">
            <svg className={ styles.checked }>
              <title>Checkbox</title>
              <use href={ checkImage }/>
            </svg>
          </div>
        )
      case TriState.PartialChecked:
        return (
          <div
            className={ styles.checkboxInverted }
            data-testid="image-partial-checked">
            <svg className={ styles.partialChecked }>
              <title>Checkbox</title>
              <use href={ partialCheckImage }/>
            </svg>
          </div>
        )
      case TriState.UnChecked:
      default:
        return (
          <div className={ styles.unChecked } data-testid="image-unchecked"/>
        )
    }
  }

  const getStyle = (checked: TriState): any => {
    switch (checked) {
      case TriState.Checked:
        return styles.checked
      case TriState.PartialChecked:
        return styles.partialChecked
      case TriState.UnChecked:
      default:
        return styles.unchecked
    }
  }

  return (
    <div className={ classes } onClick={ onClick } data-testid="checkbox">
      <div className={ getStyle(checked) }>{ renderCheck() }</div>
      <label className={ classNames(styles.label, labelClass) }>{ label }</label>
    </div>
  )
}

export default TriStateCheckBox
