export const blockNames = {
  'Day': 'day',
  'Week': 'week',
  'Round': 'round',
  'Playoffs': 'playoffs',
  'Playoffs - Round': 'playoffs_round',
  'Play in Groups': 'play_in_groups',
  'Play in Knockouts': 'play_in_knockouts',
  'Quarterfinals': 'quarterfinals',
  'Semifinals': 'semifinals',
  'Finals': 'finals',
  'Regional Qualifier': 'regional_qualifier',
  'Promotion': 'promotion',
  'Groups': 'groups',
  'Knockouts': 'knockouts',
  'Swiss': 'swiss',
  'Play-Ins': 'play_ins'
}

export const namesWithNumber = ['Day', 'Week', 'Round', 'Playoffs - Round']

export enum TriState {
  Checked = 'checked',
  PartialChecked = 'partialChecked',
  UnChecked = 'unChecked',
}
