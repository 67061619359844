import { RarityMetaDTO, RarityFilterOptions } from './RarityMeta.type'
import { createSlice } from '../../../../actions/utils'
import { Action } from '../../../../Store.type'
import { isEqual } from '../../../../../commons'

const initialState: DeepPartial<RarityMetaDTO> = {
  rarityFilterOptions: {
    league: null,
    search: null
  }
}

const { reducer, actions } = createSlice({
  name: 'rarityMeta',
  initialState: initialState,
  reducers: {
    initialize: () => initialState,
    clear: () => initialState,
    clearFilters: (state) => ({
      ...state,
      rarityFilterOptions: {
        league: null,
        search: null
      }
    }),
    editFilters: (
      state,
      { payload }: Action<Partial<RarityFilterOptions>>
    ) => ({
      ...state,
      rarityFilterOptions: Object.assign(
        { league: null, search: null },
        state?.rarityFilterOptions,
        payload
      )
    })
  },
  extraReducers: {}
})

export default reducer
const { initialize, clear, clearFilters, editFilters } = actions

export { initialize, clear, clearFilters, editFilters }

export const getWIPRarityMeta = (state: RarityMetaDTO): RarityMetaDTO => state
export const isRarityMetaDirty = (state: RarityMetaDTO): boolean =>
  !isEqual(state, initialState)
