import { useEffect, useState } from 'react'
import { Config } from '../../commons'
import { AppWindow } from '../../index.type'
import { AdminApiEnvSessionStorageKey } from '../../services/AdminApi/AdminApi'
import { BannersApiEnvSessionStorageKey } from '../../services/BannersService/BannersService'
import { DropsApiEnvSessionStorageKey } from '../../services/DropsApi/DropsApi'
import { EldsApiEnvSessionStorageKey } from '../../services/Elds/Elds'
import { LiveStatsApiEnvSessionStorageKey } from '../../services/LiveStatsApi/LiveStatsApi'
import { PickemApiEnvSessionStorageKey } from '../../services/PickemApi/PickemApi'
import { PromoCodeApiEnvSessionStorageKey } from '../../services/PromoCodesApi/PromoCodesApiV2'
import { StreamTimelineApiEnvSessionStorageKey } from '../../services/StreamTimeline/StreamTimeline'
import styles from './ConfigPanel.scss'
import { EnvironmentSelectorOptions, State } from './ConfigPanel.type'

const appWindow = window as AppWindow
const version = appWindow.APP_VERSION

const apiEnvKeyMap: Record<string, string> = {
  'Admin': AdminApiEnvSessionStorageKey,
  'Banners': BannersApiEnvSessionStorageKey,
  'Drops': DropsApiEnvSessionStorageKey,
  'ELDS': EldsApiEnvSessionStorageKey,
  'LiveStats': LiveStatsApiEnvSessionStorageKey,
  'Pickem': PickemApiEnvSessionStorageKey,
  'Promo Codes': PromoCodeApiEnvSessionStorageKey,
  'Stream': StreamTimelineApiEnvSessionStorageKey
}

const setApiEnvOverrideKey = (apiEnvKey: string, env: string) => {
  window.sessionStorage.setItem(apiEnvKey, env)
}

const ConfigPanel = () => {
  const [open, setOpen] = useState<State>({ isOpen: false })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.shiftKey && event.key === 'C') {
        // shift + C key
        setOpen({ isOpen: !open.isOpen })
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [open])

  const renderOptions = (environments: string[], selected?: string) =>
    environments.map((item, index) => (
      <option value={ item } selected={ item === selected } key={ index }>
        { item }
      </option>
    ))

  const renderEnvironmentSelector = (
    type: string,
    options?: EnvironmentSelectorOptions
  ): JSX.Element => {
    const apiEnvOverrideKey = apiEnvKeyMap[type]
    const isBaseEnvironmentSelector = type === 'Environment'
    // Get the currently selected api env override if available to display in the dropdown
    const selectedOption = isBaseEnvironmentSelector
      ? Config.getEnv()
      : window.sessionStorage.getItem(apiEnvOverrideKey)

    const environments = ['local', 'dev', 'test']
    if (options?.includeStaging) {
      environments.splice(3, 0, 'stage')
    }
    return (
      <div className={ styles.environmentSelectorSection }>
        <div className={ styles.environmentSelectorLabel }>
          { isBaseEnvironmentSelector ? 'Environment' : `${type} Environment` }
        </div>
        <select
          className={ styles.environmentSelector }
          onChange={ ({ target }: DOMEventTarget) => {
            const environment = (target as HTMLSelectElement).value
            // If one of the selected environments points to staging, then all other core services should be pointing to test
            if (environment === 'stage') {
              Config.setEnv('test')
            }
            // If the type of the environment selector is 'Environment', then we are setting the base environment directly
            // otherwise set the api environment override key per service
            if (isBaseEnvironmentSelector) {
              Config.setEnv(environment)
            }
            else {
              setApiEnvOverrideKey(apiEnvOverrideKey, environment)
            }
            window.location.reload?.()
          } }>
          <option value={ '' }>Select an environment</option>
          { renderOptions(environments, selectedOption || undefined) }
        </select>
      </div>
    )
  }
  return (
    <>
      { open.isOpen && (
        <div className={ styles.sidebar }>
          <h1 className={ styles.header }>
            Config Panel
            <span className={ styles.version }>v{ version }</span>
          </h1>
          { renderEnvironmentSelector('Environment') }
          { renderEnvironmentSelector('Admin') }
          { renderEnvironmentSelector('Banners') }
          { renderEnvironmentSelector('Drops', { includeStaging: true }) }
          { renderEnvironmentSelector('ELDS') }
          { renderEnvironmentSelector('LiveStats') }
          { renderEnvironmentSelector('Pickem', { includeStaging: true }) }
          { renderEnvironmentSelector('Promo Codes', {
            includeStaging: true
          }) }
          { renderEnvironmentSelector('Stream Timeline', {
            includeStaging: true
          }) }
        </div>
      ) }
    </>
  )
}

export { ConfigPanel }
