export type MessageEvent = {
  type: MessageType
  message: string
  displayType: MessageDisplayType
}

export enum MessageType {
  Error = 'error',
  Loading = 'loading',
  Success = 'success',
}

export type MessageDisplayType = 'default' | 'modal'
