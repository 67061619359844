import { AppWindow } from 'index.type'
import { Config } from '../../commons'
import { datadogRum } from '@datadog/browser-rum-slim'

const appWindow = window as AppWindow

class Datadog {
  init () {
    datadogRum.init({
      clientToken: 'pub9d6784d098dd184e002cc07b72fcb325',
      applicationId: '4520a983-ca23-44b0-958b-e5773b7e1d35',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'esports-management-portal',
      env: Config.getEnv(),
      // Specify a version number to identify the deployed version of your application in Datadog
      version: appWindow.APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      telemetrySampleRate: 0,
      allowFallbackToLocalStorage: true,
      beforeSend: (event) => {
        const resource = event.resource as { type?: string; url?: string }
        // Ignore image resizer events from being sent to Datadog, they are often hundrededs to thousands of events per second
        // especially when openning player dropdowns which slows the page to a crawl
        if (resource && resource.type === 'image' && resource.url) {
          try {
            const url = new URL(resource.url)
            if (
              url.host === 'am-a.akamaihd.net'
              && url.pathname.startsWith('/image')
            ) {
              return false
            }
          }
          catch (e) {
            // Invalid URL, ignore
          }
        }
        return true
      }
    })
  }
}

export default new Datadog()
