import {
  RarityType,
  RecordStatus
} from '@riotgames/api-types/drops/Common.type'
import { LocalizationDTO } from '@riotgames/api-types/drops/Drops.type'
import {
  GroupRequestDTO,
  GroupResponseDTO,
  InventoryDescription,
  InventoryRequestDTO,
  InventoryResponseDTO,
  RarityRequestDTO,
  RarityResponseDTO,
  TriggerRequestDTO,
  TriggerResponseDTO
} from '@riotgames/api-types/drops/DropsV2.type'
import { Envs } from '@riotgames/commons/env'
import { Config, Logger, chunkArray, fetch, urlBuilder } from '../../commons'
import { DropTriggerPreviewPayload } from '../../containers/Drops/ShareableImagePreview/DropsShareableImagePreview.type'
import { IndividualTriggerMetaDTO } from '../../store/reducers/WIP/Drops/Meta/IndividualTriggerMeta.type'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'
import EmpLambdaConfig from '../EMP/EmpLambdaConfig'
const log = new Logger('DropsApi')

export const DropsApiEnvSessionStorageKey = 'dropsApiEnv'
const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.Drops,
  Config.getEnv(DropsApiEnvSessionStorageKey)
)
const localizationApiBaseUrl = `${apiBaseUrl}/dropLocalization/v1.0/`

const dropBaseUrl = Config.getKeyByEnv({
  local: apiBaseUrl,
  dev: apiBaseUrl,
  test: apiBaseUrl,
  stage: apiBaseUrl,
  prod: apiBaseUrl
})

const localizationUrl = urlBuilder({
  local: localizationApiBaseUrl,
  dev: localizationApiBaseUrl,
  test: localizationApiBaseUrl,
  stage: localizationApiBaseUrl,
  prod: localizationApiBaseUrl
})

const lambdaUrl = EmpLambdaConfig.getLambdaUrl()

const lightweaverUrl
  = Config.getKeyByEnv({
    local: lambdaUrl,
    dev: lambdaUrl,
    test: lambdaUrl,
    stage: lambdaUrl,
    prod: lambdaUrl
  }) ?? ''

const groupsUrl = urlBuilder({
  local: `${dropBaseUrl}/drops/group/v1.0/`,
  dev: `${dropBaseUrl}/drops/group/v1.0/`,
  test: `${dropBaseUrl}/drops/group/v1.0/`,
  prod: `${dropBaseUrl}/drops/group/v1.0/`
})

const triggerUrl = urlBuilder({
  local: `${dropBaseUrl}/drops/dropTrigger/v1.0/`,
  dev: `${dropBaseUrl}/drops/dropTrigger/v1.0/`,
  test: `${dropBaseUrl}/drops/dropTrigger/v1.0/`,
  prod: `${dropBaseUrl}/drops/dropTrigger/v1.0/`
})

const inventoryUrl = urlBuilder({
  local: `${dropBaseUrl}/drops/dropInventoryItem/v1.0/`,
  dev: `${dropBaseUrl}/drops/dropInventoryItem/v1.0/`,
  test: `${dropBaseUrl}/drops/dropInventoryItem/v1.0/`,
  prod: `${dropBaseUrl}/drops/dropInventoryItem/v1.0/`
})

const rarityUrl = urlBuilder({
  local: `${dropBaseUrl}/drops/rarity/v1.0/`,
  dev: `${dropBaseUrl}/drops/rarity/v1.0/`,
  test: `${dropBaseUrl}/drops/rarity/v1.0/`,
  prod: `${dropBaseUrl}/drops/rarity/v1.0/`
})

class DropsApi {
  static async getOptions (method = 'POST'): Promise<RequestInit> {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    return {
      method: method,
      headers: headers
    }
  }

  async fetchGroups (): Promise<GroupResponseDTO[]> {
    const json: GroupResponseDTO[] = await fetch(
      groupsUrl('group/list'),
      await DropsApi.getOptions('GET')
    )
    return json
  }

  async fetchInventoryItems (): Promise<InventoryResponseDTO[]> {
    const json: InventoryResponseDTO[] = await fetch(
      inventoryUrl('inventoryItems'),
      await DropsApi.getOptions('GET')
    )
    return json
  }

  async fetchInventoryItem (
    id: string,
    sport: string
  ): Promise<InventoryResponseDTO> {
    const json: InventoryResponseDTO = await fetch(
      inventoryUrl(`dropInventoryItem/${id}?sport=${sport}`),
      await DropsApi.getOptions('GET')
    )
    return json
  }

  async fetchRarityItems (): Promise<RarityResponseDTO[]> {
    const json: RarityResponseDTO[] = await fetch(
      rarityUrl('rarity'),
      await DropsApi.getOptions('GET')
    )
    return json
  }

  // TODO: Replace this with a traditional call to our fetch utility
  // TODO: Prerequisite: Drops backend must guarantee never sending a
  // TODO: LocKey which does not exist.
  async fetchLocalizationForKey (key: string): Promise<LocalizationDTO[]> {
    // Must use window.fetch() directly to override default behavior.  This
    // prevents us from seeing ERROR: 404 - Not Found errors on the drops pages
    return window
      .fetch(
        localizationUrl(`localization/${key}`),
        await DropsApi.getOptions('GET')
      )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((body: any) => {
            const errorMessage = `${body.httpStatus} - ${
              body.message || body.errorCode
            }`
            log.error(errorMessage)
            throw new Error(errorMessage)
          })
        }

        return res.status !== 204 ? res.json() : []
      })
  }

  async fetchLocalizationForKeys (keys: string[]): Promise<LocalizationDTO[]> {
    let chunks: string[][]

    keys = keys.filter((key) => key !== 'title' && key !== 'description')
    if (keys.length > 50) {
      chunks = chunkArray(keys, 50)
    }
    else {
      chunks = [keys]
    }

    if (keys.length === 0) {
      return []
    }

    const requests = chunks.map(async (keys) => {
      const buildQueryString = `groups=${keys.join('&groups=')}`
      const options = await DropsApi.getOptions('GET')

      return fetch(
        localizationUrl(`localization/groups?${buildQueryString}`),
        options
      ).catch(() => undefined)
    })

    const successResponses: any = (await Promise.all(requests)).filter(
      (response) => response !== undefined
    )

    // NOTE: emp-API-service for localization returns result in direct array instead of returning Json
    let results: any[] = []
    if (Array.isArray(successResponses)) {
      results = successResponses
    }
    else {
      results = successResponses.map(async (result: any) => {
        if (!result?.ok) {
          const body = await result?.json()
          const errorMessage = `${body.httpStatus} - ${
            body.message || body.errorCode
          }`
          log.error(errorMessage)
          return undefined
        }

        if (result.status === 204) {
          return []
        }
        return result.json()
      })
    }
    return (await Promise.all(results)).flat().filter((item) => !!item)
  }

  async fetchTriggerItems (
    startDate: string,
    endDate: string
  ): Promise<TriggerResponseDTO[]> {
    const json: TriggerResponseDTO[] = await fetch(
      triggerUrl(`trigger?startTime=${startDate}&&endTime=${endDate}`),
      await DropsApi.getOptions('GET')
    )
    return json
  }

  async pullTrigger (triggerID: string): Promise<TriggerResponseDTO> {
    const json: TriggerResponseDTO = await fetch(
      triggerUrl(`trigger/${triggerID}`, { shouldIncludeStats: true }),
      await DropsApi.getOptions('GET')
    )
    return json
  }

  async triggerDryRunGroup (
    detail: TriggerRequestDTO
  ): Promise<TriggerResponseDTO> {
    const body = JSON.stringify(detail)
    const options = await DropsApi.getOptions('POST')
    const url = triggerUrl('trigger', { dry_run: true })

    const result = fetch(url, {
      ...options,
      body
    })
    return result
  }

  async triggerGroup (detail: TriggerRequestDTO): Promise<TriggerResponseDTO> {
    const body = JSON.stringify(detail)
    const options = await DropsApi.getOptions('POST')
    const url = triggerUrl('trigger', { dry_run: false })

    const result = fetch(url, {
      ...options,
      body
    })

    return result
  }

  async triggerIndividual (
    individualTrigger: IndividualTriggerMetaDTO
  ): Promise<TriggerResponseDTO> {
    const options = await DropsApi.getOptions('POST')

    const url = triggerUrl(
      `trigger/${individualTrigger.puuid}/${individualTrigger.dropID}`,
      { notify: true }
    )

    const result = fetch(url, {
      ...options
    })

    return result
  }

  async putFullGroup (fullGroupDTO: GroupRequestDTO): Promise<GroupResponseDTO> {
    const body = JSON.stringify(fullGroupDTO)
    const options = await DropsApi.getOptions('POST')

    const url = groupsUrl('group')

    const result = fetch(url, {
      ...options,
      body
    })
    return result
  }

  async putEditGroup (
    id: string,
    editGroupDTO: GroupRequestDTO
  ): Promise<GroupResponseDTO> {
    const body = JSON.stringify(editGroupDTO)
    const options = await DropsApi.getOptions('POST')

    const url = groupsUrl(`group/${id}`)

    const result = fetch(url, {
      ...options,
      body
    })
    return result
  }

  async putLocalization (localization: string): Promise<string> {
    const body = JSON.stringify(localization)
    const options = await DropsApi.getOptions('PUT')

    const url = localizationUrl('localization')

    const result = fetch(url, {
      ...options,
      body
    })
    return result
  }

  async putLocalizations (localizations: string): Promise<string> {
    const body = JSON.stringify(localizations)
    const options = await DropsApi.getOptions('PUT')

    const url = localizationUrl('localizations')

    const result = fetch(url, {
      ...options,
      body
    })

    return result
  }

  async putDropsInventoryFulfillmentStatusDefaultDescriptions (
    defaultDescriptions: InventoryDescription[],
    sport: string
  ): Promise<string> {
    const timestamp = Math.floor(Date.now() / 1000)
    const updatedDD = defaultDescriptions.map(
      ({ fulfillmentStatuses, localizationDTOs }, i) => {
        const key = `${timestamp}-default-description_${i}`
        const updatedDTOs = localizationDTOs.map(
          ({ localization, localeKey }) => ({
            localeGroup: key,
            localeKey,
            localization
          })
        )
        return {
          descriptionGroupKey: key,
          localizationDTOs: updatedDTOs,
          fulfillmentStatuses
        }
      }
    )
    const body = JSON.stringify(updatedDD)
    const options = await DropsApi.getOptions('PUT')

    const url = inventoryUrl(`${sport}/description`)
    const result = fetch(url, {
      ...options,
      body
    })

    return result
  }

  async getDropsInventoryFulfillmentStatusDefaultDescriptions (
    sport: string
  ): Promise<string> {
    const options = await DropsApi.getOptions('GET')

    const url = inventoryUrl(`defaultDescription/${sport}`)
    const result = fetch(url, options)

    return result
  }

  async putInventoryItem (inventoryItem: InventoryRequestDTO): Promise<string> {
    const body = JSON.stringify(inventoryItem)
    const options = await DropsApi.getOptions('PUT')

    const url = inventoryUrl('dropInventoryItem')
    const result = fetch(url, {
      ...options,
      body
    })

    return result
  }

  async pendingGroup (group: GroupResponseDTO): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = groupsUrl(`group/${group.id}`, {
      status: `${RecordStatus.Pending}`
    })

    return fetch(url, {
      ...options
    })
  }

  async deleteGroup (group: GroupResponseDTO): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = groupsUrl(`group/${group.id}`, {
      status: `${RecordStatus.Deleted}`
    })
    return fetch(url, {
      ...options
    })
  }

  async archiveGroup (group: GroupResponseDTO): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = groupsUrl(`group/${group.id}`, {
      status: `${RecordStatus.Archived}`
    })

    return fetch(url, {
      ...options
    })
  }

  async archiveInventoryItem (id: string): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = inventoryUrl(`dropInventoryItem/${id}`, { status: 'archived' })

    return fetch(url, {
      ...options
    })
  }

  async deleteInventoryItem (id: string): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = inventoryUrl(`dropInventoryItem/${id}`, { status: 'deleted' })

    return fetch(url, {
      ...options
    })
  }

  async unarchiveInventoryItem (id: string): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = inventoryUrl(`dropInventoryItem/${id}`, { status: 'active' })

    return fetch(url, {
      ...options
    })
  }

  async postRarity (rarity: RarityRequestDTO): Promise<string> {
    const typeName
      = RarityType[rarity.type as unknown as number] || ('' as string)
    const request = Object.assign({}, rarity, { type: typeName.toLowerCase() })
    const body = JSON.stringify(request)
    const options = await DropsApi.getOptions('POST')

    const url = rarityUrl('rarity')
    const result = fetch(url, {
      ...options,
      body
    })

    return result
  }

  async archiveRarity (id: number): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = rarityUrl(`rarity/${id}`, { status: 'archived' })

    return fetch(url, {
      ...options
    })
  }

  async unarchiveRarity (id: number): Promise<string> {
    const options = await DropsApi.getOptions('PUT')

    const url = rarityUrl(`rarity/${id}`, { status: 'active' })
    return fetch(url, {
      ...options
    })
  }

  async fetchDropTriggerPreviewImage (
    dropTriggerPreview: DropTriggerPreviewPayload,
    locale: string
  ): Promise<string> {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: 'dropTrigger',
        isPreview: true,
        locale,
        data: dropTriggerPreview
      })
    }
    const response = await window.fetch(lightweaverUrl, options)
    if (!response.ok) return Promise.reject('error')
    const blob = await response.blob()
    return await blobToBase64(blob)
  }
}

function blobToBase64 (blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = (event) =>
      reject(`error occurred reading image blob: ${event}`)
    reader.readAsDataURL(blob)
  })
}

export default new DropsApi()
