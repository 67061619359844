import { combineReducers } from 'redux'
import { FlattenedBannerConfig } from '../../../containers/Banners/Banners.type'
import { getBannerAssetList, linkAssets } from '../../../services/AwsS3/AwsS3'
import {
  AssetLink,
  BannerAssetPayload,
  Bucket
} from '../../../services/AwsS3/AwsS3.type'
import AwsS3Config from '../../../services/AwsS3/AwsS3Config'
import { PayloadAction } from '../../Store.type'
import { createSlice, isLoading } from '../../actions/utils'

const parseBannerTextures = (
  payload: BannerAssetPayload
): Record<string, string[]> => {
  const texturesByLeague: Record<string, string[]> = {}
  payload.assetList?.keys?.forEach((asset) => {
    // FORMAT: ${leagueId}/textures/${texture}
    const assetParts = asset.split('/')
    if (assetParts.length === 3 && assetParts[1] === 'textures') {
      const leagueId = assetParts[0]
      const textureName = assetParts[2]

      texturesByLeague[leagueId]
        ? texturesByLeague[leagueId].push(textureName)
        : texturesByLeague[leagueId] = [textureName]
    }
  })
  return texturesByLeague
}

const parseBannerScreenshots = (
  payload: BannerAssetPayload
): Record<string, Record<string, string[]>> => {
  const buildScreenshotKey = (
    leagueId: string,
    patch: string,
    version: string
  ) => `${leagueId}|${patch}|${version}`
  const isValidScreenshot = (assetParts: string[]) =>
    assetParts.length === 5
    && assetParts[3] === 'screenshots'
    && assetParts[4] !== ''
    && !assetParts[4].includes('.zip')

  const screenshots: Record<string, Record<string, string[]>> = {}
  const mappedConfigs = payload.flattenedConfigs.reduce(
    (map: Record<string, FlattenedBannerConfig>, config) => {
      const key = buildScreenshotKey(
        config.leagueId,
        config.patch,
        config.version
      )
      map[key] = config
      return map
    },
    {}
  )

  payload.assetList?.keys?.forEach((asset) => {
    // FORMAT: ${leagueId}/${patch}/${version}/screenshots/${screenshot}
    const assetParts = asset.split('/')
    if (isValidScreenshot(assetParts)) {
      const leagueId = assetParts[0]
      const patch = assetParts[1]
      const version = assetParts[2]

      const configKey = buildScreenshotKey(leagueId, patch, version)
      const config = mappedConfigs[configKey]

      if (config) {
        if (!screenshots[config.id]) screenshots[config.id] = {}

        screenshots[config.id][config.version]
          ? screenshots[config.id][config.version].push(asset)
          : screenshots[config.id][config.version] = [asset]
      }
    }
  })

  return screenshots
}

const assetManagerSlice = createSlice({
  name: 'awsS3AssetManager',
  initialState: { presignedUrl: '' } as AssetLink,
  reducers: {} as any,
  extraReducers: {
    [linkAssets.success.type]: (state, { payload }: PayloadAction<any>) =>
      payload
  }
})

const bannerTexturesSlice = createSlice({
  name: 'awsS3BannerTextures',
  initialState: {} as Record<string, string[]>,
  reducers: {},
  extraReducers: {
    [getBannerAssetList.success.type]: (
      state,
      { payload }: PayloadAction<BannerAssetPayload>
    ) => parseBannerTextures(payload)
  }
})

const bannerScreenshotsSlice = createSlice({
  name: 'awsS3BannerTextures',
  initialState: {} as Record<string, Record<string, string[]>>,
  reducers: {},
  extraReducers: {
    [getBannerAssetList.success.type]: (
      state,
      { payload }: PayloadAction<BannerAssetPayload>
    ) => parseBannerScreenshots(payload)
  }
})

const combinedReducer = combineReducers({
  assetManager: assetManagerSlice.reducer,
  isAssetLinking: isLoading(linkAssets),
  bannerTextures: bannerTexturesSlice.reducer,
  bannerScreenshots: bannerScreenshotsSlice.reducer,
  bannerAssetsLoading: isLoading(getBannerAssetList)
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

export const getAssetLinks = (state: StateShape): AssetLink =>
  state.assetManager
export const isAssetLinking = (state: StateShape) => state.isAssetLinking

export const getBannerTextures = (
  state: StateShape
): Record<string, string[]> => state.bannerTextures

export const getBannerScreenshots = (
  state: StateShape
): Record<string, Record<string, string[]>> => state.bannerScreenshots

export const areBannerAssetsLoading = (state: StateShape) =>
  state.bannerAssetsLoading
