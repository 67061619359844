import { Children, ReactNode } from 'react'
import { classNames } from '../../commons'
import styles from './OrderedList.scss'

export type Props = {
  class?: string
  children?: ReactNode
  startingIndex?: number
}

const OrderedList = (props: Props): JSX.Element => {
  const classes = classNames(styles.orderedList, props.class)

  const indexToAdd = props.startingIndex ?? 0
  return (
    <ol className={ classes }>
      { Children.toArray(props.children).map((child, index) => (
        <li className={ styles.listItem } key={ index }>
          <label className={ styles.label }>{ index + 1 + indexToAdd }</label>
          <div className={ styles.container } data-testid={ `label-${index + 1}` }>
            { child }
          </div>
        </li>
      )) }
    </ol>
  )
}

export default OrderedList
