import { createRef, useState } from 'react'
import arrowImage from '../../assets/svg/arrow.svg'
import checkImage from '../../assets/svg/check.svg'
import { classNames, mergeStyles } from '../../commons'
import { DropdownOption, DropdownValue } from '../Dropdown/Dropdown.type'
import defaultStyles from './Multiselect.scss'
import { Props, Styles } from './Multiselect.type'
import ClickoutDetector from '../../components/ClickoutDetector/ClickoutDetector'

export const Multiselect = (props: Props): JSX.Element => {
  const ref = createRef<HTMLDivElement>()
  let styles: Partial<Styles> = defaultStyles

  const { disabled, options, values, placeholder, selectedLabel, onSelect }
    = props

  const [active, setActive] = useState(false)

  styles = mergeStyles<Styles>(defaultStyles, props.styles)

  const classes = classNames(
    styles.multiselect,
    active && styles.active,
    disabled && styles.disabled
  )

  const clickoutClasses = classNames(styles.itemContainer)

  const renderChevron = (): JSX.Element => {
    const classes = classNames(
      styles.chevron,
      active ? styles.open : styles.closed
    )
    return (
      <svg data-testid="option-indicator" className={ classes }>
        <use href={ arrowImage }/>
      </svg>
    )
  }

  const renderSelectedOption = (
    values: DropdownValue[],
    placeholder: string,
    selectedLabel: string,
    disabled: boolean | undefined
  ): JSX.Element => (
    <div
      data-testid="select-item"
      onClick={ (): void | false => !disabled && setActive(true) }
      className={ styles.selectedItem }>
      <span>
        { values.length ? `${values.length} ${selectedLabel}` : placeholder }
      </span>
      { renderChevron() }
    </div>
  )

  const renderItem = (
    option: DropdownOption,
    values: DropdownValue[],
    onSelect: (values: DropdownValue[]) => void
  ): JSX.Element =>
    option.menu && option.menu.length > 0
      ? renderOptionMenu(option, values, onSelect)
      : renderSingleItem(option, values, onSelect)

  const renderOptionMenu = (
    menuOption: DropdownOption,
    values: DropdownValue[],
    onSelect: (values: DropdownValue[]) => void
  ): JSX.Element => {
    const subValues = menuOption.menu?.map((option) => option.value)
    const unselectedSubValues = subValues?.filter(
      (subValue) => !values.includes(subValue)
    )
    const isSelected = unselectedSubValues?.length === 0

    const classes = classNames(
      styles.menuBlock,
      isSelected ? styles.selected : styles.unselected
    )

    const onClick = (): void =>
      isSelected
        ? onSelect(values.filter((value) => !subValues?.includes(value)))
        : onSelect(values.concat(unselectedSubValues || []))

    return (
      <>
        <li data-testid="option-list" className={ classes } onClick={ onClick }>
          <span>{ menuOption.label } </span>
          { isSelected && (
            <svg className={ styles.check }>
              <use href={ checkImage }/>
            </svg>
          ) }
        </li>
        { menuOption.menu?.map((option) => renderItem(option, values, onSelect)) }
      </>
    )
  }

  const renderSingleItem = (
    option: DropdownOption,
    values: DropdownValue[],
    onSelect: (values: DropdownValue[]) => void
  ): JSX.Element => {
    const isSelected = values.includes(option.value)

    const classes = classNames(
      styles.item,
      isSelected ? styles.selected : styles.unselected
    )

    const onClick = (): void =>
      isSelected
        ? onSelect(values.filter((value) => value !== option.value))
        : onSelect(values.concat(option.value))

    return (
      <li
        className={ classes }
        onClick={ onClick }
        key={ `${option.value?.toString()}` }>
        <span>{ option.label }</span>
        { isSelected && (
          <svg className={ styles.check }>
            <use href={ checkImage }/>
          </svg>
        ) }
      </li>
    )
  }

  const renderMenu = (
    options: DropdownOption[],
    values: DropdownValue[],
    onSelect: (values: DropdownValue[]) => void
  ): JSX.Element => (
    <ul className={ styles.menu }>
      { options.map((option) => renderItem(option, values, onSelect)) }
    </ul>
  )

  return (
    <div data-testid="multiselect" className={ classes } tabIndex={ 0 } ref={ ref }>
      <ClickoutDetector
        onClickout={ () => setActive(false) }
        className={ clickoutClasses }>
        { renderSelectedOption(values, placeholder, selectedLabel, disabled) }
        { active && renderMenu(options, values, onSelect) }
      </ClickoutDetector>
    </div>
  )
}
