import { Envs } from '@riotgames/commons/env'
import { cloneElement } from 'react'
import { useSelector } from 'react-redux'
import { Config } from '../../commons'
import { getFeatureToggles } from '../../store/reducers'
import { RootState } from '../../store/Store.type'
import reducer, { getFeatureToggleForEnvironment } from './FeatureToggle.slice'

type Props = {
  feature: string
  isEnabled?: boolean
  children?: JSX.Element
}

// Overrides the basic Config.getEnv() behavior to merge local and dev.  There's
// no reason to believe we would want a feature that is enabled in a local env
// but disabled in dev, or vice versa.  This removes the need to define a
// duplicate set of entries in the feature list.
const getEnv = (): string =>
  Config.getEnv() === Envs.Local ? Envs.Dev : Config.getEnv()

const FeatureToggle = ({
  feature,
  children,
  isEnabled: propEnabled,
  ...propsToPass
}: Props): JSX.Element | null => {
  const isEnabled
    = useSelector((state: RootState) =>
      getFeatureToggleForEnvironment(
        getFeatureToggles(state),
        getEnv(),
        feature
      )
    ) || propEnabled

  if (children && isEnabled) {
    return cloneElement(children, propsToPass)
  }
  return null
}

;(FeatureToggle as any).isEnabled = (feature: string): boolean => {
  const featureList = reducer(undefined, { type: '@@init' })
  return getFeatureToggleForEnvironment(featureList, getEnv(), feature)
}

export default FeatureToggle
