import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip'
import questionSvg from '../../assets/svg/question.svg'

type Props = {
  id: string
  imgSrc?: string
  className?: string
  linkClassName?: string
  html?: string
  content?: string
  placement?: PlacesType
}

const Tooltip = (props: Props): JSX.Element => {
  const { id, className, linkClassName, html, content } = props

  const imgSrc = props.imgSrc || questionSvg
  const placement = props.placement || 'top'
  return (
    <>
      <ReactTooltip id={ id } className={ className } opacity={ 1 }/>
      { html ? (
        <a
          className={ linkClassName }
          data-testid={ 'tooltip' }
          data-tooltip-id={ id }
          data-tooltip-html={ html }
          data-tooltip-place={ placement }>
          <img src={ imgSrc }/>
        </a>
      ) : (
        <a
          className={ linkClassName }
          data-testid={ 'tooltip' }
          data-tooltip-id={ id }
          data-tooltip-content={ content }
          data-tooltip-place={ placement }>
          <img src={ imgSrc }/>
        </a>
      ) }
    </>
  )
}

export default Tooltip
