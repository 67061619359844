import { TournamentAdminDTO } from '@riotgames/api-types/stream-timeline/stream-timeline.type'
import { createSlice } from '../../../actions/utils'
import { Action } from '../../../Store.type'
import { isEqual } from '../../../../commons'
import {
  EditTimeParameters,
  EditTournamentSlugParameters
} from '../../../../containers/Tournament/Tournament.type'
import { createTournamentAdminSuccess } from '../../../actions/TournamentAdmin/TournamentAdminAction'

const initialState: Partial<TournamentAdminDTO> = {
  tournament_id: '',
  description: '',
  start_date: '',
  end_date: '',
  rewardable: false,
  droppable: false
}

const { reducer, actions } = createSlice({
  name: 'tournamentAdmin',
  initialState: initialState,
  reducers: {
    initialize: () => ({ ...initialState }),
    setTournamentAdmin: (state, { payload }: Action<TournamentAdminDTO>) => ({
      ...state,
      ...payload
    }),
    editTournamentId: (state, { payload }: Action<string>) => ({
      ...state,
      tournament_id: payload
    }),
    editRewardable: (state, { payload }: Action<boolean>) => ({
      ...state,
      rewardable: payload
    }),
    editDroppable: (state, { payload }: Action<boolean>) => ({
      ...state,
      droppable: payload
    }),
    editTAStartDate: (state, { payload }: Action<string>) => ({
      ...state,
      start_date: payload
    }),
    editTAEndDate: (state, { payload }: Action<string>) => ({
      ...state,
      end_date: payload
    }),
    editDescription: (state, { payload }: Action<string>) => ({
      ...state,
      description: payload
    })
  },
  extraReducers: {
    [createTournamentAdminSuccess.type]: (
      state,
      { payload }: Action<TournamentAdminDTO>
    ) => ({
      ...state,
      ...payload
    }),
    'tournaments/editStartTime': (
      state,
      { payload }: Action<EditTimeParameters>
    ) => {
      if (!state) return { ...initialState }
      if (!payload) return state
      else {
        return {
          ...state,
          start_date: payload.date.toISOString()
        }
      }
    },
    'tournaments/editStartDate': (
      state,
      { payload }: Action<EditTimeParameters>
    ) => {
      if (!state) return { ...initialState }
      if (!payload) return state
      else {
        return {
          ...state,
          start_date: payload.date.toISOString()
        }
      }
    },
    'tournaments/editEndTime': (
      state,
      { payload }: Action<EditTimeParameters>
    ) => {
      if (!state) return { ...initialState }
      if (!payload) return state
      else {
        return {
          ...state,
          end_date: payload.date.toISOString()
        }
      }
    },
    'tournaments/editEndDate': (
      state,
      { payload }: Action<EditTimeParameters>
    ) => {
      if (!state) return { ...initialState }
      if (!payload) return state
      else {
        return {
          ...state,
          end_date: payload.date.toISOString()
        }
      }
    },
    'tournaments/editTournamentSlug': (
      state,
      { payload }: Action<EditTournamentSlugParameters>
    ) => {
      if (!state) return { ...initialState }
      if (!payload) return state
      else {
        return {
          ...state,
          description: payload.slug
        }
      }
    },
    'tournaments/copyForEdit': (state, { payload }) => {
      if (!state) return { ...initialState }
      if (!payload) return state
      return {
        ...state,
        tournament_id: payload.id,
        description: payload.name.slug,
        start_date: payload.startTime,
        end_date: payload.endTime,
        droppable: false,
        rewardable: false
      }
    }
  }
})

export default reducer
const {
  initialize,
  setTournamentAdmin,
  editRewardable,
  editDroppable,
  editTournamentId,
  editTAStartDate,
  editTAEndDate,
  editDescription
} = actions

export {
  initialize,
  setTournamentAdmin,
  editRewardable,
  editDroppable,
  editTournamentId,
  editTAStartDate,
  editTAEndDate,
  editDescription
}

export const getWIPTournamentAdmin = (
  state: Partial<TournamentAdminDTO>
): Partial<TournamentAdminDTO> => state
export const isTournamentAdminDirty = (
  state: Partial<TournamentAdminDTO>
): boolean => !isEqual(state, initialState)
