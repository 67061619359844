import { useState } from 'react'
import { classNames } from '../../commons'
import RadioButton from '../../components/RadioButton/RadioButton'
import styles from './RadioButtonGroup.scss'

type Props = {
  selected?: string
  onChange?: (target: string) => void
  class?: string
  radios: string[]
}

const RadioButtonGroup = ({
  onChange,
  selected,
  radios = [],
  class: className
}: Props): JSX.Element => {
  // NOTE: per UX requirement, Radio buttons should Not be preselected
  // and should be able to be deselected when user resets the filter
  const classes = classNames(styles.radioGroup, className)
  const [selectedRadio, setSelectedRadio] = useState<string | undefined>(
    selected
  )
  const onChangeHandler = (label: string): void => {
    setSelectedRadio(label)
    if (onChange) {
      onChange(label)
    }
  }
  const createLabel = (label: string): string =>
    `${label}-${selectedRadio === label ? 'selected' : 'unselected'}`

  return (
    <div className={ classes } data-testid="radio-button-group">
      <ul>
        { radios.map((label) => (
          <li key={ createLabel(label) } className={ styles.menuItem }>
            <RadioButton
              label={ label }
              selected={ selected === undefined ? undefined : selectedRadio }
              onChange={ ({ event }) => {
                event.stopPropagation()
                onChangeHandler(label)
              } }
            />
          </li>
        )) }
      </ul>
    </div>
  )
}

export default RadioButtonGroup
