import { MouseEvent } from 'react'
import { Button } from '../'
import { classNames, stopEventPropagation } from '../../commons'
import styles from './Modal.scss'
import { ModalProps } from './Modal.type'
import xImage from '../../assets/svg/x.svg'

const fallbackCancel = 'CANCEL'
const fallbackConfirm = 'CONFIRM'
const fallbackDelete = 'DELETE'

const Modal = (props: ModalProps): JSX.Element => {
  let base: HTMLDivElement | null = null
  const onContainerClick = (event: MouseEvent): void => {
    // only exit modal if the outer opaque background is clicked
    if (event.target !== base) return
    onCancel(event)
  }

  const onCancel = (event: MouseEvent): void => {
    event && stopEventPropagation(event)
    props.onCancel()
  }

  const renderHeader = (title: string): JSX.Element => (
    <div className={ styles.header }>
      <div data-testid="header-title" className={ styles.title }>
        { title }
      </div>
      <svg
        data-testid="btn-close"
        className={ styles.close }
        onClick={ (): void => props.onCancel() }>
        <use href={ xImage } fill={ '#678678' }/>
      </svg>
    </div>
  )

  const renderDeleteButton = (): JSX.Element => {
    const deleteText = props.deleteText || fallbackDelete

    return (
      <div
        data-testid="btn-delete"
        className={ styles.delete }
        onClick={ (): void => props.onDelete?.() }>
        <div className={ styles.text }>{ deleteText }</div>
      </div>
    )
  }

  const renderFooter = (): JSX.Element => {
    const cancelText = props.cancelText || fallbackCancel
    const confirmText = props.confirmText || fallbackConfirm

    return (
      <div className={ styles.footer }>
        <div className={ styles.left }>
          { props.onDelete && renderDeleteButton() }
        </div>
        <div className={ styles.right }>
          <div
            data-testid="btn-cancel"
            className={ styles.cancel }
            onClick={ (): void => props.onCancel() }>
            <div className={ styles.text }>{ cancelText }</div>
          </div>
          <Button
            data-testid="btn-confirm"
            className={ styles.confirm }
            disabled={ props.confirmDisabled }
            onClick={ (): void => props.onConfirm() }>
            <div className={ styles.text }>{ confirmText }</div>
          </Button>
        </div>
      </div>
    )
  }

  const classes = classNames(props.className, styles.container)

  return (
    <div
      data-testid="modal"
      data-modal-active
      className={ styles.modal }
      onClick={ (event: MouseEvent): void => onContainerClick(event) }
      ref={ (dom): void => {
        base = dom
      } }>
      <div className={ classes }>
        { props.title && renderHeader(props.title) }
        <div className={ styles.content }>{ props.children }</div>
        { !props.noControls && renderFooter() }
      </div>
    </div>
  )
}

export default Modal
