import { LegacyRef, useEffect, useRef } from 'react'
import { Props } from './ClickoutDetector.type'

const ClickoutDetector = ({
  onClickout,
  className,
  children
}: Props): JSX.Element => {
  const clickoutRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        clickoutRef
        && !(clickoutRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        onClickout && onClickout()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div ref={ clickoutRef as LegacyRef<HTMLDivElement> } className={ className }>
      { children }
    </div>
  )
}

export default ClickoutDetector
