import { ComponentProps } from 'react'
import { classNames } from '../../commons'
import styles from './Footer.scss'

type Props = {
  className?: string
} & ComponentProps<'footer'>

const Footer = ({ className, children, ...props }: Props): JSX.Element => {
  const classes = classNames(styles.footer, className)
  // TODO: Add support for left-floating children when needed
  return <footer className={ classes }>{ children }</footer>
}
export default Footer
