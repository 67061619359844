import { useEffect, useState } from 'react'
import { classNames } from '../../commons'
import Tooltip from '../Tooltip/Tooltip'
import { TriState } from '../../components/TriStateCheckBox/TriStateCheckBox'
import styles from './SortableTable.scss'
import { Header, Props, State } from './SortableTable.type'

const SortableTable = <T, K extends keyof T>(
  props: Props<T, K>
): JSX.Element => {
  const tableClasses = classNames(styles.table, props.class)

  const { headers, equalWidth } = props
  const header = props.defaultSort || props.headers[0]

  const sortData = (
    data: T[],
    sortedBy: Header<T, K>,
    sortType: number,
    fromHeaderClick?: boolean
  ): State<T, K> => {
    const key = sortedBy.key || (sortedBy.label as K)

    // We are switching to use toString().localeCompare()
    // localeCompare takes a number of settings to sort strings more intelligently
    // 'en-US' can be changed to any locale and tells the sort which nations alphabetical order to use
    // sensitivity sets how to compare like letters: base will treat capital ('A'), lowercase ('a') and accented characters ('å') as equivalent
    // numeric tells whether to sort numbers in a string or alone as text or using numeric value
    // usage is to optimize behavior based on the intention to sort vs search
    // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
    const defaultSort = (a: T, b: T): number => {
      // Sanitize inputs with short circuit on equivalent data checks
      // TODO: Figure out how to remove this as unknown as string cast by eliminating
      // TODO: the possiblity that T[K] can be an object.
      const aValue = (a[key] as unknown as string) || ''
      const bValue = (b[key] as unknown as string) || ''

      return aValue.toString().localeCompare(bValue.toString(), 'en-US', {
        sensitivity: 'base',
        numeric: true,
        usage: 'sort'
      })
    }

    const providedSort
      = !fromHeaderClick && sortedBy.defaultOrdering
        ? sortedBy.defaultOrdering
        : sortedBy.sort

    const sortToUse = providedSort || defaultSort

    // If invert flag is set reverse the order of sorts; ie, first click sorts descending second ascending
    const invertSort = sortedBy.invert ? -1 : 1
    const sort = (a: T, b: T): number => sortToUse(a, b) * sortType * invertSort
    const newData = data ? [...data] : []

    if (props.defaultSort !== null) {
      newData.sort(sort)
    }

    return {
      data: newData,
      sortedBy,
      sortType
    }
  }

  const [tableState, setTableState] = useState(sortData(props.data, header, 1))
  useEffect(() => {
    const newState = sortData(
      props.data,
      tableState.sortedBy,
      tableState.sortType
    )
    setTableState(newState)
  }, [props.data])

  const onHeaderClick = (header: Header<T, K>) => (): void => {
    const data = tableState.data
    const headerKey = header.key || header.label
    const sortedByKey
      = tableState.sortedBy.key
      || tableState.sortedBy.label
      || tableState.sortedBy

    const alreadySortedByKey = headerKey === sortedByKey
    const sortType = alreadySortedByKey ? tableState.sortType * -1 : 1

    const newState = sortData(data, header, sortType, true)

    setTableState(newState)
  }

  const onRowClick = (index: number): void => {
    const { onClick } = props
    const { data } = tableState
    return onClick && onClick(data[index])
  }

  const renderHeader = (
    header: Header<T, K>,
    sortedBy: Header<T, K>,
    sortType: number
  ): JSX.Element => {
    const { label, tooltip, class: styleClass } = header
    const invertSort = header.invert ? -1 : 1
    const headerClasses = classNames(
      styles.text,
      tooltip && styles.textWithTooltip,
      sortedBy === header && sortType * invertSort === -1 && styles.ascending,
      sortedBy === header && sortType * invertSort === 1 && styles.descending,
      header.headerLabelClass
    )

    const styleOverride = equalWidth ? `${100 / headers.length}%` : 'auto'
    if (header?.labelRender) {
      return (
        <th
          style={ { width: styleOverride } }
          key={ label as string }
          onClick={ onHeaderClick(header) }
          className={ classNames(
            header.isCheckbox ? styles.headerCheckbox : styles.header,
            styleClass
          ) }>
          { header?.labelRender() }
        </th>
      )
    }

    return (
      <th
        style={ { width: styleOverride } }
        key={ label as string }
        onClick={ onHeaderClick(header) }
        className={ classNames(styles.header, styleClass) }>
        { label && (
          <div className={ headerClasses }>
            { label }
            { tooltip && (
              <Tooltip
                id={ tooltip.id }
                className={ styles.thTooltip }
                placement={ tooltip.placement }
                content={ tooltip.content }
                html={ tooltip.html }
                linkClassName={ styles.tooltipLink }
              />
            ) }
          </div>
        ) }
      </th>
    )
  }

  const renderRow = (rowEntry: T, index: number): JSX.Element => {
    const { headers, onClick, stateMap } = props
    const rowClasses = classNames(styles.row, onClick && styles.clickable)
    // TODO: Figure out how to remove this as unknown as string cast by eliminating
    // TODO: the possiblity that T[K] can be an object.
    const defaultRender = (c: T[K]): JSX.Element => 
      <span>{ c as unknown as string }</span>

    const id = (rowEntry as unknown as any)?.id || undefined
    return (
      <tr
        className={ `${rowClasses} ${
          stateMap?.[id] === TriState.Checked ? styles.highlight : ''
        }` }
        data-testid={ `sortable-table-row` }
        onClick={ (): void => onRowClick(index) }
        key={ index }>
        { headers.map((header) => {
          const key = header.key || (header.label as K)
          const content = rowEntry[key]

          // If a header defines a custom render function, delegate to that.
          // Otherwise, just render the row contents directly
          return (
            <td className={ header.class } key={ `${header.label}-${index}` }>
              { header.render
                ? header.render(content, index, rowEntry)
                : defaultRender(content) }
            </td>
          )
        }) }
      </tr>
    )
  }

  const renderRows = (): JSX.Element => {
    const { data } = tableState
    return <>{ data.map((rowEntry, index) => renderRow(rowEntry, index)) }</>
  }

  const renderEmptyRow = (colSpan: number): JSX.Element => (
    <tr className={ styles.emptyRow }>
      <td colSpan={ colSpan }>
        { props.emptyMessage ? props.emptyMessage : 'Nothing to display.' }
      </td>
    </tr>
  )

  const { data, sortedBy, sortType } = tableState
  return (
    <table data-testid="sortable-table" className={ tableClasses }>
      <tbody>
        <tr className={ styles.row }>
          { headers.map((header) => renderHeader(header, sortedBy, sortType)) }
        </tr>
        { data.length === 0 ? renderEmptyRow(headers.length) : renderRows() }
      </tbody>
    </table>
  )
}

export default SortableTable
