import { debounce } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'

const useDebounce = (callback: Function) => {
  const ref = useRef<HTMLElement | undefined>()
  useEffect(() => {
    ref.current = callback as unknown as any
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ;(ref.current as any)?.()
    }
    return debounce(func, 500)
  }, [])
  return debouncedCallback
}

export { useDebounce }
