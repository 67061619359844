import { ReactNode, MouseEvent } from 'react'
import { classNames } from '../../commons'
import plusImage from '../../assets/svg/plus.svg'
import unionImage from '../../assets/svg/union.svg'
import styles from './AddButton.scss'
import { Button } from '../Simple/Simple'

type Props = {
  onClick: (event: MouseEvent) => void
  children?: ReactNode
  class?: string
  style?: 'square' | 'rectangle'
}

function AddButton ({
  onClick,
  children,
  class: className,
  style = 'square'
}: Props): JSX.Element {
  const classes = classNames(styles.addButton, className, styles[style])

  return style === 'square' ? (
    <button data-no-scrollable className={ classes } onClick={ onClick }>
      <img data-no-scrollable className={ styles.icon } src={ plusImage }/>
      <div data-no-scrollable className={ styles.text }>
        { children }
      </div>
    </button>
  ) : (
    <Button className={ classes } onClick={ onClick }>
      <img className={ styles.plus } src={ unionImage }/>
      { children }
    </Button>
  )
}

export default AddButton
