import { ComponentType, PropsWithChildren } from 'react'
import { classNames } from '../../commons'
import { Validator } from '../../commons/validators/validators.type'
import { DropdownOption } from '../Dropdown/Dropdown.type'
import styles from './WithValidation.scss'

type ValidationProps = {
  validator?: Validator
  validationStyles?: {
    wrapper?: string
    message?: string
    invalid?: string
  }
  className?: string
  title?: string
  options?: DropdownOption[]
  value?: string
  onSelect?: (option: DropdownOption) => void
  disabled?: boolean
}

const WithValidationStateless
  = <P extends object>(WrappedComponent: ComponentType<P>) =>
    ({ ...props }: ValidationProps) => {
      const { validator, validationStyles } = props
      const wrapperClasses = classNames(styles.wrapper, validationStyles?.wrapper)
      const validationMessageClasses = classNames(
        styles.validationMessage,
        validationStyles?.message
      )
      const childClasses = classNames(
        props.className,
        validator?.isInvalid && styles.isInvalid,
        validator?.isInvalid && validationStyles?.invalid
      )
      const componentProps = props as P & PropsWithChildren
      return (
        <div className={ wrapperClasses }>
          <WrappedComponent { ...componentProps } className={ childClasses }>
            { componentProps.children }
          </WrappedComponent>
          { validator?.isInvalid 
          && <span className={ validationMessageClasses }>{ validator.message }</span>
          }
        </div>
      )
    }

export default WithValidationStateless
