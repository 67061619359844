import { classNames } from '../../commons'
import AssetUpload from '../AssetUpload/AssetUpload'
import { AssetType } from '../AssetUpload/AssetUpload.type'
import styles from './AssetManager.scss'
import { Asset, Props } from './AssetManager.type'

const AssetManager = ({
  class: className,
  assets,
  updateAssets
}: Props): JSX.Element => {
  const classes = classNames(styles.assets, className)
  const renderAsset = (asset: Asset, index: number): JSX.Element => (
    <AssetUpload
      class={ styles.asset }
      name={ asset.name }
      title={ asset.title }
      fileUrl={ asset.url }
      key={ index }
      type={ AssetType.Image } // TODO: change depending on asset URL
      onChange={ (file, url): void => {
        const newAsset = Object.assign({}, asset, { file, url })
        const updatedAssets = [...assets]
        updatedAssets[index] = newAsset
        updateAssets(updatedAssets)
      } }
    />
  )

  return (
    <div className={ classes }>
      { assets.map((asset, index) => renderAsset(asset, index)) }
    </div>
  )
}

export default AssetManager