import { useState } from 'react'
import { useTimezoneSelect, ITimezoneOption } from 'react-timezone-select'
import { createOption, Dropdown } from '../Dropdown/Dropdown'
import { DropdownOption } from '../Dropdown/Dropdown.type'

const labelStyle = 'original'

type Props = {
  onUpdate: (timezone: ITimezoneOption) => void
  value: string
  className?: string
  useShortLabels?: boolean
}

export const TimezoneSelector = ({
  onUpdate,
  className,
  value,
  useShortLabels
}: Props) => {
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle })
  const dropdownOptions = options.map((option) => {
    const label = useShortLabels ? `${option.abbrev}` : option.label
    return createOption(label, option.value)
  })

  // react-timezone-select codes the value of UTC as 'Etc/GMT' instead of standard
  const tzValue = value === 'Etc/UTC' ? 'Etc/GMT' : value
  return (
    <Dropdown
      class={ className }
      options={ dropdownOptions }
      onSelect={ (option: DropdownOption) => {
        onUpdate(parseTimezone(option.value as string))
      } }
      value={ tzValue }
    />
  )
}

export default TimezoneSelector
