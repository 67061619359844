import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { classNames } from '../../commons'
import { useAccessErrorHandling } from '../../commons/Hooks/accessDeniedHook'
import { getSupportedLocales } from '../../services/Elds/Elds.actions'
import { getLocales, isSupportedLocalesLoading } from '../../store/reducers'
import Accordion from '../Accordion/Accordion'
import { Dropdown, createOption } from '../Dropdown/Dropdown'
import { DropdownOption } from '../Dropdown/Dropdown.type'
import Loading from '../Loading/Loading'
import Section from '../Section/Section'
import { Button, Input, TextArea } from '../Simple/Simple'
import styles from './LocalizationTable.scss'
import {
  LocaleMove,
  LocaleRemove,
  LocaleUpdate,
  Localizations
} from './LocalizationTable.type'

type Props = {
  index?: number
  titles?: Localizations
  descriptions?: Localizations
  hideDescriptions?: boolean
  locales?: string[]
  isLoading?: boolean
  fetchLocales?: () => void
  onTitleChange: (update: LocaleUpdate) => void
  onDescriptionChange: (update: LocaleUpdate) => void
  onRemove: (update: LocaleRemove) => void
  onLocaleChange: (update: LocaleMove) => void
}

const LocalizationTable = (props: Props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    props.fetchLocales
      ? props.fetchLocales()
      : dispatch(getSupportedLocales() as unknown as AnyAction)
  }, [])

  const isLoading = useSelector(isSupportedLocalesLoading)
  const locales = useSelector(getLocales)

  const renderHeader = (): JSX.Element => {
    const { hideDescriptions = false } = props
    return (
      <div className={ styles.header }>
        <div className={ styles.title }>Title</div>
        { !hideDescriptions 
          ? <div className={ styles.description }>Description</div>
          : <Fragment/>
        }
        <div className={ styles.locale }>Locale</div>
      </div>
    )
  }

  const renderRow = (
    locale: string,
    availableLocales: string[]
  ): JSX.Element => {
    const {
      index,
      titles,
      descriptions,
      onTitleChange,
      onDescriptionChange,
      onRemove,
      onLocaleChange,
      hideDescriptions = false
    } = props

    const isEnglish = locale === 'en_US'

    const options = isEnglish
      ? [createOption('en_US', 'en_US')]
      : [createOption(locale, locale)].concat(
        availableLocales.map((l) => createOption(l, l))
      )

    const buttonClasses = classNames(
      styles.button,
      isEnglish && styles.disabled
    )

    return (
      <div className={ styles.row } key={ `localization-${locale}` }>
        <div className={ styles.title }>
          <Input
            className={ styles.input }
            value={ titles?.localizations[locale] || '' }
            onInput={ ({ target }: DOMEventTarget) =>
              onTitleChange({
                index,
                locale,
                text: (target as HTMLInputElement).value
              })
            }
          />
        </div>
        { !hideDescriptions ? (
          <div className={ styles.description }>
            <TextArea
              value={ descriptions?.localizations[locale] || '' }
              onInput={ ({ target }: DOMEventTarget) =>
                onDescriptionChange({
                  index,
                  locale,
                  text: (target as HTMLTextAreaElement).value
                })
              }
            />
          </div>
        ) 
          : <Fragment/>
        }
        <div className={ styles.locale }>
          <Dropdown
            options={ options }
            onSelect={ (option: DropdownOption) =>
              onLocaleChange({
                index,
                movedFrom: locale,
                locale: option.value as string
              })
            }
            value={ locale }
            disabled={ isEnglish }
          />
        </div>
        <div className={ styles.delete }>
          <button
            className={ buttonClasses }
            onClick={ () => onRemove({ index, locale }) }
            disabled={ isEnglish }>
            Delete
          </button>
        </div>
      </div>
    )
  }

  const renderAddButton = (locales: string[]): JSX.Element => {
    const { onTitleChange } = props
    return (
      <Button
        className={ styles.add }
        onClick={ () =>
          onTitleChange({
            index: props.index,
            locale: locales[0],
            text: ''
          })
        }>
        Add Localization
      </Button>
    )
  }

  // handle permission errors gracefullly
  const [accessDenied, AccessDeniedComponent] = useAccessErrorHandling()
  if (accessDenied) {
    return AccessDeniedComponent
  }

  const { titles } = props

  if (isLoading) return <Loading/>

  const allLocales = locales.map((locale) => locale.replace('-', '_'))

  const isString = (str: string | null): boolean => typeof str === 'string'

  // Title serves as canonical order
  const hasTitle = (locale: string): boolean =>
    isString(titles?.localizations[locale] || null)

  const unusedLocales = allLocales.filter((locale) => !hasTitle(locale))

  return (
    <Section class={ styles.localizationTable }>
      <Accordion title="Localization" class={ styles.accordion }>
        <div className={ styles.localization }>
          { renderHeader() }
          { titles?.order.map((locale) => renderRow(locale, unusedLocales)) }
          { renderAddButton(unusedLocales) }
        </div>
      </Accordion>
    </Section>
  )
}

export default LocalizationTable
