/* eslint-disable max-lines */
import {
  LocalizedStrings,
  LocalizedStringsWithSlug,
  PublicationStatus
} from '@riotgames/api-types/elds/Common.type'
import { Game, Match } from '@riotgames/api-types/elds/Matches.type'
import {
  DecisionPointConfig,
  Edge,
  LineupEntry,
  MatchConfig,
  Section,
  SetGroupRanks,
  Tournament,
  TournamentStage
} from '@riotgames/api-types/elds/Tournaments.type'
import { AnyAction, compose, Dispatch } from 'redux'
import {
  getLocalizedName,
  setSafe,
  exportToCSV,
  tbdTeamId,
  addIdMappingIfMissing,
  removeTeamIdFromLineup,
  remapEdges,
  remapSectionConfigs,
  remapMatchColumns,
  remapSectionColumns
} from '../../commons'
import { displayMessage } from '../../components/Message/Message.slice'
import { MessageType } from '../../components/Message/Message.type'
import {
  clearGroupRanks,
  createNewTournament,
  publishTournament,
  setGroupRanks,
  updateTournament,
  switchTeamColors,
  updateGameResults,
  getMatchesById as fetchMatches,
  getScheduleByTournamentId as fetchSchedule,
  getUnscheduledByTournmentId as fetchUnscheduled,
  getAllSchedules as fetchSchedules,
  getTeamsById as fetchTeams,
  getTournamentsById as fetchTournaments,
  getVodsForGame as fetchVods,
  getScheduleByTournamentId as fetchTournamentSchedule,
  clearGameResults,
  deleteMatch
} from '../../services/Elds/Elds.actions'
import {
  getCompletedMatches,
  getFieldForWIPTournament,
  getLeagueById,
  getMatchesFromScheduleBlocks,
  getPlayerById,
  getSeasonById,
  getSplitById,
  getSport,
  getTeamById,
  getWIPTournamentById,
  getUnscheduleByTournament,
  getTournamentById
} from '../../store/reducers'
import { RootState } from '../../store/Store.type'
import { ParticipantExport } from './ParticipantSelector/ParticipantSelector'
import { ReducerShape } from './Tournament.type'
import { updateSlug } from './Utils/Utils'
import { upsertTournamentAdmin } from '../../store/actions/TournamentAdmin/TournamentAdminAction'
import { getGamesForMatch, getMatchById } from '../../services/Elds/Elds.slice'
import { cloneDeep, to, toAll } from '../../commons/Util/Util'
import { copyForEdit } from './Tournament.slice'

const denormalizeSection = (
  model: ReducerShape,
  sectionId: string
): Section => {
  const normalizedSection = model.sections[sectionId]

  const section: Section = {
    ...normalizedSection,
    matches: normalizedSection.matchIds.map(
      (matchId) => model.matches[matchId] as MatchConfig
    ),
    decisionPoints: normalizedSection.decisionPointIds.map(
      (decisionPointId) =>
        model.decisionPoints[decisionPointId] as DecisionPointConfig
    )
  }

  return section
}

// TODO: Figure out where to insert Seeding / Standing for Sections
const denormalizeStage = (
  model: ReducerShape,
  stageId: string
): TournamentStage => {
  const normalizedStage = model.stages[stageId]

  const stage: TournamentStage = {
    ...normalizedStage,
    sections: normalizedStage.sectionIds.map((sectionId) =>
      denormalizeSection(model, sectionId)
    )
  }

  return stage
}

export const denormalizeTournament = (model: ReducerShape): Tournament => {
  const normalizedTournament = model.tournament

  return {
    ...normalizedTournament,
    stages: normalizedTournament?.stageIds.map((stageId) =>
      denormalizeStage(model, stageId)
    )
  }
}

const createTournamentSlug
  = (state: RootState) =>
    (tournament: Tournament): Tournament => {
      const getSlug = (
        entity: { name: LocalizedStringsWithSlug } | undefined
      ): string | null => entity ? entity.name.slug : null

      const leagueSlug = getSlug(getLeagueById(state, tournament.leagueId))
      const seasonSlug = getSlug(getSeasonById(state, tournament.seasonId))
      const splitSlug = getSlug(
        getSplitById(state, tournament.seasonId, tournament.splitId)
      )

      let slug: MaybeNullable<string> = leagueSlug

      if (seasonSlug) {
        slug += `_${seasonSlug}`
      }
      else {
      // Fallback to start year if a season has not been selected
        const year = new Date(tournament.startTime).getUTCFullYear()
        slug += `_${year}`
      }

      if (splitSlug) {
        slug += `_${splitSlug}`
      }

      // Tournament slugs must use _, but some slugs, such as league slugs, use -
      slug = slug?.replace(/[-_]/g, '_')

      // If the operator entered a slug, or a slug existed previously for any reason,
      // we should preserve that slug instead of automatically generating a new one.
      const existingSlug = getSlug(tournament)
      if (existingSlug && existingSlug.length > 0) {
        return tournament
      }

      return updateSlug(tournament, slug)
    }

const setTournamentSport
  = (state: RootState) =>
    (tournament: Tournament): Tournament =>
      setSafe(tournament, 'sport', getSport(state))

export const saveAndPublish
  = (tournamentId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const model = getWIPTournamentById(getState(), tournamentId)

    const tournament = compose(
      setTournamentSport(getState()),
      createTournamentSlug(getState()),
      denormalizeTournament
    )(model)

    // Tournaments must be created in a draft state, then published, so this
    // occurs in two steps.  We join them into a single step to make it more
    // convenient for calling code.
    createNewTournament(tournament)(dispatch)
      .then((action) => {
        // We have to do this weird conversion because TS thinks the payload is
        // Promise<Tournament> instead of Tournament, and produces a false error.
        const payload = action.payload as unknown as Tournament
        publishTournament(payload.id)(dispatch)
      })
      .then(() =>
        displayMessage(
          `Created Tournament Successfully!`,
          MessageType.Success
        )(dispatch)
      )
      .then(() => window.route('/tournament/list/current'))
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const deleteMatchAndRefresh
  = (
    tournamentId: string,
    stageId: string,
    groupSectionId: string,
    matchId: string,
    refreshFn?: () => void
  ) =>
    async (dispatch: Dispatch) => {
      const [err] = await to(
        deleteMatch(tournamentId, stageId, groupSectionId, matchId)(dispatch)
      )

      if (err) {
        displayMessage(err.message, MessageType.Error)(dispatch)
      }
      else if (refreshFn) {
        refreshFn()
      }
    }

export const getMatchesWithAssociatedTeams
  = (matchIds: string[]) => (dispatch: Dispatch) => {
    fetchMatches(matchIds)(dispatch)
      .then((action) => {
        const payload = action.payload as unknown as Match[]

        const teamIds: Set<string> = new Set()
        payload.forEach((match) =>
          teamIds
            .add(match.teamOne ?? tbdTeamId)
            .add(match.teamTwo ?? tbdTeamId)
        )
        teamIds.delete(tbdTeamId)
        fetchTeams(Array.from(teamIds))(dispatch)
      })
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const getScheduleWithCompletedMatches
  = (startTime: string, endTime: string) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      fetchSchedules(
        startTime,
        endTime
      )(dispatch)
        .then(() => {
          const matchIds = getCompletedMatches(getState())
          fetchMatches(matchIds)(dispatch)
        })
        .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
    }

export const getTournamentSchedulesWithMatchesById
  = (tournamentId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    Promise.all([
      fetchSchedule(tournamentId)(dispatch),
      fetchUnscheduled(tournamentId)(dispatch)
    ])
      .then(() => {
        const scheduledMatchIds = getMatchesFromScheduleBlocks(
          getState(),
          tournamentId
        )
        const unscheduledMatchIds = getUnscheduleByTournament(
          getState(),
          tournamentId
        )
          .filter((broadcastItem) => broadcastItem)
          .map((broadcastItem) => broadcastItem.matchId)

        fetchMatches(scheduledMatchIds.concat(unscheduledMatchIds))(dispatch)
      })
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const saveTournamentAsDraft
  = (tournamentId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const tournament = compose(
      setTournamentSport(state),
      createTournamentSlug(state),
      denormalizeTournament,
      (id: string) => getWIPTournamentById(state, id)
    )(tournamentId)

    createNewTournament(tournament)(dispatch)
      .then((action) => {
        displayMessage(
          "Created Tournament Successfully!  This tournament was created as a Draft.  Once you've double checked it, click Publish below.",
          MessageType.Success
        )(dispatch)

        // We have to do this weird conversion because TS thinks the payload is
        // Promise<Tournament> instead of Tournament, and produces a false error.
        const tournament = action.payload as unknown as Tournament

        // Save new Tournament Admin in Stream Timeline
        upsertTournamentAdmin(tournament.id)

        // Go to the tournament edit page, which will allow an operator to review
        // the newly created draft tournament and publish it.
        window.route(`/tournament/${tournament.id}/edit`)
      })
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const publishDraftTournament
  = (tournamentId: string) => (dispatch: Dispatch) => {
    publishTournament(tournamentId)(dispatch)
      .then(() =>
        displayMessage(
          'Published Tournament Successfully!',
          MessageType.Success
        )(dispatch)
      )
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const saveTournamentUpdate
  = (tournamentId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const normalizedTournament = compose(denormalizeTournament, (id: string) =>
      getWIPTournamentById(state, id)
    )(tournamentId)

    updateTournament(normalizedTournament)(dispatch)
      .then((action) => {
        // We have to do this weird conversion because TS thinks the payload is
        // Promise<Tournament> instead of Tournament, and produces a false error.
        const tournament = action.payload as unknown as Tournament

        // Fetch new schedule with matches if tournament is edited to update state.
        // This will ensure that the 'Schedule' tab is up to date.
        getTournamentSchedulesWithMatchesById(normalizedTournament.id)(
          dispatch,
          getState
        )

        // Update Tournament Admin in Stream Timeline
        upsertTournamentAdmin(tournament.id)(dispatch, getState)

        displayMessage(
          `Saved update to ${getLocalizedName(tournament.name)} Successfully!`,
          MessageType.Success
        )(dispatch)
      })
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const setManualStandings
  = (tournamentId: string, groupId: string, ranks: SetGroupRanks) =>
    (dispatch: Dispatch) => {
      setGroupRanks(
        tournamentId,
        groupId,
        ranks
      )(dispatch)
        .then(() => {
          displayMessage(
            'Manual Standings Saved Successfully!',
            MessageType.Success
          )(dispatch)
        })
        .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
    }

export const clearManualStandings
  = (tournamentId: string, groupId: string) => (dispatch: Dispatch) => {
    clearGroupRanks(
      tournamentId,
      groupId
    )(dispatch)
      .then(() => {
        displayMessage(
          'Manual Standings Cleared Successfully!',
          MessageType.Success
        )(dispatch)
      })
      .catch((err) => displayMessage(err.message, MessageType.Error)(dispatch))
  }

export const attemptSwitchingTeamColors
  = (game: Game, matchId: string) => (dispatch: Dispatch) => {
    switchTeamColors(
      game,
      matchId
    )(dispatch).catch((err) => {
      displayMessage(
        `An error occurred attempting to switch team colors, reason=${err.message}`,
        MessageType.Error
      )(dispatch)
    })
  }

export const attemptUpdateMatchResult
  = (games: Game[], tournamentId: string, matchId: string) =>
    async (dispatch: Dispatch) => {
      let err

      /* eslint-disable no-restricted-syntax */
      for (const game of games) {
        ;[err] = await to(updateGameResults(game, tournamentId)(dispatch))

        if (err) {
          displayMessage(
            `An error occurred attempting to update results for game ID: ${game.id}, reason=${err.message}`,
            MessageType.Error
          )(dispatch)
          return
        }
      }
      /* eslint-enable no-restricted-syntax */

      ;[err] = await toAll([
        fetchTournaments([tournamentId])(dispatch),
        fetchMatches([matchId])(dispatch)
      ])

      if (err) {
        displayMessage(
          `An error occurred attempting to fetch tournament standings, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return
      }

      displayMessage(
        'Updated setting results',
        MessageType.Success,
        'default',
        1000
      )(dispatch)
    }

export const attemptUpdateGameResult
  = (game: Game, tournamentId: string, matchId: string) =>
    async (dispatch: Dispatch) => {
      let [err] = await to(updateGameResults(game, tournamentId)(dispatch))

      if (err) {
        displayMessage(
          `An error occurred attempting to update game results, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return
      }

      ;[err] = await toAll([
        fetchTournaments([tournamentId])(dispatch),
        fetchMatches([matchId])(dispatch)
      ])

      if (err) {
        displayMessage(
          `An error occurred attempting to fetch tournament standings, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return
      }

      displayMessage(
        'Updated setting results',
        MessageType.Success,
        'default',
        1000
      )(dispatch)
    }

export const attemptClearGameResult
  = (tournamentId: string, gameId: string, matchId: string) =>
    async (dispatch: Dispatch) => {
      let [err] = await to(clearGameResults(tournamentId, gameId)(dispatch))

      if (err) {
        displayMessage(
          `An error occurred attempting to clear game results, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return
      }

      ;[err] = await toAll([
        fetchTournaments([tournamentId])(dispatch),
        fetchMatches([matchId])(dispatch)
      ])

      if (err) {
        displayMessage(
          `An error occurred attempting to fetch tournament standings, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return
      }

      displayMessage(
        'Game Winner Cleared Successfully',
        MessageType.Success,
        'default',
        1000
      )(dispatch)
    }

export const fetchMatchDetails
  = (matchId: string, tournamentId: string) =>
    (dispatch: Dispatch, getState: () => RootState) =>
      Promise.all([
        fetchTournaments([tournamentId])(dispatch),
        fetchMatches([matchId])(dispatch),
        fetchTournamentSchedule(tournamentId)(dispatch)
      ])
        .then(() => {
          const state = getState()
          const match = getMatchById(state.elds, matchId)
          const games = getGamesForMatch(state.elds, matchId)
          const teamIds = [
            match.participatingTeams[0],
            match.participatingTeams[1]
          ]

          Promise.all([
            fetchTeams(teamIds)(dispatch),
            fetchVods(games)(dispatch)
          ]).catch((err) => {
            displayMessage(
              `An error occurred attempting to fetch teams and vods, reason=${err.message}`,
              MessageType.Error
            )(dispatch)
          })
        })
        .catch((err) => {
          displayMessage(
            `An error occurred attempting to fetch match details, reason=${err.message}`,
            MessageType.Error
          )(dispatch)
        })

export const exportParticipantsToCSV
  = (tournamentId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const participants = getFieldForWIPTournament('seeding')(
      state,
      tournamentId
    )
    const currentLocalizations = getFieldForWIPTournament('name')(
      state,
      tournamentId
    )
    const eventName = getLocalizedName(currentLocalizations)
    const exportParticipants: ParticipantExport[] = participants.entries
      .filter((participant) => participant.teamId)
      .map(({ teamId }) => {
        const team = getTeamById(state, teamId)
        const partial = team?.roster?.map(({ playerId }) => {
          const player = getPlayerById(state, playerId)
          const tournamentRealmIdentity = player?.tournamentRealmIdentity
          return {
            eventName,
            tournamentId,
            teamName: team?.name,
            teamTricode: team?.tricode,
            ingameName: player?.handle,
            firstName: player?.firstName,
            lastName: player?.lastName,
            login: tournamentRealmIdentity?.login,
            password: tournamentRealmIdentity?.password,
            puuid: tournamentRealmIdentity?.puuid,
            playerId
          }
        })
        return partial
      })
      .flat()

    if (exportParticipants?.length) {
      exportToCSV(exportParticipants, eventName)
    }
    else {
      displayMessage(
        'Cannot export empty list of participants',
        MessageType.Error
      )(dispatch)
    }
  }

export const copyTournament
  = (copyTournamentId: string, copyTournamentSlug: string) =>
    async (dispatch: Dispatch, getState: () => RootState) => {
      let [err, data] = await to(fetchTournaments([copyTournamentId])(dispatch))

      if (err) {
        displayMessage(
          `An error occurred fetching the tournament to copy, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return false
      }

      // In order to copy the tournament we must
      // 1. Replace all structural ids with new ones
      // 2. Erase the ids associated to each node (e.g tournament, stage, section)
      // 3. Erase all team ids from standing and seedings except seeding for root node. In this case,
      //    we leave the seeding as is for the tournament.
      // 4. Remap all the edges with the newly mapped structural ids
      // 5. Set all parts of the tournament into a draft state

      // Begin modifying the tournament
      const idMappings: Record<string, string> = {}
      const state = getState()
      const originalTournament = getTournamentById(state, copyTournamentId)
      const tournament = cloneDeep(originalTournament) as Tournament
      const publicationStatus = PublicationStatus.Draft

      // Generate the necessary stage and section ids
      tournament.stages.forEach((stage: TournamentStage) => {
        addIdMappingIfMissing(stage.structuralId, idMappings, 'stage')
        stage.sections.forEach((section: Section) => {
          addIdMappingIfMissing(section.structuralId, idMappings, 'section')
        })
      })

      // Begin remapping and clearing necessary ids
      tournament.id = undefined as unknown as string
      tournament.name = {
        slug: copyTournamentSlug,
        localizations: {} as LocalizedStrings
      } as LocalizedStringsWithSlug
      tournament.publicationStatus = publicationStatus
      tournament.standing.entries = removeTeamIdFromLineup(
        tournament.standing.entries
      ) as LineupEntry[]
      tournament.stages = tournament.stages.map((stage: TournamentStage) => {
        stage.id = undefined as unknown as string
        stage.structuralId = idMappings[stage.structuralId]
        stage.publicationStatus = publicationStatus
        stage.seeding.entries = removeTeamIdFromLineup(
          stage.seeding.entries
        ) as LineupEntry[]
        stage.standing.entries = removeTeamIdFromLineup(
          stage.standing.entries
        ) as LineupEntry[]

        stage.sections = stage.sections.map((section: Section) => {
          section.id = undefined as unknown as string
          section.structuralId = idMappings[section.structuralId]
          section.publicationStatus = publicationStatus
          section.seeding.entries = removeTeamIdFromLineup(
            section.seeding.entries
          ) as LineupEntry[]
          section.standing.entries = removeTeamIdFromLineup(
            section.standing.entries
          ) as LineupEntry[]
          section.teamRecords = {}
          section.decisionPoints = remapSectionConfigs(
            section.decisionPoints,
            idMappings,
            'decisionPoint'
          ) as DecisionPointConfig[]
          section.matches = remapSectionConfigs(
            section.matches,
            idMappings,
            'match'
          ) as MatchConfig[]
          section.columns = remapMatchColumns(section.columns, idMappings)
          section.edges = remapEdges(section.edges, idMappings)
          return section
        })

        stage.columns = remapSectionColumns(stage.columns, idMappings)
        stage.edges = remapEdges(stage.edges, idMappings)
        return stage
      })

      tournament.edges = remapEdges(tournament.edges, idMappings) as Edge[]

      // Create the copied tournament
      ;[err, data] = await to(createNewTournament(tournament)(dispatch))

      if (err) {
        displayMessage(
          `An error occurred copying the tournament, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return false
      }

      // Save the copied tournament to stream timeline
      const copiedTournament = data.payload as unknown as Tournament
    ;(copyForEdit(copiedTournament) as AnyAction)[err] = await to(
        upsertTournamentAdmin(copiedTournament.id)(dispatch, getState)
      )

      if (err) {
        displayMessage(
          `An error occurred saving the tournament to stream timeline, reason=${err.message}`,
          MessageType.Error
        )(dispatch)
        return false
      }

      // Successfully copied tournament - navigate to edit page
      displayMessage(
        `Successfully copied tournament: ${originalTournament.name.slug} and published tournament: ${copyTournamentSlug} into a draft mode.`,
        MessageType.Success
      )(dispatch)

      // Go to the tournament edit page, which will allow an operator to review
      // the newly created draft tournament and publish it.
      window.route(`/tournament/${copiedTournament.id}/edit`)

      return true
    }
