/* eslint-disable no-console */
import { Message } from './Logger.type'

const defaultLevel = 'info'
const baseStyle = 'border-radius:2px; padding:0 4px; color:white;'

const styles: Record<string, string> = {
  debug: baseStyle + 'background:dodgerblue;',
  info: baseStyle + 'background:green;',
  warn: baseStyle + 'background:orange;',
  error: baseStyle + 'background:red;'
}

const levels: Record<string, number> = {
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
  off: 10
}

export default class Logger {
  label: string
  level: string

  constructor (label = '', level = defaultLevel) {
    this.label = label
    this.level = window.localStorage.getItem('log-level') || level

    if (levels[this.level] === undefined) {
      throw Error(
        'Invalid log level. Options are: ' + Object.keys(levels).join(', ')
      )
    }
  }

  print (level: string, messages: Message[]): void {
    // Webpack replaces `process.env.NODE_ENV` with the string "test"
    // when running unit tests in Nodejs
    process.env.NODE_ENV !== 'test'
      && levels[level] >= levels[this.level]
      && console.log('%c' + level, styles[level], this.label, '->', ...messages)
  }

  debug (...args: Message[]): void {
    this.print('debug', args)
  }

  info (...args: Message[]): void {
    this.print('info', args)
  }

  warn (...args: Message[]): void {
    this.print('warn', args)
  }

  error (...args: Message[]): void {
    this.print('error', args)
  }
}
