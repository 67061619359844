import { combineReducers } from 'redux'
import { DropdownOption } from '../../../components/Dropdown/Dropdown.type'
import patchReducer, * as fromPatch from './Patch/Patch'
import leagueReducer, * as fromLeague from './League/League'
import { League } from '../../../services/BannersService/BannersService.type'
import { FlattenedBannerConfig } from '../../../containers/Banners/Banners.type'

const combinedReducer = combineReducers({
  patches: patchReducer,
  leagues: leagueReducer
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

export const arePatchesLoading = (state: StateShape): boolean =>
  fromPatch.arePatchesLoading(state.patches)
export const getPatches = (state: StateShape): string[] =>
  fromPatch.getPatches(state.patches)
export const getPatchOptions = (state: StateShape): DropdownOption[] =>
  fromPatch.getPatchOptions(state.patches)

export const areLeaguesLoading = (state: StateShape): boolean =>
  fromLeague.areLeaguesLoading(state.leagues)
export const getLeagues = (state: StateShape): Record<string, League> =>
  fromLeague.getLeagues(state.leagues)
export const getFlattenedConfigs = (
  state: StateShape
): FlattenedBannerConfig[] => fromLeague.getFlattenedConfigs(state.leagues)
