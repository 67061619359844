import {
  EsportsGameMetadata,
  EsportsMappingRequest,
  PlatformGameDetails
} from '@riotgames/api-types/admin-api/Game_management.type'
import { Config, fetch, urlBuilder } from '../../commons'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'

export const AdminApiEnvSessionStorageKey = 'adminApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.Admin,
  Config.getEnv(AdminApiEnvSessionStorageKey)
)
const adminBaseUrl = `${apiBaseUrl}/gamemanagement/v2`

const adminUrl = urlBuilder({
  local: adminBaseUrl,
  dev: adminBaseUrl,
  test: adminBaseUrl,
  prod: adminBaseUrl
})

class AdminApi {
  static async getOptions (method = 'POST'): Promise<RequestInit> {
    return {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }

  async getEsportsGames (sport: string): Promise<EsportsGameMetadata[]> {
    return fetch(adminUrl(`/${sport}/esportsGames`))
  }

  async getEsportsGame (
    sport: string,
    id: string
  ): Promise<EsportsGameMetadata> {
    return fetch(adminUrl(`/${sport}/esportsGames/${id}`))
  }

  async getPlatformGames (sport: string): Promise<PlatformGameDetails[]> {
    const games: PlatformGameDetails[] = await fetch(
      adminUrl(`/${sport}/platformGames`)
    )
    return games.filter(
      (game) => game.state === 'in_progress' && game.teams.length > 1
    )
  }

  async getPlatformGame (
    sport: string,
    id: string
  ): Promise<PlatformGameDetails> {
    const game: PlatformGameDetails = await fetch(
      adminUrl(`/${sport}/platformGames/${id}`)
    )
    return game.teams.length > 1 ? game : ({} as PlatformGameDetails)
  }

  async mapPlatformGame (
    sport: string,
    platformGameId: string,
    mapping: EsportsMappingRequest
  ): Promise<void> {
    const options = await AdminApi.getOptions('POST')

    return fetch(
      adminUrl(`/${sport}/platformGameMetadata/${platformGameId}/mapping`),
      {
        ...options,
        body: JSON.stringify(mapping)
      }
    )
  }

  async unmapPlatformGame (
    sport: string,
    platformGameId: string
  ): Promise<void> {
    const options = await AdminApi.getOptions('DELETE')

    return fetch(
      adminUrl(`/${sport}/platformGameMetadata/${platformGameId}/mapping`),
      options
    )
  }

  async getProposedMapping (
    sport: string,
    esportsGameId: string,
    platformGameId: string
  ): Promise<EsportsMappingRequest> {
    const options = await AdminApi.getOptions('GET')

    return fetch(
      adminUrl(
        `/${sport}/esportsGames/${esportsGameId}/propose/${platformGameId}`
      ),
      options
    )
  }
}

export default new AdminApi()
