import { Sport } from '@riotgames/api-types/elds/Common.type'
import { Player } from '@riotgames/api-types/elds/Players_v3.type'

export const sportsHeaders = {
  [Sport.Lol]: ['Display Name', 'Champion', 'Account ID', 'In-Game Name'],
  [Sport.Val]: ['Display Name', 'Agent', 'In-Game Name'],
  [Sport.Wr]: ['Display Name', 'Puuid'] // TODO will be updated when we implement statMapper for Wr
}

export const tryMapPuuidToPlayer = (
  players: Record<string, Player>,
  puuid: string,
  teamId: string
): Player | undefined => {
  const teamPlayers = Object.values(players).filter(
    (player) => player.homeTeamId === teamId
  )
  for (let i = 0; i < teamPlayers.length; i++) {
    const player = teamPlayers[i]
    for (let j = 0; j < player.tournamentRealmIdentities.length; j++) {
      const identity = player.tournamentRealmIdentities[j]
      if (identity.puuid === puuid) {
        return player
      }
    }
  }
  return undefined
}

export const tryMapAccountIdToPlayer = (
  players: Record<string, Player>,
  accountId: string,
  teamId: string
): Player | undefined => {
  const [platformName, platformId] = accountId.split(':')
  const teamPlayers = Object.values(players).filter(
    (player) => player.homeTeamId === teamId
  )
  for (let i = 0; i < teamPlayers.length; i++) {
    const player = teamPlayers[i]
    for (let j = 0; j < player.tournamentRealmIdentities.length; j++) {
      const identity = player.tournamentRealmIdentities[j]
      for (let k = 0; k < identity.accounts.length; k++) {
        const account = identity.accounts[k]
        if (
          account.platformName === platformName
          && account.accountId === platformId
        ) {
          return player
        }
      }
    }
  }
  return undefined
}
