import { MouseEvent, useEffect, useState } from 'react'
import { classNames } from '../../commons'
import styles from './TabList.scss'
import { Props, Tab } from './TabList.type'
import cogImage from '../../assets/svg/cog.svg'

const TabList = (props: Props): JSX.Element => {
  const { classes, tabs } = props
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex || 0)

  const tabsClasses = classNames(styles.tabs, classes)

  useEffect(() => {
    setSelectedIndex(props.selectedIndex || 0)
  }, [props.selectedIndex])

  const onTabClick = (
    event: MouseEvent,
    tab: Tab,
    selectedIndex: number
  ): void => {
    event.preventDefault()
    event.stopPropagation()

    setSelectedIndex(selectedIndex)
    tab.onSelect()
  }

  const onSettingsClick = (event: MouseEvent, tab: Tab): void => {
    event.preventDefault()
    event.stopPropagation()

    tab.onSettingsClick?.()
  }

  const renderTabCog = (tab: Tab): JSX.Element => (
    <img
      className={ styles.settings }
      src={ cogImage }
      onClick={ (event: MouseEvent): void => onSettingsClick(event, tab) }
    />
  )

  const renderIcon = (tab: Tab): JSX.Element => (
    <img
      className={ classNames(styles.icon, props.iconClassName) }
      width="16"
      height="16"
      src={ tab.icon }
    />
  )

  const renderTab = (tab: Tab, index: number): JSX.Element => {
    const tabIsSelected = index === selectedIndex
    const tabClasses = classNames(
      styles.tab,
      tabIsSelected
        && (props.useThinSelectionBar ? styles.selectedThin : styles.selected),
      tab.onSettingsClick && styles.hasSettings
    )

    return (
      <div
        data-testid={ `tab-${index}` }
        key={ index }
        className={ tabClasses }
        onClick={ (event): void => onTabClick(event, tab, index) }>
        { tab.icon && renderIcon(tab) }
        <div className={ styles.name }>{ tab.name }</div>
        { tabIsSelected && tab.onSettingsClick && renderTabCog(tab) }
      </div>
    )
  }

  return (
    <div data-testid="tabs-list" className={ tabsClasses }>
      { tabs.map((tab: Tab, index: number) => renderTab(tab, index)) }
    </div>
  )
}

export default TabList
