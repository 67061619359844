import { combineReducers } from 'redux'
import { fetchPatches } from '../../../../services/BannersService/BannersService'
import { isLoading, createSlice } from '../../../actions/utils'
import { DropdownOption } from '../../../../components/Dropdown/Dropdown.type'
import { createOption } from '../../../../components'

const { reducer } = createSlice({
  name: 'patch',
  initialState: [] as string[],
  reducers: {},
  extraReducers: {
    [fetchPatches.success.type]: (state, { payload }) => payload
  }
})

const combinedReducer = combineReducers({
  patches: reducer,
  isLoading: isLoading(fetchPatches)
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

export const arePatchesLoading = (state: StateShape): boolean => state.isLoading

export const getPatches = (state: StateShape): string[] => state.patches

export const getPatchOptions = (state: StateShape): DropdownOption[] =>
  getPatches(state).map((patch) => createOption(patch, patch))
