import { MetadataShape } from './Metadata.type'
import { createSlice } from '../../actions/utils'
import { AppWindow } from '../../../index.type'

const appWindow = window as AppWindow

const initialState: MetadataShape = {
  version: appWindow.APP_VERSION
}

const { reducer } = createSlice({
  name: 'metadata',
  initialState: initialState,
  reducers: {},
  extraReducers: {}
})

export default reducer

export const getMetadata = (state: MetadataShape): MetadataShape => state
