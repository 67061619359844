/* eslint-disable max-lines */
import {
  AccountGroup,
  AccountGroupsResponse,
  AccountsBatch,
  TournamentRealmAccount
} from '@riotgames/api-types/elds/Accounts.type'
import {
  AccountValidationFailure,
  AccountValidationResult
} from '@riotgames/api-types/elds/Common_accounts.type'
import {
  LocalizedStringsWithSlug,
  TournamentRealmOperation,
  TournamentRealmOperationGrouped,
  TournamentRealmOperationsResponse
} from '@riotgames/api-types/elds/Common.type'
import { MediaLocale } from '@riotgames/api-types/elds/Internal.type'
import { League, LeaguesResponse } from '@riotgames/api-types/elds/Leagues.type'
import {
  Game,
  Match,
  MatchesResponse,
  OutcomeSource,
  SourcedOutcome,
  Status
} from '@riotgames/api-types/elds/Matches.type'
import {
  GameToPlayerPovMappingList,
  GameVods,
  PlayerPovStreamList,
  Stream,
  StreamGroupResponse,
  StreamGroups,
  VideoWithFirstFrameTime,
  Vods,
  VodsResponse
} from '@riotgames/api-types/elds/Media.type'
import {
  Organization,
  OrganizationsResponse
} from '@riotgames/api-types/elds/Organizations.type'
import {
  Player,
  PlayersResponse
} from '@riotgames/api-types/elds/Players_v3.type'
import {
  BroadcastState,
  Schedule,
  ScheduleBlock,
  ScheduleEntryStatus,
  SchedulesResponse,
  UnscheduledItemResponse,
  UpdateAllocation
} from '@riotgames/api-types/elds/Schedules_v3.type'
import { Season } from '@riotgames/api-types/elds/Seasons.type'
import { Team, TeamsResponse } from '@riotgames/api-types/elds/Teams.type'
import { Timespan } from '@riotgames/api-types/elds/Timeline.type'
import {
  CreateGroupMatch,
  CreateGroupMatches,
  DecisionPointConfig,
  Lineup,
  SetGroupRanks,
  Tournament,
  TournamentsResponse
} from '@riotgames/api-types/elds/Tournaments.type'
import {
  Config,
  Logger,
  encodeUrl,
  fetch,
  fetchPaginated,
  getLocalizedName,
  urlBuilder
} from '../../commons'
import { broadcastItem } from '../../commons/MatchUtil/MatchUtil'
import {
  ProviderConfiguration,
  ProviderConfigurationString
} from '../../commons/Util/Util.type'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'
import { AccountsPaginatedParams, PlayersPaginatedParams } from '../query'
import {
  AccountsResponseWithTotal,
  ExtendedBroadcastItem,
  MatchDetails,
  PlayersResponseWithTotal,
  ScheduleDataDTO,
  TrimmedPlayer,
  UnscheduledTournamentMatches
} from './Elds.type'

const log = new Logger('Elds')

export const EldsApiEnvSessionStorageKey = 'eldsApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.Elds,
  Config.getEnv(EldsApiEnvSessionStorageKey)
)

const eldsV3BaseUrl = `${apiBaseUrl}/elds/data/v3/`
const eldsBaseUrl = `${apiBaseUrl}/elds/data/v2/`
const eldsTimelineBaseUrl = `${apiBaseUrl}/elds/data/v1/timeline/`

const eldsV3Url = urlBuilder({
  local: eldsV3BaseUrl,
  dev: eldsV3BaseUrl,
  test: eldsV3BaseUrl,
  prod: eldsV3BaseUrl
})

const eldsUrl = urlBuilder({
  local: eldsBaseUrl,
  dev: eldsBaseUrl,
  test: eldsBaseUrl,
  prod: eldsBaseUrl
})

const eldsTimelineUrl = urlBuilder({
  local: eldsTimelineBaseUrl,
  dev: eldsTimelineBaseUrl,
  test: eldsTimelineBaseUrl,
  prod: eldsTimelineBaseUrl
})

class Elds {
  static async getOptions (method = 'POST'): Promise<RequestInit> {
    return {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }

  async fetchLiveAndVods () {
    const t = await Promise.all([
      this.fetchVodsData(),
      this.fetchLeaguesData(),
      this.fetchTeamsData(),
      this.fetchScheduleData()
    ])
    const data = await this.fetchTournamentAndMatchData(t)
    return this.mapDataToLiveAndVods(data)
  }

  fetchTournamentAndMatchData ([vods, leagues, teams, schedules]: [
    GameVods[],
    League[],
    Team[],
    ScheduleDataDTO[],
  ]): Promise<
    [GameVods[], League[], Team[], ScheduleDataDTO[], Tournament[], Match[]]
  > {
    const tournamentIds = schedules.map((tournament) => tournament.tournamentId)

    return Promise.all([
      vods,
      leagues,
      teams,
      schedules,
      this.getTournamentsById(tournamentIds),
      this.getMatches()
    ])
  }

  mapDataToLiveAndVods ([
    vods,
    leagues,
    teams,
    schedules,
    tournaments,
    matches
  ]: [
    GameVods[],
    League[],
    Team[],
    ScheduleDataDTO[],
    Tournament[],
    Match[],
  ]): any {
    const findId
      = (id: string) =>
        (entity: { id: string }): boolean =>
          entity.id === id
    return schedules
      .flatMap(({ broadcasts, tournamentId, blockId }) => {
        const { leagueId } = tournaments.find(
          findId(tournamentId)
        ) as Tournament
        const leagueName = leagues.find(findId(leagueId))?.name.localizations[
          'en-US'
        ]

        return broadcasts.map((broadcast) => ({
          blockId,
          leagueName,
          ...broadcast
        }))
      })
      .map((broadcastItem: ExtendedBroadcastItem) => {
        const { games, participatingTeams, id } = matches.find(
          findId(broadcastItem.matchId)
        ) as Match
        const teamInfo = participatingTeams.map((team) =>
          teams.find(findId(team))
        )

        const trimmedGames = games
          .map((game) => ({
            id: game.id,
            state: game.status
          }))
          .reduce(
            (
              gamesList: Record<
                string,
                {
                  id: string
                  state: Status
                }[]
              >,
              game
            ) => {
              if (!gamesList[game.state]) gamesList[game.state] = []
              gamesList[game.state].push(game)
              return gamesList
            },
            {}
          )

        return { games: trimmedGames, teamInfo, id, ...broadcastItem }
      })
      .map((match: MatchDetails) => {
        const { inProgress, completed } = match.games

        if (inProgress && match.inProgressInfo) {
          match.games = inProgress
          match.streams = match.inProgressInfo.bundles
            .flatMap(({ localizedStreams }) =>
              Object.keys(localizedStreams).reduce((acc: any[], curr) => {
                acc.push(
                  localizedStreams[curr].streams.flatMap((stream) => {
                    const configKey = `${stream.provider}Configuration`
                    let parameter = ''

                    switch (stream.provider) {
                      case 'youtube':
                      case 'openrec':
                      case 'nimotv':
                      case 'afreecatv':
                      case 'riotesports':
                      case 'tencent':
                        parameter = (
                          stream[
                            configKey as ProviderConfigurationString
                          ] as ProviderConfiguration
                        ).videoId
                        break
                      case 'twitch':
                      case 'mildom':
                        parameter = (
                          stream[
                            configKey as ProviderConfigurationString
                          ] as ProviderConfiguration
                        ).channelId
                        break
                      case 'trovo':
                        parameter = (
                          stream[
                            configKey as ProviderConfigurationString
                          ] as ProviderConfiguration
                        ).parameter
                        break
                    }

                    return {
                      locale: curr,
                      id: stream.id,
                      status: stream.status,
                      provider: stream.provider,
                      parameter,
                      offset: stream.statsOffsetMillis
                    }
                  })
                )

                return acc
              }, [])
            )
            .flat()

          return match
        }

        if (completed) {
          match.games = completed
            .map((game: any) => {
              const vodIndex = vods.findIndex((vod) => vod.gameId === game.id)
              const vodsList
                = vodIndex >= 0
                  ? vods[vodIndex].vods.map((vod) => ({
                    offset: vod.statsOffsetMillis,
                    ...vod
                  }))
                  : undefined
              return { vods: vodsList, ...game }
            })
            .filter((game: any) => game.vods !== undefined)

          return match
        }

        // ! Unstarted Matches can get here - creates error
        // ! By returning, we get past the error and they are ignored later on
        return match
      })
      .map((match) => ({
        blockId: match.blockId,
        broadcastId: match.broadcastId,
        league: { name: match.leagueName },
        match: { id: match.id },
        teams: match.teamInfo.map((team) => ({
          code: team?.tricode,
          name: team?.name
        })),
        games: match.games,
        streams: match.streams
      }))
      .filter((match) => match.games.length > 0)
      .reduce(
        (allMatches: { live: any[]; vods: any[] }, match) => {
          match.streams
            ? allMatches.live.push(match)
            : allMatches.vods.push(match)
          return allMatches
        },
        { live: [], vods: [] }
      )
  }

  static buildDuplicateParameterString (key: string, values: string[]): string {
    // Remove first parameter name and `=` to fit construct of Util.urlBuilder,
    // TODO: Refactor urlBuilder to allow for same-key parameters
    return values
      .map((value) => `${key}=${value}`)
      .join('&')
      .slice(key.length + 1)
  }

  // #region Accounts
  async fetchAccounts (): Promise<TournamentRealmAccount[]> {
    const options = await Elds.getOptions('GET')
    const json = await fetch(eldsUrl('accounts/'), options)
    return json.accounts
  }

  // TODO: API needs to support real union from service side.
  // Until then we will "fake" union operation here, but it won't provide us
  // exact page count
  async fetchAccountsUnionPaginated (
    params: AccountsPaginatedParams = {}
  ): Promise<AccountsBatch> {
    const {
      paginationToken,
      pageSize,
      ids,
      term,
      groupId,
      createdAfter,
      sport,
      exactMatchProperty
    } = params
    const _pageSize = pageSize || 50 // 50 is max supported by API
    const options = await Elds.getOptions('GET')
    const requests = []

    const baseUrl = `accounts/free-floating`

    const searchParams = new URLSearchParams()
    // Set the base search params
    searchParams.set('pageSize', encodeUrl(_pageSize.toString()))
    searchParams.set('includeMeta', 'true')
    if (createdAfter) {
      searchParams.set('createdAfter', createdAfter)
    }
    if (ids) {
      const accountIds = ids?.map((id) => `accountIds=${id}`).join('&')
      searchParams.append(accountIds, '')
    }
    let url = baseUrl + `?${searchParams.toString()}`

    if (paginationToken !== '' && String(paginationToken) !== 'undefined') {
      url = url + `&paginationToken=${encodeUrl(paginationToken as string)}`
    }

    if (groupId) {
      url = url + `&groupId=${encodeUrl(groupId as string)}`
    }

    if (sport) {
      url = url + `&sport=${encodeUrl(sport as string)}`
    }

    if (term) {
      requests.push(fetch(eldsUrl(url + `&search=${encodeUrl(term)}`), options))
    }

    let result: AccountsResponseWithTotal = {
      accounts: [],
      paginationToken: '',
      totalSize: 0
    }
    let combinedResults: AccountsResponseWithTotal[] = []
    if (requests.length > 0) {
      combinedResults = await Promise.all(requests)
    }
    else {
      combinedResults.push(await fetch(eldsUrl(url), options))
    }
    const merged: TournamentRealmAccount[] = []
    combinedResults.forEach((res) => merged.push(...res.accounts))

    // remove duplicates
    let uniques = merged.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    )

    // check exact match
    if (exactMatchProperty) {
      uniques = uniques.filter(
        (acc) =>
          acc[exactMatchProperty.property]?.current.toLowerCase()
          === exactMatchProperty.value?.toLowerCase()
      )
    }
    result = {
      accounts: uniques,
      paginationToken:
        combinedResults.length > 0
          ? combinedResults[combinedResults.length - 1].paginationToken
          : undefined,
      totalSize:
        combinedResults.length > 0
          ? combinedResults[combinedResults.length - 1].totalSize
          : undefined
    } as AccountsResponseWithTotal

    return result
  }

  async fetchAccountsPaginated (
    params: AccountsPaginatedParams = {}
  ): Promise<AccountsBatch> {
    let url = 'accounts/free-floating?pageSize=50'
    const { paginationToken, pageSize, ids, login, handle } = params
    if (ids) {
      const accountIds = ids?.map((id) => `accountIds=${id}`).join('&')
      url = url + `&${accountIds}`
    }
    else {
      if (paginationToken) {
        url = url + `&paginationToken=${encodeUrl(paginationToken)}`
      }
      if (pageSize) url = url + `&pageSize=${pageSize}`
      if (handle) url = url + `&handleContains=${encodeUrl(handle)}`
      if (login) url = url + `&loginContains=${encodeUrl(login)}`
    }
    const json: AccountsBatch = await fetch(
      eldsUrl(url),
      await Elds.getOptions('GET')
    )
    return json
  }

  async fetchAccountsByIds (ids: string[]): Promise<TournamentRealmAccount[]> {
    const options = await Elds.getOptions('GET')
    const queryParams = ids.map((id) => `accountIds=${id}`).join('&')
    const json = await fetch(eldsUrl(`accounts?${queryParams}`), options)
    return json.accounts
  }

  async upsertAccounts (
    accounts: Nullable<TournamentRealmAccount[]>
  ): Promise<TournamentRealmAccount[]> {
    const options = await Elds.getOptions('POST')
    const json = await fetch(eldsUrl('accounts'), {
      ...options,
      body: JSON.stringify({ accounts })
    })
    return json.accounts
  }

  async fetchAccountOperationsById (
    ids: string[]
  ): Promise<TournamentRealmOperationGrouped[]> {
    const options = await Elds.getOptions('GET')
    const queryParams = ids.map((id) => `accountIds=${id}`).join('&')
    const json = await fetch(
      eldsUrl(`accounts/operations?${queryParams}`),
      options
    )
    return json.accountsWithOperations
  }

  async requestDeleteAccount (id: string): Promise<void> {
    const options = await Elds.getOptions('DELETE')
    return fetch(eldsUrl(`accounts/${id}/requestremoval`), options)
  }

  async requestDeleteAccounts (ids: string[]): Promise<any> {
    const options = await Elds.getOptions('POST')
    const body = JSON.stringify({ accountIds: ids })
    return fetch(eldsUrl('accounts/requestremoval'), {
      ...options,
      body
    })
  }

  async fetchAccountGroups (
    paginationToken?: string
  ): Promise<AccountGroupsResponse> {
    const options = await Elds.getOptions('GET')
    const url = paginationToken
      ? eldsUrl(`accounts/groups?paginationToken=${encodeUrl(paginationToken)}`)
      : eldsUrl('accounts/groups')
    const json = await fetch(url, options)
    return json
  }

  async fetchAccountGroupsByIds (ids: string[]): Promise<AccountGroup[]> {
    const options = await Elds.getOptions('GET')
    const queryParams = ids.map((id) => `accountGroupIds=${id}`).join('&')
    const json = await fetch(eldsUrl(`accounts/groups?${queryParams}`), options)
    return json.accountgroups
  }

  async fetchAccountGroupsByName (
    search: string,
    paginationToken?: string
  ): Promise<AccountGroupsResponse> {
    const options = await Elds.getOptions('GET')
    const queryParams = `search=${search}`
    const url = paginationToken
      ? eldsUrl(
        `accounts/groups?${queryParams}&paginationToken=${encodeUrl(paginationToken)}`
      )
      : eldsUrl(`accounts/groups?${queryParams}`)
    const json = await fetch(url, options)
    return json
  }

  async createAccountGroup (
    group: Partial<AccountGroup>
  ): Promise<AccountGroup> {
    const body = JSON.stringify(group)
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl('accounts/groups'), {
      ...options,
      body
    })
  }

  async updateAccountGroup (
    group: Partial<AccountGroup>
  ): Promise<AccountGroup> {
    const body = JSON.stringify(group)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`accounts/groups/${group.id}`), {
      ...options,
      body
    })
  }

  async validateAccounts (
    entries: Record<string, { displayName: string }[]>
  ): Promise<AccountValidationFailure[]> {
    const options = await Elds.getOptions('POST')
    const json = await fetch(eldsUrl('accounts/validate'), {
      ...options,
      body: JSON.stringify(entries)
    })
    return json.invalid
  }
  // #endregion

  // #region Internal
  async getSupportedRegions (): Promise<LocalizedStringsWithSlug[]> {
    const options = await Elds.getOptions('GET')
    const response = await fetch(eldsUrl('internal/supportedRegions'), options)
    return response.regions
  }

  async getSupportedLocales (): Promise<string[]> {
    const options = await Elds.getOptions('GET')
    const response = await fetch(eldsUrl('internal/supportedLocales'), options)
    return response.locales
  }

  async getSupportedMediaLocales (): Promise<MediaLocale[]> {
    const options = await Elds.getOptions('GET')
    const response = await fetch(
      eldsUrl('internal/supportedMediaLocales'),
      options
    )
    return response.locales
  }

  // #endregion

  // #region Leagues
  async getLeaguesByTeams (teams: Team[]): Promise<League[]> {
    const leagueIds: Set<string> = teams.reduce((set, team) => {
      !!team.homeLeagueId && set.add(team.homeLeagueId)
      return set
    }, new Set<string>())
    return this.getLeaguesByIds(Array.from(leagueIds))
  }

  async getLeaguesByIds (ids: string[]): Promise<League[]> {
    const leagueUrl = eldsUrl('leagues', {
      leagueIds: Elds.buildDuplicateParameterString('leagueIds', ids)
    })

    const json: LeaguesResponse = await fetch(
      leagueUrl,
      await Elds.getOptions('GET')
    )

    return json.leagues
  }

  async fetchLeaguesData (): Promise<League[]> {
    const json: LeaguesResponse = await fetch(
      eldsUrl('leagues'),
      await Elds.getOptions('GET')
    )
    return json.leagues
  }

  async insertLeaguesData (detail: League): Promise<League> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl(`leagues`), {
      ...options,
      body
    })
  }

  async updateLeaguesData (detail: League): Promise<League> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`leagues/${detail.id}`), {
      ...options,
      body
    })
  }

  async updateLeaguesDataBatch (details: League[]): Promise<League[]> {
    details.forEach((detail, index) => {
      details[index] = detail
    })
    const body = JSON.stringify({ leagues: details })
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl('leagues/updateBatch'), {
      ...options,
      body
    })
  }

  // #endregion

  // #region Matches
  async getMatchesById (ids: string[]): Promise<Match[]> {
    // Chunk the list of IDs to prevent URLs that are too large when many matches
    // are requested at the same time
    const MAX_PER_CHUNK = 10
    const options = await Elds.getOptions('GET')

    const chunks = ids.reduce((acc: string[][], curr, idx) => {
      const index = Math.floor(idx / MAX_PER_CHUNK)
      const arr: string[] = acc[index] || []
      arr.push(curr)
      acc[index] = arr

      return acc
    }, [])

    const urls = chunks.map((chunk) =>
      eldsUrl('matches', {
        matchIds: Elds.buildDuplicateParameterString('matchIds', chunk)
      })
    )

    return await Promise.all(urls.map((url) => fetch(url, options))).then(
      (response) => response.flatMap(({ matches }) => matches)
    )
  }

  async getMatches (matchIds?: string[]): Promise<Match[]> {
    const matchUrl = eldsUrl('matches/')
    const json: MatchesResponse = await fetch(
      matchUrl,
      await Elds.getOptions('GET')
    )
    return json.matches
  }

  async fetchMatchMapping (): Promise<Game[]> {
    const data = await this.getMatches()
    return data.flatMap((d) => d.games)
  }

  async switchTeamColors (game: Game, matchId: string): Promise<void> {
    const teamColors = {
      redTeam: game.redTeam,
      blueTeam: game.blueTeam
    }

    const body = JSON.stringify(teamColors)
    const options = await Elds.getOptions('Put')
    return fetch(eldsUrl(`matches/${matchId}/game/${game.id}/sides`), {
      ...options,
      body
    })
  }

  // #endregion

  // #region Media
  async deleteVod (id: string): Promise<void> {
    const options = await Elds.getOptions('DELETE')
    return fetch(eldsUrl(`media/vods/${id}`), options)
  }

  // Fetch the Game Vods for the specified games
  async fetchVods (games: Game[]): Promise<GameVods[]> {
    const gameIds: string[] = games.map((game: Game) => game.id)
    return await Elds.fetchVodsDataHelper(gameIds).then(
      (gameVods: GameVods[]) =>
        gameVods.filter((gameVod: GameVods) =>
          gameIds.includes(gameVod.gameId)
        )
    )
  }

  async fetchVodsData (gameIds?: string[]): Promise<GameVods[]> {
    return await Elds.fetchVodsDataHelper(gameIds)
  }

  static async fetchVodsDataHelper (gameIds?: string[]): Promise<GameVods[]> {
    const queryParams = gameIds?.map((gameId) => `gameIds=${gameId}`).join('&')
    const url = queryParams
      ? eldsUrl(`media/vodDetails?${queryParams}`)
      : eldsUrl('media/vodDetails')
    return await fetch(url, await Elds.getOptions('GET')).then(
      (json: VodsResponse) => json.gameVods
    )
  }

  async fetchAvailableVodsDetailsForGames (
    gameIds: string[]
  ): Promise<GameVods[]> {
    const requests = []
    const maxLength = 20

    while (gameIds.length > 0) {
      const gameIdsForRequest
        = gameIds.length > maxLength
          ? [...gameIds.slice(0, maxLength)]
          : [...gameIds.slice(0, gameIds.length)]
      const queryParams = gameIdsForRequest
        .map((gameId) => `gameIds=${gameId}`)
        .join('&')
      const url = eldsUrl(`media/vodDetails/available?${queryParams}`)
      requests.push(fetch(url, await Elds.getOptions('GET')))
      gameIds = [...gameIds.slice(maxLength)]
    }

    const responses = await Promise.all(requests)
    return await responses.flatMap((json) => json.gameVods)
  }

  async insertGameVodsFor (gameVod: GameVods): Promise<GameVods[]> {
    const options = await Elds.getOptions('POST')
    const body = JSON.stringify(gameVod)
    return fetch(eldsUrl(`media/vodDetails`), {
      ...options,
      body
    }).then((json: VodsResponse) => json.gameVods)
  }

  // Update the specified GameVod
  async updateGameVod (gameVod: GameVods): Promise<void> {
    const options = await Elds.getOptions('POST')
    const body = JSON.stringify(gameVod)

    await fetch(eldsUrl(`media/updateVodDetailData`), {
      ...options,
      body
    })
  }

  // VODs Details cached endpoint
  async fetchVodsDetails (): Promise<Vods[]> {
    const options = await Elds.getOptions('GET')
    const json = await fetch(eldsUrl('media/vodDetails/cached'), options)
    return json.gameVods
  }

  async postOffsetForVods (
    id: string,
    gameId: string,
    locale: string,
    provider: string,
    videoId: string,
    statsOffsetMillis: number
  ): Promise<any> {
    // TODO: Determine how to resolve non-standard locale codes
    if (locale === 'es-MX') locale = 'es1'

    const body = JSON.stringify({
      gameId,
      vods: [
        {
          id,
          videoId,
          locale,
          provider,
          statsOffsetMillis
        }
      ]
    })

    const options = await Elds.getOptions()
    return fetch(eldsUrl('media/updateVodDetailData'), {
      ...options,
      body
    })
  }

  async fetchStreamDetails (): Promise<Stream[]> {
    const { streams } = await fetch(
      eldsUrl('media/streams'),
      await Elds.getOptions('GET')
    )
    return streams
  }

  async getStreamGroups (): Promise<StreamGroups[]> {
    const streamGroupsUrl = eldsUrl('media/streamGroups')
    const json: StreamGroupResponse = await fetch(
      streamGroupsUrl,
      await Elds.getOptions('GET')
    )

    return json.streamGroups
  }

  async insertStreamGroup (group: StreamGroups): Promise<StreamGroups> {
    const body = JSON.stringify(group)
    const options = await Elds.getOptions()
    return fetch(eldsUrl(`media/streamGroups`), {
      ...options,
      body
    })
  }

  async updateStreamGroup (group: StreamGroups): Promise<StreamGroups> {
    const body = JSON.stringify(group)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`media/streamGroups/${group.id}`), {
      ...options,
      body
    })
  }

  async deleteStreamGroup (id: string): Promise<void> {
    const options = await Elds.getOptions('DELETE')
    return fetch(eldsUrl(`media/streamGroups/${id}`), options)
  }

  // Stream marked as Partial because type assumes all fields are required
  // when they are not.  Streams will not have both youtubeConfiguration and
  // twitchConfiguration, for example
  async insertStreamDetail (detail: Partial<Stream>): Promise<Stream> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions()
    return fetch(eldsUrl('media/streams'), {
      ...options,
      body
    })
  }

  // Stream marked as Partial because type assumes all fields are required
  // when they are not.  Streams will not have both youtubeConfiguration and
  // twitchConfiguration, for example
  async updateStreamDetail (detail: Partial<Stream>): Promise<Stream> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`media/streams/${detail.id}`), {
      ...options,
      body
    })
  }

  async deleteStreamDetail (id: string): Promise<void> {
    const options = await Elds.getOptions('DELETE')
    return fetch(eldsUrl(`media/streams/${id}`), options)
  }

  // Read/Update operations to map player id to seat mumber
  // for POV VODs and streams
  async getPovMappingsForGameIds (
    gameIds: string[]
  ): Promise<GameToPlayerPovMappingList> {
    const options = await Elds.getOptions('GET')
    const queryParams = gameIds.map((gameId) => `gameIds=${gameId}`).join('&')
    return fetch(eldsUrl(`media/pov?${queryParams}`), options)
  }

  async updatePovMappings (
    associations: GameToPlayerPovMappingList
  ): Promise<void> {
    const body = JSON.stringify(associations)
    const options = await Elds.getOptions()
    fetch(eldsUrl('media/pov'), {
      ...options,
      body
    })
  }

  async setVideoFirstFrame (
    withFirstFrameTime: VideoWithFirstFrameTime
  ): Promise<void> {
    const body = JSON.stringify(withFirstFrameTime)
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl('media/setVodFirstFrameTime'), {
      ...options,
      body
    })
  }

  // #endregion

  // #region Organizations
  async fetchOrganizations (): Promise<Organization[]> {
    const json: OrganizationsResponse = await fetch(
      eldsUrl('organizations'),
      await Elds.getOptions('GET')
    )
    return json.organizations
  }

  async updateOrganization (organization: Organization): Promise<Organization> {
    const body = JSON.stringify(organization)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`organizations/${organization.id}`), {
      ...options,
      body
    })
  }

  async insertOrganization (organization: Organization): Promise<Organization> {
    const body = JSON.stringify(organization)
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl('organizations'), {
      ...options,
      body
    })
  }

  // #endregion

  // #region Players
  async updatePlayersData (detail: Player): Promise<Player> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`players/${detail.id}`), {
      ...options,
      body
    })
  }

  async fetchPlayersData (ids?: string[]): Promise<Player[]> {
    if (ids) {
      const requests = []
      const maxLength = 20
      while (ids.length > 0) {
        const idsForRequest
          = ids.length > maxLength
            ? [...ids.slice(0, maxLength)]
            : [...ids.slice(0, ids.length)]
        const queryParams = idsForRequest
          .map((gameId) => `playerIds=${gameId}`)
          .join('&')
        const url = eldsUrl(`players?${queryParams}`)
        requests.push(fetch(url, await Elds.getOptions('GET')))
        ids = [...ids.slice(maxLength)]
      }

      const responses = await Promise.all(requests)
      return await responses.flatMap((json) => json.players)
    }

    const json: PlayersResponse = await fetch(
      eldsUrl('players'),
      await Elds.getOptions('GET')
    )
    return json.players
  }

  // NOTE: this is a temporary method to support "union-like" operations
  // for paginated Players call. It has limitation and cannot provide exact "pageSize"
  // restriction.
  async fetchPlayersDataUnionPaginated (
    params: PlayersPaginatedParams = {}
  ): Promise<PlayersResponse> {
    const {
      paginationToken,
      ids,
      pageSize,
      term,
      firstName,
      lastName,
      status,
      sport,
      exactMatchProperty
    } = params
    const _pageSize = pageSize || 20
    const options = await Elds.getOptions('GET')
    const requests = []

    let url = `players?pageSize=${_pageSize}&includeMeta=true`
    if (ids) {
      const playerIds = ids?.map((id) => `playerIds=${id}`).join('&')
      url = url + `&${playerIds}`
    }

    if (paginationToken !== '' && String(paginationToken) !== 'undefined') {
      url
        = url
        + `&paginationToken=${encodeURIComponent(paginationToken as string)}`
    }
    if (sport) {
      url = url + `&sport=${sport}`
    }
    if (status) {
      url = url + `&status=${status}`
    }

    if (firstName && lastName) {
      if (firstName) {
        requests.push(
          fetch(eldsV3Url(url + `&firstNameContains=${firstName}`), options)
        )
      }
      if (lastName) {
        requests.push(
          fetch(eldsV3Url(url + `&lastNameContains=${lastName}`), options)
        )
      }
    }
    else {
      if (term) {
        requests.push(fetch(eldsV3Url(url + `&search=${term}`), options))
      }
    }

    let result: PlayersResponseWithTotal = {
      players: [],
      paginationToken: '',
      totalSize: 0
    }
    let combinedResults: PlayersResponseWithTotal[] = []
    if (requests.length > 0) {
      combinedResults = await Promise.all(requests)
    }
    else {
      combinedResults.push(await fetch(eldsV3Url(url), options))
    }
    const merged: Player[] = []
    combinedResults.forEach((res) => merged.push(...res.players))

    // remove duplicates
    let uniques = merged.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    )

    // check exact match
    if (exactMatchProperty) {
      uniques = uniques.filter(
        (acc) =>
          acc[exactMatchProperty.property]?.toLowerCase()
          === exactMatchProperty.value?.toLowerCase()
      )
    }

    result = {
      players: uniques,
      paginationToken:
        combinedResults.length > 0
          ? combinedResults[combinedResults.length - 1].paginationToken
          : undefined,
      totalSize:
        combinedResults.length > 0
          ? combinedResults[combinedResults.length - 1].totalSize
          : undefined
    } as PlayersResponseWithTotal

    return result
  }

  async fetchPlayersDataPaginated (
    params: PlayersPaginatedParams = {}
  ): Promise<PlayersResponse> {
    const {
      paginationToken,
      pageSize,
      ids,
      handle,
      teamName,
      leagueName,
      firstName,
      lastName,
      status
    } = params
    const _pageSize = pageSize || 20
    let url = `players?pageSize=${_pageSize}`
    if (ids) {
      const playerIds = ids?.map((id) => `playerIds=${id}`).join('&')
      url = url + `&${playerIds}`
    }

    if (paginationToken) url = url + `&paginationToken=${paginationToken}`
    // if (pageSize) url = url + `&pageSize=${pageSize}`
    if (status) url = url + `&statusContains=${status}`
    if (handle) url = url + `&handleContains=${handle}`
    if (teamName) url = url + `&teamNameContains=${teamName}`
    if (leagueName) url = url + `&leagueNameContains=${leagueName}`
    if (firstName) url = url + `&firstNameContains=${firstName}`
    if (lastName) url = url + `&lastNameContains=${lastName}`

    const json: PlayersResponse = await fetch(
      eldsV3Url(url),
      await Elds.getOptions('GET')
    )
    return json
  }

  async fetchPlayersOperations (
    playerId: string
  ): Promise<TournamentRealmOperation[]> {
    const json: TournamentRealmOperationsResponse = await fetch(
      eldsUrl(`players/${playerId}/operations`),
      await Elds.getOptions('GET')
    )

    return json.operations
  }

  async insertPlayerData (detail: Player): Promise<Player> {
    const trimmedPlayer = detail as TrimmedPlayer
    trimmedPlayer.photoUrl = trimmedPlayer.photoUrl || undefined

    const body = JSON.stringify(trimmedPlayer)
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl('players'), {
      ...options,
      body
    })
  }

  async updatePlayerData (detail: Player): Promise<Player> {
    const trimmedPlayer = detail as TrimmedPlayer
    trimmedPlayer.photoUrl = trimmedPlayer.photoUrl || undefined
    const body = JSON.stringify(trimmedPlayer)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`players/${trimmedPlayer.id}`), {
      ...options,
      body
    })
  }

  async resetPlayerPassword (playerId: string): Promise<void> {
    const body = JSON.stringify({})
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`players/${playerId}/identity/resetpassword`), {
      ...options,
      body
    })
  }

  async getPlayersByIds (ids: string[]): Promise<Player[]> {
    const playersUrl = eldsUrl('players', {
      playerIds: Elds.buildDuplicateParameterString('playerIds', ids)
    })

    const json: PlayersResponse = await fetch(
      playersUrl,
      await Elds.getOptions('GET')
    )
    return json.players
  }

  async validatePlayers (
    entries: Record<string, { displayName: string }[]>
  ): Promise<AccountValidationFailure[]> {
    const options = await Elds.getOptions('POST')
    const json = await fetch(eldsUrl('players/validate'), {
      ...options,
      body: JSON.stringify(entries)
    })
    return json.invalid
  }
  // #endregion

  // #region Schedules
  async fetchSchedule (
    startTime: string,
    endTime: string
  ): Promise<SchedulesResponse> {
    const scheduleUrl = eldsV3Url('schedules', {
      viewMode: 'prescheduled',
      startTime,
      endTime
    })

    return await fetch(scheduleUrl, await Elds.getOptions('GET'))
  }

  // TODO: Fix to return standard type
  async fetchScheduleData (): Promise<ScheduleDataDTO[]> {
    const today = new Date(Date.now())
    const tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    ).toISOString()
    const tenDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 10
    ).toISOString()

    const { schedules } = await this.fetchSchedule(tenDaysAgo, tomorrow)

    return schedules
      .flatMap(({ scheduleBlocks }) => scheduleBlocks)
      .map(({ tournamentId, broadcasts, id }) => ({
        tournamentId,
        blockId: id,
        broadcasts: broadcasts
          .flatMap(({ broadcastItems, inProgressInfo, id }) =>
            broadcastItems.map((item) => ({
              broadcastId: id,
              inProgressInfo,
              ...item
            }))
          )
          .filter((broadcastItem) => broadcastItem.status !== 'unstarted')
      }))
      .filter(({ broadcasts }) => broadcasts.length > 0)
  }

  async fetchScheduleV3Data (tournamentId: string): Promise<Schedule[]> {
    const scheduleUrl = eldsV3Url(
      `schedules/${tournamentId}?viewMode=prescheduled`
    )
    const scheduleData: SchedulesResponse = await fetch(
      scheduleUrl,
      await Elds.getOptions('GET')
    )

    // TODO: Currently, new schedule blocks are being returned as new schedules. When
    // this is fixed on ELDS, we should update this to just return the blocks
    return scheduleData.schedules
  }

  async updateScheduleV3Block (block: ScheduleBlock): Promise<ScheduleBlock> {
    // TODO: When adding broadcasts or broadcast items to a block that has already been
    // pushed to ELDS, you will get an error if broadcastState and status are not
    // initialized for broadcast/broadcastItems. This prevents that error until it
    // is fixed on ELDS
    block.broadcasts.map((broadcast) => {
      if (!broadcast.broadcastState) {
        broadcast.broadcastState = BroadcastState.NotLive
      }
      if (!broadcast.status) broadcast.status = ScheduleEntryStatus.Unstarted

      broadcast.broadcastItems.map((broadcastItem) => {
        if (!broadcastItem.broadcastState) {
          broadcastItem.broadcastState = BroadcastState.NotLive
        }
        if (!broadcastItem.status) {
          broadcastItem.status = ScheduleEntryStatus.Unstarted
        }
      })
    })

    log.info('Update schedule block with id ' + block.id)
    const body = JSON.stringify(block)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsV3Url(`schedules/block/${block.id}`), {
      ...options,
      body
    })
  }

  async deleteScheduleV3Block (blockId: string): Promise<void> {
    log.info('Deleting schedule block with id ' + blockId)
    const options = await Elds.getOptions('DELETE')
    return fetch(eldsV3Url(`schedules/block/${blockId}`), options)
  }

  async addScheduleV3Block (block: ScheduleBlock): Promise<ScheduleBlock> {
    log.info(
      'Adding new schedule block with name ' + getLocalizedName(block.nameSlug)
    )
    const body = JSON.stringify(block)
    const options = await Elds.getOptions('POST')
    return fetch(eldsV3Url(`schedules/block`), {
      ...options,
      body
    })
  }

  async fetchUnscheduledMatches (
    tournamentId: string
  ): Promise<UnscheduledTournamentMatches[]> {
    const options = await Elds.getOptions('GET')
    const url = `schedules/${tournamentId}/unscheduled`

    return fetch(eldsV3Url(url, options)).then(
      (response: UnscheduledItemResponse) => [
        {
          tournamentId: tournamentId,
          broadcastItems: response.matchIds.map(broadcastItem)
        }
      ]
    )
  }

  async updateStreamAllocation (
    blockId: string,
    broadcastId: string,
    streamId: string,
    updateAllocation: UpdateAllocation
  ): Promise<any> {
    const url = `schedules/block/${blockId}/broadcast/${broadcastId}/stream/${streamId}/allocation`

    const body = JSON.stringify(updateAllocation)

    const options = await Elds.getOptions('PUT')
    return fetch(eldsV3Url(url), {
      ...options,
      body
    })
  }

  // #endregion

  // #region Seasons
  async fetchSeasons (): Promise<Season[]> {
    const { seasons } = await fetch(
      eldsUrl('seasons'),
      await Elds.getOptions('GET')
    )
    return seasons
  }

  async createSeason (season: Nullable<Season>): Promise<Season> {
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl('seasons'), {
      ...options,
      body: JSON.stringify(season)
    })
  }

  async archiveSeason (season: Nullable<Season>): Promise<Season> {
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`seasons/${season?.id}`), {
      ...options,
      body: JSON.stringify(season)
    })
  }

  async unarchiveSeason (season: Nullable<Season>): Promise<Season> {
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`seasons/${season?.id}`), {
      ...options,
      body: JSON.stringify(season)
    })
  }

  async editSeason (season: Nullable<Season>): Promise<Season> {
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`seasons/${season?.id}`), {
      ...options,
      body: JSON.stringify(season)
    })
  }

  // #endregion

  // #region Teams
  async fetchTeamsData (id?: string): Promise<Team[]> {
    const url = id ? `${eldsUrl('teams')}?teamIds=${id}` : eldsUrl('teams')
    const json: TeamsResponse = await fetch(url, await Elds.getOptions('GET'))
    return json.teams
  }

  async insertTeamsData (detail: Team): Promise<Team> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl('teams'), {
      ...options,
      body
    })
  }

  async updateTeamsData (detail: Team): Promise<Team> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`teams/${detail.id}`), {
      ...options,
      body
    })
  }

  async getTeamsByIds (ids: string[]): Promise<Team[]> {
    const teamUrl = eldsUrl('teams', {
      teamIds: Elds.buildDuplicateParameterString('teamIds', ids)
    })

    if (ids.length === 0) {
      log.warn('Tried to fetch zero teams')
      return Promise.resolve([])
    }

    const json: TeamsResponse = await fetch(
      teamUrl,
      await Elds.getOptions('GET')
    )
    return json.teams
  }

  // #endregion

  // #region Tournaments
  async insertTiebreaker (
    tournamentId: string,
    stageId: string,
    groupId: string,
    detail: CreateGroupMatch
  ): Promise<void> {
    const body = JSON.stringify(detail)
    const options = await Elds.getOptions('POST')
    return fetch(
      eldsUrl(
        `tournaments/${tournamentId}/stage/${stageId}/group/${groupId}/match`
      ),
      {
        ...options,
        body
      }
    )
  }

  async getTournament (id: string | number): Promise<Tournament> {
    const queryParam = `tournamentIds=${id}`
    const tournamentUrl = eldsUrl(`tournaments?${queryParam}`)
    const json: TournamentsResponse = await fetch(
      tournamentUrl,
      await Elds.getOptions('GET')
    )
    return json.tournaments[0]
  }

  async getTournamentsSummary (
    ids?: (string | number)[]
  ): Promise<Tournament[]> {
    const queryParams = ids?.map((id) => `tournamentIds=${id}`).join('&')
    const tournamentUrl = queryParams
      ? eldsUrl(`tournaments?viewMode=summary&${queryParams}`)
      : eldsUrl('tournaments?viewMode=summary')
    const json: TournamentsResponse = await fetch(
      tournamentUrl,
      await Elds.getOptions('GET')
    )

    return json.tournaments
  }

  async fetchTournamentsData (): Promise<Tournament[]> {
    const request = async (
      paginationToken?: string
    ): Promise<TournamentsResponse> =>
      await fetch(
        eldsUrl('tournaments/paginated', { paginationToken }),
        await Elds.getOptions('GET')
      )

    return fetchPaginated(
      request,
      ({ paginationToken }) => paginationToken,
      (responses) => responses.flatMap((response) => response.tournaments)
    )
  }

  async fetchTournamentsCachedData (): Promise<Tournament[]> {
    const response: TournamentsResponse = await fetch(
      eldsUrl('tournaments/cached'),
      await Elds.getOptions('GET')
    )
    return response.tournaments
  }

  async getTournamentsById (ids: (string | number)[]): Promise<Tournament[]> {
    const queryParams = ids.map((id) => `tournamentIds=${id}`).join('&')
    const tournamentUrl = eldsUrl(`${'tournaments'}?${queryParams}`)
    const json: TournamentsResponse = await fetch(
      tournamentUrl,
      await Elds.getOptions('GET')
    )

    return json.tournaments
  }

  async updateTournament (tournament: Tournament): Promise<Tournament> {
    const body = JSON.stringify(tournament)
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`tournaments/${tournament.id}`), {
      ...options,
      body
    })
  }

  async updateGameResults (game: Game, tournamentId: string): Promise<void> {
    // Add the source of the outcome
    const sourcedOutcome: SourcedOutcome = {
      outcome: game.teamOutcomes,
      outcomeSource: OutcomeSource.SetByHuman
    }
    const body = JSON.stringify(sourcedOutcome)
    const options = await Elds.getOptions('POST')
    return fetch(
      eldsUrl(`tournaments/${tournamentId}/results/game/${game.id}/outcome`),
      {
        ...options,
        body
      }
    )
  }

  async clearGameResults (tournamentId: string, gameId: string): Promise<void> {
    const options = await Elds.getOptions('DELETE')
    return fetch(
      eldsUrl(`tournaments/${tournamentId}/results/game/${gameId}`),
      {
        ...options
      }
    )
  }

  async createTournament (tournament: Tournament): Promise<Tournament> {
    const options = await Elds.getOptions('POST')
    return fetch(eldsUrl('tournaments'), {
      ...options,
      body: JSON.stringify(tournament)
    })
  }

  async publishTournament (tournamentId: string): Promise<Tournament> {
    const options = await Elds.getOptions('PUT')
    return fetch(eldsUrl(`tournaments/${tournamentId}/publish`), {
      ...options
    })
  }

  async replaceTeamInTournament (
    tournamentId: string,
    oldTeamId: string,
    newTeamId: string
  ): Promise<void> {
    const options = await Elds.getOptions('PUT')
    const replacements = {
      replacements: [
        {
          teamToBeReplaced: oldTeamId,
          teamReplacement: newTeamId
        }
      ]
    }
    return fetch(eldsUrl(`tournaments/${tournamentId}/replaceTeams`), {
      ...options,
      body: JSON.stringify(replacements)
    })
  }

  async decideDecisionPoint (
    tournamentId: string,
    decisionPoint: DecisionPointConfig
  ): Promise<void> {
    const options = await Elds.getOptions('POST')
    return fetch(
      eldsUrl(`tournaments/${tournamentId}/results/decide/${decisionPoint.id}`),
      {
        ...options,
        body: JSON.stringify(decisionPoint.standing)
      }
    )
  }

  async finalizeStanding (
    tournamentId: string,
    groupId: string | undefined,
    lineup: Lineup | null
  ): Promise<void> {
    const options = await Elds.getOptions('POST')
    return fetch(
      eldsUrl(`tournaments/${tournamentId}/results/group/${groupId}`),
      {
        ...options,
        body: JSON.stringify(lineup)
      }
    )
  }

  async createGroupMatches (
    tournamentId: string,
    stageId: string,
    groupSectionId: string,
    matches: CreateGroupMatches
  ): Promise<void> {
    const options = await Elds.getOptions('POST')
    return fetch(
      eldsUrl(
        `tournaments/${tournamentId}/stage/${stageId}/group/${groupSectionId}/matches`
      ),
      {
        ...options,
        body: JSON.stringify(matches)
      }
    )
  }

  async deleteMatch (
    tournamentId: string,
    stageId: string,
    groupSectionId: string,
    matchId: string
  ): Promise<void> {
    const options = await Elds.getOptions('DELETE')
    return fetch(
      eldsUrl(
        `tournaments/${tournamentId}/stage/${stageId}/group/${groupSectionId}/match/${matchId}`
      ),
      options
    )
  }

  async setGroupRanks (
    tournamentId: string,
    groupId: string,
    ranks: SetGroupRanks
  ) {
    const body = JSON.stringify(ranks)
    const options = await Elds.getOptions('POST')
    return fetch(
      eldsUrl(`tournaments/${tournamentId}/results/group/${groupId}/ranks/`),
      {
        ...options,
        body
      }
    )
  }

  async clearGroupRanks (tournamentId: string, groupId: string) {
    const options = await Elds.getOptions('DELETE')
    return fetch(
      eldsUrl(`tournaments/${tournamentId}/results/group/${groupId}/ranks/`),
      {
        ...options
      }
    )
  }

  // #endregion

  // #region Timeline
  async fetchTimespans (platformGameId?: string): Promise<Timespan[]> {
    if (!platformGameId) return []
    const url = eldsTimelineUrl(`timespans/platformGame/${platformGameId}`)
    const options = await Elds.getOptions('GET')
    const json = await fetch(url, options)
    return json.timespans
  }
  // #endregion
}

export default new Elds()
