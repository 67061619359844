import { compose, Dispatch } from 'redux'
import { RootState } from '../../Store.type'
import { TournamentAdminDTO } from '@riotgames/api-types/stream-timeline/stream-timeline.type'
import {
  createTournamentAdmin,
  getTournamentAdmin
} from '../../../services/StreamTimeline/StreamTimeline.actions'
import { createAction } from '../utils'
import {
  editDroppable,
  editRewardable,
  editTournamentId,
  setTournamentAdmin
} from '../../reducers/WIP/TournamentAdmin/TournamentAdmin'
import { getWIPTournamentById } from '../../reducers'
import { denormalizeTournament } from '../../../containers/Tournament/Tournament.actions'

export const upsertTournamentAdmin
  = (tournamentId: string) =>
    async (dispatch: Dispatch, getState: () => RootState) => {
      dispatch(editTournamentId(tournamentId))

      const state = getState()
      const normalizedTournament = compose(denormalizeTournament, (id: string) =>
        getWIPTournamentById(state, tournamentId)
      )(tournamentId)

      const newTA = {
        tournament_id: normalizedTournament.id,
        start_date: normalizedTournament.startTime,
        end_date: normalizedTournament.endTime,
        description: normalizedTournament.name?.slug,
        droppable: !!state.wip.tournamentAdmin.droppable,
        rewardable: !!state.wip.tournamentAdmin.rewardable
      } as TournamentAdminDTO

      await createTournamentAdmin(newTA)(dispatch)
      dispatch(createTournamentAdminSuccess(newTA))
    }

export const fetchTournamentAdmin
  = (tournamentId: string) =>
    async (dispatch: Dispatch, getState: () => RootState) => {
      const action = await getTournamentAdmin(tournamentId)(dispatch)
      if (!action?.payload) {
        dispatch(editDroppable(false))
        dispatch(editRewardable(false))
        return await upsertTournamentAdmin(tournamentId)(dispatch, getState)
      }
      const tournamentAdmin = await action.payload
      if (!tournamentAdmin || !tournamentAdmin.tournament_id) {
        dispatch(editDroppable(false))
        dispatch(editRewardable(false))
        return await upsertTournamentAdmin(tournamentId)(dispatch, getState)
      }

      dispatch(setTournamentAdmin(tournamentAdmin))
    }

export const createTournamentAdminSuccess = createAction(
  'tournamentAdmin/createNew/success'
)
