import { AnyAction, Dispatch } from 'redux'
import { Logger } from '../../commons'
import { fetch } from '../../commons/Util/Util'
import { FlattenedBannerConfig } from '../../containers/Banners/Banners.type'
import { Thunk } from '../../store/Store.type'
import { createAction, createThunk } from '../../store/actions/utils'
import { Bucket, FunctionalityType, UploadConfig } from './AwsS3.type'
import AwsS3Config from './AwsS3Config'

const log = new Logger('AwsS3')

export enum AccessPurpose {
  Download = 'download',
  Upload = 'upload',
  List = 'list',
}

class AwsS3 {
  static async getOptions (method = 'GET', body?: any): Promise<RequestInit> {
    const opt: any = {
      method: method
    }
    if (method === 'GET') {
      opt.headers = {
        // add any extra header
      }
    }
    if (body) {
      opt.body = body
    }
    return opt
  }

  async list (): Promise<any> {
    const url = this.buildUrl(FunctionalityType.Banner, AccessPurpose.List)
    return this.invoke(url, AccessPurpose.List, 'GET')
  }

  private async _download (
    leagueId: string,
    leagueSlug: string,
    gameVersion: string,
    bannerConfigVersion: string,
    functionalityType: FunctionalityType
  ): Promise<any> {
    const key = `${leagueId}/game-configs/${leagueSlug}-${gameVersion}-${bannerConfigVersion}.zip`
    const url = this.buildUrl(functionalityType, AccessPurpose.Download, key)
    return this.invoke(url, AccessPurpose.Download, 'GET')
  }

  private async _linkAsset (
    key: string,
    functionalityType: FunctionalityType = FunctionalityType.Banner
  ): Promise<any> {
    if (!key || key.length === 0) {
      return Promise.reject('(!) invalid asset key')
    }
    const url = this.buildUrl(functionalityType, AccessPurpose.Download, key)
    return this.invoke(url, AccessPurpose.Download, 'GET')
  }

  async linkAssets (assets: any[]) {
    const requests: any[] = []
    assets.forEach((asset) => {
      requests.push(
        this._linkAsset(
          asset.url,
          asset.functionalityType as FunctionalityType
        )
      )
    })
    const responses = await Promise.allSettled<any[]>(requests)
    const presignedUrls = []
    for (let i = 0; i < responses.length; i++) {
      const response = responses[i] as any
      if (response?.status === 'fulfilled') {
        presignedUrls.push({
          index: i,
          url: response.value.url,
          timestamp: Date.now()
        })
      }
      else if (response?.status === 'rejected') {
        presignedUrls.push({ index: i, url: '' })
      }
    }
    return presignedUrls
  }

  async downloadBannerConfiguration (
    leagueId: string,
    leagueSlug: string,
    gameVersion: string,
    bannerConfigVersion: string
  ): Promise<void> {
    try {
      const response = await this._download(
        leagueId,
        leagueSlug,
        gameVersion,
        bannerConfigVersion,
        FunctionalityType.Banner
      )
      const { url } = response
      log.info('(@) Download presigned link:', url)
      const link = document.createElement('a')
      link.href = url
      link.download = `${leagueSlug}-${gameVersion}-${bannerConfigVersion}.zip`
      link.target = '_blank'
      link.rel = 'noopener noreferrer'
      link.click()
      link.remove()
      URL.revokeObjectURL(link.href)
    }
    catch (error) {
      log.error(error)
    }
  }

  private async _upload (
    key: string,
    functionalityType: FunctionalityType
  ): Promise<any> {
    const url = this.buildUrl(functionalityType, AccessPurpose.Upload, key)
    log.info('(@) Acquire presigned Url from: ', url)
    // https://docs.google.com/document/d/16g3FXKRWD3FU8OHKKjxea7LNh4XCTR8Unb-dt8Un2EI/edit
    return this.invoke(url, AccessPurpose.Upload, 'GET')
  }

  async uploadFile (
    folder: string,
    file: File,
    uploadConfig: UploadConfig = {}
  ): Promise<string> {
    if (folder.length > 0 && !folder.endsWith('/')) {
      folder = folder + '/'
    }
    const fileName = (file.name || '').replace(/[^0-9a-z._-]/gi, '')
    const key = uploadConfig.uniqueKey
      ? folder + Date.now() + '_' + fileName
      : folder + fileName

    const protocol = uploadConfig.bucket === Bucket.Banners ? 'https' : 'http'
    try {
      log.info('(@) File to upload: ', file)
      log.info('(@) Key: ', key)
      const response = await this._upload(
        key,
        uploadConfig.bucket === Bucket.Banners
          ? FunctionalityType.Banner
          : FunctionalityType.NonBanner
      )
      const { url } = response
      const status = await window.fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          // TODO: what file type needs to be supported? JSON and Binary only?
          'Content-Type': file.name.includes('.json')
            ? 'application/json'
            : 'binary/octet-stream'
        }
      })
      if (status.ok) {
        const bucket = AwsS3Config.getS3BucketUrl(uploadConfig.bucket)
        const assetUrl = `${protocol}://${bucket}/${key}`
        log.info('(@) Uploaded', uploadConfig)
        log.info('(@) AssetUrl: ', assetUrl)
        return Promise.resolve(assetUrl)
      }
      else {
        throw new Error('Failed to upload file')
      }
    }
    catch (error) {
      log.error(error)
      return Promise.reject(error)
    }
  }

  buildUrl (ft: FunctionalityType, purpose: AccessPurpose, key?: string) {
    const baseUrl = AwsS3Config.getPresignedUrlBase()
    let path = `${baseUrl}/emp-assets/`
    switch (purpose) {
      case AccessPurpose.Download:
      case AccessPurpose.Upload:
        path = `${path}presignedurl`
        break
      case AccessPurpose.List:
      default:
        path = `${path}keys`
        break
    }
    let qs = `purpose=${purpose}&functionalityType=${ft}`
    if (key) {
      qs += `&key=${key}`
    }
    return `${path}?${qs}`
  }

  private async invoke (
    url: string,
    purpose: AccessPurpose,
    method: string = 'GET',
    body?: any
  ) {
    switch (purpose) {
      case AccessPurpose.Upload:
        return await fetch(url, await AwsS3.getOptions(method))
      case AccessPurpose.Download:
      case AccessPurpose.List:
      default:
        return await fetch(url, await AwsS3.getOptions(method))
    }
  }

  async getPresignedUrlForAsset (
    assetKey: string,
    functionalityType: FunctionalityType
  ): Promise<string> {
    const presignedUrlResult = await fetch(
      this.buildUrl(functionalityType, AccessPurpose.Download, assetKey)
    )
    return presignedUrlResult.url
  }

  getBannerAssetList (): Promise<(string | undefined)[]> {
    return this.list()
  }
}

export const UNIQUE_KEY_UPLOAD_CONFIG = { uniqueKey: true }

const api = new AwsS3()
export default api

export const uploadAsset = (
  action: string,
  directory: string,
  uploadConfig: UploadConfig = {}
): Thunk<any> => {
  const awsStart = createAction<Parameters<any>>('aws/startUpload/' + action)
  const awsFailed = createAction<Error, Parameters<any>>(
    'aws/failedUpload/' + action
  )
  const awsSuccess = createAction<any, any>(action)

  const actionCreator
    = (file: File) =>
      (dispatch: Dispatch): Promise<AnyAction> => {
      // If no file is passed in, dispatch that directly.  This represents the user
      // clicking the remove button
        if (!file) {
          return Promise.resolve(dispatch(awsSuccess(file)))
        }

        dispatch(awsStart())
        log.info('(@) Asset file to upload:', file)
        return api.uploadFile(directory, file, uploadConfig).then(
          (url) => dispatch(awsSuccess(url)),
          (err) => dispatch(awsFailed(err))
        )
      }

  actionCreator.start = awsStart
  actionCreator.success = awsSuccess
  actionCreator.failed = awsFailed

  return actionCreator as Thunk<any>
}

export const getBannerAssetList = createThunk(
  'awsS3/getBannerAssetList',
  (flattenedConfigs: FlattenedBannerConfig[]) =>
    api
      .getBannerAssetList()
      .then((assetList) => ({ flattenedConfigs, assetList }))
)

export const linkAssets = createThunk(
  'assetManager/linkAssets',
  (assets: any[]) =>
    api.linkAssets(assets).then((presignedUrl) => presignedUrl)
)
