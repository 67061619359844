import { Action } from '../../../../store/Store.type'
import { createSlice } from '../../../actions/utils'

export type AccessError = {
  code: number
  credentials: string
  method: string
  mode: string
  path: string
  queryParams: string
  resource: string
}

const getCurrentPath = () => window.location.pathname

const accessErrorsSlice = createSlice({
  name: 'accessErrors',
  initialState: {} as Record<string, AccessError[]>,
  reducers: {
    addAccessError: (state = {}, action: Action<AccessError>) => {
      const currentPath = getCurrentPath()
      const existingErrors = state[currentPath] || []
      return {
        ...state,
        [currentPath]: existingErrors.concat(action.payload as AccessError)
      }
    },
    removeAccessError: (state = {}, action: Action<AccessError>) => {
      const currentPath = getCurrentPath()
      const existingErrors = state[currentPath] || []
      return {
        ...state,
        [currentPath]: existingErrors.filter(
          (error) => error !== action.payload
        )
      }
    },
    clearAccessErrors: () => ({}) as Record<string, AccessError[]>
  }
})

export const { addAccessError, removeAccessError, clearAccessErrors }
  = accessErrorsSlice.actions

export const getAccessErrors = (
  state: Record<string, AccessError[]>,
  currentPath: string
): AccessError[] => state[currentPath] ?? []
/* example usage:
 * import { getAccessErrors } from '../../../store/reducers'
 * const accessErrors = useSelector((state: RootState) => getAccessErrors(state, window.location.pathname))
 */

export const getAccessErrorsByPath = (
  state: Record<string, AccessError[]>,
  currentPath: string,
  path: string
): AccessError[] =>
  getAccessErrors(state, currentPath)
    .filter((error) => error.method === 'GET')
    .filter((error) => error.path.includes(path))
/* example usage:
 * import { getAccessErrorsByPath } from '../../../store/reducers'
 * const accessErrors = useSelector((state: RootState) => getAccessErrorsByPath(state, window.location.pathname, '/players'))
 */

export const getAccessErrorsByResource = (
  state: Record<string, AccessError[]>,
  currentPath: string,
  resource: string
): AccessError[] =>
  getAccessErrors(state, currentPath)
    .filter((error) => error.method === 'GET')
    .filter((error) => error.resource === resource)
/* example usage:
 * import { getAccessErrorsByResource } from '../../../store/reducers'
 * const accessErrors = useSelector((state: RootState) => getAccessErrorsByResource(state, window.location.pathname, 'players'))
 */

export default accessErrorsSlice.reducer
