import { LocalizedStringsWithSlug } from '@riotgames/api-types/elds/Common.type'
import { Season, Split } from '@riotgames/api-types/elds/Seasons.type'
import { MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { AnyAction } from 'redux'
import {
  debounce,
  deriveState,
  getLocalizedName,
  stopEventPropagation
} from '../../../commons'
import { useAccessErrorHandling } from '../../../commons/Hooks/accessDeniedHook'
import {
  Button,
  Confirm,
  Footer,
  Header,
  Input,
  SortableTable
} from '../../../components'
import { ConfirmType } from '../../../components/Confirm/Confirm'
import Loading from '../../../components/Loading/Loading'
import { Header as SortableHeader } from '../../../components/SortableTable/SortableTable.type'
import { getAllSeasons } from '../../../services/Elds/Elds.actions'
import { isLoadingSelector } from '../../../store/actions/utils'
import { getSeasonsByFilter, isSeasonLoading } from '../../../store/reducers'
import { RootState } from '../../../store/Store.type'
import {
  archiveExistingSeason,
  unarchiveExistingSeason
} from '../Season.actions'
import styles from './SeasonList.scss'

export enum SeasonFilter {
  Current = 'current',
  Old = 'old',
  Archived = 'archived',
}

type Props = {
  seasons?: Season[]
  isLoading?: boolean
  isSeasonsLoading?: boolean

  onArchive?: (id: string) => void
  onUnarchive?: (id: string) => void
}

const SeasonList = ({
  isSeasonsLoading: preIsSeasonLoading,
  onArchive,
  onUnarchive,
  seasons: preSeason
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filter = useParams<{ filter: SeasonFilter }>().filter as SeasonFilter
  const [search, setSearch] = useState<string>('')
  const [archiveSelectedId, setArchiveSelectedId] = useState<string>('')

  const seasons
    = preSeason
    || useSelector((state: RootState) => getSeasonsByFilter(state, filter))
  const isSeasonsLoading
    = preIsSeasonLoading || useSelector(isLoadingSelector(isSeasonLoading))

  const archive = onArchive || archiveExistingSeason
  const unArchive = onUnarchive || unarchiveExistingSeason

  useEffect(() => {
    dispatch(getAllSeasons() as unknown as AnyAction)
  }, [dispatch])

  const renderHeader = (): JSX.Element => (
    <Header
      label="Seasons & Splits"
      title="Seasons"
      links={ [
        {
          label: 'Current Seasons',
          url: `/season/list/${SeasonFilter.Current}`
        },
        { label: 'Old Seasons', url: `/season/list/${SeasonFilter.Old}` },
        {
          label: 'Archived Seasons',
          url: `/season/list/${SeasonFilter.Archived}`
        }
      ] }
    />
  )

  const renderFilter = (): JSX.Element => (
    <>
      <div className={ styles.filter }>
        <Input
          className={ styles.input }
          onInput={ debounce(
            (event: InputEvent) =>
              setSearch((event.target as HTMLInputElement).value),
            150,
            false
          ) }
          placeholder="Search"
        />
      </div>
    </>
  )

  const renderTable = (): JSX.Element | null => {
    const searchTerm = search
    const seasonsData = seasons.filter((season) => {
      if (searchTerm && searchTerm.length >= 2) {
        const isInName = getLocalizedName(season.name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
        return isInName
      }

      return true
    })
    const headers: SortableHeader<Season, keyof Season>[] = [
      {
        label: 'name',
        render: (name: LocalizedStringsWithSlug) => 
          <span>{ getLocalizedName(name) }</span>
        
      },
      {
        label: 'start time',
        key: 'startTime',
        render: (startTime: string) => 
          <span>{ new Date(startTime).toDateString() }</span>
        
      },
      {
        label: 'end time',
        key: 'endTime',
        render: (endTime: string) => 
          <span>{ new Date(endTime).toDateString() }</span>
        
      },
      {
        label: 'splits',
        render: (splits: Split[]) => <span>{ splits.length }</span>
      },
      {
        label: '',
        key: 'id',
        render: (id: string) => (
          <div className={ styles.actions }>
            <Button
              className={ styles.action }
              onClick={ (event: MouseEvent) => {
                stopEventPropagation(event)
                filter === SeasonFilter.Archived
                  ? dispatch(unArchive(id) as unknown as AnyAction)
                  : setArchiveSelectedId(id)
              } }>
              { filter === SeasonFilter.Archived ? 'Unarchive' : 'Archive' }
            </Button>
            <a
              className={ styles.action }
              onClick={ () => navigate(`/season/${id}/edit`) }>
              Edit
            </a>
          </div>
        )
      }
    ]

    return (
      <div className={ styles.table }>
        <SortableTable
          class={ styles.data }
          headers={ headers }
          data={ seasonsData }
          onClick={ (row) => navigate(`/season/${row.id}/edit`) }
        />
      </div>
    )
  }
  const renderFooter = (): JSX.Element => (
    <Footer>
      <Button onClick={ () => navigate('/season/create') }>Create Season</Button>
    </Footer>
  )

  // handle permission errors gracefullly
  const [accessDenied, AccessDeniedComponent] = useAccessErrorHandling()
  if (accessDenied) {
    return AccessDeniedComponent
  }

  // Reroutes /season/list/asdf to a valid url
  if (!filter || !Object.values(SeasonFilter).includes(filter)) {
    return <Navigate to="/season/list/current" replace/>
  }

  return (
    <>
      { isSeasonsLoading 
        ? <Loading/>
        : (
          <div className={ styles.list }>
            { renderHeader() }
            { renderFilter() }
            { renderTable() }
            { renderFooter() }
            { archiveSelectedId && (
              <Confirm
                title="Archive Season"
                type={ ConfirmType.WARN }
                body="Are you sure you want to archive this season? This action cannot be undone"
                onCancel={ () => setArchiveSelectedId('') }
                onConfirm={ () => {
                  dispatch(archive(archiveSelectedId) as unknown as AnyAction)
                  setArchiveSelectedId('')
                } }
              />
            ) }
          </div>
        ) }
    </>
  )
}

export default SeasonList
