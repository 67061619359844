import { MouseEvent, ReactNode, useState } from 'react'
import caretImage from '../../assets/svg/caret.svg'
import { classNames } from '../../commons'
import ClickoutDetector from '../../components/ClickoutDetector/ClickoutDetector'
import styles from './CaretButton.scss'

type Props = {
  onClick: (event: MouseEvent) => void
  children?: ReactNode
  class?: string
  menuClass?: string
  links: Array<{
    label: string
    onClick: () => void
  }>
}

function CaretButton ({
  onClick,
  children,
  class: className,
  menuClass,
  links
}: Props): JSX.Element {
  const classes = classNames(className)
  const menuClasses = classNames(styles.menuOpen, menuClass)

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <div className={ classes }>
      <ClickoutDetector onClickout={ () => setMenuOpen(false) }>
        <button className={ styles.mainButton } onClick={ onClick }>
          <div className={ styles.text }>{ children }</div>
        </button>
        <button
          data-testid="caret-button"
          className={ styles.caretButton }
          onClick={ () => setMenuOpen(!menuOpen) }>
          <img className={ styles.icon } src={ caretImage }/>
          <ul className={ menuOpen ? menuClasses : styles.menuClosed }>
            { links.map((link) => (
              <li
                key={ link.label }
                className={ styles.menuItem }
                onClick={ link.onClick }>
                <a className={ styles.menuItemBtn }>{ link.label }</a>
              </li>
            )) }
          </ul>
        </button>
      </ClickoutDetector>
    </div>
  )
}

export default CaretButton
