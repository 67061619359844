import { TournamentAdminDTO } from '@riotgames/api-types/stream-timeline/stream-timeline.type'
import { Config, Logger, fetch, urlBuilder } from '../../commons'
import EmpApiConfig, { ServiceCategory } from '../EMP/EmpApiConfig'

const log = new Logger('StreamTimeline')

export const StreamTimelineApiEnvSessionStorageKey = 'streamTimelineCodeApiEnv'

const apiBaseUrl = EmpApiConfig.getApiBaseUrl(
  ServiceCategory.StreamTimeline,
  Config.getEnv(StreamTimelineApiEnvSessionStorageKey)
)
const streamTimelineUrl = Config.getKeyByEnv({
  local: apiBaseUrl,
  dev: apiBaseUrl,
  test: apiBaseUrl,
  stage: apiBaseUrl,
  prod: apiBaseUrl
})

const tournamentAdminUrl = urlBuilder({
  local: `${streamTimelineUrl}/streamline/v1/tournamentAdmin/`,
  dev: `${streamTimelineUrl}/streamline/v1/tournamentAdmin/`,
  test: `${streamTimelineUrl}/streamline/v1/tournamentAdmin/`,
  prod: `${streamTimelineUrl}/streamline/v1/tournamentAdmin/`
})

const removeTrailingSlash = (url: string): string => url.replace(/\/$/, '')

class StreamTimeline {
  static async getOptions (method = 'POST'): Promise<RequestInit> {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    return {
      method: method,
      headers: headers
    }
  }

  async fetchTournamentConfigForTournament (
    key: string
  ): Promise<TournamentAdminDTO | undefined> {
    const response = await fetch(
      removeTrailingSlash(tournamentAdminUrl(`${key}`)),
      await StreamTimeline.getOptions('GET')
    )

    if (response.ok) {
      return (await response.json()) as TournamentAdminDTO
    }
    else {
      return undefined
    }
  }

  async fetchTournamentConfig (): Promise<TournamentAdminDTO[]> {
    const json: TournamentAdminDTO[] = await fetch(
      removeTrailingSlash(tournamentAdminUrl('')),
      await StreamTimeline.getOptions('GET')
    )
    return json
  }

  async putTournamentAdmin (
    tournamentAdmin: TournamentAdminDTO
  ): Promise<TournamentAdminDTO> {
    const body = JSON.stringify(tournamentAdmin)
    const options = await StreamTimeline.getOptions('POST')

    const url = tournamentAdminUrl('')

    let json: TournamentAdminDTO | undefined
    try {
      json = await fetch(url, {
        ...options,
        body
      })
    }
    catch (e) {
      // log.error(e)
    }
    return json as TournamentAdminDTO
  }
}

export default new StreamTimeline()
