import { combineReducers } from 'redux'
import { fetchBannerLeagues } from '../../../../services/BannersService/BannersService'
import { isLoading, createSlice } from '../../../actions/utils'
import {
  League,
  LeaguesResponse
} from '../../../../services/BannersService/BannersService.type'
import { Action, PayloadAction } from '../../../Store.type'
import { keyBy } from '../../../../commons'
import { FlattenedBannerConfig } from '../../../../containers/Banners/Banners.type'

const { reducer } = createSlice({
  name: 'league',
  initialState: {} as Record<string, League>,
  reducers: {},
  extraReducers: {
    [fetchBannerLeagues.success.type]: (
      state,
      { payload }: PayloadAction<LeaguesResponse>
    ) => keyBy('eldsId')(payload.leagues)
  }
})

const combinedReducer = combineReducers({
  leagues: reducer,
  isLoading: isLoading(fetchBannerLeagues)
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

export const areLeaguesLoading = (state: StateShape): boolean => state.isLoading
export const getLeagues = (state: StateShape): Record<string, League> =>
  state.leagues

export const getFlattenedConfigs = (
  state: StateShape
): FlattenedBannerConfig[] =>
  Object.values(state.leagues).flatMap((league) =>
    league.patches.map((patch) => ({
      id: patch.configuration.id,
      leagueId: league.eldsId,
      patch: patch.patch,
      status: patch.configuration.status,
      version: `v${patch.configuration.version}`,
      url: patch.configuration.url
    }))
  )
