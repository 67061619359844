import { Player } from '@riotgames/api-types/elds/Players_v3.type'
import { RecordStatus, Sport } from '@riotgames/api-types/elds/Common.type'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { isEqual, set } from '../../commons'
import { insertPlayer } from '../../services/Elds/Elds.actions'
import { createSlice, SliceConfig } from '../../store/actions/utils'
import { Action } from '../../store/Store.type'
import { AddPlayersPayload } from './Players.type'
import { playerHandleValidator } from '../../commons/validators/validators'

type ModelShape = Nullable<Player>[]
type MaybeModelShape = Maybe<ModelShape>

export const createPlayer = (
  handle = '',
  firstName = '',
  lastName = '',
  sport = Sport.Lol,
  photoUrl = ''
): Nullable<Player> => ({
  id: null,
  status: RecordStatus.Active,
  handle: handle,
  firstName: firstName,
  lastName: lastName,
  photoUrl: photoUrl,
  homeTeamId: null,
  tournamentRealmIdentity: null,
  tournamentRealmIdentities: null,
  sport: sport
})

const playersInitialState = [createPlayer()]
const playerSlice = createSlice<ModelShape>({
  name: 'player',
  initialState: playersInitialState,
  reducers: {
    addPlayers: (state: ModelShape, { payload }: Action<AddPlayersPayload>) => {
      if (payload) {
        const players = payload.map(
          ({ handle, firstName, lastName, sport, photoUrl }) =>
            createPlayer(handle, firstName, lastName, sport, photoUrl)
        )
        if (players.length > 1) {
          return players
        }
        return state.concat(players)
      }
    },
    removePlayer: (state: MaybeModelShape, { payload }: Action<number>) =>
      state?.filter((_, index) => index !== payload),
    removeDuplicatePlayers: (
      state: MaybeModelShape,
      { payload }: Action<ModelShape[]>
    ) =>
      state?.filter(
        (player) => !payload?.includes(player as unknown as Player[])
      ),
    removeInvalidPlayers: (
      state: MaybeModelShape,
      { payload }: Action<ModelShape[]>
    ) =>
      state?.filter(
        (player) => !payload?.includes(player as unknown as Player[])
      ),
    updateHandle: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((player, index) => {
        if (index === payload?.index) {
          return {
            ...player,
            handle: payload.value
          }
        }

        return player
      }),
    updateHandleCollision: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: boolean }>
    ) =>
      state.map((player, index) => {
        if (index === payload?.index) {
          return {
            ...player,
            handleCollision: payload.value
          }
        }

        return player
      }),
    updateFirstName: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((player, index) => {
        if (index === payload?.index) {
          return {
            ...player,
            firstName: payload.value
          }
        }

        return player
      }),
    updateLastName: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((player, index) => {
        if (index === payload?.index) {
          return {
            ...player,
            lastName: payload.value
          }
        }

        return player
      }),
    updatePhotoUrl: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((player, index) => {
        if (index === payload?.index) {
          return {
            ...player,
            photoUrl: payload.value
          }
        }

        return player
      }),
    updateSport: (
      state: ModelShape,
      { payload }: Action<{ index: number; value: string }>
    ) =>
      state.map((player, index) => {
        if (index === payload?.index) {
          return {
            ...player,
            sport: payload.value as Sport
          }
        }

        return player
      }),
    clearPlayers: () => playersInitialState,
    replacePlayers: (state: ModelShape, { payload }: Action<ModelShape>) =>
      payload
  },
  extraReducers: {
    [insertPlayer.success.type]: () => playersInitialState
  }
} as SliceConfig<ModelShape>)

export const {
  addPlayers,
  clearPlayers,
  removePlayer,
  replacePlayers,
  removeDuplicatePlayers,
  removeInvalidPlayers,
  updateHandle,
  updateHandleCollision,
  updateFirstName,
  updateLastName,
  updateSport,
  updatePhotoUrl
} = playerSlice.actions

// #region Player Duplicate Slice
const duplicateInitialState: ModelShape = []
const duplicateSlice = createSlice<ModelShape>({
  name: 'player',
  initialState: duplicateInitialState,
  reducers: {
    addDuplicatePlayer: (
      state: ModelShape,
      { payload }: Action<ModelShape>
    ) => {
      if (payload) {
        return state.concat(payload)
      }
    },
    removeDuplicatePlayer: (
      state: MaybeModelShape,
      { payload }: Action<number>
    ) => state?.filter((_, index) => index !== payload),
    clearDuplicatePlayers: () => duplicateInitialState
  }
} as SliceConfig<ModelShape>)

export const {
  addDuplicatePlayer,
  clearDuplicatePlayers,
  removeDuplicatePlayer
} = duplicateSlice.actions
// #endregion Player Duplicate Slice

// #region Player Existing Slice
const existingInitialState: string[] = []
const existingSlice = createSlice<string[]>({
  name: 'player',
  initialState: [],
  reducers: {
    addExistingPlayers: (
      state: string[],
      { payload }: Action<Record<string, string>[]>
    ) => {
      if (payload) {
        const handles = payload.map((player) =>
          player.displayName.toLowerCase()
        )
        const newState = [...new Set(state.concat(handles))]
        return newState
      }
    },
    removeExistingPlayer: (state: [], { payload }: Action<number>) =>
      state?.filter((_, index) => index !== payload),
    clearExistingAccounts: () => existingInitialState
  }
} as unknown as SliceConfig<string[]>)

export const {
  addExistingPlayers,
  clearExistingPlayers,
  removeExistingPlayer
} = existingSlice.actions
// #endregion Player Existing Slice

// #region Player Invalid Slice
const invalidInitialState: ModelShape = []
const invalidSlice = createSlice<ModelShape>({
  name: 'player',
  initialState: invalidInitialState,
  reducers: {
    addInvalidPlayer: (state: ModelShape, { payload }: Action<ModelShape>) => {
      if (payload) {
        return state.concat(payload)
      }
    },
    removeInvalidPlayer: (
      state: MaybeModelShape,
      { payload }: Action<number>
    ) => state?.filter((_, index) => index !== payload),
    clearInvalidPlayers: () => invalidInitialState
  }
} as SliceConfig<ModelShape>)

export const { addInvalidPlayer, clearInvalidPlayers, removeInvalidPlayer }
  = invalidSlice.actions
// #endregion Player Invalid Slice

// #region Player CSV
const playersCSVInitialState: MaybeModelShape = []
const playersCSVSlice = createSlice({
  name: 'player',
  initialState: playersCSVInitialState,
  reducers: {
    setPlayersCSV: (state, { payload }: Action<MaybeModelShape[]>) => payload,
    clearPlayersCSV: () => playersCSVInitialState
  }
} as SliceConfig<MaybeModelShape>)

export const { setPlayersCSV, clearPlayersCSV } = playersCSVSlice.actions
// #endregion Player CSV

// #region Player tab
const playersTabInitialState = 'valid'
const playersTabSlice = createSlice({
  name: 'player',
  initialState: playersTabInitialState,
  reducers: {
    changePlayersTab: (state, { payload }: Action<string>) => payload
  }
} as SliceConfig<string>)

export const { changePlayersTab } = playersTabSlice.actions
// #endregion Player tab

const uiInitialState = {
  handle: '',
  firstName: '',
  lastName: '',
  sport: Sport.Lol
}

const uiSlice = createSlice({
  name: 'player',
  initialState: uiInitialState,
  reducers: {
    changeFirstName: (state, { payload }: Action<string>) =>
      set('firstName')(state, payload),
    changeLastName: (state, { payload }: Action<string>) =>
      set('lastName')(state, payload),
    changeSport: (state, { payload }: Action<Sport>) =>
      set('sport')(state, payload)
  },
  extraReducers: {
    [clearPlayers.type]: () => uiInitialState,
    [insertPlayer.success.type]: () => uiInitialState
  }
} as SliceConfig<typeof uiInitialState>)

export const { changeHandle, changeFirstName, changeLastName, changeSport }
  = uiSlice.actions

const combinedReducer = combineReducers({
  players: playerSlice.reducer,
  playersCSV: playersCSVSlice.reducer,
  duplicate: duplicateSlice.reducer,
  existing: existingSlice.reducer,
  invalid: invalidSlice.reducer,
  tab: playersTabSlice.reducer,
  ui: uiSlice.reducer
})

export default combinedReducer
type StateShape = ReturnType<typeof combinedReducer>

export const getPlayers = (state: StateShape) => state.players
const getPlayersCSV = (state: StateShape) => state.playersCSV
const getDuplicatePlayers = (state: StateShape) => state.duplicate
const getExistingPlayers = (state: StateShape) => state.existing
const getInvalidPlayers = (state: StateShape) => state.invalid
const getTab = (state: StateShape) => state.tab
const getUI = (state: StateShape) => state.ui

const isPlayersSliceDirty = createSelector(
  [getPlayers],
  (players) => !isEqual(players, playersInitialState)
)

const isUISliceDirty = createSelector(
  [getUI],
  (ui) => !isEqual(ui, uiInitialState)
)

const isPlayersInvalid = createSelector(
  [getInvalidPlayers],
  (invalid) => invalid
)

export const isWIPPlayersDirty = createSelector(
  [isPlayersSliceDirty, isUISliceDirty],
  (players, ui) => players || ui
)

export const getCSVPlayers = createSelector([getPlayersCSV], (csv) => csv)

export const getCSVInvalidPlayers = createSelector(
  [getPlayersCSV],
  (players) =>
    players?.filter(
      (player) =>
        player && playerHandleValidator(player.handle || '').isInvalid
    )
)

export const getDuplicatePlayersArr = createSelector(
  [getDuplicatePlayers],
  (invalid) => invalid
)

export const getExistingPlayersArr = createSelector(
  [getExistingPlayers],
  (existing) => existing
)

export const getInvalidPlayersArr = createSelector(
  [getInvalidPlayers],
  (invalid) => invalid
)

export const getWIPPlayersTab = createSelector([getTab], (tab) => tab)
