import { UserManagementConfiguration } from './UserManagementConfig.type'
class UserManagementConfig {
  getConfigs (): UserManagementConfiguration {
    const {
      EMP_PERMIT_IO_ENVID,
      EMP_PERMIT_IO_TENANT,
      EMP_PERMIT_IO_URL,
      EMP_PERMIT_IO_CL_ROUTE_CACHE_TTL_MS,
      EMP_PERMIT_IO_CL_ROUTE_ROLES_API,
      EMP_PERMIT_IO_AUDIT_LOG_URL,
      EMP_PERMIT_ELEMENT_ACCESS
    } = process.env
    return {
      envId: EMP_PERMIT_IO_ENVID || '',
      tenant: EMP_PERMIT_IO_TENANT || '',
      endpoint: EMP_PERMIT_IO_URL || '',
      cacheTTL: EMP_PERMIT_IO_CL_ROUTE_CACHE_TTL_MS || '60000',
      rolesApiEndpoint: EMP_PERMIT_IO_CL_ROUTE_ROLES_API || '',
      auditLogEndpoint: EMP_PERMIT_IO_AUDIT_LOG_URL || '',
      elementAccess: EMP_PERMIT_ELEMENT_ACCESS || ''
    }
  }
}

export default new UserManagementConfig()
