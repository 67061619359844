import { LocalizedStringsWithSlug } from '@riotgames/api-types/elds/Common.type'
import { Validator, ValidatorFunction } from './validators.type'
import { Player } from '@riotgames/api-types/elds/Players_v3.type'

/**
 * Combines multiple Validator Functions into a single function.
 * @param validators Validator Functions to combine
 * @returns A single Validator Function which calls all combined Validators and
 * returns the first one that fails
 */
export const combineValidators
  = <T extends ValidatorFunction>(...validators: T[]) =>
    (...params: Parameters<T>): Validator =>
      validators
        .map((validator) => validator(...params))
        .find((validator) => validator.isInvalid) || { isInvalid: false }

/**
 * Check required Validator(s) before returning expected Validator.
 * All required Validator(s) must pass to return expected Validator
 * If required Validator(s) fail, will ignore expected Validator
 * @param validators[] Validator to process
 * @returns validator (expected Validator if required pass)
 */
export const requiredValidators = (
  requiredValidators: Validator[],
  validator: Validator
): Validator => {
  const areRequiredValid = requiredValidators.every(
    (validator) => validator && !validator.isInvalid
  )

  if (areRequiredValid) {
    return validator
  }

  return { isInvalid: false }
}

// #region String Validators
export const DEFAULT_STRING_MAX_LENGTH = 100
export const stringMaxLength = (
  slug: string,
  maxLength?: number,
  message?: string
): Validator => ({
  isInvalid: slug?.length > (maxLength || DEFAULT_STRING_MAX_LENGTH),
  message:
    (message || 'String')
    + ' must be fewer than '
    + (maxLength || DEFAULT_STRING_MAX_LENGTH)
    + ' characters'
})
// #endregion

// #region Localized String Validators
export const DEFAULT_LOCALIZED_MAX_LENGTH = 40
export const localizedNameMaxLength = (slug: string): Validator =>
  stringMaxLength(slug, DEFAULT_LOCALIZED_MAX_LENGTH, 'Localized name')

export const checkLocalizationsAreInvalid = (name: LocalizedStringsWithSlug) =>
  Object.values(name.localizations).some(
    (localeName) => localizedNameMaxLength(localeName).isInvalid
  )
// #endregion

// #region Slug Validators
export const DEFAULT_SLUG_MAX_LENGTH = 80
export const slugMaxLength = (slug: string): Validator =>
  stringMaxLength(slug, DEFAULT_SLUG_MAX_LENGTH, 'Slugs')

export const slugRegEx = (slug: string): Validator => ({
  isInvalid: !/^[a-z0-9_]*$/.test(slug),
  message: 'Slugs must match [a-z0-9_]'
})

export const slugCannotBeEmpty = (slug: string): Validator => ({
  isInvalid: !slug,
  message: 'Slugs cannot be empty'
})

export const slugValidator = combineValidators(
  slugMaxLength,
  slugRegEx,
  slugCannotBeEmpty
)
// #endregion

// #region Date Validators
export const dateIsOnOrBefore = (
  date: Date,
  beforeDate: Date,
  message?: string
): Validator => ({
  isInvalid: date < beforeDate,
  message: message || `Date must be after ${beforeDate.toUTCString()}`
})

// #endregion

// #region Login Validators
export const loginMaxLength = (login: string): Validator => ({
  isInvalid: login?.length >= 25,
  message: 'Logins must be fewer than 25 characters'
})

export const loginMinLength = (login: string): Validator => ({
  isInvalid: login?.length < 4 && login?.length > 0,
  message: 'Logins must be at least 4 characters long'
})

export const loginIsEmpty = (login: string): Validator => ({
  isInvalid: login?.length === 0,
  message: 'Logins cannot be autogenerated, and must be filled in'
})

export const loginValidator = combineValidators(loginMaxLength, loginMinLength)
// #endregion

// #region Display Name Validators
export const displayNameSpecialChars = (displayName: string): Validator => ({
  isInvalid: !/^[a-zA-Z0-9_ ]*$/.test(displayName),
  message: 'Display Names may not contain special characters'
})

export const displayNameMaxLength = (displayName: string): Validator => ({
  isInvalid: displayName?.length >= 16,
  message: 'Display Names must be fewer than 16 characters'
})

export const displayNameMinLength = (displayName: string): Validator => ({
  isInvalid: displayName?.length < 3 && displayName?.length > 0,
  message: 'Display Names must be at least 3 characters long'
})

export const displayNameValidator = combineValidators(
  displayNameSpecialChars,
  displayNameMaxLength,
  displayNameMinLength
)

export const displayNameSpecialCharsEnhanced = (
  displayName: any
): Validator => ({
  isInvalid: !/^[a-zA-Z0-9_ ]*$/.test(displayName?.proposed),
  message: 'Display Names may not contain special characters'
})

export const displayNameMaxLengthEnhanced = (displayName: any): Validator => ({
  isInvalid: displayName?.proposed?.length >= 16,
  message: 'Display Names must be fewer than 16 characters'
})

export const displayNameMinLengthEnhanced = (displayName: any): Validator => ({
  isInvalid:
    displayName?.proposed?.length < 3 && displayName?.proposed?.length > 0,
  message: 'Display Names must be at least 3 characters long'
})

export const displayNameDuplication = (display: any): Validator => ({
  isInvalid: display?.hasCollision,
  message: 'Display name already in use'
})

export const displayNameValidatorEnhanced = combineValidators(
  displayNameSpecialCharsEnhanced,
  displayNameMaxLengthEnhanced,
  displayNameMinLengthEnhanced,
  displayNameDuplication
)

// #endregion

export const playerHandleSpecialChars = (playerHandle: string): Validator => ({
  isInvalid: !/^[a-zA-Z0-9_ ]*$/.test(playerHandle),
  message: 'Handle may not contain special characters'
})

export const playerHandleMaxLength = (playerHandle: string): Validator => ({
  isInvalid: playerHandle?.length > 11,
  message: 'Max: 11 characters'
})

export const playerHandleValidator = combineValidators(
  playerHandleSpecialChars,
  playerHandleMaxLength
)

export const playerHandleSpecialCharsEnhanced = (
  player: Nullable<Player & { handleCollision?: boolean }>
): Validator => ({
  isInvalid: !/^[a-zA-Z0-9_ ]*$/.test(player?.handle || ''),
  message: 'Handle may not contain special characters'
})

export const playerHandleMaxLengthEnhanced = (
  player: Nullable<Player & { handleCollision?: boolean }>
): Validator => ({
  isInvalid: player && player.handle ? player.handle?.length > 11 : false,
  message: 'Max: 11 characters'
})

export const handleDuplication = (
  player: Nullable<Player & { handleCollision?: boolean }>
): Validator => ({
  isInvalid: player?.handleCollision || false,
  message: 'Handle already in use'
})

export const playerHandleValidatorEnhanced = combineValidators(
  playerHandleSpecialCharsEnhanced,
  playerHandleMaxLengthEnhanced,
  handleDuplication
)

// #region Field Validators
export const fieldIsTruthy = (
  field: string | number | boolean | null | undefined,
  message = 'Field is not valid.'
): Validator => {
  function isFieldInvalid () {
    if (typeof field === 'number') {
      return field <= 0
    }

    return !!field === false
  }

  return {
    isInvalid: isFieldInvalid(),
    message
  }
}
// #endregion
