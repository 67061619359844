import { createAction, createThunk } from '../../store/actions/utils'
import PromoCodesApiV2 from './PromoCodesApiV2'

export const getAllUnclaimedPromoCodes = createThunk(
  'promoCodesApi/getAllUnclaimed',
  PromoCodesApiV2.getAll
)

export const getAllClaimedPromoCodes = createThunk(
  'promoCodesAPI/getAllClaimed',
  PromoCodesApiV2.getAll
)
