import { createThunk } from '../../store/actions/utils'
import Elds from './Elds'

// #region Action Creators
export const getAllLeagues = createThunk(
  'elds/getAllLeagues',
  Elds.fetchLeaguesData
)
export const getAllSchedules = createThunk(
  'elds/getAllSchedules',
  Elds.fetchSchedule
)

// #region Timeline

export const getTimespansByPlatformGameId = createThunk(
  'elds/getTimespans',
  Elds.fetchTimespans
)

// #endregion

// #region Tournaments
export const getAllTournaments = createThunk(
  'elds/getAllTournaments',
  Elds.fetchTournamentsData
)
export const getAllTournamentsCached = createThunk(
  'elds/getAllTournamentsCached',
  Elds.fetchTournamentsCachedData
)
export const getTournamentsById = createThunk(
  'elds/getTournamentsById',
  Elds.getTournamentsById
)

export const getAllTournamentsSummary = createThunk(
  'elds/getAllTournamentsSummary',
  Elds.getTournamentsSummary
)

// #endregion

export const getAllStreams = createThunk(
  'elds/getAllStreams',
  Elds.fetchStreamDetails
)
export const getAllStreamGroups = createThunk(
  'elds/getAllStreamGroups',
  Elds.getStreamGroups
)
export const getAllTeams = createThunk('elds/getAllTeams', Elds.fetchTeamsData)
export const getAllPlayers = createThunk(
  'elds/getAllPlayers',
  Elds.fetchPlayersData
)
export const getAllOrganizations = createThunk(
  'elds/getAllOrganizations',
  Elds.fetchOrganizations
)

export const insertPlayer = createThunk(
  'elds/insertPlayer',
  Elds.insertPlayerData
)

export const updatePlayer = createThunk(
  'elds/updatePlayer',
  Elds.updatePlayerData
)
export const updateTeam = createThunk('elds/updateTeam', Elds.updateTeamsData)
export const updateOrganization = createThunk(
  'elds/updateOrganization',
  Elds.updateOrganization
)

export const insertOrganization = createThunk(
  'elds/insertOrganization',
  Elds.insertOrganization
)

export const getAllSeasons = createThunk(
  'elds/getAllSeasons',
  Elds.fetchSeasons
)

export const createNewSeason = createThunk(
  'elds/createNewSeason',
  Elds.createSeason
)

export const archiveSeason = createThunk(
  'elds/archiveSeason',
  Elds.archiveSeason
)

export const unarchiveSeason = createThunk(
  'elds/unarchiveSeason',
  Elds.unarchiveSeason
)

export const editSeason = createThunk('elds/editSeason', Elds.editSeason)

export const createNewTournament = createThunk(
  'elds/createNewTournament',
  Elds.createTournament
)

export const publishTournament = createThunk(
  'elds/publishTournament',
  Elds.publishTournament
)

export const updateTournament = createThunk(
  'elds/updateTournament',
  Elds.updateTournament
)

export const getSupportedRegions = createThunk(
  'elds/getSupportedRegions',
  Elds.getSupportedRegions
)

export const getSupportedLocales = createThunk(
  'elds/getSupportedLocales',
  Elds.getSupportedLocales
)

export const setGroupRanks = createThunk(
  'elds/setGroupRanks',
  Elds.setGroupRanks
)

export const clearGroupRanks = createThunk(
  'elds/clearGroupRanks',
  Elds.clearGroupRanks
)

// Match Details
export const switchTeamColors = createThunk(
  'elds/switchTeamColors',
  Elds.switchTeamColors
)

export const updateGameResults = createThunk(
  'elds/updateGameResults',
  Elds.updateGameResults
)

export const clearGameResults = createThunk(
  'elds/clearGameResults',
  Elds.clearGameResults
)
// END Match Details

export const getSupportedMediaLocales = createThunk(
  'elds/getSupportedMediaLocales',
  Elds.getSupportedMediaLocales
)

// #region Accounts
export const getAllAccounts = createThunk(
  'elds/getAccounts',
  Elds.fetchAccounts
)

export const getAccountsPaginated = createThunk(
  'elds/getAccounts',
  Elds.fetchAccountsPaginated
)

export const getAccountsUnionPaginated = createThunk(
  'elds/getAccountsUnion',
  Elds.fetchAccountsUnionPaginated
)

export const getAccountsByIds = createThunk(
  'elds/getAccountsByIds',
  Elds.fetchAccountsByIds
)

export const createAccounts = createThunk(
  'elds/createAccounts',
  Elds.upsertAccounts
)

export const editAccount = createThunk('elds/editAccount', Elds.upsertAccounts)

export const deleteAccount = createThunk(
  'elds/requestDeleteAccount',
  Elds.requestDeleteAccount
)

export const deleteAccounts = createThunk(
  'elds/requestDeleteAccounts',
  Elds.requestDeleteAccounts
)

export const getAllAccountGroups = createThunk(
  'elds/getAccountGroups',
  Elds.fetchAccountGroups
)

export const getAccountGroupsByIds = createThunk(
  'elds/getAccountGroupsByIds',
  Elds.fetchAccountGroupsByIds
)

export const getAccountGroupsByName = createThunk(
  'elds/getAccountGroupsByName',
  Elds.fetchAccountGroupsByName
)

export const createAccountGroup = createThunk(
  'elds/createAccountGroup',
  Elds.createAccountGroup
)

export const editAccountGroup = createThunk(
  'elds/editAccountGroup',
  Elds.updateAccountGroup
)

export const getTournamentRealmOperationsFor = createThunk(
  'elds/tournamentRealmOperationsForAccounts',
  Elds.fetchAccountOperationsById
)

export const validateAccounts = createThunk(
  'elds/validateAccounts',
  Elds.validateAccounts
)
// #endregion

// #region Media

export const insertStreamDetail = createThunk(
  'elds/insertStreamDetail',
  Elds.insertStreamDetail
)

export const updateStreamDetail = createThunk(
  'elds/updateStreamDetail',
  Elds.updateStreamDetail
)

export const deleteStreamDetail = createThunk(
  'elds/deleteStreamDetail',
  Elds.deleteStreamDetail
)

export const updatePovMappings = createThunk(
  'elds/updatePovMappings',
  Elds.updatePovMappings
)

export const insertStreamGroup = createThunk(
  'elds/insertStreamGroup',
  Elds.insertStreamGroup
)

export const updateStreamGroup = createThunk(
  'elds/updateStreamGroup',
  Elds.updateStreamGroup
)

export const deleteStreamGroup = createThunk(
  'elds/deleteStreamGroup',
  Elds.deleteStreamGroup
)

export const getMatchesById = createThunk(
  'elds/getMatchesById',
  Elds.getMatchesById
)

export const getMatches = createThunk('elds/getMatches', Elds.getMatches)

export const deleteMatch = createThunk('elds/deleteMatch', Elds.deleteMatch)

export const getTeamsById = createThunk('elds/getTeamsById', Elds.getTeamsByIds)

export const getScheduleByTournamentId = createThunk(
  'elds/getScheduleByTournamentId',
  Elds.fetchScheduleV3Data
)

export const getUnscheduledByTournmentId = createThunk(
  'elds/fetchUnscheduledMatches',
  Elds.fetchUnscheduledMatches
)

export const updateScheduleV3 = createThunk(
  'elds/updateScheduleV3Block',
  Elds.updateScheduleV3Block
)

export const deleteScheduleV3 = createThunk(
  'elds/deleteScheduleV3Block',
  Elds.deleteScheduleV3Block
)

export const addScheduleV3 = createThunk(
  'elds/addScheduleV3Block',
  Elds.addScheduleV3Block
)

export const deleteVod = createThunk('elds/deleteVod', Elds.deleteVod)

export const getAllVods = createThunk('elds/fetchVods', Elds.fetchVodsData)

export const getVodsForGame = createThunk(
  'elds/fetchVodsForGame',
  Elds.fetchVods
)

export const insertVodsForGame = createThunk(
  'elds/insertVodsForGame',
  Elds.insertGameVodsFor
)

export const updateVods = createThunk('elds/updateVods', Elds.updateGameVod)

export const getAllVodsDetails = createThunk(
  'elds/getVodsDetails',
  Elds.fetchVodsDetails
)

export const getAvailableVodsDetailsForGames = createThunk(
  'elds/getAvailableVodsDetailsForGames',
  Elds.fetchAvailableVodsDetailsForGames
)

export const updateVideoFirstFrame = createThunk(
  'elds/updateVideoFristFrame',
  Elds.setVideoFirstFrame
)
// #endregion

// #region Schedules
export const updateStreamAllocation = createThunk(
  'elds/updateStreamAllocation',
  Elds.updateStreamAllocation
)
// #endregion

// #region Players
export const getAllPlayersPaginated = createThunk(
  'elds/getAllPlayersPaginated',
  Elds.fetchPlayersDataPaginated
)

export const getAllPlayersUnionPaginated = createThunk(
  'elds/getAllPlayersUnionPaginated',
  Elds.fetchPlayersDataUnionPaginated
)
export const validatePlayers = createThunk(
  'elds/validatePlayers',
  Elds.validatePlayers
)
// #endregion
