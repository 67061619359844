const baseUrl = 'https://ddragon.leagueoflegends.com'

const getBaseCDNUrl = (patch?: string) => {
  if (!patch) return `${baseUrl}/cdn`
  return `${baseUrl}/cdn/${patch}`
}

export type Champion = {
  id: string
  name: string
}

export const fetchDDVersions = async (): Promise<string[] | any> => {
  const res = await window.fetch(`${baseUrl}/api/versions.json`)
  return await res.json()
}

export const fetchDDChampions = async (
  patch: string,
  locale: string
): Promise<{ [championId: string]: Champion }> => {
  const res = await window.fetch(
    `${getBaseCDNUrl(patch)}/data/${locale}/champion.json`
  )
  const json = await res.json()
  return (json as unknown as any).data
}
