import { Dispatch } from 'redux'
import { getSafe } from '../../commons/Util/Util'
import { Action } from '../../store/Store.type'
import { createSlice } from '../../store/actions/utils'
import { MessageDisplayType, MessageEvent, MessageType } from './Message.type'

const AUTO_DISMISS_DELAY = 5000

// #region Reducers

const { actions, reducer } = createSlice({
  name: 'message',
  initialState: {} as Record<string, MessageEvent[]>,
  reducers: {
    add: (state, action: Action<MessageEvent>) => {
      if (action.payload) {
        const displayType = action.payload.displayType ?? 'default'
        const exisitingMessages: MessageEvent[] = getSafe(
          state,
          displayType,
          []
        )
        const dupe = exisitingMessages.find(
          (msg) => msg.message === action.payload?.message
        )
        if (dupe) {
          return {
            ...state
          }
        }

        // To reduce overcrowding only show the latest message if display type is modal
        if (displayType === 'modal') {
          return {
            ...state,
            [displayType]: [action.payload]
          }
        }
        return {
          ...state,
          [displayType]: exisitingMessages.concat(action.payload)
        }
      }
      return {
        ...state
      }
    },
    remove: (state, action: Action<MessageEvent>) => {
      const displayType = action?.payload?.displayType ?? 'default'
      const exisitingMessages = getSafe(
        state,
        displayType,
        []
      ) as MessageEvent[]

      return {
        ...state,
        [displayType]: exisitingMessages.filter(
          (msg) =>
            msg.message !== action.payload?.message
            || msg.type !== action.payload?.type
        )
      }
    }
  }
})

export default reducer
export const addMessage = actions.add
export const removeMessage = actions.remove

export const displayMessage
  = (
    message: string,
    type: MessageType = MessageType.Success,
    displayType: MessageDisplayType = 'default',
    timeout: number = AUTO_DISMISS_DELAY
  ) =>
    (dispatch: Dispatch): void => {
      const msg: MessageEvent = { type, message, displayType }
      dispatch(addMessage(msg))

      if (type !== MessageType.Error) {
        setTimeout(() => {
          dispatch(removeMessage(msg))
        }, timeout)
      }
    }

export const displayMessageWithDebounce = (
  message: string,
  type: MessageType = MessageType.Success,
  displayType: MessageDisplayType = 'default',
  timeout: number = AUTO_DISMISS_DELAY,
  debounce: number = 3000
) => {
  let lastTime = new Date().getTime()
  return (dispatch: Dispatch): void => {
    const now = new Date().getTime()
    if (now - lastTime < debounce) {
      return
    }
    else {
      lastTime = now
    }
    const msg: MessageEvent = { type, message, displayType }
    dispatch(addMessage(msg))
    if (type !== MessageType.Error) {
      setTimeout(() => {
        dispatch(removeMessage(msg))
      }, timeout)
    }
  }
}

// #endregion

// #region Selectors

export const getMessages = (
  state: Record<string, MessageEvent[]>,
  type: MessageDisplayType
): MessageEvent[] => state[type] ?? []

// #endregion
