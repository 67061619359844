import styles from './AccessDenied.scss'
// eslint-disable-next-line camelcase
import errorImage from '../../assets/svg/error.svg'

const AccessDenied = () => (
  <div className={ styles.userManagement }>
    <div className={ styles.accessDenied }>
      <img className={ styles.error } src={ errorImage }/>
      <div className={ styles.title }>ACCESS DENIED</div>
      <div className={ styles.description }>
        Reach out to #ask-esports-digital if you need access to this page.
      </div>
    </div>
  </div>
)

export { AccessDenied }
