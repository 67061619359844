import { useState } from 'react'
import spacetime from 'spacetime'
import { useTimeout } from 'usehooks-ts'
import { classNames } from '../../commons'
import styles from './Annoucement.scss'

type Props = {
  children?: React.ReactNode
  type: AnnoucementType
  autoCloseTimeMs?: number
}

export enum AnnoucementType {
  Warning = 'warning',
  Info = 'info',
}

const typeToIconEmoji = {
  [AnnoucementType.Warning]: '⚠️',
  [AnnoucementType.Info]: '🚀'
}

const closeAnnoucementCookieName = 'closeAnnoucementBanner'

const Annoucement = (props: Props) => {
  const shouldCloseAnnoucement = document.cookie
    .split(';')
    .find((cookie) => cookie.includes(closeAnnoucementCookieName))
    ?.split('=')[1]
  const [open, setOpen] = useState<boolean>(shouldCloseAnnoucement !== 'true')

  const hideForToday = () => {
    setOpen(false)
    const oneDayFromNow = spacetime.now().add(1, 'day').toNativeDate()
    // Write cookie after fade out animation completes
    setTimeout(() => {
      document.cookie = `${closeAnnoucementCookieName}=true;expires=${oneDayFromNow.toUTCString()};domain=emp.riotesports.com;path=/`
    }, 2000)
  }

  useTimeout(() => {
    setOpen(false)
  }, props.autoCloseTimeMs ?? null)

  // If the cookie value reads to close the annoucement, return null immediately to prevent annoucement banner from flickering
  if (shouldCloseAnnoucement) {
    return <></>
  }

  const classes = classNames(
    styles[props.type],
    styles.annoucementBar,
    !open && styles.hidden
  )
  return (
    <div className={ classes }>
      <span className={ styles.icon }>{ typeToIconEmoji[props.type] }</span>
      { props.children }
      <span className={ styles.close } onClick={ () => hideForToday() }>
        <svg className={ styles.svgIcon }/>
      </span>
    </div>
  )
}

export default Annoucement
