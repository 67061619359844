import { createRef, useEffect } from 'react'
import { classNames } from '../../commons'
import styles from './Scrollable.scss'

type Props = {
  className?: string
} & JSX.IntrinsicElements['div']

/**
 * A Scrollable Container adds click event handlers to allow the user to click
 * and drag to scroll left-to-right.
 *
 * Any child elements that have the attribute `data-no-scrollable` will not
 * trigger the scroll event.
 *
 * Props:
 * - Class: An optional class that is appended to the parent container.
 */
const Scrollable = (props: Props): JSX.Element => {
  const classes = classNames(styles.scrollable, props.className)
  const ref = createRef<HTMLDivElement>()

  // Using a prop directly on the class because setState is an async operation.
  // This can result in a stuttering or other visually degraded performance as
  // the actual update is delayed ever so slightly.
  let mousePosition = {
    left: 0,
    x: 0
  }

  useEffect(() => {
    const element = ref.current

    const onMouseDown = (event: MouseEvent): void => {
      const clickedElement = event.target as HTMLElement
      if (clickedElement.getAttribute('data-no-scrollable')) {
        return
      }
      element?.addEventListener('mouseup', unregisterEvents)
      element?.addEventListener('mousemove', onMouseMove)
      element?.addEventListener('mouseleave', unregisterEvents)

      mousePosition = {
        left: element?.scrollLeft || 0,
        x: event.clientX
      }
    }

    element?.addEventListener('mousedown', onMouseDown)

    const unregisterEvents = (): void => {
      element?.removeEventListener('mouseup', unregisterEvents)
      element?.removeEventListener('mousemove', onMouseMove)
      element?.removeEventListener('mouseleave', unregisterEvents)
    }

    const onMouseMove = (event: MouseEvent): void => {
      const dx = event.clientX - mousePosition.x

      ;(element as HTMLDivElement).scrollLeft = mousePosition.left - dx
    }

    return () => {
      if (element) {
        element.removeEventListener('mousedown', onMouseDown)
        unregisterEvents()
      }
    }
  }, [])

  return (
    <div className={ classes } ref={ ref }>
      { props.children }
    </div>
  )
}

export default Scrollable
