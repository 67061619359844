/* eslint-disable max-lines */
import { RecordStatus } from '@riotgames/api-types/drops/Common.type'
import {
  DropRequestDTO,
  DropResponseDTO,
  GroupResponseDTO
} from '@riotgames/api-types/drops/DropsV2.type'
import { CodesResponse } from '@riotgames/api-types/drops/PromoCodeV2.type'
import { ScheduleEntryStatus } from '@riotgames/api-types/elds/Schedules_v3.type'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { getLocalizedName } from '../../commons'
import featureToggle from '../../components/FeatureToggle/FeatureToggle.slice'
import messages, * as fromMessages from '../../components/Message/Message.slice'
import { FlattenedBannerConfig } from '../../containers/Banners/Banners.type'
import { BroadcastWithBlockId } from '../../containers/Drops/List/DropsList.type'
import { SeasonFilter } from '../../containers/Season/List/SeasonList'
import { getSectionLocalizationForSlug } from '../../containers/Tournament/Builders/Tournament/SectionNames'
import { getStageLocalizationForSlug } from '../../containers/Tournament/Builders/Tournament/StageNames'
import { NormalizedTournament } from '../../containers/Tournament/Tournament.type'
import { League as BannerLeague } from '../../services/BannersService/BannersService.type'
import drops, * as fromDrops from '../../services/DropsApi/DropsApi.slice'
import elds, * as fromElds from '../../services/Elds/Elds.slice'
import livestats, * as fromLivestats from '../../services/LiveStatsApi/LiveStatsApi.slice'
import pickem, * as fromPickem from '../../services/PickemApi/PickemApi.slice'
import promoCodes from '../../services/PromoCodesApi/PromoCodesApiV2.slice'
import viewerAccounts, * as fromViewerAccounts from '../../services/ViewerAccountApi/ViewerAccount.slice'
import awsS3, * as fromAwsS3 from './AwsS3/AwsS3'
import banners, * as fromBanners from './Banners/Banners'
import metadata, * as fromMetadata from './Metadata/Metadata'
import userConfig, * as fromUserConfig from './UserConfig/UserConfig'
import accessErrors, * as fromAccessErrors from './RBAC/AccessErrors/AccessErrors'
import wip, * as fromWIP from './WIP/WIP'
import { MessageDisplayType } from '../../components/Message/Message.type'
import { TournamentFilter } from '../../containers/Tournament/List/TournamentList'

const combinedReducer = combineReducers({
  awsS3,
  banners,
  drops,
  pickem,
  promoCodes,
  elds,
  viewerAccounts,
  featureToggle,
  messages,
  wip,
  userConfig,
  accessErrors,
  metadata,
  livestats
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

// #region Selectors

// #region Livestats
export const getFirstEventFrameById = (state: StateShape, id: string) =>
  fromLivestats.getFirstEventFrameById(state.livestats, id)

export const getCurrentLivestatsById = (state: StateShape, id: string) =>
  fromLivestats.getCurrentLivestatsById(state.livestats, id)

export const getCurrentLivestatsParticipantIdToNameByGameId = (
  state: StateShape,
  id: string
) =>
  fromLivestats.getCurrentLivestatsParticipantIdToNameByGameId(
    state.livestats,
    id
  )

export const getGameStartTimeById = (state: StateShape, id: string) =>
  fromLivestats.getGameStartTimeById(state.livestats, id)

export const isFirstFrameLoading = (state: StateShape) =>
  fromLivestats.isFirstFrameLoading(state.livestats)

export const isGameStartFrameLoading = (state: StateShape) =>
  fromLivestats.isGameStartFrameLoading(state.livestats)
// #endregion

// #region User Config

export const getSport = (state: StateShape) =>
  fromUserConfig.getSport(state.userConfig)

// #endregion

// #region Accounts
export const getWIPEditAccounts = (state: StateShape) =>
  fromWIP.getWIPEditAccounts(state.wip)

export const getWIPAccounts = (state: StateShape) =>
  fromWIP.getWIPAccounts(state.wip)

export const getWIPAccountsExisting = (state: StateShape) =>
  fromWIP.getWIPAccountsExisting(state.wip)

export const isWIPAccountsDirty = (state: StateShape) =>
  fromWIP.isWIPAccountsDirty(state.wip)

export const isWIPAccountsInvalid = (state: StateShape) =>
  fromWIP.isWIPAccountsInvalid(state.wip)

export const getSelectedSportForNewAccounts = (state: StateShape) =>
  fromWIP.getSelectedSportForNewAccounts(state.wip)

export const getSelectedGroupForNewAccounts = (state: StateShape) =>
  fromWIP.getSelectedGroupForNewAccounts(state.wip)

export const getExpirationDateForNewAccounts = (state: StateShape) =>
  fromWIP.getExpirationDateForNewAccounts(state.wip)

export const getWIPAccountById = (state: StateShape, id: string) =>
  fromWIP.getWIPAccountById(state.wip, id)

export const isEditAccountDirty = (state: StateShape, accountId: string) =>
  fromWIP.isEditAccountDirty(
    state.wip,
    accountId,
    getAccountById(state, accountId)
  )

export const isEditAccountDisplayLoginDirty = (
  state: StateShape,
  accountId: string
) =>
  fromWIP.isEditAccountDisplayLoginDirty(
    state.wip,
    accountId,
    getAccountById(state, accountId)
  )

export const getWIPExportAccountIds = (state: StateShape) =>
  fromWIP.getWIPExportAccountIds(state.wip)
export const getWIPExportAccountsHashmap = (state: StateShape) =>
  fromWIP.getWIPExportAccountsHashMap(state.wip)

// #endregion
export const getWIPRemoveAccountsHashmap = (state: StateShape) =>
  fromWIP.getWIPRemoveAccountsHashMap(state.wip)

export const getWIPPlayers = (state: StateShape) =>
  fromWIP.getWIPPlayers(state.wip)

export const getWIPAccountsCSV = (state: StateShape) =>
  fromWIP.getWIPAccountsCSV(state.wip)

export const getWIPAccountsDuplicate = (state: StateShape) =>
  fromWIP.getWIPAccountsDuplicate(state.wip)

export const getWIPPlayersExisting = (state: StateShape) =>
  fromWIP.getWIPPlayersExisting(state.wip)

export const getWIPAccountsInvalid = (state: StateShape) =>
  fromWIP.getWIPAccountsInvalid(state.wip)

export const getWIPAccountsTab = (state: StateShape) =>
  fromWIP.getWIPAccountsTab(state.wip)

export const getWIPPlayersCSV = (state: StateShape) =>
  fromWIP.getWIPPlayersCSV(state.wip)

export const getWIPPlayersCSVInvalid = (state: StateShape) =>
  fromWIP.getWIPPlayersCSVInvalid(state.wip)

export const getWIPPlayersDuplicate = (state: StateShape) =>
  fromWIP.getWIPPlayersDuplicate(state.wip)

export const getWIPPlayersInvalid = (state: StateShape) =>
  fromWIP.getWIPPlayersInvalid(state.wip)

export const getWIPPlayersTab = (state: StateShape) =>
  fromWIP.getWIPPlayersTab(state.wip)

export const isWIPPlayersDirty = (state: StateShape) =>
  fromWIP.isWIPPlayersDirty(state.wip)

export const isCreatePlayerLoading = (state: StateShape) =>
  fromElds.isInsertPlayerLoading(state.elds)

// #region Stat Sync
export const getEditOffsetById = (
  state: StateShape,
  gameId: string,
  providerId: string
) => fromWIP.getEditOffsetById(state.wip, gameId, providerId)

// TODO: Add call from ELDS to pull stream offset data. This value does not exist on our current data models
export const isEditOffsetDirty = (state: StateShape, id: string) =>
  fromWIP.isEditOffsetDirty(state.wip, id)

export const getEditProviderIdById = (state: StateShape, id: string) =>
  fromWIP.getEditProviderIdById(state.wip, id)

export const getEditGameIdByMatchId = (state: StateShape, matchId: string) =>
  fromWIP.getEditGameIdByMatchId(state.wip, matchId)

export const getEditGameTimeById = (
  state: StateShape,
  gameId: string,
  providerId: string
) => fromWIP.getEditGameTimeById(state.wip, gameId, providerId)

export const getEditLivestatsStatusById = (
  state: StateShape,
  gameId: string,
  providerId: string
) => fromWIP.getEditLivestatsStatusById(state.wip, gameId, providerId)
// #endregion

// #region AWS S3

export const getBannerTextures = (state: StateShape) =>
  fromAwsS3.getBannerTextures(state.awsS3)

export const getBannerScreenshots = (
  state: StateShape
): Record<string, Record<string, string[]>> =>
  fromAwsS3.getBannerScreenshots(state.awsS3)

export const areBannerAssetsLoading = (state: StateShape) =>
  fromAwsS3?.areBannerAssetsLoading(state.awsS3)

// #endregion

// #region Banners
export const getPatchOptions = (state: StateShape) =>
  fromBanners.getPatchOptions(state.banners)

export const getBannerLeagues = (
  state: StateShape
): Record<string, BannerLeague> => fromBanners.getLeagues(state.banners)

export const areBannerLeaguesLoading = (state: StateShape): boolean =>
  fromBanners.areLeaguesLoading(state.banners)

export const getFlattenedConfigs = (
  state: StateShape
): FlattenedBannerConfig[] => fromBanners.getFlattenedConfigs(state.banners)

// #endregion

// #region Drops
export const getLocalization = (state: StateShape) =>
  fromDrops.getLocalization(state.drops)

export const getAllLocalizationsForKey = (state: StateShape) =>
  fromDrops.getAllLocalizationsForKey(state.drops)

export const getTriggerById = (state: StateShape, id: string) =>
  fromDrops.getTriggerById(state.drops, id)

export const getTriggerInfoById = (state: StateShape, id: string) =>
  fromDrops.getTriggerInfoById(state.drops, id)

export const getTriggers = (state: StateShape) =>
  fromDrops.getTriggers(state.drops)

export const getGroups = (state: StateShape) =>
  fromDrops.getGroups(state.drops, getSport(state))
export const getGroupById = (state: StateShape, id: string) =>
  fromDrops.getGroupById(state.drops, id)

export const getInventoryItems = (state: StateShape) =>
  fromDrops.getInventoryItems(state.drops)

export const getInventoryItemById = (state: StateShape, id: string) =>
  fromDrops.getInventoryItemById(state.drops, id)

export const getRarity = (state: StateShape) => fromDrops.getRarity(state.drops)

export const getRarityById = (state: StateShape, id: number) =>
  fromDrops.getRarityById(state.drops, id)

export const isGroupLoading = (state: StateShape) =>
  fromDrops.isGroupLoading(state.drops)

export const isInventoryItemsLoading = (state: StateShape) =>
  fromDrops.isInventoryItemsLoading(state.drops)

export const isRarityLoading = (state: StateShape) =>
  fromDrops.isRarityLoading(state.drops)

export const isLocalizationForKeysLoading = (state: StateShape) =>
  fromDrops.isLocalizationForKeysLoading(state.drops)

export const isTriggerLoading = (state: StateShape) =>
  fromDrops.isTriggerLoading(state.drops)

export const isTriggersLoading = (state: StateShape) =>
  fromDrops.isTriggersLoading(state.drops)

// #endregion

// #region Pick'Em

export const getPickemLeagues = (state: StateShape) =>
  fromPickem.getPickemLeagues(state.pickem)

export const getPickemUnscheduledItemsByEldsTournamentId = (
  state: StateShape,
  id: string
) => fromPickem.getPickemUnscheduledItems(state.pickem, id)

export const getPickemCelebritiesByEldsTournamentId = (
  state: StateShape,
  id: string
) => fromPickem.getPickemCelebritiesByEldsTournamentId(state.pickem, id)

export const getPickemPickBlocksByEldsTournamentId = (
  state: StateShape,
  id: string
) => fromPickem.getPickemPickBlocks(state.pickem, id)

export const getPickemRewardCriteriaByEldsTournamentId = (
  state: StateShape,
  id: string
) => fromPickem.getPickemRewardCriteriaByEldsTournamentId(state.pickem, id)

export const getPickemPromptsByEldsTournamentId = (
  state: StateShape,
  id: string
) => fromPickem.getPromptsByEldsTournamentId(state.pickem, id)

export const getAllPickemChoiceTiers = (state: StateShape) =>
  fromPickem.getAllChoiceTiers(state.pickem)

export const getPickemQuestion = (state: StateShape, id: string) =>
  fromPickem.getPickemQuestion(state.pickem, id)

export const getPickemAnswer = (state: StateShape, id: string) =>
  fromPickem.getPickemAnswer(state.pickem, id)
export const getQuestions = (state: StateShape) =>
  fromPickem.getQuestions(state.pickem)

export const getPickemLocalizations = (state: StateShape, id: string) =>
  fromPickem.getPickemLocalizations(state.pickem, id)

export const getPickemAnswers = (state: StateShape) =>
  fromPickem.getAnswers(state.pickem)

export const getPickemTournamentByEldsTournamentId = (
  state: StateShape,
  id: string
) => fromPickem.getPickemTournamentByEldsTournamentId(state.pickem, id)

export const isPickemLeaguesLoading = (state: StateShape) =>
  fromPickem.isPickemLeaguesLoading(state.pickem)

export const isPickemUnscheduledItemsLoading = (state: StateShape) =>
  fromPickem.isPickemUnscheduledItemsLoading(state.pickem)

export const isPickemPickBlockOpLoading = (state: StateShape) =>
  fromPickem.isPickemPickBlockOpLoading(state.pickem)
export const isPickemQuestionLocalizationsLoading = (state: StateShape) =>
  fromPickem.isPickemQuestionLocalizationsLoading(state.pickem)

export const getPickemScoringConfigurations = (state: StateShape) =>
  fromPickem.getPickemScoringConfigurations(state.pickem)
export const getPickemScoringConfiguration = (state: StateShape, id: string) =>
  fromPickem.getPickemScoringConfiguration(state.pickem, id)

// #endregion

// #region PromoCodesApi

export const getUnclaimedPromoCodes = (state: StateShape): CodesResponse =>
  state.promoCodes.codeStatus.unclaimed
export const getClaimedPromoCodes = (state: StateShape): CodesResponse =>
  state.promoCodes.codeStatus.claimed

// #endregion

// #region ViewerAccount
export const isPuuidsLoading = (state: StateShape) =>
  fromViewerAccounts.isPuuidsLoading(state.viewerAccounts)

export const getPuuidsByDrop = (state: StateShape, dropId: string) =>
  fromViewerAccounts.getPuuidsByDrop(state.viewerAccounts, dropId)
// #endregion

// #region ELDS
export const getAccounts = (state: StateShape) =>
  fromElds.getAccounts(state.elds, getSport(state))

export const getDuplicatedAccountsByHandles = (
  state: StateShape,
  handles: string[]
) => fromElds.getDuplicatedAccountsByHandles(state.elds, handles)

export const getDuplicatedPlayersByHandles = (
  state: StateShape,
  handles: string[]
) => fromElds.getDuplicatedPlayersByHandles(state.elds, handles)

export const getTimespans = (state: StateShape) =>
  fromElds.getTimespans(state.elds)

export const getAccountById = (state: StateShape, id: string) =>
  fromElds.getAccountById(state.elds, id)

export const getAccountInMemoryById = (state: StateShape, id: string) =>
  fromElds.getAccountInMemoryById(state.elds, id)

export const isAccountGroupsLoading = (state: StateShape) =>
  fromElds.isAccountGroupsLoading(state.elds)

export const getAccountGroups = (state: StateShape) =>
  fromElds.getAccountGroups(state.elds)

export const getAccountGroupOptions = (state: StateShape) =>
  fromElds.getAccountGroupOptions(state.elds)

export const getLeagues = (state: StateShape) =>
  fromElds.getLeagues(state.elds, getSport(state))

export const getUnfilteredLeagues = (state: StateShape) =>
  fromElds.getUnfilteredLeagues(state.elds)

export const getLeagueById = (state: StateShape, id: string) =>
  fromElds.getLeagueById(state.elds, id)

export const isLeagueLoading = (state: StateShape) =>
  fromElds.isLeagueLoading(state.elds)

export const getLeagueOptions = (state: StateShape) =>
  fromElds.getLeagueOptions(state.elds, getSport(state))

export const getSchedules = (state: StateShape) =>
  fromElds.getSchedules(state.elds)

export const getScheduleById = (state: StateShape, id: string) =>
  fromElds.getScheduleById(state.elds, id)

export const getScheduleByTournament = (
  state: StateShape,
  tournamentId: string
) => fromElds.getScheduleByTournament(state.elds, tournamentId)

export const getBroadcastItemsByTournament = (
  state: StateShape,
  tournamentId: string
) => fromElds.getBroadcastItemsByTournament(state.elds, tournamentId)

export const getUnscheduleByTournament = (
  state: StateShape,
  tournamentId: string
) => fromElds.getUnscheduleByTournament(state.elds, tournamentId)

export const isScheduleLoading = (state: StateShape) =>
  fromElds.isScheduleLoading(state.elds)

export const isScheduleForTournamentLoading = (state: StateShape) =>
  fromElds.isScheduleForTournamentLoading(state.elds)

export const isUnscheduleForTournamentLoading = (state: StateShape) =>
  fromElds.isUnscheduledForTournamentLoading(state.elds)

export const getSeasons = (state: StateShape) => fromElds.getSeasons(state.elds)

export const getSeasonsByFilter = (state: StateShape, filter: SeasonFilter) =>
  fromElds.getSeasonsByFilter(state.elds, filter)

export const getSeasonOptions = (state: StateShape) =>
  fromElds.getSeasonOptions(state.elds)

export const getSplitOptionsForSeason = (state: StateShape, id: string) =>
  fromElds.getSplitOptionsForSeason(state.elds, id)

export const getSplitById = (
  state: StateShape,
  seasonId: string,
  splitId: string
) => fromElds.getSplitById(state.elds, seasonId, splitId)

export const isSplitUsedByAnyTournament = (
  state: StateShape,
  splitId: string
) => fromElds.isSplitUsedByAnyTournament(state.elds, splitId)

export const getSeasonById = (state: StateShape, id: string) =>
  fromElds.getSeasonById(state.elds, id)

export const getTournaments = (state: StateShape) =>
  fromElds.getTournaments(state.elds, getSport(state))

export const getFilteredTournaments = (
  state: StateShape,
  filter: TournamentFilter
) => fromElds.getFilteredTournamentList(state.elds, getSport(state), filter)

export const getTournamentOptions = (state: StateShape) =>
  fromElds.getTournamentOptions(state.elds, getSport(state))

export const getTournamentById = (state: StateShape, id: string) =>
  fromElds.getTournamentById(state.elds, id)

export const isTournamentLoading = (state: StateShape) =>
  fromElds.isTournamentLoading(state.elds)

export const isTournamentCachedLoading = (state: StateShape) =>
  fromElds.isTournamentCachedLoading(state.elds)

export const isTournamentsByIdLoading = (state: StateShape) =>
  fromElds.isTournamentsByIdLoading(state.elds)

export const isTournamentsSummaryLoading = (state: StateShape) =>
  fromElds.isTournamentsSummaryLoading(state.elds)

export const getTeams = (state: StateShape) =>
  fromElds.getTeams(state.elds, getSport(state))

export const getTeamsInMatch = (state: StateShape, ids: string[]) =>
  fromElds.getTeamsForMatches(state.elds, ids)

export const getTeamsInTournament = (state: StateShape, id: string) =>
  fromElds.getTeamsInTournament(state.elds, id)

export const getTournamentParticipants = (state: StateShape, id: string) =>
  getFieldForWIPTournament('seeding')(state, id)

export const getUnfilteredTeams = (state: StateShape) =>
  fromElds.getUnfilteredTeams(state.elds)

export const getTeamOptions = (state: StateShape) =>
  fromElds.getTeamOptions(state.elds, getSport(state))

export const getTeamById = (state: StateShape, id: string) =>
  fromElds.getTeamById(state.elds, id)

export const isTeamLoading = (state: StateShape) =>
  fromElds.isTeamLoading(state.elds)

export const isTeamsByIdLoading = (state: StateShape) =>
  fromElds.isTeamsByIdLoading(state.elds)

export const getPlayers = (state: StateShape) =>
  fromElds.getPlayers(state.elds, getSport(state))

export const getPlayerById = (state: StateShape, id: string) =>
  fromElds.getPlayerById(state.elds, id)

export const getPlayersOptions = (state: StateShape) =>
  fromElds.getPlayersOptions(state.elds, getSport(state))

export const getPlayersInTournament = (state: StateShape, id: string) =>
  fromElds.getPlayersInTournament(state.elds, id)

export const getPlayerIdsInTournament = (state: StateShape, id: string) =>
  fromElds.getPlayerIdsInTournament(state.elds, id)

export const isPlayerLoading = (state: StateShape) =>
  fromElds.isPlayerLoading(state.elds)

export const isPlayerPaginatedLoading = (state: StateShape) =>
  fromElds.isPlayerPaginatedLoading(state.elds)

export const isPlayerUnionPaginatedLoading = (state: StateShape) =>
  fromElds.isPlayerUnionPaginatedLoading(state.elds)

export const isSeasonLoading = (state: StateShape) =>
  fromElds.isSeasonLoading(state.elds)

export const getStreams = (state: StateShape) => fromElds.getStreams(state.elds)

export const getStreamById = (state: StateShape, id: string) =>
  fromElds.getStreamById(state.elds, id)

export const getStreamToStatus = (state: StateShape, tournamentId: string) =>
  fromElds.getStreamToStatus(state.elds, tournamentId)

export const getStreamToStatsStatus = (
  state: StateShape,
  tournamentId: string
) => fromElds.getStreamToStatsStatus(state.elds, tournamentId)

export const getStreamToOffset = (state: StateShape, tournamentId: string) =>
  fromElds.getStreamToOffset(state.elds, tournamentId)

export const isStreamLoading = (state: StateShape) =>
  fromElds.isStreamLoading(state.elds)

export const getStreamGroups = (state: StateShape) =>
  fromElds.getStreamGroups(state.elds)

export const getStreamGroupById = (state: StateShape, id: string) =>
  fromElds.getStreamGroupById(state.elds, id)

export const getStreamGroupOptions = (state: StateShape) =>
  fromElds.getStreamGroupOptions(state.elds)

export const isStreamGroupsLoading = (state: StateShape) =>
  fromElds.isStreamGroupsLoading(state.elds)

export const getOrganizations = (state: StateShape) =>
  fromElds.getOrganizations(state.elds)

export const getOrganizationById = (state: StateShape, id: string) =>
  fromElds.getOrganizationById(state.elds, id)

export const isOrganizationLoading = (state: StateShape) =>
  fromElds.isOrganizationLoading(state.elds)

export const getRegions = (state: StateShape) => fromElds.getRegions(state.elds)

export const getRegionBySlug = (state: StateShape, slug: string) =>
  fromElds.getRegionBySlug(state.elds, slug)

export const getRegionOptions = (state: StateShape) =>
  fromElds.getRegionOptions(state.elds)

export const getLocales = (state: StateShape) => fromElds.getLocales(state.elds)

export const getMatchByIds = (state: StateShape, matchId: string[]) =>
  fromElds.getMatchByIds(state.elds, matchId)

export const getMatches = (state: StateShape) =>
  fromElds.getAllMatches(state.elds)

export const getMatchesByTournamentId = (
  state: StateShape,
  tournamentId: string
) => fromElds.getMatchesByTournamentId(state.elds, tournamentId)

export const getMatchesFromScheduleBlocks = (
  state: StateShape,
  tournamentId: string
) => fromElds.getScheduleMatchesByTournament(state.elds, tournamentId)

export const getGamesForMatches = (state: StateShape, matchIds: string[]) =>
  fromElds.getGamesForMatches(state.elds, matchIds)

export const getGameById = (state: StateShape, id: string) =>
  fromElds.getGameById(state.elds, id)

export const getSectionsByTournamentId = (
  state: StateShape,
  tournamentId: string
) => fromElds.getSectionsByTournamentId(state.elds, tournamentId)

export const getMediaLocaleOptions = (state: StateShape) =>
  fromElds.getMediaLocaleOptions(state.elds)

export const getVodsDetails = (state: StateShape) =>
  fromElds.getVodsDetails(state.elds)

export const getVodsDetailsById = (state: StateShape, id: string) =>
  fromElds.getVodsDetailsById(state.elds, id)

export const getAvailableVodsDetails = (state: StateShape) =>
  fromElds.getAvailableVodsDetails(state.elds, getSport(state))

export const getCompletedMatches = (state: StateShape) =>
  fromElds.getCompletedMatches(state.elds)

export const getOperationsByAccountId = (state: StateShape, id: string) =>
  fromElds.getAccountOperationsById(state.elds, id)

export const getOperationsByAccountIdReversedChronological = (
  state: StateShape,
  id: string
) => fromElds.getAccountOperationsByIdReversedChronological(state.elds, id)

export const isCreateTournamentLoading = (state: StateShape) =>
  fromElds.isCreateTournamentLoading(state.elds)

export const isPublishTournamentLoading = (state: StateShape) =>
  fromElds.isPublishTournamentLoading(state.elds)

export const isUpdateTournamentLoading = (state: StateShape) =>
  fromElds.isUpdateTournamentLoading(state.elds)

export const isSupportedRegionsLoading = (state: StateShape) =>
  fromElds.isSupportedRegionsLoading(state.elds)

export const isSupportedLocalesLoading = (state: StateShape) =>
  fromElds.isSupportedLocalesLoading(state.elds)

export const isCreateSeasonLoading = (state: StateShape) =>
  fromElds.isCreateSeasonLoading(state.elds)

export const isCreateAccountsLoading = (state: StateShape) =>
  fromElds.isCreateAccountsLoading(state.elds)

export const isAccountLoading = (state: StateShape) =>
  fromElds.isAccountLoading(state.elds)

export const isAccountPaginatedLoading = (state: StateShape) =>
  fromElds.isAccountPaginatedLoading(state.elds)

export const isAccountByIdsLoading = (state: StateShape) =>
  fromElds.isAccountByIdsLoading(state.elds)

export const isAccountUnionPaginatedLoading = (state: StateShape) =>
  fromElds.isAccountUnionPaginatedLoading(state.elds)

export const isTimelineLoading = (state: StateShape) =>
  fromElds.isTimelineLoading(state.elds)

export const isMatchLoading = (state: StateShape) =>
  fromElds.isMatchLoading(state.elds)

export const isEditAccountLoading = (state: StateShape) =>
  fromElds.isEditAccountLoading(state.elds)

export const isSupportedMediaLocalesLoading = (state: StateShape) =>
  fromElds.isSupportedMediaLocalesLoading(state.elds)

export const isVodsDetailsLoading = (state: StateShape) =>
  fromElds.isVodsDetailsLoading(state.elds)

export const isAccountOperationsLoading = (state: StateShape) =>
  fromElds.isAccountOperationsForIdLoading(state.elds)

export const isMatchesLoading = (state: StateShape) =>
  fromElds.isMatchesLoading(state.elds)

export const isVodLoading = (state: StateShape) =>
  fromElds.isVodLoading(state.elds)

export const isAvailableVodsForGamesLoading = (state: StateShape) =>
  fromElds.isAvailableVodsForGamesLoading(state.elds)
// #endregion

// #region FeatureToggle
export const getFeatureToggles = (state: StateShape) => state.featureToggle
// #endregion

// #region Access Errors
export const getAccessErrors = (state: StateShape, currentPath: string) =>
  fromAccessErrors.getAccessErrors(state.accessErrors, currentPath)

export const getAccessErrorsByPath = (
  state: StateShape,
  currentPath: string,
  path: string
) =>
  fromAccessErrors.getAccessErrorsByPath(state.accessErrors, currentPath, path)

export const getAccessErrorsByResource = (
  state: StateShape,
  currentPath: string,
  resource: string
) =>
  fromAccessErrors.getAccessErrorsByResource(
    state.accessErrors,
    currentPath,
    resource
  )
// #endregion

// #region Messages
export const getMessages = (state: StateShape, type: MessageDisplayType) =>
  fromMessages.getMessages(state.messages, type)
// #endregion

// #region Metadata
export const getMetadata = (state: StateShape) =>
  fromMetadata.getMetadata(state.metadata)
// #endregion

// #region Work In Progress
export const getFieldForWIPTournament
  = <K extends keyof NormalizedTournament>(key: K) =>
    (state: StateShape, id: string | null): NormalizedTournament[K] =>
    fromWIP.getFieldForWIPTournament(key)(
      state.wip,
      id
    ) as NormalizedTournament[K]

export const getStageIdsForTournament = (state: StateShape, id: string) =>
  fromWIP.getStageIdsForTournament(state.wip, id)

export const getStageForTournament = (
  state: StateShape,
  tournamentId: string,
  stageId: string
) => fromWIP.getStageForTournament(state.wip, tournamentId, stageId)

export const getSectionForTournament = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
) => fromWIP.getSectionForTournament(state.wip, tournamentId, sectionId)

export const getEdgeMapForSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
) => fromWIP.getEdgeMapForSection(state.wip, tournamentId, sectionId)

export const getStandingForWIPTournament = (
  state: StateShape,
  tournamentId: string
) => fromWIP.getStandingForWIPTournament(state.wip, tournamentId)

export const getColumnForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number
) =>
  fromWIP.getColumnForWIPSection(state.wip, tournamentId, sectionId, columnId)

export const getMatchForWIPTournament = (
  state: StateShape,
  tournamentId: string,
  matchId: string
) => fromWIP.getMatchForWIPTournament(state.wip, tournamentId, matchId)

export const getWIPTournamentSlug = (state: StateShape, tournamentId: string) =>
  fromWIP.getWIPTournamentSlug(state.wip, tournamentId)

export const areLocalizationsInvalid = (
  state: StateShape,
  tournamentId: string
) => fromWIP.areLocalizationsInvalid(state.wip, tournamentId)

export const isTournamentDirty = (state: StateShape, tournamentId: string) =>
  fromWIP.isTournamentDirty(
    state.wip,
    tournamentId,
    fromElds.getTournamentById(state.elds, tournamentId)
  )

export const areTournamentRequiredFieldsMissing = (
  state: StateShape,
  tournamentId: string
) => fromWIP.areTournamentRequiredFieldsMissing(state.wip, tournamentId)

export const isTournamentFullyPublished = (
  state: StateShape,
  tournamentId: string
) => fromWIP.isTournamentFullyPublished(state.wip, tournamentId)

export const getMatchCellForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number,
  cellId: number
) =>
  fromWIP.getMatchCellForWIPSection(
    state.wip,
    tournamentId,
    sectionId,
    columnId,
    cellId
  )

export const canDeleteColumnFromSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number
) =>
  fromWIP.canDeleteColumnFromSection(
    state.wip,
    tournamentId,
    sectionId,
    columnId
  )

export const getDecisionPointsForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
) => fromWIP.getDecisionPointsForWIPSection(state.wip, tournamentId, sectionId)

export const getLabelForTournamentEdge
  = (state: StateShape, tournamentId: string) =>
    (targetNodeId: string | null, targetSlot: number, abbrev?: boolean) =>
      fromWIP.getLabelForTournamentEdge(
        state.wip,
        tournamentId,
        targetNodeId,
        targetSlot,
        abbrev
      )

export const getLabelForStageEdge
  = (state: StateShape, tournamentId: string, stageId: string) =>
    (targetNodeId: string, targetSlot: number) =>
      fromWIP.getLabelForStageEdge(
        state.wip,
        tournamentId,
        stageId,
        targetNodeId,
        targetSlot
      )

export const getLabelForSectionEdge
  = (state: StateShape, tournamentId: string, sectionId: string) =>
    (targetNodeId: string, targetSlot: number) =>
      fromWIP.getLabelForSectionEdge(
        state.wip,
        tournamentId,
        sectionId,
        targetNodeId,
        targetSlot
      )

export const getOptionsForStandingSlot = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  slot: number
) => fromWIP.getOptionsForStandingSlot(state.wip, tournamentId, sectionId, slot)

export const getOptionsForMatchSlot = (
  state: StateShape,
  tournamentId: string,
  matchId: string,
  slot: number
) => fromWIP.getOptionsForMatchSlot(state.wip, tournamentId, matchId, slot)

export const getOptionsForDecisionPointSlot = (
  state: StateShape,
  tournamentId: string,
  decisionPointId: string,
  slot: number
) =>
  fromWIP.getOptionsForDecisionPointSlot(
    state.wip,
    tournamentId,
    decisionPointId,
    slot
  )

export const getCircuitPointsForWIPTournament = (
  state: StateShape,
  tournamentId: string
) => fromWIP.getCircuitPointsForWIPTournament(state.wip, tournamentId)

export const getWIPTournamentAdmin = (state: StateShape) =>
  fromWIP.getWIPTournamentAdmin(state.wip)

export const isTournamentAdminDirty = (state: StateShape) =>
  fromWIP.isTournamentAdminDirty(state.wip)

export const getTournamentBuilderData = (state: StateShape) =>
  fromWIP.getTournamentBuilderData(state.wip)

export const getCopyTournamentData = (state: StateShape) =>
  fromWIP.getCopyTournamentData(state.wip)

export const getWIPTournamentById = (state: StateShape, id: string) =>
  fromWIP.getWIPTournamentById(state.wip, id)

export const getWIPInventoryItem = (state: StateShape) =>
  fromWIP.getWIPInventoryItem(state.wip)

export const getWIPPromoCodeContext = (state: StateShape) =>
  fromWIP.getWIPPromoCodeContext(state.wip)

export const isInventoryItemDirty = (state: StateShape) =>
  fromWIP.isInventoryItemDirty(state.wip)

export const isInventoryItemChampionSkin = (state: StateShape) =>
  fromWIP.isInventoryItemChampionSkin(state.wip)

export const getWIPRarity = (state: StateShape) =>
  fromWIP.getWIPRarity(state.wip)

export const isRarityDirty = (state: StateShape) =>
  fromWIP.isRarityDirty(state.wip)

export const getWIPTrigger = (state: StateShape) =>
  fromWIP.getWIPTrigger(state.wip)

export const getWIPGroup = (state: StateShape) => fromWIP.getWIPGroup(state.wip)
export const getWIPFirstDrop = (state: StateShape) =>
  fromWIP.getWIPFirstDrop(state.wip)
export const getWIPFirstDropField = <K extends keyof DropRequestDTO>(
  state: StateShape,
  key: K
) => fromWIP.getWIPFirstDropField(state.wip, key)
export const getWIPDropByID = (state: StateShape, id: string | null) =>
  fromWIP.getWIPDropByID(state.wip, id)
export const getWIPGroupGeolocations = (state: StateShape) =>
  fromWIP.getWIPGroupGeolocations(state.wip)
export const getWIPGroupDrops = (state: StateShape) =>
  fromWIP.getWIPGroupDrops(state.wip)

export const getWIPRarityMeta = (state: StateShape) =>
  fromWIP.getWIPRarityMeta(state.wip)
export const getWIPMeta = (state: StateShape) => fromWIP.getWIPMeta(state.wip)
export const getWIPIndividualTriggerMeta = (state: StateShape) =>
  fromWIP.getWIPIndividualTriggerMeta(state.wip)
export const getWIPTriggerMeta = (state: StateShape) =>
  fromWIP.getWIPTriggerMeta(state.wip)

export const isImageUploading = (state: StateShape) =>
  fromWIP.isImageUploading(state.wip)

export const isDropCapValid = (state: StateShape) =>
  fromWIP.isDropCapValid(state.wip)

export const getWIPGroupTitleLocalizations = (state: StateShape) =>
  fromWIP.getWIPGroupTitleLocalizations(state.wip)

export const getWIPGroupDescriptionLocalizations = (state: StateShape) =>
  fromWIP.getWIPGroupDescriptionLocalizations(state.wip)

export const getWIPInventoryTitleLocalizations = (state: StateShape) =>
  fromWIP.getWIPInventoryTitleLocalizations(state.wip)

export const getWIPInventoryDescriptionLocalizations = (state: StateShape) =>
  fromWIP.getWIPInventoryDescriptionLocalizations(state.wip)

export const getFulfillmentStatusDefaultDescriptions = (state: StateShape) =>
  fromWIP.getFulfillmentStatusDefaultDescriptions(state.wip)

export const getFetchingDefaultDescription = (state: StateShape) =>
  fromWIP.getFetchingDefaultDescription(state.wip)

export const getWIPBannerConfiguration = (state: StateShape) =>
  fromWIP.getWIPBannerConfiguration(state.wip)

// TODO Updated
export const getWIPDropResponseDTO = (state: StateShape) => {
  const drop = getWIPFirstDrop(state)

  // convert to response
  return {
    ...drop
  }
}

export const getGroupResponseDTOFromWIP = (
  state: StateShape
): GroupResponseDTO => {
  const groupDTO = getWIPGroup(state)

  const orderedDrops = groupDTO.group.orderedDropRequests.map(
    (id) => groupDTO.drops[id]
  )

  const drops = orderedDrops.map(
    (dto) =>
      ({
        inventories: dto.inventoryIDs.map((id) =>
          getInventoryItemById(state, id)
        ),
        rarity: getRarityById(state, dto.rarityID),
        sponsor: dto.sponsor,
        title: dto.title,
        description: dto.description,
        league: dto.league,
        cardProgramImageURL: dto.cardProgramImageURL,
        cardOverlaySponsorImageURL: dto.cardOverlaySponsorImageURL,
        notificationSponsorImageURL: dto.notificationSponsorImageURL,
        notificationProgramImageURL: dto.notificationProgramImageURL,
        presentedBySponsorImageURL: dto.presentedBySponsorImageURL,
        cappedDropResponse: {
          quantity: dto.cappedDropRequest?.quantity ?? null
        },
        validGeolocations: dto.validGeolocations,
        status: RecordStatus.Active,
        titleLocalizationGroup: null,
        descriptionLocalizationGroup: null,
        dropID: null,
        createdDateEpochMillis: null,
        sport: dto.sport
      }) as unknown as DropResponseDTO
  )

  return {
    id: null,
    createdDateEpochMillis: null,
    description: groupDTO.group.description,
    orderedDrops: drops
  } as unknown as GroupResponseDTO
}

export const getFilteredBroadcasts = (
  state: StateShape,
  status: ScheduleEntryStatus
): Record<string, BroadcastWithBlockId> => {
  const schedules = getSchedules(state)
  const tournaments = getTournaments(state)
  const selectedSport = getSport(state)

  const filteredBroadcasts: Record<string, BroadcastWithBlockId> = {}
  // iterate in `schedules{}`
  Object.keys(schedules).map((key) => {
    const schedule = schedules[key]
    const tournamentId = schedule.tournamentId
    // get sport for each schedule
    const tournamentSport = tournaments[tournamentId]?.sport
    // filter out the other sports
    if (tournamentSport === selectedSport) {
      // broadcasts contains 1 object usually, so having O(n²) is okay here
      schedule.broadcasts.forEach((broadcast) => {
        // filter out the unwanted statuses
        if (broadcast.status === status) {
          // create a new filtered broadcast object with scheduleId aka blockId
          filteredBroadcasts[broadcast.id] = { blockId: key, ...broadcast }
        }
      })
    }
  })

  return filteredBroadcasts
}

export const isGroupDirty = (state: StateShape) =>
  fromWIP.isGroupDirty(state.wip)
export const isDropLocalizationDirty = (state: StateShape) =>
  fromWIP.isDropLocalizationDirty(state.wip)
export const isMetaDirty = (state: StateShape) => fromWIP.isMetaDirty(state.wip)

export const isBannerConfigurationDirty = (state: StateShape) =>
  fromWIP.isBannerConfigurationDirty(state.wip)
export const isBannerConfigurationUploading = (state: StateShape) =>
  fromWIP.isBannerConfigurationUploading(state.wip)
export const getBannerGroups = (state: StateShape) =>
  fromWIP.getBannerGroups(state.wip)
export const wipConfigHasAssignedAssets = (state: StateShape) =>
  fromWIP.wipConfigHasAssignedAssets(state.wip)

export const isOrganizationDirty = (state: StateShape) =>
  fromWIP.isOrganizationDirty(state.wip)
export const isOrganizationImageUploading = (state: StateShape) =>
  fromWIP.isOrganizationImageUploading(state.wip)
export const getWIPOrganization = (state: StateShape) =>
  fromWIP.getWIPOrganization(state.wip)

export const getWIPSeasonById = (state: StateShape, id: string) =>
  fromWIP.getWIPSeasonById(state.wip, id)

export const getSlugForSeason = (state: StateShape, id: string) =>
  fromWIP.getSlugForSeason(state.wip, id)

export const getStartTimeForSeason = (state: StateShape, id: string) =>
  fromWIP.getStartTimeForSeason(state.wip, id)

export const getEndTimeForSeason = (state: StateShape, id: string) =>
  fromWIP.getEndTimeForSeason(state.wip, id)

export const getWIPSplitByIndex = (
  state: StateShape,
  seasonId: string,
  index: number
) => fromWIP.getWIPSplitByIndex(state.wip, seasonId, index)

export const isSeasonDirty = (state: StateShape, id: string) =>
  fromWIP.isSeasonDirty(state.wip, id, getSeasonById(state, id))

export const getUIDataForSplits = (state: StateShape) =>
  fromWIP.getUIDataForSplits(state.wip)

export const getStageThatContainsSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
) => fromWIP.getStageThatContainsSection(state.wip, tournamentId, sectionId)

const getLeagueForWIPTournament = (state: StateShape, tournamentId: string) =>
  getLeagueById(
    state,
    getFieldForWIPTournament('leagueId')(state, tournamentId)
  )

const getSeasonForWIPTournament = (state: StateShape, tournamentId: string) =>
  getSeasonById(
    state,
    getFieldForWIPTournament('seasonId')(state, tournamentId)
  )

export const getBracketBuilderSubtext = createSelector(
  [
    getLeagueForWIPTournament,
    getSeasonForWIPTournament,
    getStageThatContainsSection,
    getSectionForTournament
  ],
  (league, season, stage, section) => {
    let subtext = 'Creating a Bracket for'

    if (league) {
      subtext += ` ${getLocalizedName(league?.name)}`
    }

    if (season) {
      subtext += ` ${getLocalizedName(season?.name)} Season`
    }

    if (stage) {
      subtext += ` ${getStageLocalizationForSlug(stage?.name?.slug)}`
    }

    if (
      section
      && (stage && getStageLocalizationForSlug(stage?.name.slug))
        !== getSectionLocalizationForSlug(section.name.slug)
    ) {
      subtext += ` ${getSectionLocalizationForSlug(section.name.slug)}`
    }
    return subtext
  }
)

export const isWIPTournamentSummary = (state: StateShape, id: string) =>
  fromWIP.isWIPTournamentSummary(state.wip, id)
// #endregion

// #endregion

export const getPickemUnscheduledSections = createSelector(
  [getPickemUnscheduledItemsByEldsTournamentId, getSectionsByTournamentId],
  (unscheduledItems, sections) => {
    const { eldsSectionIds, eldsMatchIds } = unscheduledItems
    return Object.values(sections)
      .filter((section) => !!eldsSectionIds.includes(section.id))
      .map((section) => ({
        ...section,
        matches: section.matches.filter((match) =>
          eldsMatchIds.includes(match.id)
        )
      }))
  }
)
