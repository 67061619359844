import {
  Localizations,
  LocaleMove,
  LocaleUpdate,
  LocaleRemove
} from '../../../../../components/LocalizationTable/LocalizationTable.type'

export const initialLocalization: Localizations = {
  order: ['en_US'],
  localizations: { en_US: '' }
}

export const editLocalizationLocale = (
  state: Localizations,
  payload: LocaleUpdate
): Localizations => {
  const { locale, text } = payload
  const { order, localizations } = state

  const newOrder = order.some((k) => k === locale)
    ? order
    : order.concat(locale)
  return {
    order: newOrder,
    localizations: {
      ...localizations,
      [locale]: text
    }
  }
}

export const addLocalizationLocale = (
  state: Localizations,
  payload: LocaleUpdate
): Localizations => {
  const { locale } = payload
  const { order, localizations } = state

  const newOrder = order.some((k) => k === locale)
    ? order
    : order.concat(locale)
  return {
    order: newOrder,
    localizations
  }
}

export const removeLocalizationLocale = (
  state: Localizations,
  payload: LocaleRemove
): Localizations => {
  const { locale } = payload
  const { localizations, order } = state
  const updatedOrder = order.filter((k) => k !== locale)

  return {
    order: updatedOrder,
    localizations: {
      ...localizations,
      [locale]: null
    }
  }
}

export const changeLocalizationLocale = (
  state: Localizations,
  payload: LocaleMove
): Localizations => {
  const { locale, movedFrom } = payload
  const { localizations, order } = state

  return {
    order: order.map((originalLocale) =>
      originalLocale === movedFrom ? locale : originalLocale
    ),
    localizations: {
      ...localizations,
      [locale]: localizations[movedFrom],
      [movedFrom]: null
    }
  }
}

export const recordToLocalization = (
  record: Record<string, string>
): Localizations => ({
  order: Object.keys(record).sort((a, b) => a.localeCompare(b)),
  localizations: record
})
