import { AnyAction, combineReducers, Reducer } from 'redux'
import { isEqual } from '../../../../../commons'
import { createSlice, createAction } from '../../../../actions/utils'
import { createLocalization } from '../../../../../services/DropsApi/DropsApi.actions'
import {
  clearWIPGroup,
  addNewDrop,
  cloneGroupAction,
  removeDrop,
  createNewGroupAction
} from '../../../../actions/Drops/Drops.actions'
import { Action, AnyPayloadAction, PayloadAction } from '../../../../Store.type'
import {
  Localizations,
  LocaleMove,
  LocaleUpdate,
  LocaleRemove
} from '../../../../../components/LocalizationTable/LocalizationTable.type'
import {
  initialLocalization,
  editLocalizationLocale,
  addLocalizationLocale,
  removeLocalizationLocale,
  changeLocalizationLocale,
  recordToLocalization
} from './LocalizationUtil'

const changeLocale = createAction<LocaleMove>('groupLocalization/changeLocale')
const removeLocalization = createAction<LocaleRemove>(
  'groupLocalization/removeLocale'
)
const removeAllLocalization = createAction<number>(
  'groupLocalization/removeAllLocale'
)

const editGroupTitle = createAction<LocaleUpdate>(
  'groupLocalization/editGroupTitle'
)
const editGroupDescription = createAction<LocaleUpdate>(
  'groupLocalization/editGroupDescription'
)

const initialState: Localizations[] = [initialLocalization]

const editReducer = (
  state: Localizations[],
  { payload }: PayloadAction<LocaleUpdate>
) => {
  const index = payload.index as number
  const newState = [...state]

  newState[index] = editLocalizationLocale(state[index], payload)

  return [...newState]
}

const addLocaleReducer = (
  state: Localizations[],
  { payload }: PayloadAction<LocaleUpdate>
) => {
  const index = payload.index as number
  const newState = [...state]

  newState[index] = addLocalizationLocale(state[index], payload)

  return [...newState]
}

const removeReducer = (
  state: Localizations[],
  { payload }: PayloadAction<LocaleRemove>
) => {
  const index = payload.index as number
  const newState = [...state]

  newState[index] = removeLocalizationLocale(state[index], payload)

  return [...newState]
}

const removeAllReducer = (
  state: Localizations[],
  action: PayloadAction<number>
) => {
  const list = [...state]
  list.splice(action.payload, 1)

  if (list.length === 0) {
    return initialState
  }

  return [...list]
}

const removeDropReducer = (
  state: Localizations[],
  { payload }: PayloadAction<{ index: number }>
) => {
  const list = [...state]
  list.splice(payload.index, 1)

  if (list.length === 0) {
    return initialState
  }

  return [...list]
}

const changeReducer = (
  state: Localizations[],
  { payload }: PayloadAction<LocaleMove>
) => {
  const index = payload.index as number
  const newState = [...state]
  newState[index] = changeLocalizationLocale(state[index], payload)

  return [...newState]
}

const addNewDropReducer = (state: Localizations[]) => {
  const localizations = state[0]
  let nextIndex = [...state].findIndex((value) => !value)

  if (!nextIndex || nextIndex < 0) {
    nextIndex = state.length
  }

  const newState = [...state]
  newState[nextIndex] = localizations
  return [...newState]
}

const title = createSlice({
  name: 'groupLocalization',
  initialState: initialState,
  extraReducers: {
    [editGroupTitle.type]: editReducer,
    [editGroupDescription.type]: addLocaleReducer,
    [removeLocalization.type]: removeReducer,
    [removeAllLocalization.type]: removeAllReducer,
    [changeLocale.type]: changeReducer,
    [clearWIPGroup.type]: () => initialState,
    [createNewGroupAction.success.type]: () => initialState,
    [createLocalization.success.type]: () => initialState,
    [removeDrop.type]: removeDropReducer,
    [addNewDrop.type]: addNewDropReducer,
    [cloneGroupAction.type]: (
      state,
      {
        payload
      }: PayloadAction<{
        localizations: {
          title: Record<string, string>[]
          description: Record<string, string>[]
        }
      }>
    ) =>
      payload.localizations.title.map((record) => recordToLocalization(record))
  } as Record<string, Reducer<Localizations[], AnyPayloadAction>>
})

const description = createSlice({
  name: 'groupLocalization',
  initialState: initialState,
  extraReducers: {
    [editGroupTitle.type]: addLocaleReducer,
    [editGroupDescription.type]: editReducer,
    [removeLocalization.type]: removeReducer,
    [removeAllLocalization.type]: removeAllReducer,
    [changeLocale.type]: changeReducer,
    [createNewGroupAction.success.type]: () => initialState,
    [clearWIPGroup.type]: () => initialState,
    [createLocalization.success.type]: () => initialState,
    [removeDrop.type]: removeDropReducer,
    [addNewDrop.type]: addNewDropReducer,
    [cloneGroupAction.type]: (
      state,
      {
        payload
      }: PayloadAction<{
        localizations: {
          title: Record<string, string>[]
          description: Record<string, string>[]
        }
      }>
    ) =>
      payload.localizations.description.map((record) =>
        recordToLocalization(record)
      )
  } as Record<string, Reducer<Localizations[], AnyPayloadAction>>
})

const combinedReducer = combineReducers({
  title: title.reducer,
  description: description.reducer
})

export default combinedReducer

export {
  editGroupTitle,
  editGroupDescription,
  removeLocalization,
  removeAllLocalization,
  changeLocale
}

type StateShape = ReturnType<typeof combinedReducer>

export const getWIPTitleLocalizations = (state: StateShape): Localizations[] =>
  state.title

export const getWIPDescriptionLocalizations = (
  state: StateShape
): Localizations[] =>
  // for description defers to title as canonical order in case things get out of sync due to error loading
  state.description

export const isLocalizationDirty = (state: StateShape): boolean =>
  !isEqual(state.title, initialState)
  || !isEqual(state.description, initialState)
