import { ComponentType } from 'react'
import { classNames } from '../../commons'
import styles from './WithTitle.scss'
import { Props } from './WithTitle.type'

/**
 * WithTitle is a Higher Order Component intended to add labels to things.  It
 * represents the very common "UI Element with Eyebrow text" that appears in
 * so many different locations throughout the project.  If a title is not
 * provided, then the enhanced component simply return the base component, so
 * that we do not unnecessarily clutter the DOM with unused elements
 * @param WrappedComponent The component to enhance
 */
const WithTitle
  = <P extends object>(
    WrappedComponent: ComponentType<P>
  ): ComponentType<P & Props> =>
      ({ title, titleClass, ...props }: P & Props): JSX.Element => {
        const classes = classNames(styles.label, titleClass)
        const componentProps = props as P & Props

        if (!title) {
          return (
            <WrappedComponent { ...componentProps }>
              { componentProps.children }
            </WrappedComponent>
          )
        }

        return (
          <label className={ classes } data-testid="title-root">
            <span className={ styles.title }>{ title }</span>
            {
              <WrappedComponent { ...componentProps }>
                { componentProps.children }
              </WrappedComponent>
            }
          </label>
        )
      }

export default WithTitle
