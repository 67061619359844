import { LocalizationDTO } from '@riotgames/api-types/drops/Drops.type'
import { DropRequestDTO } from '@riotgames/api-types/drops/DropsV2.type'
import { createAction } from '../utils'

export const mapLocalizations = (
  supportedLocales: string[],
  localizations: Record<string, string | null>,
  localeGroup: string
): LocalizationDTO[] =>
  Object.entries(localizations)
    // on the off chance anything untoward gets in remove it
    .filter(([localeKey]) =>
      supportedLocales.includes(localeKey.replace('_', '-'))
    )
    .map(([localeKey, localization]) => ({
      localeKey,
      localization,
      localeGroup
    }))
    // Removes null or empty entries
    .filter(({ localization }) => !!localization) as LocalizationDTO[]

export const editLocalizationKeys = createAction<{
  title: string
  description: string
}>('groups/editLocalizationKeys', () => {
  const date = Date.now()
  return {
    title: `${date}-title`,
    description: `${date}-description`
  }
})

export type AddNewDropParameter = {
  dropRequest: DropRequestDTO
  dropID: string
  dropIndex?: number
}

export type EditInventoryParameters = {
  dropID: string
  inventoryIndex: number
  inventoryID: string
}

export type AddInventoryParameters = {
  dropID: string
}

export type RemoveInventoryParameters = {
  dropID: string
  inventoryIndex: number
}

export const clearWIPGroup = createAction('groups/clearWipProgress')
export const addNewDrop = createAction<AddNewDropParameter>('groups/addNewDrop')
export const removeDrop = createAction('groups/removeDrop')
export const editDropStrategyAction = createAction('groups/editDropStrategy')
export const cloneGroupAction = createAction('groups/cloneGroup')
export const createNewGroupStartAction = createAction('groups/createNew/start')
export const createNewGroupSuccessAction = createAction(
  'groups/createNew/success'
)
export const createNewGroupAction = {
  start: createNewGroupStartAction,
  success: createNewGroupSuccessAction
}

export const editInventoryItem = createAction<EditInventoryParameters>(
  'groups/editInventoryItem'
)

export const addInventoryItem = createAction<AddInventoryParameters>(
  'groups/addInventoryItem'
)

export const removeInventoryItem = createAction<RemoveInventoryParameters>(
  'groups/removeInventoryItem'
)

export const cloneInventoryAction = createAction('inventoryItem/clone')
export const editAssetUrl = createAction<string>('inventoryItem/editAssetUrl')
export const createInventoryItemStart = createAction(
  'inventoryItem/createNew/start'
)
export const createInventoryItemSuccess = createAction(
  'inventoryItem/createNew/success'
)

export const createInventoryItemAction = {
  start: createInventoryItemStart,
  success: createInventoryItemSuccess
}
export const resetInventoryItem = createAction(
  'inventoryItem/resetInventoryItem'
)

export const resetRarity = createAction('rarity/resetRarity')
export const createRaritySuccess = createAction('rarity/createNew/success')
export const createRarityStart = createAction('rarity/createNew/start')
export const createRarityAction = {
  start: createRarityStart,
  success: createRaritySuccess
}
