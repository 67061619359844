import { createThunk } from '../../store/actions/utils'
import LiveStatsApi from './LiveStatsApi'

// #region Action Creators
export const fetchEvents = createThunk(
  'livestats/fetchEvents',
  LiveStatsApi.fetchLivestatsFrames
)

export const getFirstFrame = createThunk(
  'livestats/getFirstFrame',
  LiveStatsApi.fetchFirstFrame
)

export const getGameStartFrame = createThunk(
  'livestats/getGameStartFrame',
  LiveStatsApi.fetchGameStartFrame
)
// #endregion
