import { useEffect, useState } from 'react'
import { classNames } from '../../commons'
import Checkbox from '../Checkbox/Checkbox'
import styles from './CheckBoxGroup.scss'

type Props = {
  className?: string
  options: string[]
  selectedOptions: string[]
  onChange: (selectedOptions: string[]) => void
}

const CheckBoxGroup = (props: Props) => {
  const { className, options, selectedOptions, onChange } = props
  const classes = classNames(styles.checkBoxGroup, className)

  const [selected, setSelected] = useState<string[]>(selectedOptions)

  useEffect(() => {
    setSelected(selectedOptions)
  }, [selectedOptions])

  const onChangeHandler = (label: string): void => {
    const newSelected = selected.includes(label)
      ? selected.filter((item) => item !== label)
      : [...selected, label]
    setSelected(newSelected)

    if (onChange) {
      onChange(newSelected)
    }
  }

  return (
    <div className={ classes } data-testid="checkbox-group">
      { options.map((option) => (
        <Checkbox
          key={ option }
          class={ styles.checkbox }
          label={ option }
          checkboxLocation="left"
          checked={ selected.includes(option) }
          onChange={ () => onChangeHandler(option) }
        />
      )) }
    </div>
  )
}

export default CheckBoxGroup
