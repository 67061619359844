/* eslint-disable max-lines */
import {
  LocalizedStringsWithSlug,
  PublicationStatus
} from '@riotgames/api-types/elds/Common.type'
import {
  BestOfConfig,
  PlayAllConfig
} from '@riotgames/api-types/elds/Matches.type'
import {
  DecisionPointConfig,
  Edge,
  Lineup,
  LineupEntry,
  MatchCell,
  MatchColumn,
  MatchConfig,
  Section,
  SectionType,
  StandingsPositionPoints,
  Tournament as TournamentType,
  TournamentStage
} from '@riotgames/api-types/elds/Tournaments.type'
import { combineReducers, compose, Reducer } from 'redux'
import { createSelector } from 'reselect'
import {
  get,
  getSafe,
  insertInto,
  keyBy,
  omit,
  replaceAt,
  set,
  setCurried,
  setSafe,
  sortBy,
  toOrdinal,
  updateDate,
  updateTime,
  isEqual,
  setAllSafe
} from '../../commons/Util/Util'
import {
  checkLocalizationsAreInvalid,
  fieldIsTruthy
} from '../../commons/validators/validators'
import { createOption } from '../../components'
import { DropdownOption } from '../../components/Dropdown/Dropdown.type'
import {
  createNewTournament,
  publishTournament,
  updateTournament
} from '../../services/Elds/Elds.actions'
import { createSlice } from '../../store/actions/utils'
import { AnyPayloadAction, PayloadAction } from '../../store/Store.type'
import { getSectionLocalizationForSlug } from './Builders/Tournament/SectionNames'
import {
  getShortStageLocalizationForSlug,
  getStageLocalizationForSlug
} from './Builders/Tournament/StageNames'
import { TournamentBuilderModals } from './Builders/Tournament/TournamentBuilder.type'
import {
  AddCellToColumnParameters,
  AddCircuitPointsParameters,
  AddColumnToSectionParameters,
  AddDecisionPointParameters,
  AddMatchParameters,
  AddSectionParameters,
  AddStageParameters,
  ChangeEdgeInSectionParameters,
  ChangeEdgesInStageParameters,
  DeleteCellFromColumnParameters,
  DeleteCircuitPointsParameters,
  DeleteColumnFromSectionParameters,
  DeleteDecisionPointParameters,
  DeleteMatchParameters,
  DeleteSectionParameters,
  DeleteStageParameters,
  EditCellTitleParameters,
  EditCircuitPointsParameters,
  EditDecisionPointDescriptionParameters,
  EditDecisionPointLabelParameters,
  EditGamesPlayedForMatchParameters,
  EditLeagueParameters,
  EditMatchDescriptionParameters,
  EditMatchStrategyTypeParameters,
  EditNumberOfDecisionPointParticipantsParameters,
  EditNumberOfGamesPerBestOfMatchParameters,
  EditNumberOfGamesPerPlayAllMatchParameters,
  EditNumberOfParticipantsParameters,
  EditNumberOfRoundsParameters,
  EditNumberOfSectionParticipantsParameters,
  EditNumberOfStageParticipants,
  EditParticipantParameters,
  EditSeasonParameters,
  EditSectionNameParameters,
  EditSectionTypeParameters,
  EditSlugForStageParameters,
  EditSplitParameters,
  EditTimeParameters,
  EditTournamentSlugParameters,
  EditTournamentDroppableParameters,
  EditTournamentRewardableParameters,
  NormalizedSection,
  NormalizedStage,
  NormalizedTournament,
  ReducerShape,
  RemoveEdgeFromSectionParameters,
  RemoveEdgesFromSectionForEntityParameters,
  RemoveEdgesFromStageForSectionParameters,
  RemoveEdgesFromTournamentForStageParameters,
  EditTournamentLocalizationParameters,
  EditStreamGroupParameters,
  EditPublicationStatusParameters,
  UIDataStateShape,
  CopyTournamentStateShape,
  ChangeEdgesInTournamentParameters,
  AutogenerateEdgesForStageParameters,
  EditTimezoneParameters,
  ClearBracketFromSectionParameters
} from './Tournament.type'
import {
  buildEdgeMapForSection,
  EdgeMap,
  fixEdge,
  getEdgeTargeting,
  getPossibleEdgesForDecisionPoint,
  getPossibleEdgesForMatchSlot,
  getPossibleEdgesForStanding,
  removeEdge,
  removeEdgesForEntity,
  replaceEdge
} from './Utils/EdgeUtils'
import {
  addMatchCellToColumn,
  addMatchToCellContents,
  createDecisionPointConfig,
  createMatchConfig,
  createNormalizedSection,
  createNormalizedStage,
  createNormalizedTournament,
  removeCellFromColumn,
  resizeLineups,
  resizePoints,
  setSectionType,
  updateAllStages,
  updateEdgesForModel,
  updateLocalizations,
  updatePoints,
  updateSlug,
  updateDroppable,
  updateRewardable
} from './Utils/Utils'

/*
 * Current Data Model Proposal
 * {
 *   [id]: {
 *     tournament: { *** }
 *     stages: {
 *       [id]: { *** }
 *     }
 *     sections: {
 *       [id]: { *** }
 *     }
 *     matches: {
 *       [id]: { *** }
 *     }
 *     decisionPoints: {
 *       [id]: { *** }
 *     }
 *   }
 * }
 */

// The above model ensures a few things reasonably easily.
// 1. Create / Edit WIPs can be worked on in parallel, since they're keyed
//    by their ID (tbd if this is elds id or structural id) they will not
//    overwrite each other when working on multiple at the same time for some
//    reason.
// 2. The tournament is normalized, so we don't have to worry about manipulating
//    deeply nested arrays (e.g. tournament.stages[0].sections[0].matches[0]) or
//    potential data duplication
// 3. Fine grained control on delete / reset behavior.  Just delete the top-level
//    object and everything associated with it disappears.

const updateModelInState
  = (
    state: Record<string, ReducerShape>,
    doNotModifyModelEdges?: boolean,
    doNotModifyStageEdges?: boolean | string,
    doNotResizeSections?: boolean
  ) =>
    (model: ReducerShape): Record<string, ReducerShape> =>
      doNotModifyModelEdges
        ? compose(setCurried(state, model.tournament.id), (model: ReducerShape) =>
          updateAllStages(model, doNotModifyStageEdges, doNotResizeSections)
        )(model)
        : compose(setCurried(state, model.tournament.id), (model: ReducerShape) =>
          updateEdgesForModel(
            model,
            doNotModifyStageEdges,
            doNotResizeSections
          )
        )(model)

const updateTournamentInModel
  = (model: ReducerShape) =>
    (tournament: NormalizedTournament): ReducerShape =>
      setSafe(model, 'tournament', tournament)

const updateStageInModel = (model: ReducerShape) => (stage: NormalizedStage) =>
  compose(
    setCurried(model, 'stages'),
    setCurried(model.stages, stage.structuralId)
  )(stage)

const updateSectionInModel
  = (model: ReducerShape) => (section: NormalizedSection) =>
    compose(
      setCurried(model, 'sections'),
      setCurried(model.sections, section.structuralId)
    )(section)

const updateMatchInModel = (model: ReducerShape) => (match: MatchConfig) =>
  compose(
    setCurried(model, 'matches'),
    setCurried(model.matches, match.structuralId)
  )(match)

const updateDecisionPointInModel
  = (model: ReducerShape) => (decisionPoint: DecisionPointConfig) =>
    compose(
      setCurried(model, 'decisionPoints'),
      setCurried(model.decisionPoints, decisionPoint.structuralId)
    )(decisionPoint)

const updateStagesInModel
  = (model: ReducerShape) => (stages: Record<string, NormalizedStage>) =>
    setSafe(model, 'stages', stages)

const updateSectionsInModel
  = (model: ReducerShape) => (sections: Record<string, NormalizedSection>) =>
    setSafe(model, 'sections', sections)

// TODO: Make these safer to use - handle initialization in a more intuitive fashion
const getModelFromState = (
  state: Record<string, ReducerShape>,
  id: string
): ReducerShape => getSafe(state, id, {} as ReducerShape)

// Returns the existing list of stageIds, or an empty array
const getStageIdsFromTournament = (
  tournament: NormalizedTournament
): string[] => getSafe(tournament, 'stageIds', [])

// Returns the existing list of sectionIds, or an empty array
const getSectionIdsFromStage = (stage: NormalizedStage): string[] =>
  getSafe(stage, 'sectionIds', [])

const create = (tournamentId?: string): ReducerShape => ({
  tournament: createNormalizedTournament(tournamentId),
  stages: {},
  sections: {},
  matches: {},
  decisionPoints: {}
})

const editDateTime
  = <K extends keyof NormalizedTournament>(
    key: K,
    updateFunc: (oldDate: Date, newDate: Date, timezone: string | null) => Date
  ) =>
      (
        state: Record<string, ReducerShape>,
        { payload }: PayloadAction<EditTimeParameters>
      ) => {
        const model = getModelFromState(state, payload.tournamentId)
        const tournament = get('tournament')(model) as NormalizedTournament
        const updateKey = set(key)
        const updateTournament = set('tournament')
        const updateModel = set(payload.tournamentId)

        let oldDate = new Date(get(key)(tournament) as string)

        // Check in case something gets corrupted to try and recover
        oldDate = isNaN(oldDate.getTime()) ? new Date() : oldDate
        const newDate = updateFunc(
          oldDate,
          new Date(payload.date),
          model.tournament?.timeZone
        )

        const updatedTournament = updateKey(tournament, newDate.toISOString())
        const updatedModel = updateTournament(model, updatedTournament)
        return updateModel(state, updatedModel)
      }

export const normalizeTournament = compose(
  (t): NormalizedTournament => omit(t, 'stages') as NormalizedTournament,
  (t: TournamentType) => ({
    ...t,
    stageIds: t.stages.sort(sortBy('id')).map((stage) => stage.structuralId),
    edges: t.edges.map(fixEdge),
    seasonId: t.seasonId || null,
    splitId: t.splitId || null,
    streamGroupId: t.streamGroupId || null
  })
)

const normalizeStage = compose(
  (s): NormalizedStage => omit(s, 'sections'),
  (s: TournamentStage) => ({
    ...s,
    // ELDS returns Sections sorted by structural Id, but Id represents their
    // Insertion Order.  EMP Relies on this ordering since it automatically
    // generates Edges based on the order of Sections within a Stage. By sorting
    // on ID here, we can ensure that Edits use the same ordering as Creation.
    sectionIds: s.sections
      .sort(sortBy('id'))
      .map((section) => section.structuralId),
    edges: s.edges.map(fixEdge)
  })
)

const normalizeSection = compose(
  (section): NormalizedSection => omit(section, 'matches', 'decisionPoints'),
  (section: Section) => ({
    ...section,
    edges: section.edges.map(fixEdge),
    matchIds: section.matches.map((match) => match.structuralId),
    decisionPointIds: section.decisionPoints.map(
      (decisionPoint) => decisionPoint.structuralId
    )
  })
)

const mapTournamentToModel = (tournament: TournamentType): ReducerShape => {
  const normalizedTournament = normalizeTournament(tournament)
  const stages = tournament.stages.map(normalizeStage)
  const sections = tournament.stages.flatMap((stage) => stage.sections)

  const normalizedSections = sections.map(normalizeSection)
  const matches = sections.flatMap((section) => section.matches)
  const decisionPoints = sections.flatMap((section) => section.decisionPoints)

  const keyByStructuralId = keyBy('structuralId')

  return {
    tournament: normalizedTournament,
    stages: keyByStructuralId(stages),
    sections: keyByStructuralId(normalizedSections),
    matches: keyByStructuralId(matches),
    decisionPoints: keyByStructuralId(decisionPoints)
  }
}

const copyExistingTournamentReducer = (
  state: Record<string, ReducerShape>,
  { payload }: PayloadAction<TournamentType>
): Record<string, ReducerShape> => ({
  ...state,
  [payload?.id]: mapTournamentToModel(payload)
})

// #region Reducers
const tournamentSlice = createSlice({
  name: 'tournament',
  initialState: {} as Record<string, ReducerShape>,
  reducers: {
    initNewTournament: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<string> // optionally allow passing the tournament id of the new tournament to init
    ) =>
      setSafe(
        state,
        payload || (null as unknown as string),
        create(payload) || (null as unknown as ReducerShape)
      ),
    copyForEdit: copyExistingTournamentReducer,
    editTournamentSlug: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditTournamentSlugParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)

      return compose(
        updateModelInState(state, true, true),
        updateTournamentInModel(model),
        updateSlug
      )(model.tournament, payload.slug)
    },
    addStage: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AddStageParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const stageIds = getStageIdsFromTournament(model.tournament)
      const stage = createNormalizedStage(payload.slug, payload.stageId)
      const stages = {
        ...model.stages,
        [stage.structuralId]: stage
      }

      const tournament = {
        ...model.tournament,
        stageIds: stageIds.concat(stage.structuralId)
      }
      const updatedModel: ReducerShape = {
        ...model,
        tournament,
        stages
      }

      return updateModelInState(
        state,
        payload.inFlowEditMode,
        stage.structuralId
      )(updatedModel)
    },
    deleteStage: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<DeleteStageParameters>
    ) => {
      const { tournamentId, stageId } = payload
      const model = getModelFromState(state, tournamentId)

      // Remove the stage from the Tournament and any edges related to that stage
      const tournament = {
        ...model.tournament,
        stageIds: model.tournament.stageIds.filter((id) => id !== stageId),
        edges: removeEdgesForEntity(model.tournament, stageId)
      }

      return compose(
        updateModelInState(state, payload.inFlowEditMode, true),
        updateTournamentInModel(model)
      )(tournament)
    },
    editSlugForStage: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditSlugForStageParameters>
    ) => {
      const { tournamentId, stageId } = payload
      const model = getModelFromState(state, tournamentId)
      const stage = model.stages[stageId]

      const name: LocalizedStringsWithSlug = {
        slug: payload.slug as string,
        localizations: stage?.name?.localizations || {}
      }

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'stages'),
        setCurried(model.stages, stageId),
        setCurried(stage, 'name')
      )(name)
    },
    editNumberOfStageParticipants: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditNumberOfStageParticipants>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const stage = getSafe(model.stages, payload.stageId)

      return compose(
        updateModelInState(state, payload.inFlowEditMode, stage.structuralId),
        updateStageInModel(model),
        (stage: NormalizedStage) => resizeLineups(stage, payload.count)
      )(stage)
    },
    editNumberOfDecisionPointParticipants: (
      state: Record<string, ReducerShape>,
      {
        payload
      }: PayloadAction<EditNumberOfDecisionPointParticipantsParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const decisionPoint = getSafe(model.decisionPoints, payload.structuralId)

      return compose(
        updateModelInState(state, true, true),
        updateDecisionPointInModel(model),
        (decisionPoint: Partial<DecisionPointConfig>) =>
          resizeLineups(decisionPoint as DecisionPointConfig, payload.count)
      )(decisionPoint)
    },
    addSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AddSectionParameters>
    ) => {
      const { tournamentId, stageId, sectionId } = payload
      const model = getModelFromState(state, tournamentId)
      const stage = getSafe(model.stages, stageId, {} as NormalizedStage)
      const sectionIds = getSectionIdsFromStage(stage)
      const alreadyUsedSlugs = sectionIds.map(
        (id) => model.sections[id].name.slug
      )

      const section = createNormalizedSection(alreadyUsedSlugs, sectionId)

      const sections = setSafe(model.sections, sectionId, section)

      // Add the new section to the current column, or create a new column if
      // this is the first section to be added to the stage
      const columns
        = stage.columns.length > 0
          ? stage.columns.map((column) => ({
            ...column,
            cells: column.cells.map((cell) => ({
              ...cell,
              contents: cell.contents.concat(section.structuralId)
            }))
          }))
          : [
            {
              cells: [
                {
                  name: stage.name,
                  contents: [section.structuralId]
                }
              ]
            }
          ]

      const updatedStage = {
        ...stage,
        sectionIds: sectionIds.concat(section.structuralId),
        columns
      }

      const stages = setSafe(model.stages, payload.stageId, updatedStage)

      const updatedModel = {
        ...model,
        stages,
        sections
      }

      return updateModelInState(state, true, stageId)(updatedModel)
    },
    addDecisionPoint: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AddDecisionPointParameters>
    ) => {
      const { tournamentId, sectionId, structuralId } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      // Create a placeholder name for the decision point,
      // defaulting to a simple Decision Point 1 / etc.
      const index = `${Object.values(model.decisionPoints).length + 1}`
      const description = `Decision Point ${index}`
      const decisionPoint = createDecisionPointConfig(
        description,
        structuralId
      ) as DecisionPointConfig

      const updatedModel = updateDecisionPointInModel(model)(decisionPoint)

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(updatedModel),
        setCurried(section, 'decisionPointIds'),
        (decisionPointIds: string[], decisionPointId: string) =>
          insertInto(decisionPointIds, decisionPointId)
      )(section.decisionPointIds, decisionPoint.structuralId)
    },
    deleteDecisionPoint: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<DeleteDecisionPointParameters>
    ) => {
      const { tournamentId, sectionId, structuralId } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      const updatedModel = {
        ...model,
        decisionPoints: omit(model.decisionPoints, structuralId)
      }

      const updatedSection = {
        ...section,
        decisionPointIds: section.decisionPointIds.filter(
          (id) => id !== structuralId
        ),
        edges: removeEdgesForEntity(section, structuralId)
      }

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(updatedModel)
      )(updatedSection)
    },
    editDecisionPointDescription: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditDecisionPointDescriptionParameters>
    ) => {
      const { tournamentId, structuralId, description } = payload
      const model = getModelFromState(state, tournamentId)

      const decisionPoint = getSafe(model.decisionPoints, structuralId)

      return compose(
        updateModelInState(state, true, true),
        updateDecisionPointInModel(model),
        (decisionPoint: Partial<DecisionPointConfig>) =>
          set('description')(decisionPoint, description)
      )(decisionPoint)
    },
    editDecisionPointLabel: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditDecisionPointLabelParameters>
    ) => {
      const { tournamentId, structuralId, index, description } = payload
      const model = getModelFromState(state, tournamentId)

      const decisionPoint = getSafe(
        model.decisionPoints,
        structuralId
      ) as DecisionPointConfig
      const updatedStandingEntry = set('description')(
        decisionPoint.standing.entries[index],
        description
      )
      const updatedStandings = {
        entries: replaceAt(
          decisionPoint.standing.entries,
          updatedStandingEntry,
          index
        )
      }

      return compose(
        updateModelInState(state, true, true),
        updateDecisionPointInModel(model),
        (decisionPoint: Partial<DecisionPointConfig>) =>
          set('standing')(decisionPoint, updatedStandings)
      )(decisionPoint)
    },
    addCircuitPoints: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AddCircuitPointsParameters>
    ) => {
      const { tournamentId } = payload

      const model = getModelFromState(state, tournamentId)
      const tournament = getSafe(
        model,
        'tournament',
        createNormalizedTournament()
      )
      const circuitPoints: StandingsPositionPoints[]
        = tournament.standing.entries.map((entry) => ({
          position: entry.index,
          points: 0
        }))
      const newTournament = updatePoints(tournament, circuitPoints)

      return compose(
        setCurried(state, payload.tournamentId),
        setCurried(model, 'tournament')
      )(newTournament)
    },
    editSectionName: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditSectionNameParameters>
    ) => {
      const { tournamentId, sectionId, slug } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'sections'),
        setCurried(model.sections, sectionId),
        setCurried(section, 'name'),
        setCurried(section.name, 'slug')
      )(slug)
    },
    editSectionType: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditSectionTypeParameters>
    ) => {
      const { tournamentId, sectionId, type } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )
      const matches: Record<string, Partial<MatchConfig>> = getSafe(
        model,
        'matches',
        {}
      )

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'sections'),
        setCurried(model.sections, sectionId),
        setSectionType
      )(section, type, matches)
    },
    editMatchStrategyType: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditMatchStrategyTypeParameters>
    ) => {
      const { tournamentId, sectionId, type } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )
      let updatedMatchTemplate
      const defaultBestOfConfig: BestOfConfig = {
        count: 1
      }
      const defaultPlayAllConfig: PlayAllConfig = {
        count: 2
      }

      if (type === 'bestOf') {
        updatedMatchTemplate = setSafe(
          omit(section.groupConfig.matchTemplate, 'playAllConfig'),
          'bestOfConfig',
          defaultBestOfConfig
        )
      }
      else {
        updatedMatchTemplate = setSafe(
          omit(section.groupConfig.matchTemplate, 'bestOfConfig'),
          'playAllConfig',
          defaultPlayAllConfig
        )
      }

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'sections'),
        setCurried(model.sections, sectionId),
        setCurried(section, 'groupConfig'),
        setCurried(section.groupConfig, 'matchTemplate')
      )(setSafe(updatedMatchTemplate, 'strategy', type))
    },
    editNumberOfRounds: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditNumberOfRoundsParameters>
    ) => {
      const { tournamentId, sectionId, rounds } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'sections'),
        setCurried(model.sections, sectionId),
        setCurried(section, 'groupConfig'),
        setCurried(section.groupConfig, 'roundRobinRounds')
      )(rounds)
    },
    editNumberOfGamesPerBestOfMatch: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditNumberOfGamesPerBestOfMatchParameters>
    ) => {
      const { tournamentId, sectionId, count } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'sections'),
        setCurried(model.sections, sectionId),
        setCurried(section, 'groupConfig'),
        setCurried(section.groupConfig, 'matchTemplate'),
        setCurried(section.groupConfig.matchTemplate, 'bestOfConfig'),
        setCurried(section.groupConfig.matchTemplate.bestOfConfig, 'count')
      )(count)
    },
    editNumberOfGamesPerPlayAllMatch: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditNumberOfGamesPerPlayAllMatchParameters>
    ) => {
      const { tournamentId, sectionId, count } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'sections'),
        setCurried(model.sections, sectionId),
        setCurried(section, 'groupConfig'),
        setCurried(section.groupConfig, 'matchTemplate'),
        setCurried(section.groupConfig.matchTemplate, 'playAllConfig'),
        setCurried(section.groupConfig.matchTemplate.playAllConfig, 'count')
      )(count)
    },
    deleteSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<DeleteSectionParameters>
    ) => {
      const { tournamentId, stageId, sectionId } = payload
      const model = getModelFromState(state, tournamentId)
      const stage = getSafe(model.stages, stageId)

      const sectionIds = getSafe(stage, 'sectionIds').filter(
        (id) => id !== sectionId
      )

      // Removes the section from the existing column, and removes the column
      // if this was the only section in the column.
      const columns = stage.columns
        .map((column) => ({
          ...column,
          cells: column.cells.map((cell) => ({
            ...cell,
            contents: cell.contents.filter((id) => id !== sectionId)
          }))
        }))
        .filter((column) =>
          column.cells.every((cell) => cell.contents.length > 0)
        )

      const updatedStage = {
        ...stage,
        sectionIds,
        columns
      }

      return compose(
        updateModelInState(state, true, stageId),
        setCurried(model, 'stages'),
        setCurried(model.stages, stageId)
      )(updatedStage)
    },
    deleteCircuitPoints: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<DeleteCircuitPointsParameters>
    ) => {
      const { tournamentId } = payload

      const model = getModelFromState(state, tournamentId)
      const tournament = getSafe(
        model,
        'tournament',
        createNormalizedTournament()
      )
      const newTournament = {
        ...tournament,
        points: null
      } as unknown as NormalizedTournament

      return compose(
        setCurried(state, payload.tournamentId),
        setCurried(model, 'tournament')
      )(newTournament)
    },
    editLeague: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditLeagueParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const tournament = get('tournament')(model) as NormalizedTournament
      return compose(
        setCurried(state, payload.tournamentId),
        setCurried(model, 'tournament'),
        setCurried(tournament, 'leagueId')
      )(payload.leagueId)
    },
    editSeason: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditSeasonParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const tournament = get('tournament')(model) as NormalizedTournament

      // Splits are season specific, so if we ever change the season, we must
      // reset the split id as well.
      const removeSplitId = (
        tournament: NormalizedTournament
      ): NormalizedTournament =>
        setSafe(tournament, 'splitId', null as unknown as string)

      return compose(
        setCurried(state, payload.tournamentId),
        setCurried(model, 'tournament'),
        removeSplitId,
        setCurried(tournament, 'seasonId')
      )(payload.seasonId)
    },
    editSplit: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditSplitParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)

      return compose(
        updateModelInState(state, true, true),
        updateTournamentInModel(model),
        setCurried(model.tournament, 'splitId')
      )(payload.splitId)
    },
    editParticipant: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditParticipantParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const tournament = get('tournament')(model) as NormalizedTournament

      const currentSeeding = get('seeding')(tournament) as Lineup
      const currentEntries = get('entries')(currentSeeding) as LineupEntry[]

      const updateTeamId = set('teamId')

      const newEntries = replaceAt(
        currentEntries,
        updateTeamId(currentEntries[payload.index], payload.teamId),
        payload.index
      )

      return compose(
        setCurried(state, payload.tournamentId),
        setCurried(model, 'tournament'),
        setCurried(tournament, 'seeding'),
        setCurried(currentSeeding, 'entries')
      )(newEntries)
    },
    editLocalizations: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditTournamentLocalizationParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      return compose(
        updateModelInState(state, true, true),
        updateTournamentInModel(model),
        updateLocalizations
      )(model.tournament, payload.locale, payload.localeName)
    },
    editNumberOfParticipants: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditNumberOfParticipantsParameters>
    ) => {
      const { tournamentId, numberOfParticipants } = payload

      const model = getModelFromState(state, tournamentId)
      const tournament = getSafe(
        model,
        'tournament',
        createNormalizedTournament()
      )

      return compose(
        setCurried(state, payload.tournamentId),
        setCurried(model, 'tournament'),
        resizePoints,
        resizeLineups
      )(tournament, numberOfParticipants)
    },
    editCircuitPoints: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditCircuitPointsParameters>
    ) => {
      const { tournamentId, position, points } = payload

      const model = getModelFromState(state, tournamentId)
      const tournament = getSafe(
        model,
        'tournament',
        createNormalizedTournament()
      )

      const entries = tournament.points.entries.map(
        (standingsPositionPoints) =>
          standingsPositionPoints.position === position
            ? { position, points }
            : standingsPositionPoints
      )

      return compose(
        setCurried(state, payload.tournamentId),
        setCurried(model, 'tournament'),
        updatePoints
      )(tournament, entries)
    },
    removeEdgesFromSectionForEntity: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<RemoveEdgesFromSectionForEntityParameters>
    ) => {
      const { tournamentId, sectionId, entityId } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(model.sections, sectionId)

      const newEdges = removeEdgesForEntity(section, entityId)

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'sections'),
        setCurried(section, 'edges')
      )(newEdges)
    },
    removeEdgesFromStageForSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<RemoveEdgesFromStageForSectionParameters>
    ) => {
      const { tournamentId, sectionId, stageId } = payload
      const model = getModelFromState(state, tournamentId)
      const stage = getSafe(model.stages, stageId)

      const newEdges = removeEdgesForEntity(stage, sectionId)

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'stages'),
        setCurried(stage, 'edges')
      )(newEdges)
    },
    removeEdgesFromTournamentForStage: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<RemoveEdgesFromTournamentForStageParameters>
    ) => {
      const { tournamentId, stageId } = payload
      const model = getModelFromState(state, tournamentId)

      const newEdges = removeEdgesForEntity(model.tournament, stageId)

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'tournament'),
        setCurried(model.tournament, 'edges')
      )(newEdges)
    },
    editTimezone: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditTimezoneParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const { tournamentId, timezone } = payload
      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'tournament'),
        setCurried(model.tournament, 'timeZone')
      )(timezone)
    },
    editStartTime: editDateTime('startTime', updateTime),
    editStartDate: editDateTime('startTime', updateDate),
    editEndTime: editDateTime('endTime', updateTime),
    editEndDate: editDateTime('endTime', updateDate),
    addColumnToSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AddColumnToSectionParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)
      const section = getSafe(
        model.sections,
        payload.sectionId,
        {} as NormalizedSection
      )

      const matchColumn: MatchColumn = {
        cells: []
      }

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model),
        setCurried(section, 'columns'),
        (column: MatchColumn) => section.columns.concat(column)
      )(matchColumn)
    },
    editNumberOfSectionParticipants: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditNumberOfSectionParticipantsParameters>
    ) => {
      const { tournamentId, sectionId, count, stageId } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        updateModelInState(state, true, false, true),
        updateSectionInModel(model),
        resizeLineups
      )(section, count)
    },
    clearBracketFromSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<ClearBracketFromSectionParameters>
    ) => {
      const { tournamentId, sectionId } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      const updatedSection: NormalizedSection = {
        ...section,
        columns: [],
        edges: [],
        matchIds: []
      }

      for (let i = 0; i < section.matchIds.length; i++) {
        const matchId = section.matchIds[i]
        delete model.matches[matchId]
      }

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model)
      )(updatedSection)
    },
    deleteColumnFromSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<DeleteColumnFromSectionParameters>
    ) => {
      const { tournamentId, sectionId, index } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model),
        setCurried(section, 'columns'),
        (columns: MatchColumn[], index: number) =>
          columns.filter((_column, idx) => idx !== index)
      )(section.columns, index)
    },
    addCellToColumn: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AddCellToColumnParameters>
    ) => {
      const { tournamentId, sectionId, index } = payload
      const model = getModelFromState(state, tournamentId)
      const section = model.sections[sectionId]

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model),
        setCurried(section, 'columns'),
        (columns: MatchColumn[], index: number) =>
          columns.map((column, idx) =>
            idx === index ? addMatchCellToColumn(column) : column
          )
      )(section.columns, index)
    },
    deleteCellFromColumn: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<DeleteCellFromColumnParameters>
    ) => {
      const { tournamentId, sectionId, columnIndex, cellIndex } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model),
        setCurried(section, 'columns'),
        (columns: MatchColumn[], colIdx: number, cellIdx: number) =>
          replaceAt(
            columns,
            removeCellFromColumn(columns[colIdx], cellIdx),
            colIdx
          )
      )(section.columns, columnIndex, cellIndex)
    },
    editCellTitle: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditCellTitleParameters>
    ) => {
      const { tournamentId, sectionId, columnIndex, cellIndex, slug } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model),
        setCurried(section, 'columns'),
        (columns: MatchColumn[], colIdx: number, cellIdx: number) =>
          columns.map((column, columnIndex) =>
            columnIndex === colIdx
              ? {
                ...column,
                cells: column.cells.map((cell, cellIndex) =>
                  cellIndex === cellIdx ? updateSlug(cell, slug) : cell
                )
              }
              : column
          )
      )(section.columns, columnIndex, cellIndex)
    },
    addMatch: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AddMatchParameters>
    ) => {
      const {
        tournamentId,
        sectionId,
        structuralId,
        columnIndex,
        cellIndex,
        description: passedDescription,
        numberOfGames
      } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      // Create a placeholder name for the match, defaulting to a simple
      // Match 1 / Match 2 / etc.
      const description
        = passedDescription ?? `Match ${Object.values(model.matches).length + 1}`

      const match = createMatchConfig(structuralId, description) as MatchConfig
      match.bestOfConfig = {
        count: numberOfGames ?? 1
      }

      const updatedModel = updateMatchInModel(model)(match)

      const addMatchToSectionMatchIds = (
        section: NormalizedSection
      ): NormalizedSection => ({
        ...section,
        matchIds: section.matchIds.concat(match.structuralId)
      })

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(updatedModel),
        addMatchToSectionMatchIds,
        setCurried(section, 'columns'),
        (columns: MatchColumn[], matchId: string) => {
          const column = columns[columnIndex]
          const cell = addMatchToCellContents(column.cells[cellIndex], matchId)
          const c = {
            ...column,
            cells: replaceAt(column.cells, cell, cellIndex)
          }

          return replaceAt(columns, c, columnIndex)
        }
      )(section.columns, match.structuralId)
    },
    deleteMatch: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<DeleteMatchParameters>
    ) => {
      const { tournamentId, sectionId, matchId } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )
      const removeMatch = (matchId: string) => (model: ReducerShape) => ({
        ...model,
        matches: omit(model.matches, matchId)
      })

      const removeMatchFromSection
        = (matchId: string) => (section: NormalizedSection) => ({
          ...section,
          matchIds: section.matchIds.filter((id) => id !== matchId)
        })

      const removeEdges
        = (matchId: string) => (section: NormalizedSection) => ({
          ...section,
          edges: section.edges.filter(
            (edge) =>
              edge.sourceNodeId !== matchId && edge.targetNodeId !== matchId
          )
        })

      return compose(
        updateModelInState(state, true, true),
        removeMatch(matchId),
        updateSectionInModel(model),
        removeMatchFromSection(matchId),
        removeEdges(matchId),
        setCurried(section, 'columns'),
        (columns: MatchColumn[], matchId: string) =>
          columns.map((column) => ({
            ...column,
            cells: column.cells.map((cell) => ({
              ...cell,
              contents: cell.contents.filter(
                (view) => view.structuralId !== matchId
              )
            }))
          }))
      )(section.columns, matchId)
    },
    autogenerateAllStageEdgesInTournament: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<AutogenerateEdgesForStageParameters>
    ) => {
      const { tournamentId } = payload
      const model = getModelFromState(state, tournamentId)
      return compose(
        setCurried(state, model.tournament.id),
        (model: ReducerShape) => updateEdgesForModel(model, true, true)
      )(model)
    },
    changeEdgesInTournament: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<ChangeEdgesInTournamentParameters>
    ) => {
      const { tournamentId, edges } = payload
      const model = getModelFromState(state, tournamentId)
      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'tournament'),
        setCurried(model.tournament, 'edges')
      )(edges)
    },
    changeEdgesInStage: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<ChangeEdgesInStageParameters>
    ) => {
      const { tournamentId, stageId, edges } = payload
      const model = getModelFromState(state, tournamentId)
      const stage = getSafe(model.stages, stageId)
      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'stages'),
        setCurried(model.stages, stageId),
        setCurried(stage, 'edges')
      )(edges)
    },
    changeEdgeInSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<ChangeEdgeInSectionParameters>
    ) => {
      const { tournamentId, sectionId, edge } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model),
        replaceEdge
      )(section, edge)
    },
    removeEdgeFromSection: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<RemoveEdgeFromSectionParameters>
    ) => {
      const { tournamentId, sectionId, id, slot } = payload
      const model = getModelFromState(state, tournamentId)
      const section = getSafe(
        model.sections,
        sectionId,
        {} as NormalizedSection
      )

      return compose(
        updateModelInState(state, true, true),
        updateSectionInModel(model),
        removeEdge
      )(section, id, slot)
    },
    editMatchDescription: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditMatchDescriptionParameters>
    ) => {
      const { tournamentId, matchId, description } = payload
      const model = getModelFromState(state, tournamentId)
      const match = getSafe(model.matches, matchId, {} as Partial<MatchConfig>)

      // Modify the match
      const m = {
        ...match,
        description
      } as MatchConfig

      return compose(
        updateModelInState(state, true, true),
        updateMatchInModel(model)
      )(m)
    },
    editGamesPlayedForMatch: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditGamesPlayedForMatchParameters>
    ) => {
      const { tournamentId, matchId, count } = payload
      const model = getModelFromState(state, tournamentId)
      const match = model.matches[matchId]

      return compose(
        updateModelInState(state, true, true),
        updateMatchInModel(model),
        (match: Partial<MatchConfig>, count: number) => ({
          ...match,
          bestOfConfig: {
            count
          }
        })
      )(match, count)
    },
    editStreamGroupId: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditStreamGroupParameters>
    ) => {
      const { tournamentId, streamGroupId } = payload
      const model = getModelFromState(state, tournamentId)
      const tournament = get('tournament')(model) as NormalizedTournament

      return compose(
        setCurried(state, tournamentId),
        setCurried(model, 'tournament'),
        setCurried(tournament, 'streamGroupId')
      )(streamGroupId)
    },
    editTournamentPublicationStatus: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditPublicationStatusParameters>
    ) => {
      const { tournamentId, publicationStatus } = payload
      const model = getModelFromState(state, tournamentId)
      const tournament = get('tournament')(model) as NormalizedTournament

      return compose(
        updateModelInState(state, true, true),
        updateTournamentInModel(model)
      )(setSafe(tournament, 'publicationStatus', publicationStatus))
    },
    editTournamentDroppable: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditTournamentDroppableParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)

      return compose(
        updateModelInState(state, true, true),
        updateTournamentInModel(model),
        updateDroppable
      )(model.tournament, payload.droppable)
    },
    editTournamentRewardable: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditTournamentRewardableParameters>
    ) => {
      const model = getModelFromState(state, payload.tournamentId)

      return compose(
        updateModelInState(state, true, true),
        updateTournamentInModel(model),
        updateRewardable
      )(model.tournament, payload.rewardable)
    },
    editStagesPublicationStatus: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditPublicationStatusParameters>
    ) => {
      const { tournamentId, publicationStatus } = payload
      const model = getModelFromState(state, tournamentId)
      const stages = get('stages')(model) as Record<string, NormalizedStage>

      return compose(
        updateModelInState(state, true, true),
        updateStagesInModel(model)
      )(setAllSafe(stages, 'publicationStatus', publicationStatus))
    },
    editSectionsPublicationStatus: (
      state: Record<string, ReducerShape>,
      { payload }: PayloadAction<EditPublicationStatusParameters>
    ) => {
      const { tournamentId, publicationStatus } = payload
      const model = getModelFromState(state, tournamentId)
      const sections = get('sections')(model) as Record<
        string,
        NormalizedSection
      >
      return compose(
        updateModelInState(state, true, true),
        updateSectionsInModel(model)
      )(setAllSafe(sections, 'publicationStatus', publicationStatus))
    }
  } as Record<string, Reducer<Record<string, ReducerShape>, AnyPayloadAction>>,
  extraReducers: {
    [createNewTournament.success.type]: (state) =>
      omit(state, null as unknown as never),
    [publishTournament.success.type]: copyExistingTournamentReducer,
    [updateTournament.success.type]: copyExistingTournamentReducer
  } as Record<string, Reducer<Record<string, ReducerShape>, AnyPayloadAction>>
})

const uiDataSlice = createSlice({
  name: 'tournament',
  initialState: {
    showModal: null,
    visibleTab: null,
    selectedStageId: null
  } as UIDataStateShape,
  reducers: {
    showModal: (
      state: UIDataStateShape,
      { payload }: PayloadAction<TournamentBuilderModals>
    ) => setSafe(state, 'showModal', payload),
    showModalForStage: (
      state: UIDataStateShape,
      {
        payload
      }: PayloadAction<{ type: TournamentBuilderModals; stageId: string }>
    ) => {
      const { type, stageId } = payload
      const newState = {
        showModal: type,
        selectedStageId: stageId,
        visibleTab: state.visibleTab
      }
      return newState
    },
    hideModal: (state: UIDataStateShape) => {
      const newState = {
        showModal: null,
        selectedStageId: null,
        visibleTab: state.visibleTab
      }
      return newState
    },
    setVisibleTab: (
      state: UIDataStateShape,
      { payload }: PayloadAction<string>
    ) => setSafe(state, 'visibleTab', payload)
  } as Record<string, Reducer<UIDataStateShape, AnyPayloadAction>>,
  extraReducers: {
    [tournamentSlice.actions.addStage.type]: (
      state,
      { payload }: PayloadAction<AddStageParameters>
    ) => ({
      ...state,
      showModal: false,
      visibleTab: payload.inFlowEditMode ? 'overall' : payload.stageId
    }),
    [tournamentSlice.actions.editSlugForStage.type]: (state) => ({
      ...state,
      showModal: false
    }),
    [tournamentSlice.actions.deleteStage.type]: (
      state: UIDataStateShape,
      { payload }: PayloadAction<DeleteStageParameters>
    ) => {
      const isRemovedStageVisible = state.visibleTab === payload.stageId

      return {
        ...state,
        showModal: false,
        // If the removed stage is the currently selected one, select the first
        // stage so we don't render blank
        visibleTab: isRemovedStageVisible
          ? payload.stageIds.find((id) => id !== payload.stageId)
          : state.visibleTab
      }
    }
  } as Record<string, Reducer<UIDataStateShape, AnyPayloadAction>>
})

const copyTournamentSlice = createSlice({
  name: 'copyTournament',
  initialState: { copyTournamentId: undefined, copyTournamentSlug: undefined },
  reducers: {
    editCopyTournamentId: (
      state: CopyTournamentStateShape,
      { payload }: PayloadAction<string>
    ) => setSafe(state, 'copyTournamentId', payload),
    editCopyTournamentSlug: (
      state: CopyTournamentStateShape,
      { payload }: PayloadAction<string>
    ) => setSafe(state, 'copyTournamentSlug', payload)
  } as Record<string, Reducer<CopyTournamentStateShape, AnyPayloadAction>>
})

// Leaving open the possibility of adding additional things here
// e.g. some metadata
const combinedReducer = combineReducers({
  uiData: uiDataSlice.reducer,
  tournaments: tournamentSlice.reducer,
  copyTournament: copyTournamentSlice.reducer
})

export const {
  initNewTournament,
  copyForEdit,
  editTournamentSlug,
  addStage,
  addSection,
  addCircuitPoints,
  deleteStage,
  deleteSection,
  deletePoints,
  deleteCircuitPoints,
  deleteDecisionPoint,
  editSectionName,
  editSectionType,
  editSlugForStage,
  editNumberOfRounds,
  editNumberOfGamesPerBestOfMatch,
  editNumberOfGamesPerPlayAllMatch,
  editLeague,
  editSeason,
  editSplit,
  editTimezone,
  editStartTime,
  editEndTime,
  editStartDate,
  editEndDate,
  editParticipant,
  editNumberOfParticipants,
  editNumberOfStageParticipants,
  addColumnToSection,
  editNumberOfSectionParticipants,
  clearBracketFromSection,
  deleteColumnFromSection,
  addCellToColumn,
  deleteCellFromColumn,
  editCellTitle,
  addMatch,
  deleteMatch,
  editCircuitPoints,
  editStageEdge,
  editTournamentEdge,
  removeEdgesFromSectionForEntity,
  removeEdgesFromStageForSection,
  removeEdgesFromTournamentForStage,
  addDecisionPoint,
  editNumberOfDecisionPointParticipants,
  editDecisionPointDescription,
  editDecisionPointLabel,
  autogenerateAllStageEdgesInTournament,
  changeEdgesInTournament,
  changeEdgesInStage,
  changeEdgeInSection,
  removeEdgeFromSection,
  editMatchDescription,
  editGamesPlayedForMatch,
  editMatchStrategyType,
  editLocalizations,
  editStreamGroupId,
  editTournamentPublicationStatus,
  editTournamentDroppable,
  editTournamentRewardable,
  editStagesPublicationStatus,
  editSectionsPublicationStatus
} = tournamentSlice.actions

export const { showModal, showModalForStage, hideModal, setVisibleTab }
  = uiDataSlice.actions

export const { editCopyTournamentId, editCopyTournamentSlug }
  = copyTournamentSlice.actions

export default combinedReducer

// #endregion

// #region Selectors

type StateShape = ReturnType<typeof combinedReducer>

// #region Tournament

// Used to get the tournament slice.  Ensures that structural changes to the
// combinedReducer result in only a single change for all selectors.
const getTournaments = (
  state: StateShape
): ReturnType<typeof tournamentSlice.reducer> => state.tournaments

// This is just an example selector that makes use of getTournaments.  Expect
// this to be changed considerably or replaced entirely as the implementation
// evolves. This should illustrate the benefit of getTournaments() if you can
// imagine how changing StateShape would be simplified when this file contains
// a dozen or more selectors that rely on tournaments.
export const getWIPTournamentById = (
  state: StateShape,
  id: string
): ReducerShape => getSafe(getTournaments(state), id, {} as ReducerShape)

export const getFieldForWIPTournament
  = <K extends keyof NormalizedTournament>(key: string) =>
    (state: StateShape, tournamentId: string | null): NormalizedTournament[K] => {
      const model = getWIPTournamentById(state, tournamentId as string)
      const tournament = getSafe(model, 'tournament', {} as NormalizedTournament)

      return get(key)(tournament) as NormalizedTournament[K]
    }

export const getStageIdsForTournament = compose(
  (model: ReducerShape) => getSafe(model.tournament, 'stageIds', []),
  getWIPTournamentById
)

export const getWIPTournamentSlug = createSelector(
  [getWIPTournamentById],
  compose(
    (name: LocalizedStringsWithSlug) => getSafe(name, 'slug', null),
    (tournament: NormalizedTournament) => getSafe(tournament, 'name'),
    (model: ReducerShape) => getSafe(model, 'tournament')
  )
)

export const getStageForTournament = (
  state: StateShape,
  tournamentId: string,
  stageId: string
): NormalizedStage =>
  compose(
    (model: ReducerShape) =>
      getSafe(model.stages, stageId, {} as NormalizedStage),
    getWIPTournamentById
  )(state, tournamentId)

export const getSectionForTournament = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
): NormalizedSection =>
  compose(
    (model: ReducerShape) => model.sections[sectionId],
    getWIPTournamentById
  )(state, tournamentId)

export const getStandingForWIPTournament = (
  state: StateShape,
  tournamentId: string
): Lineup =>
  compose(
    (model: ReducerShape) => model.tournament.standing,
    getWIPTournamentById
  )(state, tournamentId)

export const getColumnForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number
): MatchColumn => {
  const section = getSectionForTournament(state, tournamentId, sectionId)

  return section.columns[columnId]
}

export const getMatchCellForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  columnId: number,
  cellId: number
): MatchCell => {
  const column = getColumnForWIPSection(
    state,
    tournamentId,
    sectionId,
    columnId
  )

  return column.cells[cellId]
}

export const getMatchForWIPTournament = createSelector(
  [getWIPTournamentById, (_state, _tournamentId, matchId) => matchId],
  (model, matchId) =>
    getSafe(model.matches, matchId, {} as Partial<MatchConfig>)
)

export const canDeleteColumnFromSection = createSelector(
  [
    getSectionForTournament,
    getColumnForWIPSection,
    (_state: any, _tournamentId: any, _sectionId: any, index: any) => index
  ],
  (section: NormalizedSection, column: MatchColumn, index: number) => {
    const isLastColumnInSection = index === section.columns.length - 1
    const isColumnEmpty = column.cells.length === 0
    return isLastColumnInSection && isColumnEmpty
  }
)
export const getCircuitPointsForWIPTournament = (
  state: StateShape,
  tournamentId: string
): StandingsPositionPoints[] =>
  compose(
    (model: ReducerShape) =>
      getSafe(
        model.tournament.points,
        'entries',
        null as unknown as StandingsPositionPoints[]
      ),
    getWIPTournamentById
  )(state, tournamentId)

export const getEdgeMapForSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
): EdgeMap => {
  const tournament = getWIPTournamentById(state, tournamentId)
  const section = getSafe(tournament.sections, sectionId)

  return buildEdgeMapForSection(tournament, section)
}

export const areLocalizationsInvalid = createSelector(
  [getWIPTournamentById],
  (model: ReducerShape) => {
    if (!model || !model.tournament || !model.tournament.name) return false

    return checkLocalizationsAreInvalid(model.tournament.name)
  }
)

export const isTournamentDirty = createSelector(
  [
    getWIPTournamentById,
    (_state: any, _tournamentId: any, tournament: any) => tournament
  ],
  (model: ReducerShape, tournament: TournamentType) => {
    // Use a fresh version of the tournament model for an existing tournament,
    // or use a fresh version of a new tournament for a non-exisiting tournament
    const compareTo = tournament ? mapTournamentToModel(tournament) : create()
    return !isEqual(model, compareTo, true)
  }
)

export const areTournamentRequiredFieldsMissing = createSelector(
  [getWIPTournamentById],
  (model: ReducerShape) => {
    if (model && model.tournament) {
      const requiredFields = [
        model.tournament.leagueId,
        model.tournament.name?.slug,
        model.tournament.seasonId,
        model.tournament.splitId
      ]

      return !requiredFields.every((field) => !fieldIsTruthy(field).isInvalid)
    }

    return true
  }
)

export const isTournamentFullyPublished = createSelector(
  [getWIPTournamentById],
  (model: ReducerShape) => {
    if (!model || !model.tournament) {
      return false
    }

    const getPublicationStatus = get<PublicationStatus>('publicationStatus')
    const stages = model.tournament.stageIds?.map(
      (stageId) => model.stages[stageId]
    )
    if (!stages) {
      return false
    }
    const sections = stages
      .flatMap(getSectionIdsFromStage)
      .map((sectionId) => model.sections[sectionId])

    const publicationStatus = [
      getPublicationStatus(model.tournament),
      ...stages.map(getPublicationStatus),
      ...sections.map(getPublicationStatus)
    ]

    return publicationStatus.every(
      (status) => status === PublicationStatus.Published
    )
  }
)

export const getDecisionPointsForWIPSection = (
  state: StateShape,
  tournamentId: string,
  sectionId: string
): Partial<DecisionPointConfig>[] => {
  const tournament = getWIPTournamentById(state, tournamentId)
  const section = getSafe(
    tournament.sections,
    sectionId,
    {} as NormalizedSection
  )

  return section.decisionPointIds.map((id) =>
    getSafe(tournament.decisionPoints, id)
  )
}

export const getDecisionPointForTournament = createSelector(
  [
    getWIPTournamentById,
    (_state, _tournamentId, decisionPointId) => decisionPointId
  ],
  (model, decisionPointId) => model.decisionPoints[decisionPointId]
)

export const getLabelForTournamentEdge = (
  state: StateShape,
  tournamentId: string,
  targetNodeId: string | null,
  targetSlot: number,
  abbrev?: boolean
): string => {
  const model = getWIPTournamentById(state, tournamentId)
  const edge = getEdgeTargeting(model.tournament, targetNodeId, targetSlot)

  if (edge) {
    if (edge.sourceNodeId) {
      const source = getStageForTournament(
        state,
        tournamentId,
        edge.sourceNodeId
      )
      return `${
        abbrev
          ? getShortStageLocalizationForSlug(source.name.slug)
          : getStageLocalizationForSlug(source.name.slug)
      } - ${toOrdinal(edge.sourceSlot)} Place`
    }

    return abbrev
      ? `TS - ${toOrdinal(edge.sourceSlot)} Seed`
      : `Tournament Seeding - ${toOrdinal(edge.sourceSlot)} Seed`
  }

  return 'TBD'
}

export const getLabelForStageEdge = (
  state: StateShape,
  tournamentId: string,
  stageId: string,
  targetNodeId: string,
  targetSlot: number
): string => {
  const stage = getStageForTournament(state, tournamentId, stageId)
  const edge = getEdgeTargeting(stage, targetNodeId, targetSlot)

  if (edge) {
    if (edge.sourceNodeId) {
      const source = getSectionForTournament(
        state,
        tournamentId,
        edge.sourceNodeId
      )
      return `${getSectionLocalizationForSlug(source.name.slug)} - ${toOrdinal(
        edge.sourceSlot
      )} Place`
    }

    return `${getStageLocalizationForSlug(stage.name.slug)} - ${toOrdinal(
      edge.sourceSlot
    )} Seed`
  }

  return 'TBD'
}

const getLabelForEdgeSource = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  edge: Edge
): string => {
  const section = getSectionForTournament(state, tournamentId, sectionId)

  if (!edge) {
    return 'TBD'
  }

  // If source is a Match
  if (edge.sourceNodeId && section.matchIds.includes(edge.sourceNodeId)) {
    const match = getMatchForWIPTournament(
      state,
      tournamentId,
      edge.sourceNodeId
    )
    const result = edge.sourceSlot === 1 ? 'Winner' : 'Loser'

    return `${result} of ${match.description}`
  }

  // If source is a Decision Point
  if (
    edge.sourceNodeId
    && section.decisionPointIds.includes(edge.sourceNodeId)
  ) {
    const decisionPoint = getDecisionPointForTournament(
      state,
      tournamentId,
      edge.sourceNodeId
    )

    const entry = decisionPoint?.standing?.entries.find(
      (entry) => entry.index === edge.sourceSlot
    )
    const description = entry?.description || toOrdinal(entry?.index || 0)

    return `${decisionPoint.description} - ${description}`
  }

  return `${getSectionLocalizationForSlug(section.name.slug)} - ${toOrdinal(
    edge.sourceSlot
  )} Seed`
}

export const getLabelForSectionEdge = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  targetNodeId: string,
  targetSlot: number
): string => {
  const section = getSectionForTournament(state, tournamentId, sectionId)
  const edge = getEdgeTargeting(section, targetNodeId, targetSlot)

  return getLabelForEdgeSource(state, tournamentId, sectionId, edge)
}

export const getOptionsForStandingSlot = (
  state: StateShape,
  tournamentId: string,
  sectionId: string,
  slot: number
): DropdownOption[] => {
  const section = getSectionForTournament(state, tournamentId, sectionId)
  const decisionPointsForSection = section.decisionPointIds?.map((id) =>
    getDecisionPointForTournament(state, tournamentId, id)
  )
  const possibleEdges = getPossibleEdgesForStanding(
    section,
    slot,
    decisionPointsForSection
  )

  return [createOption('TBD', null)].concat(
    possibleEdges.map((edge) =>
      createOption(
        getLabelForEdgeSource(state, tournamentId, sectionId, edge),
        JSON.stringify(edge)
      )
    )
  )
}

export const getOptionsForMatchSlot = (
  state: StateShape,
  tournamentId: string,
  matchId: string,
  slot: number
): DropdownOption[] => {
  const model = getWIPTournamentById(state, tournamentId)
  const section = Object.values(model.sections).find((section) =>
    section.matchIds.includes(matchId)
  ) as NormalizedSection
  const possibleEdges = getPossibleEdgesForMatchSlot(
    section,
    section.decisionPointIds.map((id) => model.decisionPoints[id]),
    matchId,
    slot
  )

  return [createOption('TBD', null)].concat(
    possibleEdges.map((edge) =>
      createOption(
        getLabelForEdgeSource(state, tournamentId, section.structuralId, edge),
        JSON.stringify(edge)
      )
    )
  )
}

export const getOptionsForDecisionPointSlot = (
  state: StateShape,
  tournamentId: string,
  decisionPointId: string,
  slot: number
): DropdownOption[] => {
  const model = getWIPTournamentById(state, tournamentId)
  const section = Object.values(model.sections).find((section) =>
    section.decisionPointIds.includes(decisionPointId)
  ) as NormalizedSection
  const possibleEdges = getPossibleEdgesForDecisionPoint(
    section,
    decisionPointId,
    slot
  )

  return [createOption('TBD', null)].concat(
    possibleEdges.map((edge) =>
      createOption(
        getLabelForEdgeSource(state, tournamentId, section.structuralId, edge),
        JSON.stringify(edge)
      )
    )
  )
}

export const isWIPTournamentSummary = (state: StateShape, id: string) =>
  !getFieldForWIPTournament('seeding')(state, id)
  && !getFieldForWIPTournament('standing')(state, id)
  && !getFieldForWIPTournament('streamGroupId')(state, id)
  && !getFieldForWIPTournament('points')(state, id)
  && !getFieldForWIPTournament('splitId')(state, id)

// #endregion

// #region UI Data
export const getUIData = (
  state: StateShape
): ReturnType<typeof uiDataSlice.reducer> => state.uiData

const getStagesForTournament = (state: StateShape, tournamentId: string) =>
  getStageIdsForTournament(state, tournamentId).map((stageId) =>
    getStageForTournament(state, tournamentId, stageId)
  )

export const getStageThatContainsSection = createSelector(
  [
    getStagesForTournament,
    (_state: StateShape, _tournamentId: string, sectionId: string) => sectionId
  ],
  (stages: NormalizedStage[], sectionId: string) =>
    stages.find((stage) => stage.sectionIds.includes(sectionId))
)

// #endregion

// #region Copy Tournament
export const getCopyTournamentData = (
  state: StateShape
): ReturnType<typeof copyTournamentSlice.reducer> => state.copyTournament
// #endregion

// #endregion
