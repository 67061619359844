import StreamTimeline from './StreamTimeline'
import { createThunk } from '../../store/actions/utils'

// #region Action Creators
export const getAllTournamentAdmin = createThunk(
  'streamTimeline/getAllTournamentAdmin',
  StreamTimeline.fetchTournamentConfig
)
export const getTournamentAdmin = createThunk(
  'streamTimeline/getTournamentAdmin',
  StreamTimeline.fetchTournamentConfigForTournament
)
export const createTournamentAdmin = createThunk(
  'streamTimeline/createTournamentAdmin',
  StreamTimeline.putTournamentAdmin
)

// #endregion
