import { DropFilterOptions, DropStrategy, MetaDTO } from './Meta.type'
import { createSlice, isLoading } from '../../../../actions/utils'
import { Action, AnyPayloadAction, PayloadAction } from '../../../../Store.type'
import {
  clearWIPGroup,
  cloneGroupAction,
  createNewGroupSuccessAction,
  editDropStrategyAction
} from '../../../../actions/Drops/Drops.actions'
import { isEqual } from '../../../../../commons'
import { GroupResponseDTO } from '@riotgames/api-types/drops/DropsV2.type'
import {
  editCardOverlaySponsorImageURL,
  editCardProgramImageURL,
  editNotificationProgramImageURL,
  editNotificationSponsorImageURL
} from '../Group/Group'
import { combineReducers, Reducer } from 'redux'

const initialState: MetaDTO = {
  dropStrategy: DropStrategy.Default,
  dropFilterOptions: {
    league: null,
    sponsor: null,
    search: null
  },
  editGroupID: null
}

const { reducer, actions } = createSlice({
  name: 'meta',
  initialState: initialState,
  reducers: {
    initialize: () => initialState,
    clear: () => initialState,
    clearFilters: (state) => ({
      ...state,
      dropFilterOptions: {
        league: null,
        sponsor: null,
        search: null
      }
    }),
    editFilters: (state, { payload }: Action<Partial<DropFilterOptions>>) => ({
      ...state,
      dropFilterOptions: Object.assign(
        { league: null, sponsor: null, search: null },
        state?.dropFilterOptions,
        payload
      )
    }),
    clearEditGroupID: (state) => ({
      ...state,
      editGroupID: null
    }),
    editEditGroupID: (state, { payload }: Action<string>) => ({
      ...state,
      editGroupID: payload
    })
  },
  extraReducers: {
    [clearWIPGroup.type]: () => initialState,
    [createNewGroupSuccessAction.type]: () => initialState,
    [editDropStrategyAction.type]: (
      state: MetaDTO,
      { payload }: PayloadAction<{ dropStrategy: DropStrategy }>
    ) => ({
      ...state,
      dropStrategy: payload.dropStrategy
    }),
    [cloneGroupAction.type]: (
      state: MetaDTO,
      { payload }: PayloadAction<{ group: GroupResponseDTO }>
    ) => {
      let strategy
      const drops = payload.group.orderedDrops
      if (!drops) return DropStrategy.Default
      if (drops.length === 2) {
        strategy = DropStrategy.Capped
      }
      else if (
        drops.length === 1
        && drops[0].cappedDropResponse != null
        && (drops[0].cappedDropResponse.quantity >= 0
          || drops[0].cappedDropResponse.percent >= 0)
      ) {
        strategy = DropStrategy.CappedOnly
      }
      else {
        strategy = DropStrategy.Default
      }

      return {
        ...state,
        dropStrategy: strategy
      }
    }
  } as Record<string, Reducer<MetaDTO, AnyPayloadAction>>
})

const combinedReducer = combineReducers({
  meta: reducer,
  editCardProgramImageUrlLoading: isLoading(editCardProgramImageURL, false),
  editCardOverlaySponsorImageUrlLoading: isLoading(
    editCardOverlaySponsorImageURL,
    false
  ),
  editNotificationSponsorImageUrlLoading: isLoading(
    editNotificationSponsorImageURL,
    false
  ),
  editNotificationProgramImageUrlLoading: isLoading(
    editNotificationProgramImageURL,
    false
  ),
  editPresentedBySponsorImageUrlLoading: isLoading(
    editCardProgramImageURL,
    false
  )
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

const {
  initialize,
  clear,
  clearFilters,
  editFilters,
  clearEditGroupID,
  editEditGroupID
} = actions

export {
  initialize,
  clear,
  clearFilters,
  editFilters,
  clearEditGroupID,
  editEditGroupID
}

export const getWIPMeta = (state: StateShape): MetaDTO => state.meta
export const isMetaDirty = (state: StateShape): boolean =>
  !isEqual(state.meta, initialState)

export const isImageUploading = (state: StateShape): boolean =>
  state.editCardOverlaySponsorImageUrlLoading
  || state.editCardProgramImageUrlLoading
  || state.editNotificationProgramImageUrlLoading
  || state.editNotificationSponsorImageUrlLoading
  || state.editPresentedBySponsorImageUrlLoading
