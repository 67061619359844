import { useEffect, useState } from 'react'
import styles from './GridOverlay.scss'
import { State } from './GridOverlay.type'

const GridOverlay = () => {
  const [hideGrid, setHideGrid] = useState<State>({
    hide: true
  })
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // shift + G key
      if (event.shiftKey && event.which === 71) {
        setHideGrid({ hide: !hideGrid.hide })
      }
    }
    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [hideGrid])

  const columns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
    <div
      key={ index }
      style={ { gridColumn: `col-start ${index}` } }
      className={ styles.column }
    />
  ))
  if (hideGrid.hide) {
    return null
  }
  return (
    <div data-testid="grid-overlay" className={ styles.grid }>
      { columns }
    </div>
  )
}

export default GridOverlay
