import { combineReducers } from 'redux'
import groupReducer, * as fromGroup from './Group/Group'
import inventoryItemReducer, * as fromInventoryItem from './InventoryItem/DropsInventoryItem'
import rarityReducer, * as fromRarity from './Rarity/DropsRarity'
import groupLocalizationReducer, * as fromGroupLocalization from './Localization/GroupLocalization'
import inventoryLocalizationReducer, * as fromInventoryLocalization from './Localization/InventoryLocalization'
import fulfillmentStatusDefaultDescriptionsReducer, * as fromFulfillmentStatusDefaultDescriptions from './Localization/FulfillmentStatusDefaultDescriptions'
import triggerReducer, * as fromTrigger from './Trigger/Trigger'
import metaReducer, * as fromMeta from './Meta/Meta'
import individualTriggerMetaReducer, * as fromIndividualTriggerMeta from './Meta/IndividualTriggerMeta'
import promoCodeReducer, * as fromPromoCodeContext from './PromoCodes/PromoCodes'
import { DropRequestDTO } from '@riotgames/api-types/drops/DropsV2.type'
import rarityMetaReducer, * as fromRarityMeta from './Meta/RarityMeta'
import triggerMetaReducer, * as fromTriggerMeta from './Meta/TriggerMeta'

const combinedReducer = combineReducers({
  trigger: triggerReducer,
  group: groupReducer,
  groupLocalization: groupLocalizationReducer,
  inventoryLocalization: inventoryLocalizationReducer,
  fulfillmentStatusDefaultDescriptions: fulfillmentStatusDefaultDescriptionsReducer,
  inventoryItem: inventoryItemReducer,
  rarity: rarityReducer,
  meta: metaReducer,
  rarityMeta: rarityMetaReducer,
  individualTriggerMeta: individualTriggerMetaReducer,
  triggerMeta: triggerMetaReducer,
  promoCodeContext: promoCodeReducer
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

export const getWIPInventoryItem = (state: StateShape) =>
  fromInventoryItem.getWIPInventoryItem(state.inventoryItem)

export const getWIPPromoCodeContext = (state: StateShape) =>
  fromPromoCodeContext.getWIPPromoCodeContext(state.promoCodeContext)

export const isInventoryItemDirty = (state: StateShape) =>
  fromInventoryItem.isInventoryItemDirty(state.inventoryItem)

export const isInventoryItemChampionSkin = (state: StateShape) =>
  fromInventoryItem.isInventoryItemChampionSkin(state.inventoryItem)

export const getWIPRarity = (state: StateShape) =>
  fromRarity.getWIPRarity(state.rarity)

export const isRarityDirty = (state: StateShape) =>
  fromRarity.isRarityDirty(state.rarity)

export const getWIPTrigger = (state: StateShape) =>
  fromTrigger.getWIPTrigger(state.trigger)

export const getWIPGroup = (state: StateShape) =>
  fromGroup.getWIPGroup(state.group)
export const getWIPFirstDrop = (state: StateShape) =>
  fromGroup.getWIPFirstDrop(state.group)
export const getWIPFirstDropField = <K extends keyof DropRequestDTO>(
  state: StateShape,
  key: K
) => fromGroup.getWIPFirstDropField(state.group, key)

export const getWIPDropByID = (state: StateShape, id: string | null) =>
  fromGroup.getWIPDropByID(state.group, id)

export const getWIPGroupGeolocations = (state: StateShape) =>
  fromGroup.getWIPGroupGeolocations(state.group)
export const getWIPGroupDrops = (state: StateShape) =>
  fromGroup.getWIPGroupDrops(state.group)

export const isDropCapValid = (state: StateShape) =>
  fromGroup.isDropCapValid(state.group)

export const getWIPGroupTitleLocalizations = (state: StateShape) =>
  fromGroupLocalization.getWIPTitleLocalizations(state.groupLocalization)

export const getWIPGroupDescriptionLocalizations = (state: StateShape) =>
  fromGroupLocalization.getWIPDescriptionLocalizations(state.groupLocalization)

export const getWIPInventoryTitleLocalizations = (state: StateShape) =>
  fromInventoryLocalization.getWIPTitleLocalizations(
    state.inventoryLocalization
  )

export const getWIPInventoryDescriptionLocalizations = (state: StateShape) =>
  fromInventoryLocalization.getWIPDescriptionLocalizations(
    state.inventoryLocalization
  )

export const getFulfillmentStatusDefaultDescriptions = (state: StateShape) =>
  fromFulfillmentStatusDefaultDescriptions.getDefaultDescriptions(
    state.fulfillmentStatusDefaultDescriptions
  )

export const getFetchingDefaultDescription = (state: StateShape) =>
  fromFulfillmentStatusDefaultDescriptions.isFetchingDefaultDescription(
    state.fulfillmentStatusDefaultDescriptions
  )

export const getWIPRarityMeta = (state: StateShape) =>
  fromRarityMeta.getWIPRarityMeta(state.rarityMeta)

export const getWIPMeta = (state: StateShape) => fromMeta.getWIPMeta(state.meta)
export const getWIPIndividualTriggerMeta = (state: StateShape) =>
  fromIndividualTriggerMeta.getWIPIndividualTriggerMeta(
    state.individualTriggerMeta
  )
export const getWIPTriggerMeta = (state: StateShape) =>
  fromTriggerMeta.getWIPTriggerMeta(state.triggerMeta)

export const isImageUploading = (state: StateShape) =>
  fromMeta.isImageUploading(state.meta)
export const isGroupDirty = (state: StateShape) =>
  fromGroup.isGroupDirty(state.group)
export const isDropLocalizationDirty = (state: StateShape) =>
  fromGroupLocalization.isLocalizationDirty(state.groupLocalization)
export const isInventoryLocalizationDirty = (state: StateShape) =>
  fromInventoryLocalization.isLocalizationDirty(state.inventoryLocalization)
export const isMetaDirty = (state: StateShape) =>
  fromMeta.isMetaDirty(state.meta)
