import { League } from '@riotgames/api-types/elds/Leagues.type'
import {
  LeagueConfigurationStatus,
  PermissionsResponseAccessLevel
} from '../../services/BannersService/BannersService.type'
import { Action } from '../../store/Store.type'

// TODO: export from common types repo w/ real DTO types
export type BannerConfigurationDTO = {
  league: string | null
  patch: string | null
  banners: Record<string, Banner>
}

export type Banner = {
  texture: string | null
  name: string
  group: number | null
}

export type BannerGameInfo = {
  x: number
  z: number
  id: string
  name: string
  targetName: string
  attributes: BannerGameAttributes
}

export type BannerGameAttributes = {
  location: BannerLocation
  locationMeta: BannerLocationMeta
  type: BannerType
  number: number
}

export enum BannerLocation {
  BotLane = 'Bot Lane',
  MidLane = 'Mid Lane',
  TopLane = 'Top Lane',
  Base = 'Base',
  Baron = 'Baron',
  Dragon = 'Dragon',
  Fountain = 'Fountain',
  Jungle = 'Jungle',
  BaseGate = 'Base Gate',
}

export enum BannerLocationMeta {
  BlueSide = 'Blue Side',
  RedSide = 'Red Side',
  East = 'East',
  West = 'West',
  South = 'South',
  North = 'North',
  Alcove = 'Alcove',
}

export enum BannerType {
  Hero = 'Hero',
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
  VerticalThin = 'Vertical Thin',
}

export type BannerTextureUpdatePayload = {
  texture: string | null
  groupIndex: number
}

export type FlattenedBannerConfig = {
  id: string
  leagueId: string
  patch: string
  status: LeagueConfigurationStatus
  version: string
  url: string
}
