import { RarityRequestDTO } from '@riotgames/api-types/drops/DropsV2.type'
import { isEqual } from '../../../../../commons'
import { Action } from '../../../../Store.type'
import { createSlice } from '../../../../actions/utils'
import { resetRarity } from '../../../../actions/Drops/Drops.actions'
import { RarityType } from '@riotgames/api-types/drops/Common.type'

const initialState: Partial<RarityRequestDTO> = {
  name: undefined,
  league: undefined,
  type: RarityType.Common as unknown as string,
  style: undefined,
  badgeForegroundColor: undefined,
  badgeBackgroundColor: undefined,
  notificationColorOne: undefined,
  notificationColorTwo: undefined
}

const generateStyle = (state: Partial<RarityRequestDTO>) =>
  state.notificationColorOne && state.notificationColorTwo
    ? `background: radial-gradient(circle, ${state.notificationColorOne} 3%, ${state.notificationColorTwo} 60%);`
    : undefined

const { reducer, actions } = createSlice({
  name: 'rarity',
  initialState: initialState,
  reducers: {
    editName: (state, { payload }: Action<string>) => ({
      ...state,
      name: payload
    }),
    editLeague: (state, { payload }: Action<string>) => ({
      ...state,
      league: payload
    }),
    editType: (state, { payload }: Action<string>) => ({
      ...state,
      type: payload
    }),
    editStyle: (state, { payload }: Action<string>) => ({
      ...state,
      style: payload
    }),
    editBadgeForegroundColor: (state, { payload }: Action<string>) => ({
      ...state,
      badgeForegroundColor: payload
    }),
    editBadgeBackgroundColor: (state, { payload }: Action<string>) => ({
      ...state,
      badgeBackgroundColor: payload
    }),
    editNotificationColorOne: (state, { payload }: Action<string>) => {
      const newState = { ...state, notificationColorOne: payload }
      return { ...newState, style: generateStyle(newState) }
    },
    editNotificationColorTwo: (state, { payload }: Action<string>) => {
      const newState = { ...state, notificationColorTwo: payload }
      return { ...newState, style: generateStyle(newState) }
    }
  },
  extraReducers: {
    [resetRarity.type]: () => initialState
  }
})

export default reducer
const {
  editName,
  editLeague,
  editType,
  editStyle,
  editBadgeForegroundColor,
  editBadgeBackgroundColor,
  editNotificationColorOne,
  editNotificationColorTwo
} = actions
export {
  editName,
  editLeague,
  editType,
  editStyle,
  editBadgeForegroundColor,
  editBadgeBackgroundColor,
  editNotificationColorOne,
  editNotificationColorTwo
}

export const getWIPRarity = (
  state: Partial<RarityRequestDTO>
): Partial<RarityRequestDTO> => state
export const isRarityDirty = (state: Partial<RarityRequestDTO>): boolean =>
  !isEqual(state, initialState)
