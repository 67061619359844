import { ReactNode, useState } from 'react'
import { classNames } from '../../commons/'
import styles from './Accordion.scss'

type Props = {
  children?: ReactNode
  title: JSX.Element | string
  onChange?: (newState: boolean) => void
  open?: boolean
  grey?: boolean
  class?: string
  titleClassName?: string
}

const Accordion = (props: Props): JSX.Element => {
  const [open, setOpen] = useState(props.open !== false)

  const onClick = () => {
    props.onChange ? props.onChange(!props.open) : setOpen(!open)
  }

  const {
    onChange,
    children,
    title,
    grey,
    class: classNameFromProps,
    titleClassName,
    ...rest
  } = props
  const shouldRender = onChange ? props.open : open
  const classes = classNames(styles.accordion, classNameFromProps)
  const containerClasses = classNames(
    styles.container,
    !shouldRender && styles.hide
  )

  const colorClass = grey ? styles.grey : styles.white
  const titleClasses = classNames(
    styles.title,
    shouldRender ? styles.open : styles.closed,
    colorClass,
    titleClassName
  )

  return (
    <div { ...rest } className={ classes }>
      <div data-testid="accordion" onClick={ onClick } className={ titleClasses }>
        { title }
      </div>
      <div className={ containerClasses }>{ children }</div>
    </div>
  )
}

export default Accordion
