import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { createSlice, isLoading } from '../../store/actions/utils'
import { PayloadAction } from '../../store/Store.type'
import {
  fetchEvents,
  getGameStartFrame,
  getFirstFrame
} from './LiveStatsApi.actions'
import {
  StatsWindow,
  StatsFrame
} from '@riotgames/api-types/events-api/aggregates-api.type'

// #region Reducers
const currentLivestatsSlice = createSlice({
  name: 'currentLivestats',
  initialState: {} as Record<string, StatsWindow>,
  extraReducers: {
    [fetchEvents.success.type]: (
      state,
      { payload }: PayloadAction<StatsWindow>
    ) => ({
      ...state,
      [payload?.esportsGameId]: payload
    })
  }
})

const firstEventFrameSlice = createSlice({
  name: 'firstEventFrame',
  initialState: {} as Record<string, StatsFrame | null>,
  extraReducers: {
    [getFirstFrame.success.type]: (
      state,
      { payload }: PayloadAction<StatsWindow>
    ) => {
      const firstFrame = payload.frames ? payload.frames[0] : null
      return {
        ...state,
        [payload?.esportsGameId]: firstFrame
      }
    }
  }
})

const gameStartFrameSlice = createSlice({
  name: 'gameStartFrameSlice',
  initialState: {} as Record<string, StatsFrame | null | undefined>,
  extraReducers: {
    [getGameStartFrame.success.type]: (
      state,
      { payload }: PayloadAction<StatsWindow>
    ) => {
      const gameStartFrame = payload.frames
        ? payload.frames.find(
          (frame: StatsFrame) =>
            frame.blueTeam.totalGold !== 0 || frame.redTeam.totalGold !== 0
        )
        : null

      return {
        ...state,
        [payload?.esportsGameId]: gameStartFrame
      }
    }
  }
})

const isLoadingReducer = combineReducers({
  isLivestatsLoading: isLoading(fetchEvents),
  isFirstFrameLoading: isLoading(getFirstFrame),
  isGameStartFrameLoading: isLoading(getGameStartFrame)
})

const combinedReducer = combineReducers({
  firstEventFrame: firstEventFrameSlice.reducer,
  currentLivestats: currentLivestatsSlice.reducer,
  gameStartFrame: gameStartFrameSlice.reducer,
  isLoading: isLoadingReducer
})

export default combinedReducer
// #endregion

// #region Selectors
type StateShape = ReturnType<typeof combinedReducer>
const getCurrentLivestats = (state: StateShape) => state.currentLivestats

export const getCurrentLivestatsById = (
  state: StateShape,
  id: string
): StatsFrame | null => {
  const currentData = state.currentLivestats[id]
  const frame
    = currentData && currentData.frames
      ? currentData.frames[currentData.frames.length - 1]
      : null
  return frame
}

export const getCurrentLivestatsParticipantIdToNameByGameId = createSelector(
  [getCurrentLivestats, (_: StateShape, id: string) => id],
  (getCurrentLivestats, id) => {
    const currentGameData = getCurrentLivestats[id]?.gameMetadata
    if (currentGameData) {
      const participantsData = [
        ...currentGameData.blueTeamMetadata.participantMetadata,
        ...currentGameData.redTeamMetadata.participantMetadata
      ]
      return participantsData.reduce(
        (acc: Record<string, string>, participantData) => {
          acc[participantData.participantId] = participantData.summonerName
          return acc
        },
        {}
      )
    }
  }
)

export const getFirstEventFrameById = (
  state: StateShape,
  id: string
): StatsFrame | null => state.firstEventFrame[id]

export const getGameStartTimeById = (
  state: StateShape,
  id: string
): string | undefined => state.gameStartFrame[id]?.rfc460Timestamp
// #endregion

// #region Loading
export const isFirstFrameLoading = (state: StateShape): boolean =>
  state.isLoading.isFirstFrameLoading

export const isGameStartFrameLoading = (state: StateShape): boolean =>
  state.isLoading.isGameStartFrameLoading
// #endregion
