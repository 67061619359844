import { isEqual, set } from '../../../../../commons'
import { createSlice, isLoading } from '../../../../actions/utils'
import { Action } from '../../../../Store.type'
import { Organization } from '@riotgames/api-types/elds/Organizations.type'
import { RecordStatus } from '@riotgames/api-types/elds/Common.type'
import {
  clearWIPOrganization,
  createNewOrganizationAction
} from '../../../../actions/Organizations/Organizations.actions'
import { uploadAsset } from '../../../../../services/AwsS3/AwsS3'
import { AnyAction, combineReducers, Reducer } from 'redux'

const UPLOAD_DIR = 'organizations'

const initialState: DeepPartial<Organization> = {
  id: null,
  name: null,
  tricode: null,
  logoBundle: {
    lightLogo: null,
    darkLogo: null,
    altLightLogo: null,
    altDarkLogo: null,
    plateImage: null,
    backgroundImage: null
  },
  status: RecordStatus.Active,
  primaryColor: null,
  secondaryColor: null
}

const editLightLogo = uploadAsset('organizations/editLightLogo', UPLOAD_DIR)
const editDarkLogo = uploadAsset('organizations/editDarkLogo', UPLOAD_DIR)
const editAltLightLogo = uploadAsset(
  'organizations/editAltLightLogo',
  UPLOAD_DIR
)
const editAltDarkLogo = uploadAsset('organizations/editAltDarkLogo', UPLOAD_DIR)
const editBackgroundImage = uploadAsset(
  'organizations/editBackgroundImage',
  UPLOAD_DIR
)
const editPlateImage = uploadAsset('organizations/editPlateImage', UPLOAD_DIR)

const { reducer, actions } = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    initialize: (state, { payload }) => payload || initialState,
    clear: () => initialState,
    editName: (state, { payload }: Action<string>) =>
      ({
        ...state,
        name: payload
      } as Organization),
    editTricode: (state, { payload }: Action<string>) =>
      ({
        ...state,
        tricode: payload
      } as Organization),
    toggleStatus: (state) =>
      ({
        ...state,
        status:
          state?.status === RecordStatus.Active
            ? RecordStatus.Archived
            : RecordStatus.Active
      } as Organization)
  },
  extraReducers: {
    [createNewOrganizationAction.success.type]: () => initialState,
    [clearWIPOrganization.type]: () => initialState,
    [editLightLogo.success.type]: (state, { payload }: Action<string>) => ({
      ...state,
      logoBundle: set('lightLogo')(state?.logoBundle, payload)
    }),
    [editDarkLogo.success.type]: (state, { payload }: Action<string>) => ({
      ...state,
      logoBundle: set('darkLogo')(state?.logoBundle, payload)
    }),
    [editAltLightLogo.success.type]: (state, { payload }: Action<string>) => ({
      ...state,
      logoBundle: set('altLightLogo')(state?.logoBundle, payload)
    }),
    [editAltDarkLogo.success.type]: (state, { payload }: Action<string>) => ({
      ...state,
      logoBundle: set('altDarkLogo')(state?.logoBundle, payload)
    }),
    [editBackgroundImage.success.type]: (
      state,
      { payload }: Action<string>
    ) => ({
      ...state,
      logoBundle: set('backgroundImage')(state?.logoBundle, payload)
    }),
    [editPlateImage.success.type]: (state, { payload }: Action<string>) => ({
      ...state,
      logoBundle: set('plateImage')(state?.logoBundle, payload)
    })
  } as Record<string, Reducer<Organization, AnyAction>>
})

const combinedReducer = combineReducers({
  organization: reducer,
  isLightLogoLoading: isLoading(editLightLogo, false),
  isDarkLogoLoading: isLoading(editDarkLogo, false),
  isAltLightLogoLoading: isLoading(editAltLightLogo, false),
  isAltDarkLogoLoading: isLoading(editAltDarkLogo, false),
  isBackgroundImageLoading: isLoading(editBackgroundImage, false),
  isPlateImageLoading: isLoading(editPlateImage, false)
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

const { initialize, clear, editName, editTricode, toggleStatus } = actions

export {
  initialize,
  clear,
  editName,
  editTricode,
  toggleStatus,
  editLightLogo,
  editDarkLogo,
  editAltLightLogo,
  editAltDarkLogo,
  editBackgroundImage,
  editPlateImage
}

export const getWIPOrganization = (state: StateShape): Organization =>
  state.organization

export const isOrganizationImageUploading = (state: StateShape): boolean =>
  state.isLightLogoLoading
  || state.isDarkLogoLoading
  || state.isAltLightLogoLoading
  || state.isAltDarkLogoLoading
  || state.isBackgroundImageLoading
  || state.isPlateImageLoading

export const isOrganizationDirty = (state: StateShape): boolean =>
  !isEqual(state.organization, initialState)
