import permit from '@permitio/permit-js'
import { useEffect, useState } from 'react'
import SingleSignOn from '../../services/SingleSignOn/SingleSignOn'
import styles from './Invitation.scss'
// eslint-disable-next-line camelcase
import { OktaJwtPayload } from 'components/AuthComponent/utils'
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import errorImage from '../../assets/svg/error.svg'
import { getSessionStorageData, setSessionStorageData } from '../../commons'
import Logger from '../../commons/Logger/Logger'
import Loading from '../../components/Loading/Loading'

interface Props {
  inviteCode?: string
  envId: string
}

const log = new Logger('Invitation')
const defaultRedirectDelayMs = 3000
const defaultApprovalWaitDelayMs = 5000
const Invitation = (props: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [approvalFailed, setApprovalFailed] = useState<boolean>(false)
  const [approved, setApproved] = useState<boolean>(false)
  const navigate = useNavigate()

  const renderApprovalFailed = () => (
    <div className={ styles.invitation }>
      <div className={ styles.approvalFailed }>
        <img className={ styles.error } src={ errorImage }/>
        <div className={ styles.title }>User Registration Failed</div>
        <div className={ styles.description }>
          Reach out to #ask-esports-digital if you need access to this page.
        </div>
      </div>
    </div>
  )

  const renderApproved = () => (
    <div className={ styles.invitation }>
      <div className={ styles.approved }>
        <div className={ styles.title }>Approved</div>
        <div className={ styles.description }>
          Redirecting to default page in 3 seconds...
        </div>
      </div>
    </div>
  )

  const timeout = (ms: number) =>
    new Promise((_, reject) => {
      setTimeout(() => {
        reject()
      }, ms)
    })

  const approve = (
    idToken: any,
    email: any,
    inviteCode: string,
    envId: string
  ) =>
    permit.elements.approve({
      inviteCode,
      envId,
      email,
      token: idToken,
      user_key_claim: 'preferred_username'
    } as any)

  const inviteCode = props.inviteCode ?? getSessionStorageData('invite_code')
  const { envId } = props

  // auto redirect upon approval success
  useEffect(() => {
    const timer = setTimeout(() => {
      if (approved) {
        log.info('(@) Redirect')
        navigate('/leagues/list/current')
      }
    }, defaultRedirectDelayMs)
    return () => clearTimeout(timer)
  }, [approved])

  useEffect(() => {
    const approveUser = async () => {
      const user = await SingleSignOn.getUser()
      if (user && inviteCode) {
        const decoded = jwtDecode<OktaJwtPayload>(user?.id_token)
        const { preferred_username: email } = decoded
        log.info('(@) Reaching Permit IO.....')
        log.info('(@) Invitation code: ', inviteCode)
        await Promise.race([
          approve(user?.id_token, email, inviteCode as string, envId),
          timeout(defaultApprovalWaitDelayMs)
        ])
          .then(() => {
            log.info('(@) Approved')
            setApproved(true)
            setApprovalFailed(false)
          })
          .catch((error: Error) => {
            log.error('(@) Failed to approve', error)
            setApprovalFailed(true)
          })
          .finally(() => {
            setSessionStorageData('invite_code', null)
            setIsLoading(false)
          })
      }
      else {
        setApprovalFailed(true)
        setIsLoading(false)
      }
    }
    approveUser()
  }, [])

  const render = () => {
    if (approvalFailed) {
      return renderApprovalFailed()
    }
    if (isLoading) {
      return <Loading/>
    }
    return <div className={ styles.invitation }>{ renderApproved() }</div>
  }
  return <>{ render() }</>
}

export default Invitation
