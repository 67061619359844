import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Logger, setSessionStorageData } from '../../commons'
import { SingleSignOn } from '../../services'
import Loading from '../Loading/Loading'

const defaultHomePage = '/tournament/list/current'

declare global {
  interface Window {
    route: (path: string, options?: { replace?: boolean }) => void
  }
}

const AuthNavigator = (props: {
  children?: React.ReactNode
  log: Logger
}): JSX.Element => {
  const { children, log } = props
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const onRouteChanged = async (): Promise<void> => {
    if (SingleSignOn.isCallbackUrl()) {
      return SingleSignOn.handleAuthenticationResponse()
    }
    else {
      const didSuccssfullyAuth = await SingleSignOn.isAuthenticated().catch(
        (error) => {
          log.error(error)
        }
      )

      if (window.location.pathname && window.location.pathname.length) {
        setSessionStorageData(
          'current_page_redirect',
          window.location.pathname
            + (window.location.search ? window.location.search : '')
        )
      }
      else {
        setSessionStorageData('current_page_redirect', defaultHomePage)
      }
      if (!didSuccssfullyAuth) {
        return await SingleSignOn.authenticate().catch((reason) => {
          log.error(
            `An error occurred attempting to authenticate via SingleSignOn, reason=${reason}`
          )
        })
      }

      setIsAuthenticated(didSuccssfullyAuth)
      return Promise.resolve()
    }
  }

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    onRouteChanged()
  }, [location.pathname, location.search])

  // Hack to make the navigate function available to the window object
  // used to perform navigations inside actions
  // TODO: Once we refactor the router components to functional, we can get rid of this
  useEffect(() => {
    window.route = function (path, options) {
      navigate(path, options)
    }
  }, [])
  return <>{ isAuthenticated ? children : <Loading/> }</>
}

export default AuthNavigator
