import { classNames, Config } from '../../commons'
import styles from './LowerEnvironmentIndicator.scss'
import { Envs } from '@riotgames/commons/env'
import { useState } from 'react'
import Confirm from '../Confirm/Confirm'
import spacetime from 'spacetime'

/**
 * Component to render a fixed colored border around the app to indicate that the user
 * is currently operating in a non-production environment.
 */
const LowerEnvironmentBorder = (): JSX.Element => {
  let environmentStyle
  let environmentText
  const shouldCloseBorder = document.cookie
    .split(';')
    .find((cookie) => cookie.includes('closeLowerEnvironmentBorder'))
    ?.split('=')[1]
  const [open, setOpen] = useState<boolean>(shouldCloseBorder !== 'true')
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  switch (Config.getEnv()) {
    case Envs.Test:
      environmentStyle = styles.test
      environmentText = 'TEST'
      break
    case Envs.Dev:
      environmentStyle = styles.dev
      environmentText = 'DEV'
      break
    case Envs.Local:
    default:
      environmentStyle = styles.local
      environmentText = 'LOCAL'
      break
  }

  if (!open) {
    return <></>
  }
  const setNoShowCookie = (expiryDate: Date): void => {
    document.cookie = `closeLowerEnvironmentBorder=true;expires=${expiryDate.toUTCString()};domain=emp.riotesports.com;path=/`
  }

  return (
    <>
      <div
        className={ classNames(styles.border, styles.top, environmentStyle) }
      />
      <div
        className={ classNames(styles.border, styles.right, environmentStyle) }
      />
      <div
        className={ classNames(styles.border, styles.bottom, environmentStyle) }
      />
      <div
        className={ classNames(styles.border, styles.left, environmentStyle) }
      />
      <div
        className={ classNames(styles.environmentIndicator, environmentStyle) }>
        { environmentText }
        <span className={ styles.close } onClick={ () => setShowConfirm(true) }>
          <svg className={ styles.svgIcon }/>
        </span>
      </div>
      { showConfirm && (
        <Confirm
          title={ `Hide Environment Indicator` }
          body={ `Are you sure you want to hide the environment indicator?` }
          extraActions={ [
            <button
              key="hideForToday"
              className={ styles.hideForSession }
              onClick={ () => {
                setOpen(false)
                setShowConfirm(false)
                setNoShowCookie(spacetime.now().add(1, 'day').toNativeDate())
              } }>
              Hide for today
            </button>
          ] }
          cancelText="No"
          confirmText="Hide for 1 month"
          onCancel={ (): void => {
            setShowConfirm(false)
          } }
          onConfirm={ () => {
            setOpen(false)
            setShowConfirm(false)
            setNoShowCookie(spacetime.now().add(1, 'month').toNativeDate())
          } }
        />
      ) }
    </>
  )
}

export default LowerEnvironmentBorder
