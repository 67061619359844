import { PromoCodeIngestType } from '@riotgames/api-types/drops/Common.type'
import { InventoryResponseDTO } from '@riotgames/api-types/drops/DropsV2.type'
import { createInventoryItem } from '../../../../../services/DropsApi/DropsApi.actions'
import { createSlice } from '../../../../actions/utils'
import { Action, PayloadAction } from '../../../../Store.type'

export type PromoCodeContext = {
  source: PromoCodeIngestType
  key?: string
  quantity?: number
  length?: number
  dropInventoryId?: string
}

const initialState: Partial<PromoCodeContext> = {
  source: PromoCodeIngestType.Upload,
  key: '',
  quantity: undefined,
  length: 8,
  dropInventoryId: ''
}

const { reducer, actions } = createSlice({
  name: 'promoCodeContext',
  initialState,
  reducers: {
    editSource: (state, { payload }: Action<PromoCodeIngestType>) => ({
      ...state,
      source: payload
    }),
    editKey: (state, { payload }: Action<string>) => ({
      ...state,
      key: payload
    }),
    editQuantity: (state, { payload }: Action<number>) => ({
      ...state,
      quantity: payload
    }),
    editLength: (state, { payload }: Action<number>) => ({
      ...state,
      length: payload
    }),
    resetPromoCodeContext: () => initialState
  },
  extraReducers: {
    [createInventoryItem.success.type]: (
      state,
      { payload }: PayloadAction<InventoryResponseDTO>
    ) => ({ ...state, dropInventoryId: payload.id })
  }
})

export default reducer
const {
  editSource,
  editKey,
  editQuantity,
  editLength,
  resetPromoCodeContext
} = actions

export { editSource, editKey, editQuantity, editLength, resetPromoCodeContext }

export const getWIPPromoCodeContext = (
  state: Partial<PromoCodeContext>
): Partial<PromoCodeContext> => state
