import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { EventBus } from '../../services/'
import {
  AccessError as AccessErrorType,
  addAccessError
} from '../../store/reducers/RBAC/AccessErrors/AccessErrors'

const AccessError = (): JSX.Element => {
  const dispatch = useDispatch()
  useEffect(() => {
    // Maps the old way of broadcasting events to the new redux way
    EventBus.on('accessError', (accessError: AccessErrorType) =>
      dispatch(addAccessError(accessError))
    )
  }, [])

  return <></>
}

export default AccessError
