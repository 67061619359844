import { createSlice } from '../../../../actions/utils'
import {
  BannerConfigurationDTO,
  BannerGameInfo,
  Banner,
  BannerTextureUpdatePayload
} from '../../../../../containers/Banners/Banners.type'
import { bannerData } from '../../../../../containers/Banners/Banners.data'
import { isEqual, cloneDeep, isNullOrUndefined } from '../../../../../commons'
import { createSelector } from 'reselect'
import { BannerGroup } from '../../../../../containers/Banners/Create/BannersCreate'
import { Action, AnyPayloadAction, PayloadAction } from '../../../../Store.type'
import { combineReducers, Reducer } from 'redux'
import {
  createNewConfig,
  clearWIPBannerConfig
} from '../../../../actions/Banners/Config/ConfigActions'

const applyBannerGroupTexture = (
  banners: Record<string, Banner>,
  payload: BannerTextureUpdatePayload
): Record<string, Banner> => {
  const newBanners = cloneDeep(banners)
  Object.keys(newBanners).forEach((key) => {
    if (newBanners[key].group === payload.groupIndex) {
      newBanners[key].texture = payload.texture
    }
  })

  return newBanners
}

const resetBannerTextures = (
  banners: Record<string, Banner>
): Record<string, Banner> => {
  const newBanners = cloneDeep(banners)
  Object.keys(newBanners).forEach((key) => {
    newBanners[key].texture = null
  })

  return newBanners
}

const initBanners = (bannerGameInfo: BannerGameInfo[]) => {
  const banners: Record<string, Banner> = {}
  bannerGameInfo.forEach((banner) => {
    banners[banner.id] = {
      name: banner.targetName,
      texture: null,
      group: null
    }
  })
  return banners
}

const initialState: BannerConfigurationDTO = {
  league: null,
  patch: null,
  banners: initBanners(bannerData)
}

const bannersSlice = createSlice({
  name: 'banners',
  initialState: initialState,
  reducers: {
    initialize: (
      state: BannerConfigurationDTO,
      { payload }: Action<BannerConfigurationDTO>
    ) => payload || initialState,
    editLeagueId: (state, { payload }: Action<string>) => ({
      ...state,
      league: payload
    }),
    editPatch: (state, { payload }: Action<string>) => ({
      ...state,
      patch: payload
    }),
    editBanners: (state, { payload }: Action<Record<string, Banner>>) => ({
      ...state,
      banners: payload
    }),
    editBannerGroupTexture: (
      state: BannerConfigurationDTO,
      { payload }: PayloadAction<BannerTextureUpdatePayload>
    ) => ({
      ...state,
      banners: applyBannerGroupTexture(state.banners, payload)
    }),
    resetAllTextures: (state: BannerConfigurationDTO) => ({
      ...state,
      banners: resetBannerTextures(state.banners)
    })
  } as Record<string, Reducer<BannerConfigurationDTO, AnyPayloadAction>>,
  extraReducers: {
    [clearWIPBannerConfig.type]: () => initialState
  }
})

const isUploadingSlice = createSlice({
  name: 'isUploading',
  initialState: false,
  reducers: {},
  extraReducers: {
    [createNewConfig.start.type]: () => true,
    [createNewConfig.success.type]: () => false,
    [createNewConfig.failed.type]: () => false
  }
})

const combinedReducer = combineReducers({
  bannerConfiguration: bannersSlice.reducer,
  isUploading: isUploadingSlice.reducer
})

export default combinedReducer

type StateShape = ReturnType<typeof combinedReducer>

// Renamed
const {
  initialize,
  editLeagueId,
  editPatch,
  editBanners,
  editBannerGroupTexture,
  resetAllTextures
} = bannersSlice.actions

export {
  initialize,
  editLeagueId,
  editPatch,
  editBanners,
  editBannerGroupTexture,
  resetAllTextures
}

export const getWIPBannerConfiguration = (
  state: StateShape
): BannerConfigurationDTO => state.bannerConfiguration

export const isBannerConfigurationDirty = (state: StateShape): boolean =>
  !isEqual(state.bannerConfiguration, initialState)

export const isBannerConfigurationUploading = (state: StateShape): boolean =>
  state.isUploading

const initializeGroups = (banners: Record<string, Banner>): BannerGroup[] => {
  const groups: BannerGroup[] = []
  if (!banners) return groups

  // find highest indexed group
  let maxGroupIndex = -1
  Object.keys(banners).forEach((id) => {
    const groupIndex = banners[id].group
    if (!isNullOrUndefined(groupIndex)) {
      maxGroupIndex = Math.max(groupIndex as number, maxGroupIndex)
    }
  })

  // create temporary holders for all groups up to the highest index
  for (let i = 0; i <= maxGroupIndex; i++) {
    groups.push({
      texture: null,
      banners: []
    })
  }

  // place banners in their corresponding group
  Object.keys(banners).forEach((id) => {
    const groupIndex = banners[id].group

    if (!isNullOrUndefined(groupIndex)) {
      if (banners[id].texture) {
        groups[groupIndex as number].texture = banners[id].texture
      }
      groups[groupIndex as number].banners.push(id)
    }
  })

  return groups
}

export const getBanners = (state: StateShape): Record<string, Banner> =>
  state.bannerConfiguration.banners

export const getBannerGroups = createSelector([getBanners], (banners) =>
  initializeGroups(banners)
)

export const wipConfigHasAssignedAssets = (state: StateShape): boolean =>
  !!Object.values(state.bannerConfiguration.banners).find(
    (banner) => banner.texture
  )
