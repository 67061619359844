/* eslint-disable max-lines */
import {
  BannerGameInfo,
  BannerConfigurationDTO,
  FlattenedBannerConfig,
  BannerLocation,
  BannerLocationMeta,
  BannerType
} from './Banners.type'
import { BannerGroup } from './Create/BannersCreate'
import {
  LeagueConfigurationStatus,
  League
} from '../../services/BannersService/BannersService.type'

// Pulled from etc/scripts/banners/export.json
// Beautified using https://csvjson.com/json_beautifier
export const bannerData: DeepPartial<BannerGameInfo[]> = [
  {
    x: 0.2790851607046999,
    z: 0.34466150120827427,
    id: '027a9808-d6a4-469e-9582-0497a69d2ee5',
    name: 'LevelProp_Srx_Banner_Vertical1',
    targetName: 'ORDER_MIDLANE_VERT_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 1,
      location: BannerLocation.MidLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.14052802435675224,
    z: 0.3156518309410406,
    id: '749c717a-0551-4532-8f43-6d36c763efdb',
    name: 'LevelProp_Srx_Banner_Horizontal1',
    targetName: 'ORDER_BASEGATE_WALL_HORIZONTAL_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 1,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: -0.02223523154101283,
    z: 0.05382918476448819,
    id: '9075c72a-55c2-4b5a-942d-0a455e4bef8e',
    name: 'LevelProp_Srx_Banner_Hero1',
    targetName: 'ORDER_FOUNTAIN_HERO_BANNER_1',
    attributes: {
      type: BannerType.Hero,
      number: 1,
      location: BannerLocation.Fountain,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.20795274932028884,
    z: 0.30102147131514817,
    id: '86eef37c-002b-42c6-a455-3d1f4b684ff4',
    name: 'LevelProp_Srx_Banner_Horizontal2',
    targetName: 'ORDER_BASEGATE_WALL_HORIZONTAL_BANNER_2',
    attributes: {
      type: BannerType.Horizontal,
      number: 2,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.2949818361337759,
    z: 0.21616032797613463,
    id: '5b0553aa-0c8d-4264-9ef2-9cad01d8cdff',
    name: 'LevelProp_Srx_Banner_Horizontal3',
    targetName: 'ORDER_BASEGATE_WALL_HORIZONTAL_BANNER_3',
    attributes: {
      type: BannerType.Horizontal,
      number: 3,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.3121633810232314,
    z: 0.14686070615301472,
    id: 'fe6e502b-1b55-4ced-abc6-a1faeb3e8483',
    name: 'LevelProp_Srx_Banner_Horizontal4',
    targetName: 'ORDER_BASEGATE_WALL_HORIZONTAL_BANNER_4',
    attributes: {
      type: BannerType.Horizontal,
      number: 4,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.3254918165724975,
    z: 0.14794367412484086,
    id: '410cbf4e-c81d-41bb-966e-c5947c41f198',
    name: 'LevelProp_Srx_Banner_Horizontal5',
    targetName: 'ORDER_BASEGATE_WALL_HORIZONTAL_BANNER_6',
    attributes: {
      type: BannerType.Horizontal,
      number: 5,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.3086328046957785,
    z: 0.22155344430737214,
    id: 'fbecc243-d6cc-4073-ada0-bb4fbd8b9598',
    name: 'LevelProp_Srx_Banner_Horizontal6',
    targetName: 'ORDER_BASEGATE_WALL_HORIZONTAL_BANNER_5',
    attributes: {
      type: BannerType.Horizontal,
      number: 6,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.3323637392284352,
    z: 0.41550662919961534,
    id: '17a3bd5d-1c81-4990-a012-af77d58a42cd',
    name: 'LevelProp_Srx_Banner_Vertical2',
    targetName: 'ORDER_MIDLANE_VERT_BANNER_2',
    attributes: {
      type: BannerType.Vertical,
      number: 2,
      location: BannerLocation.MidLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.03294331859353811,
    z: 0.1256731060979042,
    id: 'effd95ad-89c5-4396-a786-cbf4c99ef96a',
    name: 'LevelProp_Srx_Banner_Horizontal7',
    targetName: 'ORDER_BASE_HORIZ_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 7,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.03006199045633387,
    z: 0.2775867468635525,
    id: 'ab43cec0-28d0-4de2-9ecd-dbe383f12dfc',
    name: 'LevelProp_Srx_Banner_Horizontal8',
    targetName: 'ORDER_BASE_HORIZ_BANNER_3',
    attributes: {
      type: BannerType.Horizontal,
      number: 8,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.12403809151160398,
    z: 0.015938347544895454,
    id: 'f1652fa5-8597-4a9e-aaea-68fbdd0a6c3b',
    name: 'LevelProp_Srx_Banner_Horizontal9',
    targetName: 'ORDER_BASE_HORIZ_BANNER_4',
    attributes: {
      type: BannerType.Horizontal,
      number: 9,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.27568628469297923,
    z: 0.015938347544895454,
    id: '83bfe256-72f8-407d-b506-7ec7e11e099b',
    name: 'LevelProp_Srx_Banner_Horizontal10',
    targetName: 'ORDER_BASE_HORIZ_BANNER_6',
    attributes: {
      type: BannerType.Horizontal,
      number: 10,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.4318770623281362,
    z: 0.11687719873944057,
    id: 'dcfd7831-1a45-420a-b8d1-68c3a0a450cb',
    name: 'LevelProp_Srx_Banner_VerticalThin1',
    targetName: 'ORDER_BOTLANE_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 1,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.4957074060404148,
    z: 0.11702173131862267,
    id: '2161fef4-eb0e-4ac6-a73b-b4e79eaafb26',
    name: 'LevelProp_Srx_Banner_VerticalThin3',
    targetName: 'ORDER_BOTLANE_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 3,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.3727149394457874,
    z: 0.9647728103669847,
    id: 'b447f83b-6a2d-44c4-a13a-783f29e08f30',
    name: 'LevelProp_Srx_Banner_VerticalThin4',
    targetName: 'CHAOS_TOPLANE_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 4,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.4422392945200727,
    z: 0.9647478541712503,
    id: '8cc00d13-bb8f-47c4-b862-553fe79ea5e3',
    name: 'LevelProp_Srx_Banner_VerticalThin5',
    targetName: 'CHAOS_TOPLANE_VERT_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 5,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.6753844795094951,
    z: 0.12184708611523258,
    id: '2abcf4d1-8014-4008-8ce9-b891e6609927',
    name: 'LevelProp_Srx_Banner_VerticalThin6',
    targetName: 'ORDER_BOTLANE_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 6,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.6349156364721203,
    z: 0.12053824355966901,
    id: '8ca339d9-16b5-4be6-be12-6683d9132584',
    name: 'LevelProp_Srx_Banner_VerticalThin7',
    targetName: 'ORDER_BOTLANE_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 7,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.8192882643475868,
    z: 0.22089182226197399,
    id: '286ad0ea-6a36-4665-a318-a5d823f9bf7c',
    name: 'LevelProp_Srx_Banner_Vertical4',
    targetName: 'CHAOS_BOTLANE_VERT_COMBO_BANNER_2_2',
    attributes: {
      type: BannerType.Vertical,
      number: 4,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.8277066231622923,
    z: 0.22198578938612995,
    id: 'e33e18a3-10b1-491f-8bc1-fdd53eb2998f',
    name: 'LevelProp_Srx_Banner_VerticalThin11',
    targetName: 'CHAOS_BOTLANE_VERT_COMBO_BANNER_2_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 11,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.8110039899616125,
    z: 0.22321375867397517,
    id: '28b3b586-c1f9-429d-9b2d-267a29c9b6ee',
    name: 'LevelProp_Srx_Banner_VerticalThin12',
    targetName: 'CHAOS_BOTLANE_VERT_COMBO_BANNER_2_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 12,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9353407295010419,
    z: 0.2831979632123693,
    id: '5f09bcc2-376e-4510-93c7-a9e839fd0d51',
    name: 'LevelProp_Srx_Banner_Vertical5',
    targetName: 'CHAOS_BOTLANE_VERT_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 5,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.939454895227807,
    z: 0.5179451049036273,
    id: '1a8bb30e-f84d-4d88-a58e-5daf1c9fa73f',
    name: 'LevelProp_Srx_Banner_Vertical6',
    targetName: 'CHAOS_BOTLANE_VERT_COMBO_BANNER_1_2',
    attributes: {
      type: BannerType.Vertical,
      number: 6,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9369915244254634,
    z: 0.5268369780477932,
    id: 'd7c70f00-388c-4f64-b43e-74e263641ad0',
    name: 'LevelProp_Srx_Banner_VerticalThin15',
    targetName: 'CHAOS_BOTLANE_VERT_COMBO_BANNER_1_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 15,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9476323868670182,
    z: 0.5162486978005768,
    id: '24b3ead2-474f-4bc9-a65a-bdeefcc61125',
    name: 'LevelProp_Srx_Banner_VerticalThin16',
    targetName: 'CHAOS_BOTLANE_VERT_COMBO_BANNER_1_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 16,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.16697566761216448,
    z: 0.6905632800871992,
    id: '42b4ce7b-b8ee-47da-98be-16acd6bd1497',
    name: 'LevelProp_Srx_Banner_Vertical3',
    targetName: 'ORDER_WEST_JUNGLE_VERT_COMBO_BANNER_1_2',
    attributes: {
      type: BannerType.Vertical,
      number: 3,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.15885021980420494,
    z: 0.6891323719939503,
    id: '2a75bed1-56d3-414f-9553-dba21a3ef48a',
    name: 'LevelProp_Srx_Banner_VerticalThin10',
    targetName: 'ORDER_WEST_JUNGLE_VERT_COMBO_BANNER_1_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 10,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.17394591300184883,
    z: 0.694338227958814,
    id: '6e1dabf3-5a5d-4949-8f8e-95550f908238',
    name: 'LevelProp_Srx_Banner_VerticalThin17',
    targetName: 'ORDER_WEST_JUNGLE_VERT_COMBO_BANNER_1_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 17,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.553357666389292,
    z: 0.6112413696658475,
    id: '5e3742b1-464b-40c7-83aa-464cc53404d0',
    name: 'LevelProp_Srx_Banner_Vertical7',
    targetName: 'CHAOS_MIDLANE_VERT_BANNER_2',
    attributes: {
      type: BannerType.Vertical,
      number: 7,
      location: BannerLocation.MidLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.6153593077215204,
    z: 0.6810892034648252,
    id: '709d07f3-1c24-4ae3-b7ac-66e423d41151',
    name: 'LevelProp_Srx_Banner_Vertical8',
    targetName: 'CHAOS_MIDLANE_VERT_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 8,
      location: BannerLocation.MidLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.0367935434759436,
    z: 0.44475013449514555,
    id: 'a36fb506-2f69-4138-9e88-d6aa156af5e9',
    name: 'LevelProp_Srx_Banner_Vertical9',
    targetName: 'ORDER_TOPLANE_VERT_COMBO_BANNER_1_2',
    attributes: {
      type: BannerType.Vertical,
      number: 9,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.031150345017281426,
    z: 0.4401069243680506,
    id: '0fed979e-d257-4b5d-a1d7-9e963b681a80',
    name: 'LevelProp_Srx_Banner_VerticalThin19',
    targetName: 'ORDER_TOPLANE_VERT_COMBO_BANNER_1_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 19,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.03897113667318574,
    z: 0.45455061358945026,
    id: '064a8636-63ba-41f3-8ab9-f4eb152cdff6',
    name: 'LevelProp_Srx_Banner_VerticalThin20',
    targetName: 'ORDER_TOPLANE_VERT_COMBO_BANNER_1_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 20,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.037235463538530474,
    z: 0.684663758256961,
    id: '45f01c81-fed4-4959-892e-815aef118901',
    name: 'LevelProp_Srx_Banner_Vertical10',
    targetName: 'ORDER_TOPLANE_VERT_COMBO_BANNER_2_2',
    attributes: {
      type: BannerType.Vertical,
      number: 10,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.03061060555584105,
    z: 0.68081746540609,
    id: '1f7b7876-c1cc-4add-9d08-130fa08851ce',
    name: 'LevelProp_Srx_Banner_VerticalThin21',
    targetName: 'ORDER_TOPLANE_VERT_COMBO_BANNER_2_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 21,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.037008079757956794,
    z: 0.6921257900882654,
    id: 'aa7c4142-e7ef-4274-9a51-43f1166a9ef5',
    name: 'LevelProp_Srx_Banner_VerticalThin22',
    targetName: 'ORDER_TOPLANE_VERT_COMBO_BANNER_2_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 22,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.04298928703309077,
    z: 0.5531080452632979,
    id: '1db263f5-96d7-4153-8c13-36deab4665f3',
    name: 'LevelProp_Srx_Banner_VerticalThin23',
    targetName: 'ORDER_TOPLANE_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 23,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.042775118534395556,
    z: 0.6035772960944653,
    id: '8ade42e7-ca11-489b-b0fb-9a728b15ac8c',
    name: 'LevelProp_Srx_Banner_VerticalThin24',
    targetName: 'ORDER_TOPLANE_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 24,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.9299169441909915,
    z: 0.42008589715681044,
    id: 'e77d3a94-af6a-4fff-8308-0e943b0373e6',
    name: 'LevelProp_Srx_Banner_VerticalThin25',
    targetName: 'CHAOS_BOTLANE_HERO_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 25,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9301697717995909,
    z: 0.3635907110194729,
    id: 'ffddb219-2a8a-4a0c-907a-14ece36ec108',
    name: 'LevelProp_Srx_Banner_VerticalThin26',
    targetName: 'CHAOS_BOTLANE_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 26,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.3373482258041542,
    z: 0.9385519949730722,
    id: '05dded55-c531-4221-83a1-67dcff12b581',
    name: 'LevelProp_Srx_Banner_Vertical11',
    targetName: 'CHAOS_TOPLANE_VERT_BANNER_2',
    attributes: {
      type: BannerType.Vertical,
      number: 11,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.4833015823536384,
    z: 0.9410330998679067,
    id: '366b43ca-ea59-4c1f-b108-d2d9c5a8d037',
    name: 'LevelProp_Srx_Banner_Vertical12',
    targetName: 'CHAOS_TOPLANE_VERT_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 12,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.053234960817606196,
    z: 0.9036255081026496,
    id: '298fb7a9-1483-4d07-8a27-485e0f0b8ca7',
    name: 'LevelProp_Srx_Banner_Horizontal15',
    targetName: 'TOPLANE_ACLOVE_HORIZ_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 15,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.Alcove
    }
  },
  {
    x: 0.044896800693681994,
    z: 0.39078299158969737,
    id: '41af1b8d-8a68-4d49-a685-8de40b11a4a2',
    name: 'LevelProp_Srx_Banner_VerticalThin29',
    targetName: 'ORDER_TOPLANE_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 29,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.042772703523611066,
    z: 0.32288904688732556,
    id: '6bd4fa27-764a-4243-a06d-22b9b35b40c8',
    name: 'LevelProp_Srx_Banner_VerticalThin30',
    targetName: 'ORDER_BASE_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 30,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.05145732948691519,
    z: 0.7595172812280724,
    id: 'd5c147b0-f1e5-4d73-ba51-4e7b3c9369fa',
    name: 'LevelProp_Srx_Banner_VerticalThin31',
    targetName: 'ORDER_TOPLANE_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 31,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.3682276560478729,
    z: 0.12255561821184202,
    id: '05e34690-248c-46e2-9e0c-3acd637917be',
    name: 'LevelProp_Srx_Banner_Vertical13',
    targetName: 'ORDER_BOTLANE_HERO_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 13,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.5701263849696889,
    z: 0.11640750826544677,
    id: 'dfc57a58-2f5c-4120-84ef-70350c2da02e',
    name: 'LevelProp_Srx_Banner_Vertical14',
    targetName: 'ORDER_BOTLANE_HERO_BANNER_2',
    attributes: {
      type: BannerType.Vertical,
      number: 14,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.240719368461942,
    z: 0.9387125615847469,
    id: 'e29fe595-5427-40fc-a7b4-316a6547156e',
    name: 'LevelProp_Srx_Banner_VerticalThin36',
    targetName: 'CHAOS_TOPLANE_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 36,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.5589124423627102,
    z: 0.9441474197394845,
    id: 'e231b408-ddb1-47f6-a290-d13a785f7e50',
    name: 'LevelProp_Srx_Banner_VerticalThin37',
    targetName: 'CHAOS_TOPLANE_VERT_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 37,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.703576623695897,
    z: 0.9525787023696296,
    id: '7fa767c9-0d3c-4b84-9047-755c4d534be8',
    name: 'LevelProp_Srx_Banner_Horizontal18',
    targetName: 'CHAOS_BASE_HORIZ_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 18,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.8361470210683996,
    z: 0.952580221723515,
    id: '6cca7423-6804-4790-bc7b-2a877fd69366',
    name: 'LevelProp_Srx_Banner_Horizontal19',
    targetName: 'CHAOS_BASE_HORIZ_BANNER_3',
    attributes: {
      type: BannerType.Horizontal,
      number: 19,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9257831952484221,
    z: 1,
    id: 'af875e8b-3546-45a4-9c17-458da2fe7da6',
    name: 'LevelProp_Srx_Banner_Hero3',
    targetName: 'CHAOS_FOUNTAIN_HERO_BANNER_1',
    attributes: {
      type: BannerType.Hero,
      number: 3,
      location: BannerLocation.Fountain,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9777647684589872,
    z: 0.9488661773140293,
    id: 'c0bd6c03-694f-4966-9331-abc9fd679cce',
    name: 'LevelProp_Srx_Banner_Hero4',
    targetName: 'CHAOS_FOUNTAIN_HERO_BANNER_2',
    attributes: {
      type: BannerType.Hero,
      number: 4,
      location: BannerLocation.Fountain,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.7705307773793433,
    z: 0.9539792557118493,
    id: '5621b853-ab10-4676-9981-7027c4c5223d',
    name: 'LevelProp_Srx_Banner_Horizontal20',
    targetName: 'CHAOS_BASE_HORIZ_BANNER_2',
    attributes: {
      type: BannerType.Horizontal,
      number: 20,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.7368554922402011,
    z: 0.9508465742869333,
    id: '5b0013c0-4906-4a6b-8dc4-b39bdcb0392e',
    name: 'LevelProp_Srx_Banner_VerticalThin38',
    targetName: 'CHAOS_BASE_VERT_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 38,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.8038704049238217,
    z: 0.9508465742869333,
    id: '059babdb-ad03-4481-a5eb-883a37651c4e',
    name: 'LevelProp_Srx_Banner_VerticalThin39',
    targetName: 'CHAOS_BASE_VERT_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 39,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9383457006088765,
    z: 0.8635125936010892,
    id: '02c95906-ab1c-402a-93b3-f54720814ad8',
    name: 'LevelProp_Srx_Banner_Horizontal21',
    targetName: 'CHAOS_BASE_HORIZ_BANNER_4',
    attributes: {
      type: BannerType.Horizontal,
      number: 21,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9383457006088765,
    z: 0.7935256581049028,
    id: '70eeb1ba-ba4e-440e-a9ce-b7031141d3cb',
    name: 'LevelProp_Srx_Banner_Horizontal22',
    targetName: 'CHAOS_BASE_HORIZ_BANNER_5',
    attributes: {
      type: BannerType.Horizontal,
      number: 22,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9354502551737971,
    z: 0.7268760319079832,
    id: '6d1ff302-f5f3-451d-93c6-6f3f2057acdc',
    name: 'LevelProp_Srx_Banner_Horizontal23',
    targetName: 'CHAOS_BASE_HORIZ_BANNER_6',
    attributes: {
      type: BannerType.Horizontal,
      number: 23,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9349831603929293,
    z: 0.8271872667024027,
    id: '7b586af0-5c75-40ab-8dee-7166c78db73c',
    name: 'LevelProp_Srx_Banner_VerticalThin40',
    targetName: 'CHAOS_BASE_VERT_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 40,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9349831603929293,
    z: 0.7600764357845727,
    id: 'a3f133ee-8905-4b13-824d-e5c57e25ffb1',
    name: 'LevelProp_Srx_Banner_VerticalThin41',
    targetName: 'CHAOS_BASE_VERT_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 41,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.7528434018530034,
    z: 0.6760257465183682,
    id: '8f4bd391-3b1a-43bf-baf0-a5df24c7285c',
    name: 'LevelProp_Srx_Banner_Horizontal24',
    targetName: 'CHAOS_BASEGATE_WALL_HORIZONTAL_BANNER_5',
    attributes: {
      type: BannerType.Horizontal,
      number: 24,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.8259194474059833,
    z: 0.6569810011585235,
    id: '0f8d2d5d-eaa1-434a-8dc5-edcc951444bf',
    name: 'LevelProp_Srx_Banner_Horizontal25',
    targetName: 'CHAOS_BASEGATE_WALL_HORIZONTAL_BANNER_6',
    attributes: {
      type: BannerType.Horizontal,
      number: 25,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.6587012700521782,
    z: 0.7659661949579304,
    id: '7bacd777-c80f-449a-b414-9a8801aa8005',
    name: 'LevelProp_Srx_Banner_Horizontal26',
    targetName: 'CHAOS_BASEGATE_WALL_HORIZONTAL_BANNER_3',
    attributes: {
      type: BannerType.Horizontal,
      number: 26,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.6416592476017057,
    z: 0.839139668125268,
    id: '51db31bd-0507-4fe9-babf-874b3e5d6ca5',
    name: 'LevelProp_Srx_Banner_Horizontal27',
    targetName: 'CHAOS_BASEGATE_WALL_HORIZONTAL_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 27,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.6555958950952366,
    z: 0.8400663770153178,
    id: 'd065c08d-73a4-4204-99e5-9fa08e1c8916',
    name: 'LevelProp_Srx_Banner_Horizontal28',
    targetName: 'CHAOS_BASEGATE_WALL_HORIZONTAL_BANNER_2',
    attributes: {
      type: BannerType.Horizontal,
      number: 28,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.6714851869502756,
    z: 0.7715338511075863,
    id: '39fa25d4-8073-48ff-923f-d054f8e829f2',
    name: 'LevelProp_Srx_Banner_Horizontal29',
    targetName: 'CHAOS_BASEGATE_WALL_HORIZONTAL_BANNER_4',
    attributes: {
      type: BannerType.Horizontal,
      number: 29,
      location: BannerLocation.BaseGate,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.031312775672471885,
    z: 0.2042943357646659,
    id: '2969b9fd-0431-4a39-abd0-570e9e26d338',
    name: 'LevelProp_Srx_Banner_Horizontal30',
    targetName: 'ORDER_BASE_HORIZ_BANNER_2',
    attributes: {
      type: BannerType.Horizontal,
      number: 30,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.03372238931152148,
    z: 0.16789182083960014,
    id: '8378fd1c-5073-4a14-b268-9236db3ccdcb',
    name: 'LevelProp_Srx_Banner_VerticalThin27',
    targetName: 'ORDER_BASE_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 27,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.033722387318115964,
    z: 0.2409885181457081,
    id: '30e0e4d0-92ed-4269-80fc-02e92ea78f98',
    name: 'LevelProp_Srx_Banner_VerticalThin28',
    targetName: 'ORDER_BASE_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 28,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.20182482939247012,
    z: 0.015938347544895454,
    id: '14292300-f298-4c0a-b9c0-efdfe67043b9',
    name: 'LevelProp_Srx_Banner_Horizontal31',
    targetName: 'ORDER_BASE_HORIZ_BANNER_5',
    attributes: {
      type: BannerType.Horizontal,
      number: 31,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.1649854355962178,
    z: 0.008413877996998274,
    id: '5791fba1-c5c5-4465-b7c7-a8dfff61503c',
    name: 'LevelProp_Srx_Banner_VerticalThin42',
    targetName: 'ORDER_BASE_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 42,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.23715508763399867,
    z: 0.008413877996998274,
    id: '63cea17a-4131-4ac8-a5b6-a3036c17e279',
    name: 'LevelProp_Srx_Banner_VerticalThin43',
    targetName: 'ORDER_BASE_VERT_THIN_BANNER_5',
    attributes: {
      type: BannerType.VerticalThin,
      number: 43,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.48087626167581343,
    z: 0.42811211034310825,
    id: '7247aa65-4bcb-4007-bd27-148a9abd1fda',
    name: 'LevelProp_Srx_Banner_VerticalThin44',
    targetName: 'JUNGLE_EAST_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 44,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.5476263544232411,
    z: 0.3853430904731274,
    id: 'a7fdcd7e-702d-4bef-acca-e801956fef8c',
    name: 'LevelProp_Srx_Banner_VerticalThin45',
    targetName: 'JUNGLE_EAST_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 45,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.47179730421984245,
    z: 0.5483535828724465,
    id: 'b167f1b5-4f88-432e-b29d-e1ad037242e5',
    name: 'LevelProp_Srx_Banner_VerticalThin46',
    targetName: 'ORDER_WEST_JUNGLE_VERT_THIN_BANNER_6',
    attributes: {
      type: BannerType.VerticalThin,
      number: 46,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.42498952732018525,
    z: 0.5922359658736307,
    id: '10fe01cd-4098-4212-9522-a3ea2c87445c',
    name: 'LevelProp_Srx_Banner_VerticalThin47',
    targetName: 'ORDER_WEST_JUNGLE_VERT_THIN_BANNER_5',
    attributes: {
      type: BannerType.VerticalThin,
      number: 47,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.4481334119015513,
    z: 0.5628191087651138,
    id: '4b870c79-5206-40cd-9f01-0a411f27a317',
    name: 'LevelProp_Srx_Banner_Horizontal11',
    targetName: 'ORDER_WEST_JUNGLE_HORIZ_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 11,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.7181532978857526,
    z: 0.3602032468786491,
    id: '8a67fb30-e456-4b8c-ba89-0ccc9167e57c',
    name: 'LevelProp_Srx_Banner_Horizontal12',
    targetName: 'JUNGLE_EAST_HORIZ_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 12,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.22696986187839685,
    z: 0.8317718362348575,
    id: '56bc0876-e3af-47d0-958b-6a91076f195d',
    name: 'LevelProp_Srx_Banner_VerticalThin48',
    targetName: 'JUNGLE_NORTH_VERT_THIN_BANNER_6',
    attributes: {
      type: BannerType.VerticalThin,
      number: 48,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.North
    }
  },
  {
    x: 0.26089617258755066,
    z: 0.7924152690281133,
    id: '8bebf169-1282-4ed1-b186-4d7583c7e2a2',
    name: 'LevelProp_Srx_Banner_VerticalThin49',
    targetName: 'JUNGLE_NORTH_VERT_THIN_BANNER_5',
    attributes: {
      type: BannerType.VerticalThin,
      number: 49,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.North
    }
  },
  {
    // The x and z coordinates for these two banners in Baron's pit are not accurate
    // and had to be hardcoded to match the in-game location for the banner
    x: 0.329,
    z: 0.7303550581327425,
    id: 'bf300fed-ec51-43b2-a511-67856b6dce7d',
    name: 'LevelProp_Srx_Banner_VerticalThin50',
    targetName: 'BARON_PIT_VERT_COMBO_BANNER_1_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 50,
      location: BannerLocation.Baron
    }
  },
  {
    x: 0.371,
    z: 0.693321452416972,
    id: 'bfb244b8-e75c-46f5-91ee-fbfa206e341f',
    name: 'LevelProp_Srx_Banner_VerticalThin51',
    targetName: 'BARON_PIT_VERT_COMBO_BANNER_1_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 51,
      location: BannerLocation.Baron
    }
  },
  {
    x: 0.7455375227895213,
    z: 0.13432297364580473,
    id: '51c9f5b2-9843-4b8b-acbf-6f2308eb3438',
    name: 'LevelProp_Srx_Banner_Vertical16',
    targetName: 'ORDER_BOTLANE_VERT_COMBO_BANNER_1_2',
    attributes: {
      type: BannerType.Vertical,
      number: 16,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.7364288715649613,
    z: 0.13349140217032268,
    id: '6cea859e-adeb-4c0a-b209-adb409d67ffc',
    name: 'LevelProp_Srx_Banner_VerticalThin52',
    targetName: 'ORDER_BOTLANE_VERT_COMBO_BANNER_1_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 52,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.7505357399424452,
    z: 0.14192030878960446,
    id: '40c862ae-a270-415b-9104-02ce32f63361',
    name: 'LevelProp_Srx_Banner_VerticalThin53',
    targetName: 'ORDER_BOTLANE_VERT_COMBO_BANNER_1_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 53,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.7111910819334928,
    z: 0.5176875420933761,
    id: '624e1253-f0f0-466a-b0af-b7be04743cf0',
    name: 'LevelProp_Srx_Banner_VerticalThin55',
    targetName: 'JUNGLE_EAST_VERT_THIN_BANNER_5',
    attributes: {
      type: BannerType.VerticalThin,
      number: 55,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.7187771540765189,
    z: 0.5166342580940022,
    id: 'fbb21808-9ecf-4a4e-a93b-f914d2644afc',
    name: 'LevelProp_Srx_Banner_Vertical17',
    targetName: 'JUNGLE_EAST_VERT_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 17,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.5029635934911558,
    z: 0.8289629064942422,
    id: 'c1aec253-0b7c-4e6f-8576-d9c472cfa5d4',
    name: 'LevelProp_Srx_Banner_Vertical18',
    targetName: 'JUNGLE_NORTH_VERT_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 18,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.North
    }
  },
  {
    x: 0.44063162094380637,
    z: 0.7798848019418765,
    id: '14051264-1283-4e81-bb93-9dd1e5f9fa9c',
    name: 'LevelProp_Srx_Banner_VerticalThin56',
    targetName: 'JUNGLE_NORTH_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 56,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.North
    }
  },
  {
    x: 0.5074201943913444,
    z: 0.7576014729586366,
    id: 'cf80a809-05d4-4a2b-abd9-54855232fe38',
    name: 'LevelProp_Srx_Banner_VerticalThin57',
    targetName: 'JUNGLE_NORTH_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 57,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.North
    }
  },
  {
    x: 0.47239597971211134,
    z: 0.6190787142335206,
    id: '574051a0-1c6d-44d6-a948-62d1f43eb735',
    name: 'LevelProp_Srx_Banner_VerticalThin59',
    targetName: 'JUNGLE_NORTH_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 59,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.North
    }
  },
  {
    x: 0.422169033932565,
    z: 0.6756254129329083,
    id: '406986ff-93b1-418b-97a2-7bc4ee7b22f5',
    name: 'LevelProp_Srx_Banner_VerticalThin58',
    targetName: 'JUNGLE_NORTH_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 58,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.North
    }
  },
  {
    x: 0.6102098150106823,
    z: 0.3050833831110303,
    id: '443c3069-7c41-46ae-adc0-dbb942c234a3',
    name: 'LevelProp_Srx_Banner_VerticalThin60',
    targetName: 'DRAGON_PIT_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 60,
      location: BannerLocation.Dragon
    }
  },
  {
    x: 0.7343706245527564,
    z: 0.3446492170704775,
    id: '5fbf5891-fa77-458c-9fa7-b883b8070254',
    name: 'LevelProp_Srx_Banner_VerticalThin61',
    targetName: 'JUNGLE_EAST_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 61,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.5626045164315714,
    z: 0.2869627928336409,
    id: '746067f5-7e83-4b4e-b859-8b591580d97c',
    name: 'LevelProp_Srx_Banner_Horizontal13',
    targetName: 'JUNGLE_SOUTH_HORIZ_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 13,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.South
    }
  },
  {
    x: 0.44880680023261726,
    z: 0.21901532323348766,
    id: '5186b49e-d780-4237-bcde-bd4967352043',
    name: 'LevelProp_Srx_Banner_Vertical19',
    targetName: 'JUNGLE_SOUTH_VERT_BANNER_1',
    attributes: {
      type: BannerType.Vertical,
      number: 19,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.South
    }
  },
  {
    x: 0.3950111323176346,
    z: 0.18073509702949506,
    id: 'b362a0c0-f571-45a8-874d-a1da1b9e4a97',
    name: 'LevelProp_Srx_Banner_VerticalThin62',
    targetName: 'SOUTH_JUNGLE_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 62,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.South
    }
  },
  {
    x: 0.43632605639287,
    z: 0.3255678940740091,
    id: '34069040-b294-437e-90aa-9c17029454ef',
    name: 'LevelProp_Srx_Banner_VerticalThin64',
    targetName: 'JUNGLE_SOUTH_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 64,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.South
    }
  },
  {
    x: 0.4929732829270581,
    z: 0.35132609853650015,
    id: '3291c565-635e-4664-bf53-62c25583df1f',
    name: 'LevelProp_Srx_Banner_VerticalThin65',
    targetName: 'JUNGLE_SOUTH_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 65,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.South
    }
  },
  {
    x: 0.16028561530202423,
    z: 0.3765041684282012,
    id: 'd03be1eb-c9ba-46d4-8ee1-35965eee469b',
    name: 'LevelProp_Srx_Banner_VerticalThin66',
    targetName: 'ORDER_WEST_JUNGLE_VERT_THIN_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 66,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.2056016869848029,
    z: 0.36287989585120056,
    id: 'bf1504e3-a44a-4a17-898d-1d9ccab4a207',
    name: 'LevelProp_Srx_Banner_VerticalThin67',
    targetName: 'ORDER_WEST_JUNGLE_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 67,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.1765229637340462,
    z: 0.5833225631616422,
    id: 'b1909bf9-6cc8-43f0-90d9-94114607698b',
    name: 'LevelProp_Srx_Banner_VerticalThin68',
    targetName: 'ORDER_WEST_JUNGLE_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 68,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.23493161122525677,
    z: 0.5978048827637448,
    id: '2758b8aa-2163-40e3-bbe4-b090770e88d1',
    name: 'LevelProp_Srx_Banner_VerticalThin69',
    targetName: 'ORDER_WEST_JUNGLE_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 69,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.West
    }
  },
  {
    x: 0.016285920747564863,
    z: 0.5793702064788999,
    id: '3a2a67b0-a72f-4c8b-bb58-35521c16c371',
    name: 'LevelProp_Srx_Banner_Hero2',
    targetName: 'ORDER_TOPLANE_HERO_BANNER_1',
    attributes: {
      type: BannerType.Hero,
      number: 2,
      location: BannerLocation.TopLane,
      locationMeta: BannerLocationMeta.BlueSide
    }
  },
  {
    x: 0.632720753472122,
    z: 0.9410838204263359,
    id: '53158f4a-541a-447b-b0a7-7b6edfcc5359',
    name: 'LevelProp_Srx_Banner_VerticalThin73',
    targetName: 'CHAOS_BASE_VERT_THIN_BANNER_6',
    attributes: {
      type: BannerType.VerticalThin,
      number: 73,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.927214683671427,
    z: 0.3948655439697458,
    id: '04259735-792b-482c-88e7-6061db85a30b',
    name: 'LevelProp_Srx_Banner_Horizontal37',
    targetName: 'CHAOS_BOTLANE_HORIZ_BANNER_1',
    attributes: {
      type: BannerType.Horizontal,
      number: 37,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.9255867889895909,
    z: 0.5884134331313929,
    id: '3ef8891c-e61f-4457-9968-50d4f045a87a',
    name: 'LevelProp_Srx_Banner_VerticalThin74',
    targetName: 'CHAOS_BOTLANE_HERO_BANNER_1',
    attributes: {
      type: BannerType.VerticalThin,
      number: 74,
      location: BannerLocation.BotLane,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.924869067319815,
    z: 0.6594687976757894,
    id: 'b94319d3-b8f1-493f-b495-04befcdcb246',
    name: 'LevelProp_Srx_Banner_VerticalThin75',
    targetName: 'CHAOS_BASE_VERT_THIN_BANNER_5',
    attributes: {
      type: BannerType.VerticalThin,
      number: 75,
      location: BannerLocation.Base,
      locationMeta: BannerLocationMeta.RedSide
    }
  },
  {
    x: 0.7006752139915092,
    z: 0.3727259070720492,
    id: 'ee7c3d4e-c5b3-4b38-acf9-e02d3416788b',
    name: 'LevelProp_Srx_Banner_VerticalThin13',
    targetName: 'JUNGLE_EAST_VERT_THIN_BANNER_3',
    attributes: {
      type: BannerType.VerticalThin,
      number: 13,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.6294828250218867,
    z: 0.32213961750168957,
    id: '787e66e3-65e2-43d5-9920-3d30ca102c5f',
    name: 'LevelProp_Srx_Banner_VerticalThin14',
    targetName: 'DRAGON_PIT_VERT_THIN_BANNER_2',
    attributes: {
      type: BannerType.VerticalThin,
      number: 14,
      location: BannerLocation.Dragon
    }
  },
  {
    x: 0.7266619499967801,
    z: 0.5197245430801803,
    id: '98813ac0-c5da-4fdd-a809-5f53c983fd7d',
    name: 'LevelProp_Srx_Banner_VerticalThin8',
    targetName: 'JUNGLE_EAST_VERT_THIN_BANNER_6',
    attributes: {
      type: BannerType.VerticalThin,
      number: 8,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.5249397092258642,
    z: 0.16323524363098166,
    id: '91777807-3bd7-4e5c-bb4c-44210c18e7a8',
    name: 'LevelProp_Srx_Banner_VerticalThin9',
    targetName: 'JUNGLE_SOUTH_VERT_THIN_BANNER_4',
    attributes: {
      type: BannerType.VerticalThin,
      number: 9,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.South
    }
  },
  {
    x: 0.5089636005737256,
    z: 0.40046961612263343,
    id: 'cf54182c-dc7d-4fb1-9700-ba2a78c9fa93',
    name: 'LevelProp_Srx_Banner_Horizontal14',
    targetName: 'JUNGLE_EAST_HORIZ_BANNER_2',
    attributes: {
      type: BannerType.Horizontal,
      number: 14,
      location: BannerLocation.Jungle,
      locationMeta: BannerLocationMeta.East
    }
  },
  {
    x: 0.3572184601084108,
    z: 0.7138268186132618,
    id: 'b394ef60-8d75-4ac8-8afe-0874063a2c2e',
    name: 'LevelProp_Srx_Banner_Vertical15',
    targetName: 'BARON_PIT_VERT_COMBO_BANNER_1_2',
    attributes: {
      type: BannerType.Vertical,
      number: 15,
      location: BannerLocation.Baron
    }
  }
]

export const testBannerGroups: BannerGroup[] = [
  {
    texture: 'test_texture_1',
    banners: ['027a9808-d6a4-469e-9582-0497a69d2ee5']
  },
  {
    texture: '',
    banners: ['9075c72a-55c2-4b5a-942d-0a455e4bef8e']
  }
]

export const testBannerConfiguration: DeepPartial<BannerConfigurationDTO> = {
  league: '99332500638116286',
  patch: '10.1',
  banners: {
    '027a9808-d6a4-469e-9582-0497a69d2ee5': {
      texture: 'test_texture_1',
      group: 0,
      name: 'ORDER_MIDLANE_VERT_BANNER_1'
    },
    '749c717a-0551-4532-8f43-6d36c763efdb': {
      texture: '',
      group: null,
      name: 'ORDER_BASEGATE_WALL_HORIZONTAL_BANNER_1'
    },
    '9075c72a-55c2-4b5a-942d-0a455e4bef8e': {
      texture: '',
      group: 1,
      name: 'ORDER_FOUNTAIN_HERO_BANNER_1'
    }
  }
}

export const testPatches: string[] = ['10.1', '10.2']

export const testBannerLeagues: Record<string, League> = {
  '99180831287467561': {
    id: '4990093428461180318',
    eldsId: '99180831287467561',
    slug: 'lec',
    patches: [
      {
        patch: '10.2',
        configuration: {
          id: '3970037070389291957',
          url: 'http://dev-banners.lolesports.com/98767991332355509/10.2/v3/config.json',
          status: LeagueConfigurationStatus.NeedsScreenshots,
          version: 3
        }
      }
    ]
  },
  '99332500638116286': {
    id: '6723393773393844394',
    eldsId: '99332500638116286',
    slug: 'na-academy',
    patches: [
      {
        patch: '10.4',
        configuration: {
          id: '4625618659617712029',
          url: 'http://dev-banners.lolesports.com/98767991299243165/10.4/v4/config.json',
          status: LeagueConfigurationStatus.NeedsScreenshots,
          version: 4
        }
      }
    ]
  }
}

export const testFlattenedConfigs: FlattenedBannerConfig[] = Object.values(
  testBannerLeagues
).flatMap((league) =>
  league.patches.map((patch) => ({
    id: patch.configuration.id,
    leagueId: league.eldsId,
    patch: patch.patch,
    status: patch.configuration.status,
    version: `v${patch.configuration.version}`,
    url: patch.configuration.url
  }))
)
