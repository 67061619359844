import { createThunk } from '../../store/actions/utils'
import ViewerAccountApi from './ViewerAccountApi'

export const getPuuidsByDropId = createThunk(
  'viewerAccountApi/getPuuidsByDropId',
  ViewerAccountApi.fetchPuuidsByDropId
)

export const getFulfillmentInfoByTriggerIds = createThunk(
  'viewerAccountApi/getFulfillmentInfoByTriggerIds',
  ViewerAccountApi.fetchFulfillmentInfoByTriggerIds
)
