import { combineReducers, Reducer } from 'redux'
import { isEqual } from '../../../../../commons'
import { createSlice, createAction } from '../../../../actions/utils'
import { createLocalization } from '../../../../../services/DropsApi/DropsApi.actions'
import {
  resetInventoryItem,
  cloneInventoryAction,
  createInventoryItemSuccess
} from '../../../../actions/Drops/Drops.actions'
import { AnyPayloadAction, PayloadAction } from '../../../../Store.type'
import {
  Localizations,
  LocaleMove,
  LocaleUpdate,
  LocaleRemove
} from '../../../../../components/LocalizationTable/LocalizationTable.type'
import {
  initialLocalization,
  addLocalizationLocale,
  removeLocalizationLocale,
  editLocalizationLocale,
  changeLocalizationLocale,
  recordToLocalization
} from './LocalizationUtil'

const changeLocale = createAction<LocaleMove>(
  'inventoryLocalization/changeLocale'
)
const removeLocalization = createAction<LocaleRemove>(
  'inventoryLocalization/removeLocale'
)

const editInventoryTitle = createAction<LocaleUpdate>(
  'inventoryLocalization/editInventoryTitle'
)
const editInventoryDescription = createAction<LocaleUpdate>(
  'inventoryLocalization/editInventoryDescription'
)

const initialState: Localizations = initialLocalization

const editReducer = (
  state: Localizations,
  { payload }: PayloadAction<LocaleUpdate>
) => editLocalizationLocale(state, payload)

const addLocaleReducer = (
  state: Localizations,
  { payload }: PayloadAction<LocaleUpdate>
) => addLocalizationLocale(state, payload)

const removeReducer = (
  state: Localizations,
  { payload }: PayloadAction<LocaleRemove>
) => removeLocalizationLocale(state, payload)

const changeReducer = (
  state: Localizations,
  { payload }: PayloadAction<LocaleMove>
) => changeLocalizationLocale(state, payload)

const title = createSlice({
  name: 'inventoryLocalization',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [editInventoryTitle.type]: editReducer,
    [editInventoryDescription.type]: addLocaleReducer,
    [removeLocalization.type]: removeReducer,
    [changeLocale.type]: changeReducer,
    [createInventoryItemSuccess.type]: () => initialState,
    [resetInventoryItem.type]: () => initialState,
    [createLocalization.success.type]: () => initialState,
    [cloneInventoryAction.type]: (
      state,
      {
        payload
      }: PayloadAction<{
        localizations: {
          title: Record<string, string>
          description: Record<string, string>
        }
      }>
    ) => recordToLocalization(payload.localizations.title)
  } as Record<string, Reducer<Localizations, AnyPayloadAction>>
})

const description = createSlice({
  name: 'inventoryLocalization',
  initialState: initialState,
  extraReducers: {
    [editInventoryTitle.type]: addLocaleReducer,
    [editInventoryDescription.type]: editReducer,
    [removeLocalization.type]: removeReducer,
    [changeLocale.type]: changeReducer,
    [createInventoryItemSuccess.type]: () => initialState,
    [resetInventoryItem.type]: () => initialState,
    [createLocalization.success.type]: () => initialState,
    [cloneInventoryAction.type]: (
      state,
      {
        payload
      }: PayloadAction<{
        localizations: {
          title: Record<string, string>
          description: Record<string, string>
        }
      }>
    ) => recordToLocalization(payload.localizations.description)
  } as Record<string, Reducer<Localizations, AnyPayloadAction>>
})

const combinedReducer = combineReducers({
  title: title.reducer,
  description: description.reducer
})

export default combinedReducer

export {
  editInventoryTitle,
  editInventoryDescription,
  removeLocalization,
  changeLocale
}

type StateShape = ReturnType<typeof combinedReducer>

export const getWIPTitleLocalizations = (state: StateShape): Localizations =>
  state.title

export const getWIPDescriptionLocalizations = (
  state: StateShape
): Localizations => state.description

export const isLocalizationDirty = (state: StateShape): boolean =>
  !isEqual(state.title, initialState)
  || !isEqual(state.description, initialState)
